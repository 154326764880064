import { Capitalize } from "src/components/Paradise/sharedStyles"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"

export function OrderStatusBadge({
  status,
  size = "x-small",
}: {
  status: TParadiseOrder["status"]
  size?: MBadgeProps["size"]
}) {
  return (
    <MBadge size={size} color="warning">
      <Capitalize>{status}</Capitalize>
    </MBadge>
  )
}
