import styled from "styled-components"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { SmokingDetectionFeedback } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionFeedback"
import { SmokingDetectionToggleActiveIcon } from "src/components/Homes/HomeDetails/SmokingDetection/SmokingDetectionToggleActiveIcon"
import {
  ISmokingDetection,
  THome,
  Violations,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchSmokingDetection } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import HomeIcon from "src/ui/icons/home.svg"
import { BetaFeaturePill } from "src/ui/MBadge/BetaFeaturePill"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function SmokingDetectionPopover({
  homeId,
  anchorEl,
  violations,
  onClose,
  state = "disabled",
}: {
  homeId: THome["home_id"]
  onClose: () => void
  anchorEl: HTMLElement | null
  state: ISmokingDetection["state"] | undefined
  violations?: Violations[]
}) {
  const { org } = useOrganization()
  const { t, langKeys } = useTranslate()
  const patchSmokingDetection = usePatchSmokingDetection({ orgId: org.id })

  function handleTurnOffClick() {
    patchSmokingDetection.mutate({
      homeId,
      data: { state: "disabled" },
    })
    onClose()
  }

  function handleTurnOnClick() {
    patchSmokingDetection.mutate({
      homeId,
      data: { state: "enabled" },
    })
    onClose()
  }

  const enabled = state === "enabled"

  const buttons = [
    {
      icon: (
        <SmokingDetectionToggleActiveIcon
          toggleState="off"
          state={state}
          iconProps={{ height: "56" }}
        />
      ),
      onClick: () => {
        handleTurnOffClick()
      },
      label: t(langKeys.off),
      id: "off",
      disablePointerEvents: !enabled,
    },
    {
      icon: (
        <SmokingDetectionToggleActiveIcon
          toggleState="on"
          state={state}
          iconProps={{ height: "56" }}
        />
      ),
      onClick: () => {
        handleTurnOnClick()
      },
      label: (
        <div>
          <div>{t(langKeys.on)}</div>
          <BetaFeaturePill />
        </div>
      ),
      disableOnWarning: true,
      id: "on",
      disablePointerEvents: enabled,
    },
  ]

  return (
    <MonitoringPopup
      open={true}
      error={false}
      anchorEl={anchorEl}
      onClose={onClose}
      title={t(langKeys.smoking_detection_title)}
      description={t(langKeys.smoking_detection_short_description)}
      warning={t(langKeys.update_your_plan)}
      violations={violations}
      buttons={buttons}
      subtitle={
        <div>
          <StyledHomeIcon width="24px" height="24px" />
          {t(langKeys.home_indoor_label)}
        </div>
      }
    >
      <Grid>
        <Divider />
        <MText variant="bodyS" color="secondary">
          {t(langKeys.smoking_detection_disclaimer)}
        </MText>

        <Divider />
        <SmokingDetectionFeedback homeId={homeId} />
      </Grid>
    </MonitoringPopup>
  )
}

const Grid = styled.div`
  display: grid;
  margin-top: ${spacing.L};
  grid-gap: ${spacing.L};
`

const StyledHomeIcon = styled(HomeIcon)`
  margin-right: ${spacing.XS};
`
