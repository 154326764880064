import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { featureAvailabilityKeys } from "src/data/featureAvailability/queries/featureAvailabilityQueryCache"
import {
  FeatureAvailability,
  FeatureAvailabilityResponse,
} from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchFeatureAvailability({
  organizationId,
  options,
}: {
  organizationId: string
  options?: UseQueryOptions<
    /* the data type of what is kept in the cache: */
    FeatureAvailability[],
    /* Error type; since we use axios, it can be hard-coded to AxiosError: */
    AxiosError,
    /* the data type returned by the request (usually the same as cache data): */
    FeatureAvailability[],
    /* The data type of the query key: */
    ReturnType<typeof featureAvailabilityKeys.organization>
  >
}) {
  async function fetchFeatureAvailability(): Promise<FeatureAvailability[]> {
    const response = await minutApiHttpClient.get<FeatureAvailabilityResponse>(
      `${API_DEFAULT}/organizations/${organizationId}/feature_availability`
    )
    return response.data.features
  }

  return useQuery(
    featureAvailabilityKeys.organization(organizationId),
    fetchFeatureAvailability,
    {
      // Default to a long stale time as it's not something that changes often
      staleTime: 60 * 5 * 1000,
      ...options,
      enabled:
        options?.enabled !== undefined
          ? options.enabled && !!organizationId
          : !!organizationId,
    }
  )
}
