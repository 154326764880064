/** Download a file given a URL */
export function downloadFromUrl(fileUrl: string, filename?: string) {
  const hiddenLinkElement = document.createElement("a")
  hiddenLinkElement.href = fileUrl
  filename && hiddenLinkElement.setAttribute("download", filename)
  hiddenLinkElement.style.display = "hidden"
  hiddenLinkElement.click()
}

/** Convert any data to a file and download it
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export function downloadFile(data: any, filename: string, contentType: string) {
  const file = new File([data], filename, {
    type: contentType,
  })
  const fileUrl = URL.createObjectURL(file)
  downloadFromUrl(fileUrl, filename)
  URL.revokeObjectURL(fileUrl)
}
