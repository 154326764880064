import { components } from "@minuthq/minut-api-types/schema"
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  deviceKeys,
  useDeviceCache,
} from "src/data/devices/queries/deviceQueryCache"
import {
  HardwareType,
  TVirtualDevice,
} from "src/data/devices/types/deviceTypes"
import { eventKeys } from "src/data/events/queries/eventQueryCache"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export type IVirtualEnvironmentRequest =
  components["requestBodies"]["VirtualDeviceEnvironment"]["content"]["application/json"]

export function virtualDevicesKeys() {
  return ["virtualDevices"] as const
}

export function useFetchVirtualDevices({
  orgId,
  options,
}: {
  orgId: string
  options?: UseQueryOptions<
    TVirtualDevice[],
    AxiosError,
    TVirtualDevice[],
    ReturnType<typeof virtualDevicesKeys>
  >
}) {
  async function fetchVirtualDevices(orgId: string): Promise<TVirtualDevice[]> {
    const result = await minutApiHttpClient.get<{ devices: TVirtualDevice[] }>(
      `${API_DEFAULT}/virtual_devices/organizations/${orgId}/devices`
    )
    return result.data?.devices
  }

  return useQuery(virtualDevicesKeys(), () => fetchVirtualDevices(orgId), {
    enabled: !!orgId,
    ...options,
  })
}

interface TPostVirtualDeviceProps {
  orgId: string
  homeId: string
  description: string
  hardwareType?: HardwareType
  soundDuration?: number
  mac?: string
}
/**
 * Create a virtual device. The result is ignore at this time,
 * as the created device will be returned in the normal device query
 */
export function usePostVirtualDevice() {
  const { orgId } = useOrganization()
  const { invalidateCachedDeviceLists } = useDeviceCache(orgId)
  const queryClient = useQueryClient()

  async function postVirtualDevice({
    description,
    homeId,
    hardwareType,
    soundDuration,
    mac,
  }: TPostVirtualDeviceProps): Promise<void> {
    await minutApiHttpClient.post(
      `${API_DEFAULT}/virtual_devices/organizations/${orgId}/homes/${homeId}/devices`,
      {
        description,
        hardware_type: hardwareType?.toLowerCase(),
        sound_duration: soundDuration,
        mac: mac ?? undefined,
      }
    )
  }

  return useMutation<void, AxiosError<unknown>, TPostVirtualDeviceProps>({
    mutationFn: postVirtualDevice,
    onSuccess() {
      // Since this query creates a new device make sure to update the device list
      invalidateCachedDeviceLists()
      queryClient.invalidateQueries(virtualDevicesKeys())
    },
  })
}

/**
 * Sends a command to a virtual device to simulate a button press.
 * The result of the query is ignored, as the server doesn't return anything.
 */
export function usePostVirtualDeviceEvent() {
  const queryClient = useQueryClient()

  async function postVirtualDeviceEvent(
    deviceId: string,
    orgId: string,
    eventType: IVirtualEnvironmentRequest["event_type"]
  ): Promise<void> {
    await minutApiHttpClient.post(
      `${API_DEFAULT}/virtual_devices/organizations/${orgId}/devices/${deviceId}/environment`,
      {
        event_type: eventType,
      }
    )
  }

  return useMutation<
    void,
    AxiosError<unknown>,
    {
      deviceId: string
      orgId: string
      homeId: string
      eventType: IVirtualEnvironmentRequest["event_type"]
    }
  >(
    async ({ deviceId, orgId, eventType }) =>
      postVirtualDeviceEvent(deviceId, orgId, eventType),
    {
      onSuccess: (data, vars) => {
        return Promise.all([
          queryClient.invalidateQueries(
            homeKeys.homeDetail(vars.orgId, vars.homeId)
          ),
          queryClient.invalidateQueries(homeKeys.homeLists(vars.orgId)),
          queryClient.invalidateQueries(virtualDevicesKeys()),
          queryClient.invalidateQueries(eventKeys.all()),
          queryClient.invalidateQueries(
            deviceKeys.devices(vars.orgId, { home_ids: [vars.homeId] })
          ),
        ])
      },
    }
  )
}

/**
 * Sets the sound level of a virtual device. The result is
 * ignored, as the server doesn't return anything.
 */
export function usePostVirtualEnvironment() {
  const queryClient = useQueryClient()

  async function postVirtualEnvironment({
    deviceId,
    orgId,
    body,
  }: {
    deviceId: string
    orgId: string
    body: IVirtualEnvironmentRequest
  }) {
    await minutApiHttpClient.post(
      `${API_DEFAULT}/virtual_devices/organizations/${orgId}/devices/${deviceId}/environment`,
      body
    )
  }

  return useMutation<
    void,
    AxiosError,
    {
      deviceId: string
      orgId: string
      homeId: string
      body: IVirtualEnvironmentRequest
    }
  >(postVirtualEnvironment, {
    onSuccess: (_data, vars) => {
      queryClient.invalidateQueries(virtualDevicesKeys())
      queryClient.invalidateQueries(eventKeys.all())
      queryClient.invalidateQueries(
        deviceKeys.devices(vars.orgId, { home_ids: [vars.homeId] })
      )
      queryClient.invalidateQueries(
        orgsKeys.homeDetail(vars.orgId, vars.homeId)
      )
    },
  })
}
