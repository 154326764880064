import { useLocation } from "react-router-dom"

import { ILocationState } from "src/router/routeTypes"

export function useAppLocation() {
  /**
   * Do not remove undefined from here, the typing becomes wrong then.
   * Typescript thinks that location.state is always ILocationState, which is
   * not true, in reality it should be optional. However react router have not
   * typed it up corrected, therfore we need to give it ILocationState |
   * undefined to denote that it is a optional prop
   */
  // eslint-disable-next-line no-restricted-syntax
  return useLocation<ILocationState | undefined>()
}
