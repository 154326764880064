import styled from "styled-components"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { TParadiseOrdersSearchFilter } from "src/components/Paradise/ParadiseOrders/useParadiseOrdersFilter"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { Filter } from "src/ui/Filter/Filter"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrdersFilters({
  filter,
  setFilter,
}: {
  filter: TParadiseOrdersSearchFilter
  setFilter: TSetSearchParamsProp<TParadiseOrdersSearchFilter>
}) {
  return (
    <FilterWrapper>
      <Filter
        filters={[
          {
            id: "order_number",
            active: !!filter.orders_order_number,
            label: "Order number",
            value: filter.orders_order_number || "",
            render: (
              <FilterContent>
                <SearchFilter
                  initialValue={filter.orders_order_number ?? undefined}
                  placeholder="Order number"
                  onChange={(v) => setFilter("orders_order_number", v)}
                />
              </FilterContent>
            ),
            alwaysVisible: true,
            onRemove: () => {
              setFilter("orders_order_number", null)
            },
          },
          {
            id: "user_id",
            active: !!filter.orders_user_id,
            label: "User ID",
            value: filter.orders_user_id || "",
            render: (
              <FilterContent>
                <SearchFilter
                  initialValue={filter.orders_user_id ?? undefined}
                  placeholder="User ID"
                  onChange={(v) => setFilter("orders_user_id", v)}
                />

                <Note>
                  <MText variant="bodyS" color="tertiary">
                    *Full id is required
                  </MText>
                </Note>
              </FilterContent>
            ),
            alwaysVisible: true,
            onRemove: () => {
              setFilter("orders_user_id", null)
            },
          },
        ]}
      />
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  display: block;
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`

const Note = styled.div`
  margin-top: ${spacing.XS};
  margin-left: ${spacing.M};
`
