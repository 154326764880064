/* The 100–900 values roughly correspond to the following common weight names:
 *  100 — Thin / Extra Thin / Hairline
 *  200 — Extra Light / Ultra Light
 *  300 — Light
 *  400 — Regular (Normal)
 *  500 — Medium
 *  600 — Semi Bold / Demi Bold
 *  700 — Bold
 *  800 — Extra Bold / Ultra Bold
 *  900 — Black / Ultra Black / Heavy
 */
export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}
