import { useState } from "react"
import styled from "styled-components"

import { Badge } from "@material-ui/core"
import { useDebouncedCallback } from "use-debounce"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import {
  BoxBottom,
  DropDownButtonLabel,
  EmptyBox,
  ErrorBox,
  ItemsList,
  StyledSearch,
  StyledSelect,
} from "src/ui/DropdownSelect/sharedStyles"
import SearchIcon from "src/ui/icons/search.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function DropdownMultiSelect<
  V extends string,
  O extends { [key: string]: unknown } = Record<string, unknown>,
>({
  label,
  options = [],
  selectedValues,
  onChange,
  onSearch,
  onClearSelection,
  debounceDelay = 400,
  placeholder,
  loading,
  errorMsg,
  onClick,
}: {
  label: string
  options: TSelectOption<V, O>[]
  selectedValues: TSelectOption<V>["value"][]
  onChange: ({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<V, O>
  }) => void
  onSearch?: (s: string) => void
  onClearSelection?: () => void
  debounceDelay?: number
  placeholder?: string
  loading?: boolean
  errorMsg?: React.ReactNode
  onClick?: () => void
}) {
  const { t, langKeys } = useTranslate()
  const [textfieldValue, setTextfieldValue] = useState("")

  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearch?.(search),
    debounceDelay,
    {
      leading: true,
    }
  )

  function handleChangeTextField(s: string) {
    debouncedSearch(s)
    setTextfieldValue(s)
  }

  function handleOptionClick(option: TSelectOption<V, O>) {
    const isCurrentlyChecked = selectedValues.includes(option.value)

    onChange({ checked: !isCurrentlyChecked, option })
  }

  return (
    <Badge
      badgeContent={selectedValues.length}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <DropdownButton
        variant="secondary"
        displayValue={
          <DropDownButtonLabel values={selectedValues.length}>
            {label}
          </DropDownButtonLabel>
        }
        placement="bottom-start"
        onClick={onClick}
      >
        {() => (
          <StyledSelect>
            {onSearch && (
              <StyledSearch>
                <MTextField
                  value={textfieldValue}
                  onChange={(value) => handleChangeTextField(value)}
                  size="small"
                  endAdornment={
                    loading ? (
                      <MCircularProgress size={20} />
                    ) : (
                      <SearchIcon color={mColors.textInactive} width={18} />
                    )
                  }
                  placeholder={placeholder}
                />
              </StyledSearch>
            )}

            {options.length === 0 && textfieldValue.length > 0 && (
              <EmptyBox>
                <MText variant="bodyS" color="secondary">
                  {t(langKeys.search_no_matches_found)}
                </MText>
              </EmptyBox>
            )}

            <ItemsList
              $showBorderTop={!!onSearch}
              $showBorderBottom={!!onClearSelection}
              tabIndex={-1}
            >
              {options.map((option) => (
                <div key={option.value}>
                  <StyledMCheckbox
                    label={<StyledNanoText>{option.label}</StyledNanoText>}
                    checked={selectedValues.indexOf(option.value) > -1}
                    inputProps={{ name: option.value }}
                    onCheck={(checked) =>
                      !option.disabled &&
                      handleOptionClick({ ...option, checked })
                    }
                    disabled={option.disabled}
                    fullWidth
                  />
                </div>
              ))}
            </ItemsList>

            {!!errorMsg && (
              <ErrorBox>
                <MBanner type="error" size="small">
                  {errorMsg}
                </MBanner>
              </ErrorBox>
            )}

            {onClearSelection && (
              <BoxBottom>
                <TextButton
                  onClick={onClearSelection}
                  disabled={!onClearSelection || !selectedValues.length}
                >
                  {t(langKeys.clear_selection)}
                </TextButton>
              </BoxBottom>
            )}
          </StyledSelect>
        )}
      </DropdownButton>
    </Badge>
  )
}

const StyledMCheckbox = styled(MCheckbox)`
  padding: ${spacing.M} ${spacing.S} ${spacing.M} ${spacing.M};

  &:hover {
    background-color: ${mColors.neutral};
  }
`

const StyledNanoText = styled(MText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
