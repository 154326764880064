import { paths } from "@minuthq/minut-api-types/schema"

/*
 * The role within the organization the invited user will have.
 *
 * If not set the user will only see the homes they're explicit members of.
 *
 * If this field is set the user will have access to all homes, with the
 * corresponding home role, belonging to the organization.
 */
export type TOrganizationResponse =
  paths["/organizations/{organization_id}"]["get"]["responses"]["200"]["content"]["application/json"]
export type TOrganizationRole = NonNullable<TOrganizationResponse["user_role"]>

export enum HomeRole {
  ADMIN = "admin",
  MEMBER = "member",
  RESPONDER = "responder",
}

export enum OrganizationRank {
  RESPONDER,
  MEMBER,
  ADMIN,
  OWNER,
}

export interface IOrganizationMember {
  member_id: string
  email: string
  name: string
  role: TOrganizationRole
  user_id?: string // will be present if the member object is the same as the user that made the request
}

export interface IHomeMember {
  member_id: string
  email: string
  name: string
  role: HomeRole
  user_id?: string // will be present if the member object is the same as the user that made the request
  joined_at?: string
}
