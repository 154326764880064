import { useFetchFeatureAvailability } from "src/data/featureAvailability/queries/featureAvailabilityQueries"
import {
  Feature,
  FeatureAvailability,
} from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { ErrorService } from "src/utils/ErrorService"
import { logger } from "src/utils/logger"

/**
 * Use this hook to check if a feature is available
 * @param feature: the feature to check
 * @param refresh: whether to force a refresh, The refresh is to force a data
 * fetch from the backend since some feature availabilties are updated when an
 * action is done, example add home changes depending on amount of homes you
 * have
 * @returns
 */
export function useFeatureAvailability({
  feature,
  refresh,
}: {
  feature: FeatureAvailability["name"] | Feature.EDITFIELD_ALLOW
  refresh?: boolean
}) {
  const { org } = useOrganization()
  const fetchFeatureAvailability = useFetchFeatureAvailability({
    organizationId: org.id,
    options: {
      refetchOnMount: refresh ? "always" : true,
    },
  })

  const availability = getFeatureAvailability({
    feature,
    availabilities: fetchFeatureAvailability.data,
  })

  return {
    ready:
      !fetchFeatureAvailability.isInitialLoading ||
      !!fetchFeatureAvailability.data,
    ...availability,
  }
}

export function getFeatureAvailability({
  feature,
  availabilities,
}: {
  feature: FeatureAvailability["name"] | Feature.EDITFIELD_ALLOW
  availabilities: FeatureAvailability[] | undefined
}): Partial<FeatureAvailability> & Pick<FeatureAvailability, "available"> {
  // This is a temporary solution to solve weird edit field logic
  if (feature === Feature.EDITFIELD_ALLOW) {
    return { available: true }
  }

  if (!availabilities) {
    return { available: false }
  }

  const featureAvailability = availabilities.find(
    (featureAvailability) => featureAvailability.name === feature
  )

  if (!!featureAvailability) {
    return featureAvailability
  }

  const message = `Feature ${feature} is not in feature availabilities array`
  logger.warn(message)
  ErrorService.captureMessage(message)

  return { available: false }
}
