import { createRoot } from "react-dom/client"

import { initBackgroundMessageChannel } from "src/components/Notifications/notificationUtil"
import { initMobileAppAuth } from "src/data/auth/mobileAppAuth"
import { ErrorService } from "src/utils/ErrorService"

import { App } from "./App"

/** This is a workaround to suppress annoying deprecation warnings from React,
 * while we're working on removing them.
 *
 * To suppress the warnings, add the following line to the
 * `.env.development.local` file:
 * ```
 * REACT_APP_DISABLE_REACT_DEPRECATION_WARNINGS=1
 * ```
 */
function disableReactDeprecationWarnings() {
  if (import.meta.env.REACT_APP_DISABLE_REACT_DEPRECATION_WARNINGS) {
    // eslint-disable-next-line no-console
    const consoleError = console.error.bind(console)
    // eslint-disable-next-line no-console
    console.error = (errObj, ...args) => {
      const ignoreErrors = [
        "Support for defaultProps",
        "findDOMNode is deprecated",
        // add more warnings here as needed
      ]
      if (
        typeof errObj === "string" &&
        ignoreErrors.some((ignoreError) => errObj.includes(ignoreError))
      ) {
        return
      }
      consoleError(errObj, ...args)
    }
  }
}

disableReactDeprecationWarnings()

initMobileAppAuth()
initBackgroundMessageChannel()

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const rootElement = document.getElementById("root")
const root = createRoot(rootElement as HTMLElement)

ErrorService.init()

root.render(<App />)
