import styled from "styled-components"

export function DefaultBox({
  size = "medium",
  children,
}: {
  size?: TDefaultBoxWidth
  children: React.ReactNode
}) {
  return <Box size={size}>{children}</Box>
}

const Box = styled.div<{ size: TDefaultBoxWidth }>`
  max-width: ${(props) => {
    switch (props.size) {
      case "small":
        return "700px"
      case "medium":
        return "940px"
      case "large":
        return "1300px"
      default:
        return "unset"
    }
  }};
  margin-bottom: 10vh;
`

export type TDefaultBoxWidth = "small" | "medium" | "large" | "unset"
