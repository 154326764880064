import { useTranslate } from "src/i18n/useTranslate"
import { DeviceStatusIcon } from "src/ui/DeviceStatusIcon/DeviceStatusIcon"
export function OnlineStatus({ online }: { online: boolean }) {
  const { t, langKeys } = useTranslate()

  const color = online ? "good" : "bad"

  const label = online
    ? t(langKeys.sensor_status_online)
    : t(langKeys.sensor_status_offline)

  return (
    <DeviceStatusIcon color={color}>
      <div>{label}</div>
    </DeviceStatusIcon>
  )
}
