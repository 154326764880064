import { components, paths } from "@minuthq/minut-api-types/schema"

import {
  deviceReadingType,
  p1DeviceReadingType,
  p2DeviceReadingType,
} from "src/data/devices/logic/deviceConstants"
import {
  IBasePaginatedEndpoint,
  IPagingFilter,
} from "src/data/pagination/types/paginationTypes"

export const DEFAULT_DEVICE_DESCRIPTION = ""
export const DEFAULT_REPORT_INTERVAL: TReportInterval = "default"

/** Interface for the Device object as returned from the Backend */
export type TDevice = components["schemas"]["Device"] & {
  home: string // XXX: The BE type includes `undefined` which our code isn't equipped to deal with
  // Local device typ extensions; these are missing from the BE type:
  listening_mode: TListeningMode
  nightlight_mode?: "timebased" | "off"
}

export type TDeviceConfiguration = NonNullable<TDevice["configuration"]>

export type TVirtualDevice = NonNullable<
  paths["/virtual_devices/organizations/{organization_id}/devices"]["get"]["responses"]["200"]["content"]["application/json"]["devices"]
>[number]

export type TVirtualDeviceEnvironment = NonNullable<
  paths["/virtual_devices/organizations/{organization_id}/devices/{device_id}/environment"]["post"]["requestBody"]["content"]["application/json"]
>

export type TSignalQuality = NonNullable<TDevice["cellular"]>["signal_quality"]

export type TListeningMode =
  paths["/devices/{device_id}"]["put"]["requestBody"]["content"]["application/json"]["listening_mode"]

export type TAlarmRecognition =
  paths["/devices/{device_id}"]["put"]["requestBody"]["content"]["application/json"]["alarm_recognition"]

export type TReactions = NonNullable<
  TDevice["configuration"]
>["reactions"][number]

export type TReportInterval = NonNullable<TDevice["report_interval"]>

export type TMountStatus = NonNullable<TDevice["mount_status"]>

export type TChargeStatus = NonNullable<TDevice["charge_status"]>

export type TReactionType = NonNullable<
  TDevice["configuration"]
>["reactions"][number]["type"]

/**
 * String of format '2019-08-23T13:57:44.204Z'.
 *
 *  N.B: This is just an alias, no type checking is performed!
 */
export type TDateISO = string

export interface IFetchDevices extends IBasePaginatedEndpoint {
  devices: TDevice[]
}

export type TMouldRiskLevel = TDevice["mould_risk_level"]

export enum HardwareType {
  P1 = "P1",
  P2 = "P2",
  P3 = "AMARILLO",
  A1 = "EUREKA",
  WLD = "wld_minew",
  NONE = "NONE",
}

export enum HardwareVersionBoundary {
  P1_BOUNDARY_START = 1,
  P1_BOUNDARY_END = 99,
  P2_BOUNDARY_START = 100,
  P2_BOUNDARY_END = 199,
  P3_BOUNDARY_START = 200,
  P3_BOUNDARY_END = 299,
  A1_BOUNDARY_START = 300,
  A1_BOUNDARY_END = 399,
  WLD_BOUNDARY_START = 10100,
  WLD_BOUNDARY_END = 10199,
}

export type TExportType = "sound" | "motion" | "temperature" | "humidity"

export type DeviceReadingType =
  | (typeof deviceReadingType)[number]
  | (typeof p1DeviceReadingType)[number]
  | (typeof p2DeviceReadingType)[number]

export interface IDeviceReadings {
  unit?: "c" | "%" | "hPa"
  values: {
    value: number | undefined
    min?: number
    max?: number
    datetime: string | Date
  }[]
  time_resolution?: number
}

export interface IDeviceMotionReadings {
  values: [number, number][]
}

export interface IDeleteDeviceError {
  error_key: "insufficient_access"
}

export interface IDevicesFilter extends IPagingFilter {
  active?: boolean
  home_ids?: string[]
  ids?: string[]
  limit?: number
  low_battery?: boolean
  offline?: boolean
  offset?: number
  smoking_detection?: boolean
  sort?: "home_name" | "battery"
}
