import { useCallback, useState } from "react"

import {
  RecentlyViewedContext,
  TAddRecentlyViewedPageArgs,
  TRecentlyViewedPage,
  TRecentlyViewedPagesStorage,
} from "src/components/RecentlyViewed/RecentlyViewedContext"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { localStorageFactory } from "src/utils/storageUtil"

const MAX_PAGES = 50

const recentlyViewedStorage = localStorageFactory<TRecentlyViewedPagesStorage>({
  key: "minut.paradise.recentlyViewedPages",
})

function initilizeState(userId: string) {
  const stored = recentlyViewedStorage.get()?.[userId]

  if (stored) {
    return stored
  }

  return []
}

export function RecentlyViewedProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { user_id } = useGetUser()

  const [recentlyViewedPages, setRecentlyViewedPages] = useState<
    TRecentlyViewedPage[]
  >(() => initilizeState(user_id))

  const addPage = useCallback(
    (page: TAddRecentlyViewedPageArgs) => {
      setRecentlyViewedPages((prev) => {
        const alreadyLoggedIndex = prev.findIndex((p) => p.path === page.path)
        const length = prev.length

        const newPage: TRecentlyViewedPage = {
          ...page,
          timestamp: new Date().toISOString(),
        }

        let updatedRecentlyViewedPages: TRecentlyViewedPage[]

        // If the page already has been logged we bump it to the top
        if (alreadyLoggedIndex !== -1) {
          updatedRecentlyViewedPages = [
            newPage,
            ...prev.toSpliced(alreadyLoggedIndex, 1),
          ]
          // Remove the oldest one if the limit is reaches
        } else if (length === MAX_PAGES) {
          updatedRecentlyViewedPages = [newPage, ...prev.slice(0, -1)]
          // Else add it to the top
        } else {
          updatedRecentlyViewedPages = [newPage, ...prev]
        }

        recentlyViewedStorage.set({
          ...recentlyViewedStorage.get(),
          [user_id]: updatedRecentlyViewedPages,
        })

        return updatedRecentlyViewedPages
      })
    },
    [user_id]
  )

  const clear = useCallback(() => {
    setRecentlyViewedPages([])
    recentlyViewedStorage.clear()
  }, [])

  return (
    <RecentlyViewedContext.Provider
      value={{
        recentlyViewedPages,
        addPage,
        clear,
      }}
    >
      {children}
    </RecentlyViewedContext.Provider>
  )
}
