import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"
import { TMButtonProps } from "src/ui/Button/MButton"

export interface IOption {
  name: string
  value: string
  onClick: () => void
}

export function DropdownListButton({
  displayValue,
  options,
  listSubheader,
  hidden,
  loading,
  ...props
}: {
  displayValue: string
  options?: IOption[]
  listSubheader?: string
  hidden?: boolean
  loading?: boolean
} & TMButtonProps) {
  const { t, langKeys } = useTranslate()

  async function handleTemplateClick(closeMenu: () => void, option: IOption) {
    const { onClick } = option
    closeMenu()
    onClick()
  }

  if (hidden) {
    return null
  }

  const subheader = listSubheader ? (
    <ListSubheader component="div" id="nested-list-subheader">
      {listSubheader}
    </ListSubheader>
  ) : undefined

  return (
    <DropdownButton
      displayValue={displayValue}
      placement="bottom-end"
      {...props}
    >
      {({ closeMenu }) => {
        return (
          <List subheader={subheader}>
            {loading && (
              <ListItem disabled>
                <ListItemText primary={t(langKeys.loading)} />
              </ListItem>
            )}
            {options?.map((option) => {
              return (
                <ListItem
                  button
                  onClick={() => handleTemplateClick(closeMenu, option)}
                  key={option.value}
                >
                  <ListItemText primary={option.name} />
                </ListItem>
              )
            })}
          </List>
        )
      }}
    </DropdownButton>
  )
}
