import { useEffect } from "react"

import Intercom from "@intercom/messenger-js-sdk"

import { useAppData } from "src/context/useAppData"

export function useInit() {
  const { ready, user } = useAppData()

  // Initilize Intercom
  useEffect(() => {
    if (!ready || !import.meta.env.REACT_APP_INTERCOM_APP_ID) return

    // Only initilize Intercom for logged in users
    if (user) {
      Intercom({
        app_id: import.meta.env.REACT_APP_INTERCOM_APP_ID,
        // Make sure we hide the floating button, but the post message will still render
        hide_default_launcher: true,
      })
    }
  }, [ready, user])
}
