import { createContext } from "react"

import { FeatureFlag } from "src/constants/featureFlags"
import { debug } from "src/utils/logger"

export type TFlags = {
  [key in FeatureFlag]: boolean
}

export type TFlagOverride =
  | {
      value: boolean | undefined
      reason: React.ReactNode
      flag: FeatureFlag
      enforced?: boolean
    }
  | undefined

export const FlagContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
  isEnabled: (
    _flag: FeatureFlag,
    _options?: { localStorageOnly?: boolean }
  ): boolean => false,
  setFeature: (_flag: FeatureFlag, _value: boolean) =>
    debug.log("provider not initialized"),
  flags: {} as TFlags,
  getOverride: (_flag: FeatureFlag): TFlagOverride => undefined,
  /** `toggles` contains data that can be dynamically set after the FlagContext
   * has been loaded */
  get toggles() {
    return {
      get roles(): string[] {
        return []
      },
      setRoles: (_role: string[]) => {},
    }
  },
})
