import { usePostTrackEventWithParams } from "./analyticsQueries"

export type THomegroupEventTypes =
  | THomegroupLocationEventTypes
  | THomegroupBuildingEventTypes

function usePostHomegroupTrackingEvent<TEventProperties>(
  event: THomegroupEventTypes
) {
  return usePostTrackEventWithParams<THomegroupEventTypes, TEventProperties>({
    event,
  })
}

type THomegroupLocationEventTypes =
  | "Change Location Address Clicked"
  | "Add Location Address Clicked"
  | "Add Location Address Initiated"
  | "Add Location Address Confirmed"

export function usePostHomegroupChangeAddressClicked() {
  const x = usePostHomegroupTrackingEvent("Change Location Address Clicked")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressClicked() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Clicked")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressInitiated() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostHomegroupAddAddressConfirmed() {
  const x = usePostHomegroupTrackingEvent("Add Location Address Confirmed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

type THomegroupBuildingEventTypes =
  | "Create Building Clicked"
  | "Create Building Initiated"
  | "Building Address Initiated"
  | "Building Address Confirmed"
  | "Create Building Confirmed"

export function usePostCreateBuildingClicked() {
  const x = usePostHomegroupTrackingEvent("Create Building Clicked")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostCreateBuildingInitiated() {
  const x = usePostHomegroupTrackingEvent("Create Building Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostBuildingAddressInitiated() {
  const x = usePostHomegroupTrackingEvent("Building Address Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostBuildingAddressConfirmed() {
  const x = usePostHomegroupTrackingEvent("Building Address Confirmed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostCreateBuildingConfirmed() {
  const x = usePostHomegroupTrackingEvent("Create Building Confirmed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}
