import { HTMLAttributes } from "react"

import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"

export function TabPanel({ children, value, index, ...rest }: ITabPanel) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}
    >
      <ErrorBoundary>{value === index && children}</ErrorBoundary>
    </div>
  )
}

export interface ITabPanel extends HTMLAttributes<React.ReactNode> {
  value?: number
  index?: number
  children: React.ReactNode
}
