import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"
import { browserHistory, Routes } from "src/router/routes"
import { Logger } from "src/utils/logger"
import { hasNativeNotificationSound } from "src/utils/systemUtil"

export type TMNotificationParams = {
  title: string
  onClick?: (e: Event) => void
} & NotificationOptions

export type TNotificationPermission =
  | NotificationPermission
  | PermissionState
  | "unsupported"
  | "unknown"

const notificationLoggerEnabled = isStoredFeatureEnabled(
  FeatureFlag.LOGGING_DEBUG
)
export const notificationLogger = new Logger({
  enabled: notificationLoggerEnabled,
  prefix: "[notification]",
})

export function getBrowserNotificationState(): TNotificationPermission {
  if ("Notification" in window) {
    try {
      // eslint-disable-next-line no-restricted-globals
      return Notification.permission
    } catch {
      notificationLogger.error("Your browser doesn't support notifications.")
      return "unsupported"
    }
  }
  notificationLogger.error("Your browser doesn't support notifications.")
  return "unsupported"
}

// https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus
export const browserPermissionStatus = {
  value: getBrowserNotificationState(),
  async init() {
    const permissionStatus = await navigator.permissions.query({
      name: "notifications",
    })
    notificationLogger.info(
      `notification permission status is ${permissionStatus.state}`
    )
    permissionStatus.onchange = () => {
      notificationLogger.info(
        `notifications permission status has changed to ${permissionStatus.state}`
      )
      this.value = permissionStatus.state
    }
    return permissionStatus
  },
  async requestPermission(enable: boolean) {
    this.init()
    if (enable) {
      return await requestNotificationPermission()
    }
    return this.value
  },
  get granted() {
    return this.value === "granted"
  },
}

export function showMNotification(params: TMNotificationParams) {
  const { title, onClick, ...options } = params

  try {
    // eslint-disable-next-line no-restricted-globals
    const n = new Notification(title, { icon: "/logo192.png", ...options })
    n.onclick = onClick ?? null
    playNotificationSound()
  } catch (e) {
    notificationLogger.error("Your browser doesn't support notifications.")
  }
}

export async function requestNotificationPermission(): Promise<TNotificationPermission> {
  try {
    // eslint-disable-next-line no-restricted-globals
    return await Notification.requestPermission()
  } catch (e) {
    notificationLogger.error("Your browser doesn't support notifications.")
    return "unsupported"
  }
}

function playSound(url: string) {
  const audio = new Audio(url)
  audio.play().catch((e) => notificationLogger.info(e))
}

function playNotificationSound() {
  notificationLogger.info("playing notification sound")
  if (hasNativeNotificationSound()) return
  playSound("/SoftNotifUp.mp3")
}

function navigateToHome(event: NavigateEvent) {
  const targetPath = Routes.Home.location(event.data.homeId).pathname
  const url = new URL(targetPath, window.location.origin)
  event.data.orgId && url.searchParams.set("organization", event.data.orgId)

  notificationLogger.log("redirecting to", url)
  return browserHistory.push({ pathname: url.pathname, search: url.search })
}

/** Sets up a communication channel between service worker and app. */
export function initBackgroundMessageChannel() {
  const swMessageChannel = new BroadcastChannel("sw-message")
  notificationLogger.info("Created SW message channel")

  swMessageChannel.onmessage = (event: SWMessageEvent) => {
    if (isSoundEvent(event)) {
      return playNotificationSound()
    }
    if (isNavigateEvent(event)) {
      return navigateToHome(event)
    }

    return notificationLogger.error("unhandled broadcast message", event)
  }
  return swMessageChannel
}

type SoundEvent = MessageEvent<{ type: "play-notification-sound" }>
type NavigateEvent = MessageEvent<{
  type: "navigate"
  homeId: string
  orgId: string | undefined
}>
type SWMessageEvent = SoundEvent | NavigateEvent

function isSoundEvent(event: SWMessageEvent): event is SoundEvent {
  return event.data.type === "play-notification-sound"
}
function isNavigateEvent(event: SWMessageEvent): event is NavigateEvent {
  return (
    event.data?.type === "navigate" && typeof event.data?.homeId === "string"
  )
}
