import { captureConsoleIntegration } from "@sentry/integrations"
// eslint-disable-next-line no-restricted-imports
import * as Sentry from "@sentry/react"

import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"

const ErrorSeverityLevel = {
  info: "info",
  warning: "warning",
  error: "error",
  fatal: "fatal",
  debug: "debug",
} as const

type TErrorSeverityLevel = keyof typeof ErrorSeverityLevel

interface TErrorServiceUser {
  id: string
  email: string
  name: string
  impersonating: boolean
}

interface TMetaData {
  severityLevel?: TErrorSeverityLevel
  user?: TErrorServiceUser
  context?: {
    [key: string]: Record<string, unknown>
  }
  tags?: {
    [key: string]: string | number
  }
}

const sentryIntegrations = isStoredFeatureEnabled(
  FeatureFlag.LOGGING_SENTRY_CAPTURE_CONSOLE
)
  ? // https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole
    [captureConsoleIntegration()]
  : []

function init() {
  Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    release: import.meta.env.REACT_APP_SENTRY_RELEASE,
    environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: sentryIntegrations,
  })
}

function captureException(error: Error | unknown, metaData?: TMetaData) {
  const { context = {}, tags = {}, severityLevel, user } = metaData || {}

  Sentry.captureException(error, {
    tags: prefixKeys(tags),
    contexts: prefixKeys(context),
    user,
    level: severityLevel,
  })
}

function captureMessage(message: string, metaData?: TMetaData) {
  const { context = {}, tags = {}, severityLevel, user } = metaData || {}

  Sentry.captureMessage(message, {
    tags: prefixKeys(tags),
    contexts: prefixKeys(context),
    user,
    level: severityLevel,
  })
}

function setUser({ user }: { user: TErrorServiceUser | null }) {
  // Clear if user is null
  if (!user) {
    return Sentry.setUser(null)
  }

  Sentry.setUser(user)
}

function setContext({
  name,
  context,
}: {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  context: Record<string, any>
}) {
  Sentry.setContext(`minut-${name}`, context)
}

function setTags(tags: Record<string, string | number>) {
  Sentry.setTags(prefixKeys(tags))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
function prefixKeys(data: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [`minut-${key}`, value])
  )
}

export const ErrorService = {
  init,
  captureException,
  captureMessage,
  setUser,
  setContext,
  setTags,
}
