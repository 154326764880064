import { AxiosError } from "axios"

export function isClientError(errno?: number): boolean {
  return !!errno && errno >= 400 && errno < 500
}

export function defaultRetryHandler(
  failureCount: number,
  error: AxiosError | unknown
): boolean {
  const axiosError = error as AxiosError
  if (!axiosError.response) {
    return false
  }

  const errno = axiosError.response?.status
  switch (errno) {
    case 408:
    case 502:
    case 503:
    case 504:
      return failureCount <= 2
    default:
      return false
  }
}
