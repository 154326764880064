import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useDeleteMultipleHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"

export function DeleteMultipleHomesDialog({
  open,
  onClose,
  homes,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  homes: THome[]
  onSuccess: () => void
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const deleteMultipleHomes = useDeleteMultipleHomes({ orgId })

  function handleDelete() {
    deleteMultipleHomes.mutate(
      { home_ids: homes.map((home) => home.home_id).join(",") },
      { onSuccess }
    )
  }

  return (
    <MDialog
      open={open}
      onClose={onClose}
      title={t(langKeys.delete_multiple_rental_units, {
        count: homes.length,
      })}
      description={t(langKeys.delete_multiple_rental_units_description)}
      onConfirm={handleDelete}
      confirmLabel={t(langKeys.delete)}
      confirmButtonProps={{
        color: "destructive",
        loading: deleteMultipleHomes.isLoading,
      }}
      hideClose
      error={
        deleteMultipleHomes.isError && t(langKeys.failed_something_went_wrong)
      }
    />
  )
}
