import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"

/** Get the access matrix for the current user.
 *
 * NB: This function will be updated internally to use the permission
 * matrix from the BE, once they have implemented the endpoint.
 */
export function usePermissions() {
  const role = useOrganization().org.user_role
  const { hasMemberAccess, hasAdminAccess } = getAccessLogic({ role })

  return {
    reports: hasMemberAccess,
    indoorClimateModify: hasAdminAccess,
  }
}
