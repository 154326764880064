import { useQueryClient } from "@tanstack/react-query"

import { FeatureAvailability } from "src/data/featureAvailability/types/featureAvailabilityTypes"

export const featureAvailabilityKeys = {
  /** Use to target *all* featureAvailability data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["featureAvailability"] as const
  },

  /** Use to target one specific user/organization feature availability data */
  organization(organizationId: string) {
    return [...this.all(), "organization", organizationId] as const
  },

  /** Use to target one specific user/organization feature availability data */
  home(homeId: string) {
    return [...this.all(), "home", homeId] as const
  },
}

// ts-prune-ignore-next
export function useFeatureAvailabilityCache() {
  const queryClient = useQueryClient()

  function updateCachedOrganization(
    organizationId: string,
    updater: (
      featureAvailabilities: FeatureAvailability[] | undefined
    ) => FeatureAvailability[] | undefined
  ) {
    const cacheKey = featureAvailabilityKeys.organization(organizationId)

    return queryClient.setQueryData<FeatureAvailability[] | undefined>(
      cacheKey,
      updater
    )
  }

  function updateCachedHome(
    homeId: string,
    updater: (
      featureAvailabilities: FeatureAvailability[] | undefined
    ) => FeatureAvailability[] | undefined
  ) {
    const cacheKey = featureAvailabilityKeys.home(homeId)

    return queryClient.setQueryData<FeatureAvailability[] | undefined>(
      cacheKey,
      updater
    )
  }

  return { updateCachedOrganization, updateCachedHome }
}
