import { useAppData } from "src/context/useAppData"
import { useFetchFeatureAvailability } from "src/data/featureAvailability/queries/featureAvailabilityQueries"

/** Fetches and caches available features for later use */
export function useFeatureAvailabilityCache() {
  const { loading: appDataLoading, activeOrg } = useAppData()
  const appReady = !appDataLoading

  const fetchFeatureAvailability = useFetchFeatureAvailability({
    organizationId: activeOrg?.id ?? "",
    options: { enabled: Boolean(appReady && activeOrg?.id) },
  })

  const loading =
    (fetchFeatureAvailability.isInitialLoading &&
      !fetchFeatureAvailability.data) ||
    !appReady

  const error = fetchFeatureAvailability.error
  const data = fetchFeatureAvailability.data
  return { loading, error, data }
}
