import { TUser } from "src/data/user/user"

export function getTemperatureUnitWithFallback(user: TUser) {
  const unit = user.temperature_unit

  if (unit) return unit

  if (navigator.language === "en-US") {
    return "F"
  } else {
    return "C"
  }
}
