import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getTemperatureUnitWithFallback } from "src/data/user/logic/userTemperature"
import {
  formatTemperatureString,
  formatTemperatureUnitString,
} from "src/utils/l10n"

export function LocalizedTemperature({
  value,
  decimals = 1,
  className = "",
}: {
  value: number | undefined
  decimals?: number
  className?: string
}) {
  const user = useGetUser()
  const unit = getTemperatureUnitWithFallback(user)

  return (
    <span className={className}>
      {value === undefined
        ? `- ${formatTemperatureUnitString(unit)}`
        : String(formatTemperatureString(value, unit, decimals))}
    </span>
  )
}

// eslint-disable-next-line import/no-default-export
export default LocalizedTemperature
