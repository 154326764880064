import {
  OrganizationRank,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"

/** Get the access rank of a role */
export function getOrgAccessRank(
  role: TOrganizationRole | undefined
): OrganizationRank {
  switch (role) {
    case "owner":
      return OrganizationRank.OWNER
    case "admin":
      return OrganizationRank.ADMIN
    case "member":
      return OrganizationRank.MEMBER
    default:
      return OrganizationRank.RESPONDER
  }
}

/** @deprecated check `accessLogic.ts` module for appropriate function */
export function hasOrgAccess(
  role: TOrganizationRole | undefined,
  minLevel: TOrganizationRole = "admin"
): boolean {
  if (role === undefined) {
    return false
  }
  const rank = getOrgAccessRank(role)
  const minRank = getOrgAccessRank(minLevel)
  return rank >= minRank
}
