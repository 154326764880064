import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { usePostCreateBuildingClicked } from "src/data/analytics/queries/homegroupAnalyticsQueries"
import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

import HomeGroupsEmptyState from "./images/locations-empty-state.svg"

export function NoHomeGroups({ type }: { type: THomeGroup["type"] }) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const postCreateBuildingClicked = usePostCreateBuildingClicked()
  const isLocation = type === "area"

  const href = isLocation
    ? Routes.CreateHomeGroupLocation.location()
    : Routes.CreateHomeGroupBuilding.location()

  const buttonActionCopy = isLocation
    ? t(langKeys.organizations_home_group_create_button_label)
    : t(langKeys.homegroups_create_building_button_label)

  function Description() {
    if (isLocation) {
      return (
        <>
          {t(langKeys.locations_empty_state_description)}{" "}
          <InternalLink to={Routes.HomeGroupBuildings.location()} noWrap>
            {t(langKeys.home_group_building_goto)}
          </InternalLink>
        </>
      )
    } else {
      return (
        <>
          {t(langKeys.buildings_empty_state_description)}{" "}
          <InternalLink to={Routes.HomeGroupLocations.location()} noWrap>
            {t(langKeys.home_group_location_goto)}
          </InternalLink>
        </>
      )
    }
  }

  return (
    <EmptyStateWithImage
      Image={HomeGroupsEmptyState}
      title={
        isLocation
          ? t(langKeys.locations_empty_state_title)
          : t(langKeys.buildings_empty_state_title)
      }
      description={<Description />}
      imageSpacing={`-${spacing.XL}`}
      content={
        <MButton
          onClick={() => {
            postCreateBuildingClicked.mutate()
            navigate(href)
          }}
        >
          {buttonActionCopy}
        </MButton>
      }
    />
  )
}
