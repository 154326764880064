import { EffectCallback, useEffect } from "react"

/**
 * A hook that runs an effect only once (at mount). Vendored in as upstream
 * usehooks-ts deprecated it for spurious reasons.
 *
 * @param {EffectCallback} effect - The effect to run.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-effect-once)
 * @example
 * useMountEffect(() => {
 *   console.log('Hello World');
 * });
 */
export function useEffectOnce(effect: EffectCallback) {
  useEffect(effect, []) // eslint-disable-line react-hooks/exhaustive-deps
}
