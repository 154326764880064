import { useQueryClient } from "@tanstack/react-query"

export const invoiceKeys = {
  all() {
    return ["invoices"] as const
  },

  nonPaid() {
    return ["invoices", "nonPaid"] as const
  },
}

export function useInvoiceCache() {
  const queryClient = useQueryClient()

  function resetInvoices() {
    queryClient.resetQueries(invoiceKeys.all())
  }

  return { resetInvoices }
}
