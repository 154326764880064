import { isValidElement } from "react"
import styled from "styled-components"

import { colorScale, mColors } from "src/ui/colors"
import CheckmarkIcon from "src/ui/icons/checkmark-base.svg"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type HTMLCheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  | "type"
  | "onChange"
  | "checked"
  | "disabled"
  | "value"
  | "children"
  | "className"
  | "style"
  | "required"
>

type MCheckboxProps = {
  /**
   * Label of the checkbox.
   * If the label is a primitive value, it will be wrapped in a `<MText />` component.
   */
  label?: React.ReactNode
  checked?: boolean
  onCheck?: (checked: boolean, e: React.ChangeEvent) => void
  error?: boolean
  disabled?: boolean
  required?: boolean
  /**
   * Props for the `<MText />` component that wraps the label.
   * Note. This is only applicable if the `label` is a primitive value.
   */
  textProps?: Omit<MTextProps, "children">
  /**
   * Props for the `<input type="checkbox" />` element.
   */
  inputProps?: HTMLCheckboxProps
  className?: string
  fullWidth?: boolean
}

export function MCheckbox({
  label,
  checked,
  onCheck,
  error,
  disabled,
  required,
  textProps,
  inputProps,
  className,
  fullWidth,
}: MCheckboxProps) {
  return (
    <StyledLabel className={className} $fullWidth={fullWidth}>
      <Checkbox $error={!!error}>
        <CheckmarkIcon />
      </Checkbox>
      <VisuallyHiddenInput
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onCheck?.(e.target.checked, e)
        }}
        disabled={disabled}
        required={required}
        {...inputProps}
      />
      {isValidElement(label) ? (
        label
      ) : (
        <MText color="unset" variant="bodyS" {...textProps}>
          {label}
        </MText>
      )}
    </StyledLabel>
  )
}

const StyledLabel = styled.label<{ $fullWidth?: boolean }>`
  position: relative;
  display: ${({ $fullWidth }) => ($fullWidth ? "flex" : "inline-flex")};
  align-items: center;
  gap: ${spacing.S};
  cursor: pointer;

  &:has(input:disabled) {
    cursor: auto;
    color: ${mColors.neutralDark2};
  }
`

const Checkbox = styled.div<{ $error: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 2px solid
    ${({ $error }) => ($error ? mColors.systemError : mColors.neutralDark3)};
  border-radius: 4px;
  transition: background-color 50ms;

  &:hover:not(:has(+ input:disabled), :has(+ input:checked)) {
    background-color: ${mColors.primaryLight};
  }

  &:has(+ input:checked:not(:disabled)) {
    background-color: ${mColors.primary};
    border-color: ${mColors.primary};

    svg {
      opacity: 1;
    }
  }

  &:has(+ input:disabled) {
    border-color: ${mColors.neutralDark2};
  }

  &:has(+ input:disabled:checked) {
    border-color: ${mColors.neutralDark2};
    background-color: ${mColors.neutralDark2};

    svg {
      opacity: 1;
    }
  }

  &:has(+ input:focus-visible) {
    outline: 2px solid ${colorScale.gaff[700]};
    outline-offset: 1px;
  }

  svg {
    opacity: 0;
    width: 14px;
    color: ${mColors.neutralLight};
  }
`

const VisuallyHiddenInput = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
`
