import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"
import {
  API_ANALYTICS_IDENTIFY,
  API_ANALYTICS_TRACK,
  API_ANALYTICS_TRACK_PAGE,
} from "src/constants/minutApi"
import { useAppData } from "src/context/useAppData"
import {
  IAnalyticsEventProperties,
  IBaseEventBody,
  ICommonEventProperties,
  IIdentifyTraits,
  IPageEvent,
  IPageEventBody,
  TCustomerSegment,
} from "src/data/analytics/types/analyticsTypes"
import {
  IUserAgentResult,
  parseUserAgent,
} from "src/data/analytics/userAgentParser"
import { TFetchCurrentSubscriptionResponse } from "src/data/billing/types/billingTypes"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { TUser } from "src/data/user/user"
import { Logger } from "src/utils/logger"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const analyticsEventLogger = new Logger({
  prefix: "[analytics:event]",
  enabled: isStoredFeatureEnabled(FeatureFlag.LOGGING_ANALYTICS),
})

export function usePostTrackPage() {
  async function postTrackPage(data: IPageEvent) {
    const { name, category, ...properties } = data

    const body: IPageEventBody = {
      name,
      category,
      properties: {
        ...properties,
      },
    }

    await minutApiHttpClient.post(`${API_ANALYTICS_TRACK_PAGE}`, body)
  }

  return useMutation<void, AxiosError, IPageEvent>(postTrackPage)
}

export function usePostIdentify() {
  async function postIdentify(traits: IIdentifyTraits) {
    await minutApiHttpClient.post(API_ANALYTICS_IDENTIFY, {
      traits,
    })
  }

  return useMutation<void, AxiosError, IIdentifyTraits>(postIdentify)
}

export function usePostTrackEventWithParams<
  EventType extends string,
  Properties,
>({ event }: { event: EventType }) {
  const { user, activeOrg, currentSubscription, numberOfHomes } = useAppData()

  async function postTrackEvent(properties: Properties) {
    const data = createTrackEvent({
      user,
      numberOfHomes,
      org: activeOrg,
      currentSubscription,
      event,
      properties,
    })
    analyticsEventLogger.info(data)
    return await minutApiHttpClient.post(API_ANALYTICS_TRACK, data)
  }

  return useMutation({
    mutationFn: postTrackEvent,
  })
}

function createTrackEvent<T extends string, P>({
  user,
  numberOfHomes,
  org,
  currentSubscription,
  event,
  properties,
}: IAnalyticsEventProperties<T, P>): IBaseEventBody {
  const commonEventProperties = getCommonEventProperties({
    user,
    numberOfHomes,
    org,
    currentSubscription,
  })

  return {
    event,
    properties: {
      ...commonEventProperties,
      ...properties,
    },
  }
}

export function getCommonEventProperties({
  user,
  numberOfHomes,
  org,
  currentSubscription,
}: {
  user: TUser | undefined
  numberOfHomes?: number
  org: IOrganization | undefined | null
  currentSubscription: TFetchCurrentSubscriptionResponse | undefined | null
}): ICommonEventProperties {
  const parser: IUserAgentResult = parseUserAgent()

  const customer_size = {
    number_of_homes: numberOfHomes ?? 0,
    customer_segment: getCustomerSegment(numberOfHomes),
  }

  const organization = {
    id: org?.id,
    user_role: org?.user_role,
    plan: org?.current_plan,
  }

  const subscription = {
    plan_id: currentSubscription?.plan_id,
    status: currentSubscription?.subscription_status,
  }

  return {
    device_category: parser.device_type,
    user_agent: parser.ua,
    os: parser.os,
    browser: parser.browser,
    browser_language: navigator.language,
    browser_width: window.innerWidth,
    browser_height: window.innerHeight,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    selected_app_language: user?.preferred_language ?? "",
    platform: "web-app",
    customer_size,
    organization,
    subscription,
  }
}

function getCustomerSegment(number_of_homes?: number): TCustomerSegment {
  if (!number_of_homes) {
    return "not_assigned"
  }
  if (number_of_homes >= 500) {
    return "enterprise"
  }
  if (number_of_homes >= 100 && number_of_homes <= 499) {
    return "large_pm"
  }
  if (number_of_homes >= 20 && number_of_homes <= 99) {
    return "mid_pm"
  }
  if (number_of_homes >= 6 && number_of_homes <= 19) {
    return "small_pm"
  }
  return "core_host"
}
