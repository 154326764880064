import { paths } from "@minuthq/minut-api-types/schema"

/**
 * @deprecated: use the string literal types directly from the BE instead
 */
export enum Feature {
  API_ACCESS = "api_access",
  CREATE_HOME = "create_home",
  GUEST_CONNECT = "guest_connect",
  INTEGRATION = "integration",
  RESPONSE_SERVICE = "response_service",
  SCHEDULED_ALARM = "scheduled_alarm",
  SENSOR_DATA_CAP = "sensor_data_cap",
  SENSOR_SWAP = "sensor_swap",
  CIGARETTE_SMOKE = "cigarette_smoke_detection",
  HOME_TOKENS = "home_tokens",
  EDITFIELD_ALLOW = "editfield_allow", //not in the api, temporary solution to solve editfield
}

export type FeatureAvailabilityResponse =
  paths["/organizations/{organization_id}/feature_availability"]["get"]["responses"]["200"]["content"]["application/json"]

export type FeatureAvailability =
  FeatureAvailabilityResponse["features"][number]
