import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"
import { debug, Logger } from "src/utils/logger"

const storageLoggerDefault = new Logger({
  enabled: isStoredFeatureEnabled(FeatureFlag.LOGGING_STORAGE_DEFAULT),
  prefix: "[Storage]",
})

export interface IStorage<DataType> {
  readonly key: string
  readonly storage: Storage
  readonly set: (value: DataType) => void
  readonly get: () => DataType | null
  readonly clear: () => void
  readonly pop: () => DataType | null
  readonly getDecoder?: (value: string | null) => DataType
  readonly setEncoder?: (value: DataType) => string
  readonly logger?: Logger
  readonly useDefaultLogger?: boolean
}

function storageDefaultMixin<DataType>({
  key,
  ...props
}: {
  key: string
} & Partial<IStorage<DataType>>): IStorage<DataType> {
  if (!key.startsWith("minut.")) {
    debug.error(
      `${key}: Keys stored by us should have a "minut.<keyname>" prefix`
    )
  }

  const logger = (() => {
    // any specified dedicated logger takes precedence before the default:
    if (props.logger) return props.logger
    // otherwise we use the default logger if it is enabled:
    if (storageLoggerDefault.enabled) return storageLoggerDefault
    return
  })()

  return {
    key,
    storage: window.localStorage, // eslint-disable-line no-restricted-properties
    clear() {
      logger?.log("Clearing", this.key)
      this.storage.removeItem(this.key)
    },
    set(value) {
      const encodedValue = this.setEncoder
        ? this.setEncoder(value)
        : JSON.stringify(value)
      logger?.log(`Setting`, this.key, encodedValue)
      this.storage.setItem(this.key, encodedValue)
    },
    get() {
      const data = this.storage.getItem(key)
      try {
        const decodedData = this.getDecoder
          ? this.getDecoder(data)
          : data
            ? (JSON.parse(data) as unknown as DataType)
            : null
        logger?.log(`Getting`, this.key, decodedData)
        return decodedData
      } catch (e) {
        debug.warn(`could not parse stored key ${key}: purging`, e)
        this.clear()
      }
      return null
    },
    pop() {
      const value = this.get()
      this.clear()
      return value
    },
    ...props,
  }
}

/**
 * Usage example:
 *
 * ```
 * interface IFakeStorage {
 *   foo: number
 *   bar?: string
 * }
 * const fakeStorage = localStorageFactory<IFakeStorage>({key: 'fake'})
 * fakeStorage.set({foo: 1})
 * const foo = fakeStorage.get()?.foo;
 * const bar = fakeStorage.get()?.bar;
 * ```
 */
export function localStorageFactory<DataType = string>(
  props: Partial<IStorage<DataType>> & { key: string }
): IStorage<DataType> {
  return {
    ...storageDefaultMixin(props),
    storage: window.localStorage, // eslint-disable-line no-restricted-properties
  }
}

/**
 * Usage example:
 *
 * ```
 * interface IFakeStorage {
 *   foo: number
 *   bar?: string
 * }
 * const exampleStorage = sessionStorageFactory<IFakeStorage>({
 *   key: "fake",
 *   getDecoder: (value: string | null) => {
 *     if (!value) {
 *       return null
 *     }
 *     return JSON.parse(window.decodeURIComponent(value))
 *   },
 *   setEncoder: (value: IFakeStorage) => {
 *     return window.encodeURIComponent(JSON.stringify(value))
 *   },
 * })
 * exampleStorage.set({ foo: 1 })
 * const foo = exampleStorage.get()?.foo
 * const bar = exampleStorage.get()?.bar
 * ```
 */
export function sessionStorageFactory<DataType = string>(
  props: Partial<IStorage<DataType>> & { key: string }
): IStorage<DataType> {
  return {
    ...storageDefaultMixin(props),
    storage: window.sessionStorage, // eslint-disable-line no-restricted-properties
  }
}
