import { useState } from "react"

import { HomeListPopover } from "src/components/Dashboard/DashboardWidgets/HomeListPopover"
import { DEVICE_LIST_LIMIT_MAX } from "src/data/devices/logic/deviceConstants"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { CrowdsFilter } from "src/data/filters/filtersHome"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { getStatefulHomesWithDevices } from "src/data/homes/logic/homeUtil"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { useTranslate } from "src/i18n/useTranslate"

import { WidgetCard } from "./WidgetCard"

export function WidgetCrowd() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { isSuspended } = useDunning()

  const limit = 4

  const fetchHomes = useFetchHomes({
    orgId: org.id,
    filters: {
      crowd_detected: true,
      limit,
    },
  })

  const homes = fetchHomes.data?.homes
  const homesTotalCount = fetchHomes.data?.paging.total_count ?? 0

  const fetchDevices = useFetchDevices({
    orgId: org.id,
    filter: {
      home_ids: homes?.map((h) => h.home_id),
      limit: DEVICE_LIST_LIMIT_MAX, // a home can have multiple devices, hence limit need to be maxed out
    },
    options: { enabled: homesTotalCount > 0 },
  })

  const devices = fetchDevices.data?.devices

  const homesWithDevices = getStatefulHomesWithDevices(
    homes || [],
    devices || []
  )

  const level = homesTotalCount > 0 ? "red" : "green"

  const linkTo =
    homesTotalCount > limit
      ? `/homes?limit=${HOME_LIST_LIMIT}&offset=0&${CrowdsFilter.id}=${CrowdsFilter.value}`
      : null

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <div>
      <WidgetCard
        alertLevel={level}
        number={homesTotalCount}
        title={CrowdsFilter.name(t)}
        icon={CrowdsFilter.icon}
        onClick={handleClick}
        disabled={isSuspended}
      />

      {open && (
        <HomeListPopover
          title={CrowdsFilter.name(t)}
          emptyStateDescription={t(
            langKeys.ongoing_crowd_detect_events_empty_state
          )}
          homesWithDevices={homesWithDevices}
          loading={fetchHomes.isInitialLoading || fetchDevices.isInitialLoading}
          anchorEl={anchorEl}
          onClose={handleClose}
          linkTo={linkTo}
        />
      )}
    </div>
  )
}
