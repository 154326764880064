import { QueryClient, useQueryClient } from "@tanstack/react-query"

import {
  DeviceReadingType,
  IDevicesFilter,
  IFetchDevices,
  TDevice,
} from "src/data/devices/types/deviceTypes"

export const deviceKeys = {
  /**
   * Use to target *all* cached devices, with fuzzy matching.
   * Matching this way is ineffective and should be used with care.
   */
  allDevices(orgId: string) {
    return [orgId, "devices"] as const
  },
  /**
   * Use to target cached devices lists, with applied filters if applicable.
   * Omitting filters will fuzzy match and target all device lists.
   */
  devices(orgId: string, filters?: IDevicesFilter) {
    const deviceList = [...this.allDevices(orgId), "list"] as const
    if (!filters) {
      return deviceList
    }
    return [...deviceList, { filters }] as const
  },
  device({ orgId, deviceId }: { orgId: string; deviceId: string }) {
    return [...this.allDevices(orgId), deviceId] as const
  },

  readings({
    orgId,
    deviceId,
    ...rest
  }: {
    orgId: string
    deviceId: string
    endAt: string
    includeMinMax?: boolean
    startAt: string
    timeResolution?: number
    type: DeviceReadingType | "motion_events"
  }) {
    return [...this.device({ orgId, deviceId }), "readings", rest] as const
  },
}

export function useDeviceCache(orgId: string) {
  const queryClient = useQueryClient()

  /**
   * Update device cache with new device data, e.g., after a PATCH.
   *
   * @param deviceId: the id of the device that was updated
   * @param updater: function that can manipulate the cache
   */
  function updateCachedDevice(
    deviceId: string,
    updater: (device: TDevice | undefined) => TDevice | undefined
  ) {
    queryClient.setQueryData<TDevice | undefined>(
      deviceKeys.device({ orgId, deviceId }),
      updater
    )
  }

  /**
   * Update cached device list with new device data, e.g., after a PATCH.
   *
   * Fuzzy matches.
   *
   * @param updater: function that can manipulate the cache
   * @param filter: filter to target specific device list in cache
   */
  function updateCachedDeviceList(
    updater: (device: IFetchDevices | undefined) => IFetchDevices | undefined,
    filter?: IDevicesFilter
  ) {
    queryClient.setQueriesData<IFetchDevices | undefined>(
      deviceKeys.devices(orgId, filter),
      updater
    )
  }

  /**
   * Set all devices in cache with a matching id to `deviceData`.
   */
  function setCachedDeviceData(deviceData: TDevice) {
    const deviceId = deviceData.device_id
    updateCachedDevice(deviceId, () => deviceData)
    updateCachedDeviceList((fetchedData) => {
      if (!fetchedData?.devices) return
      const newList = fetchedData.devices.map((device) => {
        if (device.device_id === deviceId) {
          return deviceData
        }
        return device
      })
      return { ...fetchedData, devices: newList }
    })
  }

  function invalidateCachedDeviceLists() {
    queryClient.invalidateQueries(deviceKeys.devices(orgId))
  }

  /**
   * Invalidates cached device
   * @param deviceId: the id of the device
   */
  function invalidateCachedDevice(deviceId: string) {
    queryClient.invalidateQueries(deviceKeys.device({ orgId, deviceId }))
  }

  /**
   * Remove device from cache after a delete operation
   * @param deviceId: the id of the device
   * @param queryClient: the query client to access the cache
   */
  function removeCachedDevice({
    queryClient,
    deviceId,
  }: {
    queryClient: QueryClient
    deviceId: string
  }) {
    queryClient.removeQueries(deviceKeys.device({ orgId, deviceId }))
    queryClient.setQueriesData<IFetchDevices | undefined>(
      deviceKeys.devices(orgId),
      (cache) => {
        if (!cache) return undefined
        const { devices: cachedDevices = [], paging } = cache

        const filteredDevices = cachedDevices.filter(
          (device) => device.device_id !== deviceId
        )

        return { devices: filteredDevices, paging }
      }
    )
  }

  return {
    updateCachedDevice,
    updateCachedDeviceList,
    invalidateCachedDeviceLists,
    setCachedDeviceData,
    invalidateCachedDevice,
    removeCachedDevice,
  }
}
