import { ReactElement, useEffect, useRef } from "react"
import styled from "styled-components"

import { BASE_DIALOG_CLASSNAME } from "src/ui/BaseModalDialog/baseModalDialogUtils"

type TBaseModalDialogProps = {
  open: boolean
  onClose: () => void
  /*
    `content` should only accept a single child that wraps the content of the dialog.
    Otherwise the click away listener and sizing may not work properly
  */
  content: ReactElement
}

/**
 * This is a base component that should not be used directly in the web app.
 * Use `MDialog` instead.
 *
 * @deprecated Use `MDialog` instead
 */
export function BaseModalDialog({
  open,
  onClose,
  content,
}: TBaseModalDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (!dialogRef.current) {
      return
    }

    if (open) {
      dialogRef.current.showModal()
      document.body.style.overflowY = "hidden"
    } else {
      dialogRef.current.close()

      // We should only re-add the scrollbar when no other dialogs are rendered
      // For example when nesting dialogs
      if (!document.querySelector(`.${BASE_DIALOG_CLASSNAME}[open]`)) {
        document.body.style.overflowY = ""
      }
    }

    return () => {
      // We should only re-add the scrollbar when no other dialogs are rendered
      // For example when nesting dialogs
      if (!!!document.querySelector(`.${BASE_DIALOG_CLASSNAME}[open]`)) {
        document.body.style.overflowY = ""
      }
    }
  }, [open])

  return (
    <StyledDialog
      className={BASE_DIALOG_CLASSNAME}
      ref={dialogRef}
      onClick={(e) => {
        e.stopPropagation()

        if (dialogRef.current && e.target === dialogRef.current) {
          onClose()
        }
      }}
      onCancel={(e) => {
        e.stopPropagation()

        // Make sure the `cancel` event is triggered by the dialog itself
        // This event could be invoked by a child element
        if (dialogRef.current && e.target === dialogRef.current) {
          onClose()
        }
      }}
    >
      {content}
    </StyledDialog>
  )
}

const StyledDialog = styled.dialog`
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  max-width: none;
  max-height: none;
  background: none;
  border: none;

  &[open] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
`
