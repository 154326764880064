import styled from "styled-components"

import IconButton from "@material-ui/core/IconButton"

import { primaryTint } from "src/ui/colors"
import AddIcon from "src/ui/icons/add-circle-outline.svg"
import RemoveIcon from "src/ui/icons/remove-circle-outline.svg"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function QuantitySelector({
  value,
  label,
  labelProps,
  onAdd = () => {},
  onRemove = () => {},
  loading,
  disableRemove,
  disableAdd,
  addTooltip,
  ...props
}: {
  value: number | string
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  labelProps?: any
  onAdd: () => void
  onRemove: () => void
  loading?: boolean
  disableRemove?: boolean
  disableAdd?: boolean
  addTooltip?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  props?: any
}) {
  return (
    <Box {...props}>
      {label && (
        <MText variant="subtitle" {...labelProps}>
          {label}
        </MText>
      )}
      <IconButton
        size="small"
        onClick={onRemove}
        style={{ fill: primaryTint, stroke: primaryTint }}
        disabled={loading || disableRemove}
      >
        <RemoveIcon
          width="32"
          height="32"
          fill={disableRemove ? "#ccc" : "inherit"}
          stroke={disableRemove ? "#ccc" : "inherit"}
        />
      </IconButton>
      <Value value={String(value)}>
        {loading ? <MCircularProgress size={15} /> : value}
      </Value>
      <IconButton
        size="small"
        disabled={loading || disableAdd}
        onClick={onAdd}
        style={{ fill: primaryTint, stroke: primaryTint }}
      >
        <TooltipWrapper title={addTooltip}>
          <AddIcon
            width="32"
            height="32"
            fill={disableAdd ? "#ccc" : "inherit"}
            stroke={disableAdd ? "#ccc" : "inherit"}
          />
        </TooltipWrapper>
      </IconButton>
    </Box>
  )
}

function TooltipWrapper({
  title,
  children,
}: {
  title: React.ReactNode
  children: React.ReactNode
}) {
  if (!title) {
    return <>{children}</>
  }
  return (
    <MTooltip title={title}>
      <span>{children}</span>
    </MTooltip>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Value = styled(MText).attrs(() => ({
  variant: "heading2",
  textAlign: "center",
}))<{
  value: string
}>`
  padding: 0 ${spacing.M};
  min-width: ${({ value }) => value.length + 2.2 + "ch"};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
