import { css } from "styled-components"

import { minimumGray } from "src/ui/colors"

export const shadowBottom = css`
  border-bottom: ${minimumGray};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`

export const shadowTop = css`
  border-top: ${minimumGray};
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.06);
`

export const cardBoxShadow = css`
  box-shadow:
    0px 0px 4px 0px rgba(18, 31, 65, 0.08),
    0px 8px 16px 0px rgba(18, 31, 65, 0.05);
`
