import { useState } from "react"
import styled from "styled-components"

import { colorsLegacy } from "src/ui/colors"
import ChevronDown from "src/ui/icons/chevron-down.svg"
import { JsonValue } from "src/ui/JsonTree/JsonTypes"
import { MText } from "src/ui/MText"
import { isObject } from "src/utils/genericUtil"

type JsonRendererProps = {
  value: JsonValue
  objKey: string | undefined | null
  depth: number
  initialCollapsed?: boolean
}

/** This component will recursively render an interactive object tree based on
 * JSON data. */
export function JsonRenderer({
  objKey,
  value,
  depth,
  initialCollapsed = false,
}: JsonRendererProps) {
  const isArrayValue = Array.isArray(value)
  const isObjectValue = isObject(value)
  const [open, setOpen] = useState<boolean>(!initialCollapsed)

  // A leaf node containing a JSON primitive => end recursion and render the value
  if (!isObjectValue && !isArrayValue) {
    return (
      <ItemGrid>
        <div></div>
        <div>
          <span hidden={!objKey}>{renderJsonValue(objKey)}: </span>
          <span>{renderJsonValue(value)}</span>,
        </div>
      </ItemGrid>
    )
  }

  const entries = Object.entries(value)
  const nbrItems = entries.length

  // Handle nested object/array
  return (
    <ItemGrid>
      <GridHeader onClick={() => setOpen(!open)}>
        <ImgWrapper>
          <Chevron $open={open} />
        </ImgWrapper>
        <div>
          {objKey && `"${objKey}": `}
          <LeadingBracket
            isObject={isObject(value)}
            open={open}
            nbrItems={nbrItems}
          />
        </div>
      </GridHeader>

      <GridBody $open={open}>
        <VerticalLineCell />
        <div>
          {entries.map(([k, v]) => {
            return (
              <JsonRenderer
                key={k}
                objKey={isObjectValue ? k : null}
                value={v}
                depth={depth + 1}
              />
            )
          })}
        </div>
      </GridBody>

      <GridFooter $open={open}>
        <VerticalLineCell />
        <div>
          <MText variant="bodyS">{isObject(value) ? "}" : "]"},</MText>
        </div>
      </GridFooter>
    </ItemGrid>
  )
}

const Chevron = styled(ChevronDown)<{ $open: boolean }>`
  padding: 0 2px;
  height: 14px;
  width: 100%;
  transition: transform 0.2s ease-out;
  transform: rotate(${(props) => (props.$open ? "0" : "-90deg")});
`

const GridHeader = styled.div`
  display: contents;
  cursor: pointer;
  user-select: none;
`
const GridBody = styled.div<{ $open: boolean }>`
  display: ${(props) => (props.$open ? "contents" : "none")};
`
const GridFooter = styled(GridBody)``

/** This CSS component applies hover effects to itself and its children */
const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(2ch, auto) 1fr;
  align-items: center;

  & > ${GridHeader}:hover > * {
    background-color: ${colorsLegacy.primaryTint}32;
    & > ${Chevron} {
      scale: 1.3;
      color: ${colorsLegacy.primaryTint};
    }
  }
`

type LeadingBracketProps = {
  isObject: boolean
  open: boolean
  nbrItems: number
}
function LeadingBracket({ isObject, open, nbrItems }: LeadingBracketProps) {
  if (!open) {
    const placeholder = isObject ? `{ ... } ` : `[ ... ] `
    return (
      <MText as="span" variant="bodyS">
        {placeholder}
        <MText as="span" variant="nano" color="secondary">
          {`${nbrItems} items`}
        </MText>
      </MText>
    )
  }

  return <span>{isObject ? "{" : "["}</span>
}

const VerticalLineCell = styled.div`
  height: 100%;
  display: flex;
  place-content: center;
  &:before {
    content: "";
    width: 1px;
    background-color: ${colorsLegacy.divider};
    height: inherit;
  }
`

/** Necessary for SVG background & positioning */
const ImgWrapper = styled.div``

function renderJsonValue(value: unknown) {
  const datatype = typeof value
  if (value === null) return "null"
  if (datatype === "number") return value
  if (datatype === "string") return `"${value}"`
  if (datatype === "boolean") return value ? "true" : "false"
  if (datatype === "undefined") return "undefined"

  return value
}
