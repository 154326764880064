import { useRef, useState } from "react"

import { FireCOAlarmButtonPopover } from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOAlarmButtonPopover"
import {
  FireCOIcon,
  FireCOIconProps,
} from "src/components/Homes/HomeDetails/FireCOAlarm/FireCOIcon"
import { useFetchProfile } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import {
  MonitoringButton,
  OnOffStatusLabel,
} from "src/ui/MonitoringButton/MonitoringButton"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function FireCOAlarmButton({
  graphicalButton,
  hideStatus,
  configureAllowed,
  homeState,
}: {
  graphicalButton?: boolean
  hideStatus?: boolean
  configureAllowed: boolean
  homeState: Pick<
    THome,
    "fire_alarm" | "carbon_monoxide_alarm" | "violations" | "profile_id"
  >
}) {
  const { t } = useTranslate()

  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)

  const fetchProfile = useFetchProfile({
    id: homeState.profile_id ?? "",
    // Stale time required for some reason; it'll keep fetching the profile otherwise
    options: { staleTime: 100 },
  })
  const automatedTestingOn =
    fetchProfile.data?.fire_and_co_alarm?.automated_testing

  const mTooltip = configureAllowed ? "" : t(langKeys.not_enough_permissions)

  const fireAlerting = homeState.fire_alarm?.status === "alerting"
  const coAlerting = homeState.carbon_monoxide_alarm?.status === "alerting"

  function handleClick() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  function ConfigureButton() {
    if (graphicalButton) {
      return (
        <FireCOButtonIcon
          alerting={(fireAlerting && "fire") || (coAlerting && "co") || null}
          isUpdating={fetchProfile.isLoading}
          disabled={!configureAllowed}
          onClick={handleClick}
          hideStatus={hideStatus}
        />
      )
    }
    return (
      <MButton
        variant="subtle"
        size="small"
        onClick={handleClick}
        loading={fetchProfile.isLoading}
        disabled={!configureAllowed}
      >
        {t(langKeys.manage)}
      </MButton>
    )
  }

  return (
    <div>
      <MTooltip title={mTooltip} placement={graphicalButton ? "left" : "top"}>
        <div ref={ref}>
          <ConfigureButton />
        </div>
      </MTooltip>

      <FireCOAlarmButtonPopover
        open={open}
        handleClose={handleClose}
        anchorEl={ref.current}
        automatedTestsOn={!!automatedTestingOn}
        violations={homeState.violations}
      />
    </div>
  )
}

function FireCOButtonIcon({
  alerting,
  isUpdating,
  disabled,
  onClick,
  hideStatus,
}: {
  alerting: FireCOIconProps["alerting"]
  isUpdating?: boolean
  disabled: boolean
  onClick: React.MouseEventHandler
  hideStatus?: boolean
}) {
  return (
    <MonitoringButton
      label={<OnOffStatusLabel isOn={true} />}
      labelType={"good"}
      onClick={onClick}
      updating={isUpdating}
      icon={
        <FireCOIcon
          alerting={alerting}
          iconProps={{ height: "30", width: "30" }}
        />
      }
      hideStatus={hideStatus}
      disabled={disabled}
      row={true}
    />
  )
}
