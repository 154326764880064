import { useFetchFlags } from "src/data/flags/flagsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"

type TUseBackendFlagsOptions = { forceFetch?: boolean; enabled?: boolean }

/** Fetches and caches backend flags for later use.
 *
 * Use this hook in contexts where an organization ID isn't necessarily
 * available yet, e.g. in routing middleware; in other cases it's more
 * convenient to use `useBackendFlags` below. */
export function useBackendFlagsBase({
  orgId,
  deviceId,
  options,
}: {
  orgId: string
  deviceId?: string
  options?: TUseBackendFlagsOptions
}) {
  const { user_id } = useGetUser()
  const fetchFlags = useFetchFlags(
    { organization_id: orgId, user_id, device_id: deviceId },
    { refresh: options?.forceFetch, enabled: options?.enabled ?? true }
  )

  // Filter out flags we don't care about:
  const {
    create_organizations_with_noise_profiles: _,
    flags_released: __,
    ...usedFlags
  } = fetchFlags.data ?? {}

  return { ...usedFlags, fetchFlags }
}

export function useBackendFlags({
  deviceId,
  options,
}: {
  deviceId?: string
  options?: TUseBackendFlagsOptions
} = {}) {
  const { orgId } = useOrganization()
  return useBackendFlagsBase({ orgId, deviceId, options })
}
