import { TSignalQuality } from "src/data/devices/types/deviceTypes"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import WifiExcellent from "src/ui/icons/wifi-excellent.svg"
import WifiGood from "src/ui/icons/wifi-good.svg"
import WifiNone from "src/ui/icons/wifi-none.svg"
import WifiOkay from "src/ui/icons/wifi-okay.svg"
import WifiWeak from "src/ui/icons/wifi-weak.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { SVGElement, SVGProps } from "src/utils/tsUtil"

export function WifiIcon({
  quality,
  offline = false,
  title,
  ...props
}: SVGProps & {
  quality: TSignalQuality
  offline: boolean
  title?: React.ReactNode
}) {
  const { t } = useTranslate()

  const wifi = offline
    ? { title: t(langKeys.offline), svg: WifiNone }
    : getIconMapping({ quality, t })

  const WifiIcon = wifi.svg

  return (
    <MTooltip title={title || wifi.title}>
      <div>
        <WifiIcon height={30} width={30} {...props} />
      </div>
    </MTooltip>
  )
}

type TIconMap = { svg: SVGElement; title: string }

function getIconMapping({
  quality,
  t,
}: {
  quality: TSignalQuality
  t: ITranslateFunction
}): TIconMap {
  const iconMapping = [
    {
      title: t(langKeys.signal_weak),
      svg: WifiWeak,
    },
    {
      title: t(langKeys.signal_okay),
      svg: WifiOkay,
    },
    {
      title: t(langKeys.signal_fair),
      svg: WifiGood,
    },
    {
      title: t(langKeys.signal_good),
      svg: WifiGood,
    },
    {
      title: t(langKeys.signal_excellent),
      svg: WifiExcellent,
    },
  ] as const satisfies TIconMap[]

  return iconMapping[Number(quality)] ?? iconMapping[0]
}
