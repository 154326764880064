import styled from "styled-components"

import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { OrderStatusBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderStatusBadge"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

export function ParadiseOrderDetailsMeta({ order }: { order: TParadiseOrder }) {
  return (
    <Box>
      <ParadiseDetailsHeading>#{order.order_number}</ParadiseDetailsHeading>

      <MText variant="bodyS" color="secondary">
        <OrderMetaBox>
          <OrderStatusBadge status={order.status} size="medium" />
          <span>•</span>
          <div>{formatUtcDate({ date: order.created_at })}</div>
        </OrderMetaBox>
      </MText>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const OrderMetaBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
  flex-wrap: wrap;
`
