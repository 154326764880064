import { IFilter } from "src/data/filters/types/filterTypes"
import { langKeys } from "src/i18n/langKeys"
import SvgBattery from "src/ui/icons/battery-monochrome.svg"
import SmokingDetection from "src/ui/icons/cigarette-smoking.svg"
import SvgOffline from "src/ui/icons/offline-monochrome.svg"

export const OfflineDeviceFilter: IFilter = {
  id: "offline",
  name: (t) => t(langKeys.offline),
  value: true,
  unique: true,
  icon: SvgOffline,
}

export const LowBatteryFilter: IFilter = {
  // filterText: "Battery less than",
  id: "low_battery",
  name: (t) => t(langKeys.low_battery),
  value: true,
  unique: true,
  icon: SvgBattery,
}

export const SmokingDetectionFilter: IFilter = {
  id: "smoking_detection",
  name: () => "Cigarette sense",
  value: true,
  unique: true,
  icon: SmokingDetection,
}
