import { IFilter, IFilterCard } from "src/data/filters/types/filterTypes"
import { langKeys } from "src/i18n/langKeys"
import BatteryMonochrome from "src/ui/icons/battery-monochrome.svg"
import Smoking from "src/ui/icons/cigarette-smoking.svg"
import Crowd from "src/ui/icons/crowd.svg"
import FireCOFilterIcon from "src/ui/icons/eventIcons/fire-alarm.svg"
import ImportantOutlined from "src/ui/icons/important-outlined.svg"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"
import OfflineSensor from "src/ui/icons/offline-info-icon-monochrome.svg"
import Speaker from "src/ui/icons/speaker-monochrome.svg"

export const TextHomeFilter: IFilter = {
  id: "name",
  name: (t) => t(langKeys.search_for_home),
  value: "",
  unique: true,
}

export const OnGoingNoiseEventFilter: IFilterCard = {
  id: "noise_ongoing",
  name: (t) => t(langKeys.ongoing_noise_events),
  value: true,
  unique: true,
  icon: Speaker,
}

export const CrowdsFilter: IFilterCard = {
  id: "crowd_detected",
  name: (t) => t(langKeys.ongoing_crowd_detect_events),
  value: true,
  unique: true,
  icon: Crowd,
}

export const SmokingDetectionFilter: IFilterCard = {
  id: "smoking_detected",
  name: (t) => t(langKeys.ongoing_smoking_detection_events),
  value: true,
  unique: true,
  icon: Smoking,
}

export const NoSensorFilter: IFilterCard = {
  id: "no_sensors",
  name: (t) => t(langKeys.no_sensor),
  value: true,
  unique: true,
  icon: OfflineSensor,
  exclusive: true,
}

export const LowBatteryFilter: IFilterCard = {
  id: "low_battery",
  name: (t) => t(langKeys.low_battery),
  value: true,
  unique: true,
  icon: BatteryMonochrome,
  exclusive: true,
}

export const UnmonitoredHomesFilter: IFilterCard = {
  id: "offline_sensors",
  name: (t) => t(langKeys.unmonitored),
  value: "any",
  unique: true,
  icon: ImportantOutlined,
  exclusive: true,
}

export const FireCOAlarmFilter: IFilterCard = {
  id: "fire_or_co_alarm_ongoing_filter",
  name: (t) => t(langKeys.fire_and_co_filter),
  value: true,
  unique: true,
  icon: FireCOFilterIcon,
  exclusive: false,
}

export const IndoorClimateFilter: IFilterCard = {
  id: "indoor_climate_ongoing_alert_filter",
  name: (t) => t(langKeys.ongoing_indoor_climate_events),
  value: true,
  unique: true,
  icon: IndoorClimateIcon,
} as const

export const homeDropdownFilters: IFilterCard[] = [
  OnGoingNoiseEventFilter,
  CrowdsFilter,
  SmokingDetectionFilter,
  FireCOAlarmFilter,
  NoSensorFilter,
  LowBatteryFilter,
  UnmonitoredHomesFilter,
  IndoorClimateFilter,
]
