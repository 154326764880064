import { FeatureFlag } from "src/constants/featureFlags"

function prefixed(s: string) {
  const STORE_PREFIX = "com.minut.web.feature"
  return `${STORE_PREFIX}.${s}`
}

export function storeFlag(key: string, value: string) {
  // eslint-disable-next-line no-restricted-properties
  window.localStorage.setItem(prefixed(key), value)
}

export function removeStoredFlag(key: string) {
  // eslint-disable-next-line no-restricted-properties
  window.localStorage.removeItem(prefixed(key))
}

export function getStoredFlag(key: string) {
  // eslint-disable-next-line no-restricted-properties
  return window.localStorage.getItem(prefixed(key))
}

export function isStoredFeatureEnabled(feature: FeatureFlag) {
  return !!getStoredFlag(feature)
}
