function zeroPad(n: number): string {
  return n < 10 ? `0${n}` : `${n}`
}

function calcDiff(startTime: Date, endTime: Date): Date {
  return new Date(endTime.getTime() - startTime.getTime())
}

export function endTimeReached(
  endTime: number | undefined | null,
  now?: number | undefined | null
) {
  if (!endTime) return true
  const _now = now || Date.now()
  return endTime - _now <= 0
}

export function getPercent(
  now: number,
  deadline: number | null,
  gracePeriodSecs: number | undefined
) {
  if (!now || !deadline || !gracePeriodSecs) {
    return 0
  }
  const progress = () => {
    const completed = (deadline - now) / 1000
    if (completed <= 0) return 1
    const p = 1 - completed / gracePeriodSecs
    return p
  }
  return Math.max(0, progress() * 100)
}

export function getCountdown(now: number, stopAt: number | null): string {
  if (!stopAt) return ""
  const diff = calcDiff(new Date(now), new Date(stopAt))
  return `${zeroPad(diff.getMinutes())}:${zeroPad(diff.getSeconds())}`
}
