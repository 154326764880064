import { paths } from "@minuthq/minut-api-types/schema"
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

type FilterKeys<Target extends object, Value> = {
  [K in keyof Target]-?: Target[K] extends Value ? K : never
}[keyof Target]

export type MinutQueryFilter<T extends TPathsGet> = Parameters<
  typeof useMinutQuery<T>
>[0]["filters"]

type TPathsGet = Readonly<FilterKeys<paths, { get: unknown }>>
type TPathsPatch = Readonly<FilterKeys<paths, { patch: unknown }>>
type TPathsPost = Readonly<FilterKeys<paths, { post: unknown }>>
type TPathsPut = Readonly<FilterKeys<paths, { put: unknown }>>
type TPathsDelete = Readonly<FilterKeys<paths, { delete: unknown }>>

type THttpClientErrorCodes = "400" | "401" | "403" | "404"
type THttpClientCodes = THttpClientErrorCodes | "200"

/**
 * A utility type that extracts the response type from a given path and method
 * in the `paths` object.
 *
 * @template PathKey -
 *  The key of the path in the `paths` object.
 * @template Method -
 *  The HTTP method associated with the path ('get', 'patch', 'delete' etc).
 * @template HTTPStatus -
 *  The HTTP status code for the response (default is any valid HTTP status code).
 *
 * @returns The inferred response type for the specified path and method, or
 * `never` if the response structure does not match.
 */
type TPathData<
  PathKey extends keyof paths,
  Method extends keyof paths[PathKey],
  HTTPStatus extends THttpClientCodes = THttpClientCodes,
> = paths[PathKey][Method] extends {
  responses: { [K in HTTPStatus]: { content: { "application/json": infer R } } }
}
  ? R
  : never

type InferQueryVariables<TPath extends TPathsGet> = {
  path: paths[TPath]["get"] extends {
    parameters: { path: infer P }
  }
    ? P
    : never
  query: paths[TPath]["get"] extends {
    parameters: { query: infer Q }
  }
    ? Q
    : never
}

/**
 * Turns a string with curly braces into a string with the curly braces replaced by any string.
 * This is useful for "removing" variables from an OpenAPI path.
 *
 * @example
 * ```
 * type Path = `/organizations/{organization_id}/homes/{home_id}`
 * type Result = BuildPathType<Path> // Result is `/organizations/${string}/homes/${string}`
 * ```
 */
type BuildPathType<T extends string> =
  T extends `${infer Start}{${string}}${infer Rest}`
    ? `${Start}${string}${BuildPathType<Rest>}`
    : T

/**
 * A custom hook that performs a query using the Minut API.
 *
 * `TPath` is the only required generic, and it must correspond to a path in the
 * minut-api-types library.
 *
 * Unfortunately TS lacks type completion for generics [even when it knows the
 * type][1]. Workaround: use `apiPath` to get type completion, then copy it to
 * `TPath` during development.
 *
 * @template TPath - The type of the query path, extending TPathGet.
 * @template ErrCodes - The type of error codes, normally only needed when
 *  errors aren't properly defined.
 * @template TReturnData - The type of data returned on a successful query;
 *  useful if you want to call `select`, but should typically be inferred.
 * @template TData - The type of data inferred from the successful query; should
 *  typically be inferred.
 * @template TError - The type of error returned by the query, extending
 *  AxiosError; should typically be inferred.
 *
 * @param {Object} params - The parameters for the query.
 * @param {string} params.queryPath - The path for the API query.
 * @param {QueryKey} params.queryKey - The key for the query, used for caching
 *  and refetching.
 * @param {UseQueryOptions<TData, TError, TReturnData>} [params.options] -
 *  Optional settings for the query.
 * @param {TPathsGet} [params.apiPath] - Not used in code; for dynamic type
 *  inference during development.
 *
 * [1]: https://github.com/microsoft/TypeScript/issues/28662
 */
export function useMinutQuery<
  TPath extends TPathsGet,
  ErrCodes extends THttpClientErrorCodes = THttpClientErrorCodes,
  TReturnData = TPathData<TPath, "get", "200">, // Only necessary for custom return data when using
  // You typically don't want to set the generics below, they should be inferred automagically
  TData = TPathData<TPath, "get", "200">,
  TError = AxiosError<TPathData<TPath, "get", ErrCodes>>,
  TQueryVariables extends
    InferQueryVariables<TPath> = InferQueryVariables<TPath>,
>({
  queryPath,
  queryKey,
  options,
  filters,
}: Readonly<{
  queryPath: BuildPathType<TPath>
  filters?: TQueryVariables["query"]
  queryKey: QueryKey
  options?: UseQueryOptions<TData, TError, TReturnData>
  apiPath?: TPathsGet // not used in code; see docstrign for why this exists
}>): UseQueryResult<TReturnData, TError> {
  const queryFn = async (): Promise<TData> => {
    const params = filters as object | undefined // casting necessary due to weird typing in minut-api-types
    const result = await minutApiHttpClient.get<TData>(
      API_DEFAULT + queryPath,
      { params }
    )
    return result.data
  }
  return useQuery<TData, TError, TReturnData>(queryKey, queryFn, options)
}

/**
 * Infers the types for the mutation variables based on the given path and method.
 *
 * @template TPath - The type of the path, which can be one of TPathPatch,
 *  TPathPost, TPathPut, or TPathDelete.
 * @template TMethod - The HTTP method used for the path, which is a key of the
 *  paths object for the given TPath.
 *
 * @property {object} path - The inferred type for the path parameters.
 * @property {object} body - The inferred type for the request body content of
 *  type "application/json".
 */
type InferMutationVariables<
  TPath extends TPathsPatch | TPathsPost | TPathsPut | TPathsDelete,
  TMethod extends keyof paths[TPath],
> = {
  path: paths[TPath][TMethod] extends {
    parameters: { path: infer R }
  }
    ? R
    : never

  query?: paths[TPath][TMethod] extends {
    parameters: { query: infer Q }
  }
    ? Q
    : never

  body: paths[TPath][TMethod] extends {
    requestBody: { content: { "application/json": infer R } }
  }
    ? R
    : never
}

/**
 * A wrapper hook to perform a typed PATCH request using react-query.
 *
 * `TPath` is the only required generic, and it must correspond to a path in the
 * minut-api-types library.
 *
 * Unfortunately TS lacks type completion for generics [even when it knows the
 * type][1]. Workaround: use `apiPath` to get type completion, then copy it to
 * `TPath` during development.
 *
 * All other generics will typically be inferred from `TPath` and the Minut api
 * types library, including the types for return data, error data, and path and
 * body parameters.
 *
 * Basically the only time you need to define any other generic is if the api
 * types library is broken.
 *
 * @template TPath - The API path for the PATCH request. This should extend
 * `TPathPatch`.
 * @template ErrCodes - The type of error codes, normally only needed when
 * errors aren't properly defined.
 * @template TData - The type of the data returned on a successful PATCH
 * request; typically inferred.
 * @template TError - The type of the error returned on a failed PATCH request;
 * typically infered.
 * @template TMutationFnVariables - The type of the variables required for the
 * mutation function; typically inferred.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Function} params.pathFn - A function that generates the API path
 * string from the path variables.
 * @param {Object} [params.options] - Optional configuration for the mutation.
 * @param {TPathsPatch} [params.apiPath] - Not used in code; for dynamic type
 * inference during development.
 *
 * [1]: https://github.com/microsoft/TypeScript/issues/28662
 */
export function useMinutPatch<
  TPath extends TPathsPatch,
  ErrCodes extends THttpClientErrorCodes = THttpClientErrorCodes,
  // You typically don't want to set the generics below, they should be inferred automagically
  TData = TPathData<TPath, "patch", "200">,
  TError = AxiosError<TPathData<TPath, "patch", ErrCodes>>,
  TMutationFnVariables extends InferMutationVariables<
    TPath,
    "patch"
  > = InferMutationVariables<TPath, "patch">,
>({
  pathFn,
  options,
}: Readonly<{
  pathFn: (args: TMutationFnVariables["path"]) => string
  options?: UseMutationOptions<TData, TError, TMutationFnVariables>
  apiPath?: TPathsPatch // not used in code, see docstring for why this exists
}>): UseMutationResult<TData, TError, TMutationFnVariables> {
  async function mutationFn(variables: TMutationFnVariables): Promise<TData> {
    const path = pathFn(variables.path)
    if (!path.startsWith("/")) {
      throw new Error("Path must start with a leading slash")
    }

    const response = await minutApiHttpClient.patch<TData>(
      API_DEFAULT + pathFn(variables.path),
      variables.body,
      variables.query ? { params: variables.query } : undefined
    )
    return response.data
  }

  return useMutation<TData, TError, TMutationFnVariables>({
    mutationFn,
    ...options,
  })
}

// XXX: Add useMinutPost, useMinutPut, and useMinutDelete hooks here later
