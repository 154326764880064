import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrderAmount } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderAmount"
import { ParadiseOrderItems } from "src/components/Paradise/ParadiseOrders/ParadiseOrderDetails/ParadiseOrderItems"
import { ParadiseOrderTrackingReferenceBadge } from "src/components/Paradise/ParadiseOrders/ParadiseOrderTrackingReferenceBadge"
import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderFulfillment({ order }: { order: TParadiseOrder }) {
  return (
    <Grid>
      <ParadiseDetailsSection title="Fulfillment" />

      <WarehouseSection order={order} />

      <ParadiseOrderItems lineItems={order.line_items} />

      <ParadiseOrderAmount totalPrice={order.total_price} tax={order.tax} />
    </Grid>
  )
}

function WarehouseSection({ order }: { order: TParadiseOrder }) {
  return (
    <WarehouseGrid>
      <MText variant="bodyS" color="secondary">
        Assigned warehouse:
      </MText>

      <MText variant="bodyS">Implement me</MText>

      <MText variant="bodyS" color="secondary">
        Tracking number:
      </MText>

      <ParadiseOrderTrackingReferenceBadge
        trackingReference={order.tracking_reference}
      />
    </WarehouseGrid>
  )
}

const Grid = styled.div`
  display: grid;
  gap: ${spacing.XS2};
`

const WarehouseGrid = styled.div`
  display: grid;
  column-gap: ${spacing.L};
  grid-template-columns: max-content 1fr;
`
