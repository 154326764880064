import { countryData } from "src/components/Account/BillingPortal/countryData"
import { TCountryDataCodes } from "src/data/countries/countryTypes"

/** Use country code to get country name; will return the country code if no
 * match is found and the fallback option is enabled. */
export function countryCodeToCountry(
  countryCode: TCountryDataCodes | string,
  options?: { fallback: boolean }
): string | undefined {
  const countryName = countryData.find((c) => c.code === countryCode)?.label
  if (options?.fallback) {
    return countryName || countryCode
  }
  return countryName
}
