import { useMinutQuery } from "src/hooks/minutQuery"

export const flagsKeys = {
  /** Use to target *all* flags data in the cache, with fuzzy matching; matching
   * this way is ineffective and should be used with care */
  all() {
    return ["flags"] as const
  },

  /** Use to target one specific organization */
  organization(organizationId: string) {
    return [...this.all(), "organization", organizationId] as const
  },

  /** Use to target one specific organization + user */
  user(orgId: string, userId: string) {
    return [...this.organization(orgId), "user", userId] as const
  },

  /** Use to target one specific organization + device */
  device(orgId: string, deviceId: string) {
    return [...this.organization(orgId), "device", deviceId] as const
  },
}

export function useFetchFlags(
  filters: {
    organization_id: string
    user_id?: string
    device_id?: string
  },
  options?: { refresh?: boolean; enabled?: boolean }
) {
  const queryKey = getQueryKey(filters)

  return useMinutQuery<`/flags`, "403">({
    queryKey,
    queryPath: `/flags`,
    filters,
    options: {
      cacheTime: 60,
      refetchOnMount: options?.refresh ? "always" : true,
      enabled: options?.enabled ?? true,
    },
  })
}

function getQueryKey(filters: {
  organization_id: string
  user_id?: string
  device_id?: string
}) {
  if (filters.device_id) {
    return flagsKeys.device(filters.organization_id, filters.device_id)
  } else if (filters.user_id) {
    return flagsKeys.user(filters.organization_id, filters.user_id)
  } else {
    return flagsKeys.organization(filters.organization_id)
  }
}
