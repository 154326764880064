import { useEffect } from "react"

import { useAppLocation } from "src/hooks/useAppLocation"

export function useScrollToTopOnRouteChange() {
  const location = useAppLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
}
