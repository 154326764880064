import { useRef, useState } from "react"
import styled from "styled-components"

import { useResizeObserver } from "usehooks-ts"

import {
  divider,
  hoverRowBackground,
  mColors,
  minimumGray,
} from "src/ui/colors"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TGridCell = JSX.Element

type TSize = "xSmall" | "small" | "medium"

type TShadowDirections = "left" | "right" | "both" | null

function numberInFr(n?: number) {
  if (!n) return ""
  return "1fr ".repeat(n).trim()
}

interface IWithFallbackResponsiveMode {
  /** @deprecated: Be explicit about handling the table view when it won't fit */
  useFallbackResponsiveMode: boolean
  mobileRows?: never
}
interface IWithResponsiveMode {
  /** @deprecated: Be explicit about handling the table view when it won't fit */
  useFallbackResponsiveMode?: never
  mobileRows: JSX.Element[]
}

export interface IGridTable {
  header?: TGridCell[]
  rows: TGridCell[]
  templateColumns?: string
  mobileRows?: JSX.Element[]
  responsiveModeTitle?: React.ReactNode
  onRowClick?: (rowIndex: number) => void
  loading?: boolean
  minWidth?: number
  children?: React.ReactNode
  cellSize?: TSize
  horizontalScroll?: boolean
  hideBorders?: boolean
  disableRowHover?: boolean
  hideHeaderDivider?: boolean
}

export function GridTable({
  rows,
  loading,
  minWidth = 680,
  mobileRows,
  cellSize,
  responsiveModeTitle,
  useFallbackResponsiveMode,
  horizontalScroll,
  hideBorders,
  disableRowHover,
  hideHeaderDivider,
  ...props
}: IGridTable & (IWithFallbackResponsiveMode | IWithResponsiveMode)) {
  const gridRef = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({
    ref: gridRef,
    box: "border-box",
  })

  const MobileView = () => {
    if (loading) {
      return (
        <GridLoading>
          <MCircularProgress />
        </GridLoading>
      )
    }

    if (mobileRows && !useFallbackResponsiveMode) {
      return (
        <div style={{ display: "grid", gap: spacing.M }}>
          {responsiveModeTitle && (
            <MText variant="subtitle">{responsiveModeTitle}</MText>
          )}

          {mobileRows.map((row, i) => (
            <div
              key={i}
              onClick={() => {
                props.onRowClick?.(i)
              }}
              style={{ cursor: !!props.onRowClick ? "pointer" : undefined }}
            >
              {row}
            </div>
          ))}
        </div>
      )
    }

    // Fallback ugly rows
    return (
      <>
        {rows.map((row, i) => (
          <Item
            key={i}
            onClick={() => {
              props.onRowClick?.(i)
            }}
            style={{ cursor: !!props.onRowClick ? "pointer" : undefined }}
          >
            {row}
          </Item>
        ))}
      </>
    )
  }

  return (
    <div ref={gridRef}>
      {width >= minWidth ? (
        <MGrid
          rows={rows}
          loading={loading}
          cellSize={cellSize}
          horizontalScroll={horizontalScroll}
          hideBorders={hideBorders}
          hideHeaderDivider={hideHeaderDivider}
          disableRowHover={disableRowHover}
          {...props}
        />
      ) : (
        <MobileView />
      )}
      {props.children}
    </div>
  )
}

function MGrid({
  header = [],
  rows,
  templateColumns,
  onRowClick,
  loading,
  cellSize,
  horizontalScroll,
  hideBorders,
  disableRowHover,
  hideHeaderDivider,
}: IGridTable) {
  const [shadowDirection, setShadowDirection] =
    useState<TShadowDirections>(null)
  const gridRef = useRef<HTMLDivElement>(null)

  const columns = templateColumns || numberInFr(header?.length) || "1fr"

  function updateShadow() {
    if (!gridRef.current) {
      setShadowDirection(null)
      return
    }

    const { scrollWidth, scrollLeft, clientWidth } = gridRef.current
    const hasScrolledLeft = scrollLeft !== 0
    const hasScrolledRight = scrollLeft + clientWidth !== scrollWidth

    if (hasScrolledLeft && hasScrolledRight) {
      setShadowDirection("both")
      return
    }

    if (hasScrolledLeft) {
      setShadowDirection("left")
      return
    }

    if (hasScrolledRight) {
      setShadowDirection("right")
      return
    }

    setShadowDirection(null)
  }

  useResizeObserver({
    ref: gridRef,
    box: "border-box",
    onResize: () => {
      if (horizontalScroll) {
        updateShadow()
      }
    },
  })

  return (
    <Grid
      $templateColumns={columns}
      $horizontalScroll={!!horizontalScroll}
      $shadowDirection={shadowDirection}
      onScroll={horizontalScroll ? updateShadow : undefined}
      ref={gridRef}
    >
      {!!header.length && (
        <GridHeader $cellSize={cellSize} $hideHeaderDivider={hideHeaderDivider}>
          {header.map((headerCell, index) => (
            <Cell key={!!headerCell.key ? headerCell.key : index}>
              <MText as="span" variant="subtitleS" color="secondary">
                {headerCell}
              </MText>
            </Cell>
          ))}
        </GridHeader>
      )}

      {loading ? (
        <LoadingState columns={header.length} />
      ) : (
        rows.map((row, index1) => {
          return (
            <GridRow
              key={row.key || index1}
              onClick={() => {
                onRowClick?.(index1)
              }}
              cursor={onRowClick ? "pointer" : undefined}
              $cellSize={cellSize}
              $disableRowHover={disableRowHover}
              $hideBorders={hideBorders}
            >
              {row}
            </GridRow>
          )
        })
      )}
    </Grid>
  )
}

function LoadingState({
  rows = 5,
  columns,
}: {
  rows?: number
  columns: number
}) {
  return (
    <>
      {Array.from(Array(rows)).map((_, index1) => {
        return (
          <GridRow key={index1}>
            {Array.from(Array(columns)).map((_, index1) => {
              return (
                <div key={index1}>
                  <MSkeleton height={"1.5em"} style={{ minWidth: "2em" }} />
                </div>
              )
            })}
          </GridRow>
        )
      })}
    </>
  )
}

const shadows = {
  left: "rgba(0, 0, 0, 0.08) 12px 0px 12px -10px inset",
  right: "rgba(0, 0, 0, 0.08) -12px 0px 12px -10px inset",
  get both() {
    return `${this.left}, ${this.right}`
  },
}

const Grid = styled.section<{
  $templateColumns: string
  $horizontalScroll: boolean
  $shadowDirection: TShadowDirections
}>`
  display: grid;
  grid-template-columns: ${(props) => props.$templateColumns};

  overflow: ${({ $horizontalScroll }) =>
    $horizontalScroll ? "auto" : "hidden"};
  box-shadow: ${({ $shadowDirection, $horizontalScroll }) =>
    $shadowDirection && $horizontalScroll && shadows[$shadowDirection]};
`

const GridRow = styled.div<{
  cursor?: string
  $cellSize?: TSize
  $hideBorders?: boolean
  $disableRowHover?: boolean
}>`
  cursor: ${({ cursor }) => (cursor ? cursor : "default")};

  display: contents;

  ${({ $disableRowHover }) =>
    !$disableRowHover &&
    `
      &:hover > * {
        background: ${hoverRowBackground};
      }
  `}

  // affects the 'cells' of the row
  > * {
    border-bottom: ${({ $hideBorders }) =>
      !$hideBorders ? `1px solid ${mColors.divider}` : undefined};

    ${({ $cellSize }) => {
      switch ($cellSize) {
        case "xSmall":
          return `
            padding-inline: ${spacing.XS};
            padding-block: ${spacing.XS2};
        `
        case "small":
          return `
        padding-inline: ${spacing.M};
        padding-block: ${spacing.S};
      `
        case "medium":
          return `padding: ${spacing.M};`
        default:
          return `padding: ${spacing.M};`
      }
    }}

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const GridHeader = styled.header<{
  $cellSize?: TSize
  $hideHeaderDivider?: boolean
}>`
  display: contents;
  border: unset;
  padding: unset;

  & > * {
    border-bottom: ${({ $hideHeaderDivider }) =>
      !$hideHeaderDivider ? `1px solid ${mColors.divider}` : undefined};
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ $cellSize }) => {
      switch ($cellSize) {
        case "xSmall":
          return `padding: ${spacing.XS2} ${spacing.XS};`
        case "small":
        case "medium":
          return `padding: ${spacing.XS} ${spacing.M};`
        default:
          return `padding: ${spacing.XS} ${spacing.M};`
      }
    }}
  }
`

const Cell = styled.div``

const Item = styled.div`
  padding: ${spacing.M} 0;
  border-bottom: 1px solid ${divider};
  &:hover {
    background: ${minimumGray}09;
  }
`

const GridLoading = styled.div`
  margin: ${spacing.L} auto ${spacing.M} auto;
  width: max-content;
  grid-column: 1 / -1;
`
