import { QueryClient } from "@tanstack/react-query"

import {
  IProfileFetchFilter,
  IProfileQueryResponse,
  IProfileResponse,
} from "src/data/homeProfiles/types/homeProfilesTypes"

export const profilesCache = {
  all(): TProfileCacheAll {
    return ["profiles"]
  },

  lists(): TProfileListsCache {
    return [...this.all(), "list"]
  },
  list(filter?: IProfileFetchFilter, orgId?: string): TProfileListCache {
    return [...this.lists(), filter, orgId]
  },

  items(): TProfileItemsCache {
    return [...this.all(), "item"]
  },
  item(id: string): TProfileItemCache {
    return [...this.items(), id]
  },
}

type TProfileCacheAll = ["profiles"]

type TProfileListsCache = ["profiles", "list"]
export type TProfileListCache = [
  ...TProfileListsCache,
  IProfileFetchFilter | undefined,
  string | undefined,
]

type TProfileItemsCache = [...TProfileCacheAll, "item"]
export type TProfileItemCache = [...TProfileItemsCache, string]

export function getCachedProfileFromList(
  queryClient: QueryClient,
  id: string
): IProfileResponse | undefined {
  const listsCache = queryClient.getQueriesData<IProfileQueryResponse>(
    profilesCache.lists()
  )
  for (const [, profileResponse] of listsCache) {
    for (const profile of profileResponse?.profiles || []) {
      if (profile.id === id) {
        return profile
      }
    }
  }
}
