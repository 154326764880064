import { useEffect } from "react"

import { TRecentlyViewedPageType } from "src/components/RecentlyViewed/RecentlyViewedContext"
import { useRecentlyViewedContext } from "src/components/RecentlyViewed/useRecentlyViewedContext"
import { useAppLocation } from "src/hooks/useAppLocation"

/**
 * Add a page to the Recently viewed pages log.
 * If the page already has been added, it gets bumped to the top.
 *
 * The page will only be added once the metadata is loaded (not `undefined`)
 *
 * @param title Title of the page
 * @param enitytId ID of the entity
 * @param type Type of the page
 */
export function useAddRecentlyViewedPage({
  title,
  enitytId,
  type,
  enabled,
}: {
  title: string | undefined
  enitytId: string | undefined
  type: TRecentlyViewedPageType | undefined
  enabled: boolean
}) {
  const { addPage } = useRecentlyViewedContext()
  const location = useAppLocation()

  useEffect(() => {
    if (enabled && title && enitytId && type) {
      addPage({
        title,
        enitytId,
        type,
        path: location.pathname,
      })
    }
  }, [enabled, title, enitytId, type, location, addPage])
}
