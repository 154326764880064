function apiPath(version: string) {
  return `${import.meta.env.REACT_APP_API_URL}/${version}`
}

export const API_DEFAULT = apiPath("v8")
export const API_PARADISE = `${import.meta.env.REACT_APP_API_URL}/dashboard`

// Feature specific API paths
export const API_ANALYTICS_TRACK = `${API_DEFAULT}/enlightenment/fries`
export const API_ANALYTICS_TRACK_PAGE = `${API_DEFAULT}/enlightenment/drink`
export const API_ANALYTICS_IDENTIFY = `${API_DEFAULT}/enlightenment/burger`
