import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { IFetchHomes } from "src/data/homes/types/homeQueryTypes"
import { occupancyKeys } from "src/data/occupancy/queries/occupancyQueryCache"
import {
  IOccupancy,
  IOccupancyConfigPayload,
  IOccupancyDataRequest,
  IOccupancyDataResponse,
  IOccupancyThresholdMapping,
} from "src/data/occupancy/types/occupancyTypes"
import {
  orgsKeys,
  useOrgQueryCache,
} from "src/data/organizations/queries/organizationQueryCache"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePatchOccupancy({
  orgId,
  homeId,
}: {
  orgId?: string
  homeId: string
}) {
  const queryClient = useQueryClient()
  const { updateCachedHomeDetail } = useOrgQueryCache()

  async function patchOccupancyConfig(
    homeId: string,
    payload?: IOccupancyConfigPayload
  ): Promise<IOccupancy> {
    const response = await minutApiHttpClient.patch(
      `${API_DEFAULT}/homes/${homeId}/occupancy_detection`,
      payload
    )
    const occupancy: IOccupancy = response.data.occupancy
    return occupancy
  }

  return useMutation(
    ({ active, capacity }: { active?: boolean; capacity?: number }) =>
      patchOccupancyConfig(homeId, { active, capacity }),
    {
      onSuccess: (result) => {
        if (!orgId) {
          return
        }
        // Deal with lists
        const cacheKey = orgsKeys.homeLists(orgId)
        queryClient.setQueriesData<IFetchHomes | undefined>(
          cacheKey,
          (cache) => {
            if (!cache) return undefined

            const { homes: cachedHomes = [], paging } = cache
            const transformedHomes = cachedHomes?.map((cachedHome) => {
              if (cachedHome?.home_id === homeId) {
                return { ...cachedHome, occupancy: result }
              }
              return cachedHome
            })
            return { homes: transformedHomes, paging }
          }
        )
        // Deal with home details
        updateCachedHomeDetail(orgId, homeId, (cachedHomes) => {
          return { ...cachedHomes, occupancy: result }
        })
      },
    }
  )
}

export function useFetchOccupancyThresholdMapping(
  queryOptions?: UseQueryOptions<
    IOccupancyThresholdMapping,
    AxiosError,
    IOccupancyThresholdMapping,
    ReturnType<typeof occupancyKeys.threshold>
  >
) {
  async function fetchOccupancyThresholdMapping(): Promise<IOccupancyThresholdMapping> {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/occupancy/capacity_threshold_mapping`
    )
    const mapping: IOccupancyThresholdMapping = response.data
    return mapping
  }
  return useQuery(
    occupancyKeys.threshold(),
    () => fetchOccupancyThresholdMapping(),
    {
      keepPreviousData: true,
      ...queryOptions,
    }
  )
}

export function useFetchOccupancyData({
  id,
  params,
  queryOptions,
}: {
  id: string
  params?: Partial<IOccupancyDataRequest>
  queryOptions?: UseQueryOptions<
    IOccupancyDataResponse,
    AxiosError,
    IOccupancyDataResponse,
    ReturnType<typeof occupancyKeys.occupancy>
  >
}) {
  async function fetchOccupancyData(
    id: string,
    params?: Partial<IOccupancyDataRequest>
  ): Promise<IOccupancyDataResponse> {
    if (!id) {
      return { values: [] }
    }

    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/occupancy_service/homes/${id}`,
      { params }
    )
    const data: IOccupancyDataResponse = response.data
    return data
  }
  return useQuery(
    occupancyKeys.occupancy({ homeId: id, params }),
    () => fetchOccupancyData(id, params),
    {
      // staleTime: 60000,
      keepPreviousData: true,
      ...queryOptions,
    }
  )
}
