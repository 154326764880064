import { useRef, useState } from "react"

import { OccupancyIcon } from "src/components/Homes/HomeDetails/Occupancy/OccupancyIcon"
import { OccupancyPopover } from "src/components/Homes/HomeDetails/Occupancy/OccupancyPopover"
import { Violations } from "src/data/homes/types/homeTypes"
import { usePatchOccupancy } from "src/data/occupancy/queries/occupancyQueries"
import { IOccupancy } from "src/data/occupancy/types/occupancyTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import {
  MonitoringButton,
  OnOffStatusLabel,
} from "src/ui/MonitoringButton/MonitoringButton"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function OccupancyButton({
  occupancy,
  homeId,
  violations,
  graphicalButton,
  hideStatus,
  toggleAllowed,
}: {
  occupancy: IOccupancy | undefined
  homeId: string
  violations?: Violations[]
  graphicalButton?: boolean
  hideStatus?: boolean
  toggleAllowed?: boolean
}) {
  const { org } = useOrganization()
  const { t } = useTranslate()

  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const mTooltip = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  const patchOccupancy = usePatchOccupancy({ orgId: org?.id, homeId })

  async function update({
    active,
    capacity,
  }: {
    active?: boolean
    capacity?: number
  }) {
    setOpen(false)
    patchOccupancy.mutate({ active, capacity })
  }

  const mButton = graphicalButton ? (
    <OccupancyButtonIcon
      active={!!occupancy?.active}
      status={occupancy?.status}
      isUpdating={patchOccupancy.isLoading}
      disabled={!toggleAllowed}
      onClick={() => setOpen(true)}
      hideStatus={hideStatus}
    />
  ) : (
    <MButton
      variant="subtle"
      size="small"
      onClick={() => setOpen(true)}
      loading={patchOccupancy.isLoading}
      disabled={!toggleAllowed}
    >
      {t(langKeys.manage)}
    </MButton>
  )

  return (
    <>
      <MTooltip title={mTooltip} placement={graphicalButton ? "left" : "top"}>
        <div ref={ref}>{mButton}</div>
      </MTooltip>
      {open && occupancy && (
        <OccupancyPopover
          open={open}
          activeInitial={occupancy.active}
          capacityInitial={occupancy.capacity}
          threshold={occupancy.scanned_devices_threshold}
          anchorEl={ref.current}
          handleClose={() => setOpen(false)}
          violations={violations}
          update={update}
        />
      )}
    </>
  )
}

function OccupancyButtonIcon({
  active,
  status,
  isUpdating,
  disabled,
  onClick,
  hideStatus,
}: {
  active: IOccupancy["active"]
  status: IOccupancy["status"]
  isUpdating?: boolean
  disabled: boolean
  onClick: () => void
  hideStatus?: boolean
}) {
  return (
    <MonitoringButton
      label={<OnOffStatusLabel isOn={active} />}
      labelType={active ? "good" : "default"}
      onClick={onClick}
      updating={isUpdating}
      icon={
        <OccupancyIcon
          active={active}
          status={status}
          iconProps={{ height: "30", width: "30" }}
        />
      }
      hideStatus={hideStatus}
      disabled={disabled}
      row={true}
    />
  )
}
