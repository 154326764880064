import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { mColors } from "src/ui/colors"
import { Container } from "src/ui/Container/Container"
import { TDefaultBoxWidth } from "src/ui/Layout/DefaultBox"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
export interface TTitleBarProps {
  title: React.ReactNode
  description?: React.ReactNode
  actionBar?: React.ReactNode
  children?: React.ReactNode
  showBottomBorder?: boolean
  bottomMargin?: spacing | 0
  breadcrumbs?: React.ReactNode
  size?: TDefaultBoxWidth
  titleVariant?: MTextProps["variant"]
}

export function Titlebar({
  title,
  description,
  actionBar,
  children,
  showBottomBorder,
  breadcrumbs,
  bottomMargin = spacing.XL2,
  size = "medium",
  titleVariant = "heading1",
}: TTitleBarProps) {
  return (
    <Container>
      <TitleBar
        $showBottomBorder={!!showBottomBorder}
        $bottomMargin={bottomMargin}
        size={size}
      >
        <LeftBox>
          {breadcrumbs}
          <TitleText>
            <StyledHeading variant={titleVariant}>{title}</StyledHeading>
            {description && (
              <MText variant="body" color="secondary">
                {description}
              </MText>
            )}
          </TitleText>
        </LeftBox>
        {actionBar}
        {children}
      </TitleBar>
    </Container>
  )
}

const LeftBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const TitleBar = styled.div<{
  $showBottomBorder: boolean
  $bottomMargin: TTitleBarProps["bottomMargin"]
  size: TDefaultBoxWidth
}>`
  display: grid;
  align-items: start;
  gap: ${spacing.M};

  button {
    flex: 0 0 auto;
  }

  border-bottom: ${({ $showBottomBorder }) =>
    !$showBottomBorder ? "none" : `1px solid ${mColors.divider}`};
  padding-bottom: ${({ $showBottomBorder: showBottomBorder }) =>
    !showBottomBorder ? "unset" : `${spacing.XL}`};

  margin-bottom: ${({ $bottomMargin }) => $bottomMargin};

  max-width: ${(props) => {
    switch (props.size) {
      case "small":
        return "700px"
      case "medium":
        return "940px"
      case "large":
        return "1300px"
      default:
        return "unset"
    }
  }};

  @container (${breakpoint.smallUp}) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`

const TitleText = styled.div`
  display: grid;
  max-width: 80ch;
`

const StyledHeading = styled(MText)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
