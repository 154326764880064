import qs from "qs"

import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const RESPONSE_SERVICE_ROUTE = `/response-service`

const ResponseService: ILocation<{ limit?: number; offset?: number } | void> & {
  tabs: [string]
} = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return {
        pathname: `${RESPONSE_SERVICE_ROUTE}`,
        search: `?limit=${HOME_LIST_LIMIT}&offset=0`,
      }
    }
    return { pathname: `${RESPONSE_SERVICE_ROUTE}`, search: `?${queryFilter}` }
  },
  tabs: ["response-service"],
}

const ActivateResponseService: ILocation<{ provider?: string }> = {
  location({ provider }) {
    if (provider) {
      return { pathname: `${RESPONSE_SERVICE_ROUTE}/activate/${provider}` }
    }
    return { pathname: `${RESPONSE_SERVICE_ROUTE}/activate` }
  },
}

const CallAssist = createBasicLocation("/call-assist")
const AssistServices = createBasicLocation("/assist")

export const ResponseServiceRoutes = {
  AssistServices,
  CallAssist,
  ResponseService,
  ActivateResponseService,
} as const
