import { usePostTrackEventWithParams } from "src/data/analytics/queries/analyticsQueries"

type TIndoorClimateEventTypes =
  | "Indoor Climate Monitoring Popup Opened"
  | "Indoor Climate Monitoring Toggled"
  | "Temperature Toggled"
  | "Humidity Toggled"

function usePostIndoorClimateTrackingEvent<TEventProperties>(
  event: TIndoorClimateEventTypes
) {
  const postTrackEvent = usePostTrackEventWithParams<
    TIndoorClimateEventTypes,
    TEventProperties
  >({
    event,
  })
  return postTrackEvent
}

export function usePostIndoorClimatePopupOpened() {
  return usePostIndoorClimateTrackingEvent(
    "Indoor Climate Monitoring Popup Opened"
  )
}

export function usePostIndoorClimateToggled() {
  return usePostIndoorClimateTrackingEvent("Indoor Climate Monitoring Toggled")
}

export function usePostTemperatureToggled() {
  return usePostIndoorClimateTrackingEvent("Temperature Toggled")
}

export function usePostHumidityToggled() {
  return usePostIndoorClimateTrackingEvent("Humidity Toggled")
}
