import { useContext } from "react"

import { FlagContext } from "src/components/Flags/FlagContext"
import { FeatureFlag } from "src/constants/featureFlags"

export function useFlags() {
  const flagContext = useContext(FlagContext)
  function toggleFlag(flag: FeatureFlag) {
    flagContext.setFeature(flag, !flagContext.isEnabled(flag))
  }

  return {
    reset: flagContext.reset,
    setFlag: flagContext.setFeature,
    toggleFlag,
    featureFlag: FeatureFlag,

    /* Here we're spreading out all the flags from the context so that they can
     * be immediately available in the hook */
    ...flagContext.flags,

    /** @deprecated */
    getFlag: flagContext.isEnabled,
  }
}
