import { BackendLoginClient } from "src/constants/env"
import { API_DEFAULT } from "src/constants/minutApi"
import {
  codeVerifierStorage,
  getJwtFromAccessToken,
} from "src/data/auth/authStorage"
import { TMaybeAuthorization } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { MLocation } from "src/router/routeTypes"
import { debug, logger } from "src/utils/logger"
import {
  setApiClientAccessToken,
  setTokenExpiredInterceptor,
} from "src/utils/minutApiHttpClient"

/**
 * Prepares everything needed to authenticate the user, send the user
 * to the authentication service.
 */
export async function redirectToAuthService(location: MLocation) {
  // validate location, so it cannot be a path
  const fromLocation = location.state?.from
  let path
  if (!fromLocation || !fromLocation.pathname) {
    debug.warn(`Path was empty; defaulting to '/'`)
    path = "/"
  } else if (
    fromLocation.pathname.includes(
      Routes.OAuthExchangeCodeForToken.location().pathname
    )
  ) {
    debug.warn(
      `Path was '${fromLocation.pathname}'; resetting to '/' in order to avoid loops`
    )
    path = "/"
  } else {
    path = fromLocation.pathname
  }
  const returnToPath = `${path}${fromLocation?.search ?? ""}${
    fromLocation?.hash ?? ""
  }`
  const { uri, codeVerifier, state } = await prepareAuthRedirect(returnToPath)
  debug.log({ uri, codeVerifier, state, returnToPath })
  codeVerifierStorage.set(codeVerifier)
  debug.warn(">>> Redirecting to auth service")
  window.location.href = uri
}

/**
 * Prepares all values needed to complete PKCE sign in
 * @param returnToPath The uri the user should be taken to after successful sign in
 * @returns A promise that resolves with the the code verifier, code challenge, uri and state
 */
async function prepareAuthRedirect(returnToPath: string) {
  const codeVerifier = generatePkceVerifier()
  const codeChallenge = await calculatePkceChallenge(codeVerifier)
  const stateValue = await calculatePkceChallenge(generatePkceVerifier())
  const state = {
    value: stateValue,
    returnToPath,
  }
  const encodedState = encodeURIComponent(JSON.stringify(state))

  const redirectUri = `${window.location.origin}${
    Routes.OAuthExchangeCodeForToken.location().pathname
  }`
  debug.log({ redirectUri })

  const uri =
    `${API_DEFAULT}/oauth/authorize` +
    `?client_id=${BackendLoginClient.clientId}` +
    `&state=${encodedState}` +
    `&response_type=code` +
    `&code_challenge=${encodeURIComponent(codeChallenge)}` +
    `&code_challenge_method=S256` +
    `&redirect_uri=${encodeURIComponent(redirectUri)}`

  return { codeVerifier, codeChallenge, state, uri }
}

function generatePkceVerifier() {
  const array = new Uint32Array(28)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ("0" + dec.toString(16)).slice(-2)).join("")
}

async function calculatePkceChallenge(verifier: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(verifier)
  const hashed = await window.crypto.subtle.digest("SHA-256", data)
  const b64 = window
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    .btoa(String.fromCharCode.apply(null, new Uint8Array(hashed) as any))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "")
  return b64
}

export function setAxiosApiToken({
  accessToken,
  onFailedRefresh,
}: {
  accessToken: string
  onFailedRefresh: () => void
}) {
  setApiClientAccessToken(accessToken)
  setTokenExpiredInterceptor({
    onFailedRefresh: () => {
      logger.log("Refresh failed, logout and try again")
      onFailedRefresh()
    },
  })
}

function getUrlAccessToken() {
  const searchParams = new URLSearchParams(window.location.search)
  const accessToken = searchParams.get("access_token")
  if (!accessToken) {
    return null
  }
  return accessToken
}

export function getUrlAuth(): TMaybeAuthorization {
  const urlAccessToken = getUrlAccessToken()
  if (!urlAccessToken) return null
  const jwtData = getJwtFromAccessToken(urlAccessToken)
  return {
    access_token: urlAccessToken,
    refresh_token: "n/a",
    expires_in: 3600, // placeholder
    user_id: jwtData.userId,
    token_type: "Bearer",
  }
}

export function purgeAccessTokenParam(location: MLocation): MLocation {
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete("user_id")
  if (searchParams.get("access_token")) {
    debug.log("Removing access token from url")
    searchParams.delete("access_token")
  }
  const newLocation = { ...location, search: String(searchParams) }
  return newLocation
}

//==============================================================================
// Authorization accessors & setters
//==============================================================================
export {
  getStoredAuthorization,
  setStoredAuthorization,
  setStoredImpersonation,
  getImpersonateActive,
} from "./authStorage"
