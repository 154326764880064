import { createContext } from "react"

import {
  getBrowserNotificationState,
  TMNotificationParams,
  TNotificationPermission,
} from "src/components/Notifications/notificationUtil"
import { NotificationPayload } from "src/data/notifications/firebase"
import { TUser } from "src/data/user/user"
import { Result } from "src/utils/tsUtil"

export type TEnableErrors =
  | "permission-denied"
  | "generic-error"
  | "token-error"
export type TEnableResult = Result<boolean, TEnableErrors>

export const NotificationContext = createContext({
  loading: false,
  /** `true` if notifications are enabled for this browser. */
  notificationsEnabled: false,
  /** Set notification enabled state. */
  setNotificationsEnabled: async (
    _enabled: boolean
  ): Promise<TEnableResult> => ({ ok: false }),
  /**
   * Display a notification if notifications are enabled; else noop.
   * params: https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#parameters
   */
  showNotification: (_params: TMNotificationParams) => {},
  /** Contains active firebase token if initialized. */
  get firebaseToken(): null | string {
    return null
  },
  /** Returns the browser notification permission state. */
  get browserPermission(): TNotificationPermission {
    return getBrowserNotificationState()
  },
  /** Contains a log for all received notifications. */
  get notificationLog(): NotificationPayload[] {
    return []
  },

  // Debugging:
  get backendNotificationsEnabled(): boolean | undefined {
    return undefined
  },
  setBackendNotificationsEnabled: async (
    _v: TUser["desktop_notifications_enabled"]
  ): Promise<TUser | undefined> => undefined,
})
