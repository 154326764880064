// eslint-disable-next-line no-restricted-imports
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom"
import styled from "styled-components"

import { useAppData } from "src/context/useAppData"
import { useAppLocation } from "src/hooks/useAppLocation"
import { TPartialLocation } from "src/router/routeTypes"
import { getLocation, locationWithOrgId } from "src/ui/Link/internalLinkUtil"
import { MLinkProps, MLinkStyle, MLinkStyleProps } from "src/ui/Link/MLink"

export type TInternalLinkProps = {
  to: TPartialLocation | string
  setFromState?: boolean
  disabled?: boolean
  noWrap?: boolean
} & Omit<LinkProps, "to"> &
  Pick<MLinkProps, "underline" | "color" | "beforeNav">

type TInternalNavLinkProps = TInternalLinkProps & Omit<NavLinkProps, "to">

export function InternalLink({
  children,
  to,
  setFromState = true,
  beforeNav,
  underline = "always",
  color = "unset",
  noWrap,
  ...rest
}: TInternalLinkProps) {
  const _to = useLinkLocation({ setFromState, to })
  return (
    <StyledInternalLink
      onClick={() => beforeNav?.()}
      {...rest}
      to={_to}
      $underline={underline}
      $color={color}
      $noWrap={noWrap}
    >
      {children}
    </StyledInternalLink>
  )
}

/**
 * For the life of me, I can't seem to remember the name `InternalLink`. Adding
 * this alias so that the auto-import will figure it out for me. 🤡
 *
 * @deprecated Use `InternalLink` instead.
 */
// ts-prune-ignore-next
export const AppLink = InternalLink

export function InternalNavLink({
  children,
  to,
  setFromState = true,
  underline = "always",
  color = "unset",
  ...rest
}: TInternalNavLinkProps) {
  const _to = useLinkLocation({ setFromState, to })

  return (
    <StyledInternalNavLink
      {...rest}
      to={_to}
      activeClassName="is-active"
      $underline={underline}
      $color={color}
    >
      {children}
    </StyledInternalNavLink>
  )
}

function useLinkLocation({
  setFromState,
  to,
}: {
  setFromState: boolean
  to: TInternalLinkProps["to"]
}) {
  const location = useAppLocation()
  const { activeOrg } = useAppData()

  const state = setFromState ? { from: location } : undefined
  const partialLocation = getLocation(to, state)

  // Here we're injecting the currently active org id as a query param:
  return locationWithOrgId({
    location: partialLocation,
    orgId: activeOrg?.id,
  })
}

const StyledInternalLink = styled(Link)<MLinkStyleProps>`
  ${MLinkStyle};
  ${({ $noWrap }) => $noWrap && "white-space: nowrap;"}
`

const StyledInternalNavLink = styled(NavLink)<MLinkStyleProps>`
  ${MLinkStyle};
`
