import {
  endTimeReached,
  getCountdown,
} from "src/data/homeActions/logic/countdown"
import { AlarmStatus } from "src/data/homes/types/alarmTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction } from "src/i18n/useTranslate"

export function getUIAlarmState(
  alarmStatus: AlarmStatus,
  deadline: number | null
): AlarmStatus {
  if (alarmStatus === "on_grace_period" && !deadline) {
    return "off"
  }
  if (alarmStatus === "on_grace_period" && endTimeReached(deadline)) {
    return "on"
  }

  return alarmStatus
}

export function getSecurityAlarmLabel({
  t,
  alarmStatus,
  deadline,
  now,
}: {
  t: TTranslateFunction
  alarmStatus: AlarmStatus | undefined
  deadline: number | null
  now: number
}): string {
  switch (alarmStatus) {
    case "off_grace_period": // TODO: WEB-107; Handle like 'ON' state for now
    case "critical_event":
    case "on":
      return t(langKeys.on)
    case "off":
      return t(langKeys.off)
    case "on_grace_period": {
      const time = getCountdown(now, deadline)
      return t(langKeys.power_saving_mode_on_in, { 0: time })
    }
    case undefined:
    default:
      return ""
  }
}
