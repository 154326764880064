// This is needed to separate different types of modals that we may need later on, for exampel popover
export const BASE_DIALOG_CLASSNAME = "base-dialog-modal"

/**
 * This makes sure that non-native popovers and similar gets rendered inside the currently active (top-most) dialog
 * This is used to set the root/container of the correct portal
 *
 * NOTE: This is needed now until the native `popover` and `anchor` APIs have better support
 */
export function getPortalRoot() {
  const openDialogs = document.querySelectorAll(
    `.${BASE_DIALOG_CLASSNAME}[open]`
  )

  if (openDialogs.length === 0) {
    return null
  }

  return openDialogs[openDialogs.length - 1] as HTMLElement
}
