import { useState } from "react"

import { format } from "date-fns"

import { usePostExportConfirmed } from "src/data/analytics/queries/exportAnalyticsQueries"
import { IHomeFilterBody } from "src/data/homes/types/homeQueryTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useDownloadHomesCSV } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { downloadFile } from "src/utils/downloadUtil"
import { slugify } from "src/utils/genericUtil"

type DownloadHomesCsvButtonProps = {
  hidden?: boolean
  filters: IHomeFilterBody
  affectedNbrHomes: number
  onError?: () => void
}

export function DownloadHomesCsvButton({
  hidden,
  filters,
  affectedNbrHomes,
  onError,
}: DownloadHomesCsvButtonProps) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const downloadHomesCSV = useDownloadHomesCSV({ orgId })

  const postExportConfirmed = usePostExportConfirmed()
  const [showDialog, setShowDialog] = useState(false)

  function handleDownloadCSV() {
    postExportConfirmed.mutate({ filters })
    downloadHomesCSV.mutate(
      { filters },
      {
        onSuccess(data) {
          downloadFile(
            data,
            generateFilename({
              date: format(new Date(), "yyyy-MM-dd"),
              name: slugify(t(langKeys.home, { count: 10 })),
              suffix: "csv",
            }),
            "text/csv"
          )
          setShowDialog(false)
        },
        onError,
      }
    )
  }

  if (hidden) return null

  return (
    <MButton onClick={() => setShowDialog(true)} variant="subtle" size="small">
      {t(langKeys.download_csv)}

      <ConfirmDialog
        title={t(langKeys.download_csv)}
        onClose={() => setShowDialog(false)}
        open={showDialog}
        onConfirm={() => handleDownloadCSV()}
        confirmButtonProps={{ loading: downloadHomesCSV.isLoading }}
        confirmLabel={t(langKeys.download_csv)}
        error={
          downloadHomesCSV.error && t(langKeys.failed_something_went_wrong)
        }
      >
        {t(langKeys.download_csv_homes_content, { nbrHomes: affectedNbrHomes })}
      </ConfirmDialog>
    </MButton>
  )
}

function generateFilename({
  name,
  date,
  suffix,
}: {
  name: string
  date: string
  suffix: string
}) {
  // Example return string: 2023-12-24_rental-units.csv
  return `${date}_${name}.${suffix}`
}
