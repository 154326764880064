import { useAppData } from "src/context/useAppData"

/** Convenience hook to get the current user.
 *
 * N.B: Can _only_ be used within authenticated routes. Contexts outside of such
 * a route must fetch the potentially undefined user from AppData.
 */
export function useGetUser() {
  const { user } = useAppData()

  if (!user) {
    throw Error(
      "Hook is being used in a context where user is not available; fetch the user from AppData instead"
    )
  }
  return user
}
