const smallWidth = "500px"
export const mediumWidth = "800px"
const normalWidth = "1024px"
const largeWidth = "1400px"

export const breakpoint = {
  smallUp: `min-width: ${smallWidth}`,
  smallDown: `max-width: ${smallWidth}`,

  mediumUp: `min-width: ${mediumWidth}`,
  mediumDown: `max-width: ${mediumWidth}`,

  normalDown: `max-width: ${normalWidth}`,
  normalUp: `min-width: ${normalWidth}`,

  largeDown: `max-width: ${largeWidth}`,
  largeUp: `min-width: ${largeWidth}`,
} as const
