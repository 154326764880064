import styled from "styled-components"

import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function EmptyState({
  icon,
  title,
  body,
  children,
}: {
  icon: React.ReactNode
  title: React.ReactNode
  children?: React.ReactNode
  body?: React.ReactNode
}) {
  return (
    <Box>
      <IconBox>{icon}</IconBox>
      <div style={{ maxWidth: "28rem" }}>
        <MText variant="heading2" marginBottom={spacing.XS}>
          {title}
        </MText>
        <MText variant="body" color="secondary">
          {body}
        </MText>
        {children}
      </div>
    </Box>
  )
}

const Box = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing.XL2};
  text-align: center;
`
const IconBox = styled.div`
  & > svg {
    width: 220px;
  }

  & > img {
    height: 220px;
  }

  margin-bottom: ${spacing.L};
`
