import { useUrlParam } from "src/hooks/useUrlParam"
import { QUERYPARAM_ACTIVE_ORG } from "src/router/routerQueryParams"

export function useOrganizationQueryId() {
  const { value: orgIdParam, replaceValue: replaceOrgIdParam } = useUrlParam(
    QUERYPARAM_ACTIVE_ORG,
    { unique: true }
  )
  return { orgIdParam, replaceOrgIdParam }
}
