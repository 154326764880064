import styled from "styled-components"

import { getPercent } from "src/data/homeActions/logic/countdown"
import { AlarmStatus } from "src/data/homes/types/alarmTypes"
import { CircleProgressBar } from "src/ui/CircleProgressBar/CircleProgressBar"
import { colorScale, mColors } from "src/ui/colors"
import SecurityAlarmFilledIcon from "src/ui/icons/security-alarm-filled.svg"
import { SVGProps } from "src/utils/tsUtil"

export function DynamicSecurityAlarmIcon({
  now,
  deadline,
  gracePeriodSecs,
  alarmStatus,
  iconProps,
  progressBarProps,
}: {
  now: number
  deadline: number | null
  gracePeriodSecs: number | undefined
  alarmStatus?: AlarmStatus
  iconProps?: SVGProps
  progressBarProps?: SVGProps
}) {
  switch (alarmStatus) {
    case "critical_event":
    case "off_grace_period":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.systemErrorLight}
          color={mColors.systemErrorDark}
          {...iconProps}
        />
      )
    case "on":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.systemInfoLight}
          color={mColors.systemInfoDark}
          {...iconProps}
        />
      )
    case undefined:
    case "off":
      return (
        <SecurityAlarmFilledIcon
          fill={mColors.neutralDark}
          color={colorScale.koti[400]}
          {...iconProps}
        />
      )
    case "on_grace_period":
      return (
        <StyledProgressBox width={iconProps?.width}>
          <CircleProgressBar
            trailStrokeColor="#0000"
            strokeColor={mColors.systemInfoDark}
            value={getPercent(now, deadline, gracePeriodSecs)}
            strokeWidth={1.5}
            icon={
              <SecurityAlarmFilledIcon
                fill={mColors.neutralDark}
                color={colorScale.koti[400]}
                {...iconProps}
              />
            }
            progressBarProps={progressBarProps}
          />
        </StyledProgressBox>
      )
    default:
      return <div></div>
  }
}

const StyledProgressBox = styled.div<{ width?: string | number }>`
  display: flex;
  ${(props) => props.width && `width:${props.width}`};
`
