import {
  ISettingContainerBaseProps,
  SettingContainer,
} from "./SettingContainer"

export function SettingStatic({
  title,
  description,
  value,
  compact,
  ...props
}: ISettingContainerBaseProps & { value: React.ReactNode }) {
  return (
    <SettingContainer
      title={title}
      description={description}
      displayValue={value}
      InputComponent={null}
      compact={compact}
      {...props}
      onSave={async () => ({ isSuccess: true })}
    />
  )
}
