import { TParadiseOrdersQueryParams } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"

export const paradiseOrderKeys = {
  all() {
    return ["paradiseOrders"] as const
  },

  orderLists() {
    return [...this.all(), "list"] as const
  },

  orderList(filter?: TParadiseOrdersQueryParams) {
    if (!filter) {
      return [...this.orderLists()] as const
    }

    return [...this.orderLists(), filter] as const
  },

  order(orderNumber: string) {
    return [...this.all(), orderNumber] as const
  },
} as const
