import { useState } from "react"
import styled, { css } from "styled-components"

import { useIsMounted } from "usehooks-ts"

import { UpgradeIconButton } from "src/components/FeatureBlockers/UpgradeIconButton"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { TextButton } from "src/ui/Button/TextButton"
import { recommendedGray } from "src/ui/colors"
import { MInfo } from "src/ui/Info/MInfo"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText, MTextProps } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface ISettingContainerBaseProps {
  title: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  value: unknown
  hasError?: boolean
  featureBlocker?: {
    dialog: React.ReactNode
    showIcon: boolean
    showDialog: boolean
  }
  infoTooltip?: React.ComponentProps<typeof MInfo>
  compact?: boolean
  showEditOnHover?: boolean
  titleProps?: {
    type?: MTextProps["variant"]
    color?: MTextProps["color"]
  }
  displayValueProps?: {
    type?: MTextProps["variant"]
    color?: MTextProps["color"]
  }
  gap?: spacing
}

export type TSettingContainerOnSaveReturnType = ReturnType<
  Parameters<typeof SettingContainer>[0]["onSave"]
>

type TSettingContainerProps = Omit<ISettingContainerBaseProps, "value">

const COMPACT_MIN_WIDTH = "300px"

export function SettingContainer({
  title,
  description,
  displayValue,
  InputComponent,
  inlineEdit,
  hasError,
  featureBlocker,
  preventSubmitOnEnter,
  onSave,
  onClose,
  compact,
  gap = spacing.S,
  showEditOnHover = false,
  titleProps = {
    type: "subtitle",
    color: "primary",
  },
  displayValueProps = {
    type: "body",
    color: "primary",
  },
  disabled,
  infoTooltip,
}: TSettingContainerProps & {
  InputComponent: (({ saving }: { saving: boolean }) => React.ReactNode) | null
  displayValue: React.ReactNode
  preventSubmitOnEnter?: boolean
  inlineEdit?: boolean
  onClose?: () => void
  onSave: () => Promise<{ isSuccess: boolean; message?: string }>
}) {
  const [saving, setSaving] = useState(false)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState("")

  const { t } = useTranslate()

  const isMounted = useIsMounted()

  async function handleSave() {
    setSaving(true)
    const response = await onSave()

    if (!isMounted()) return

    if (response.isSuccess) {
      setOpen(false)
      setError("")
    } else {
      setError(response.message ?? t(langKeys.failed_general_error_title))
    }

    setSaving(false)
  }

  function Edit() {
    if (!InputComponent) {
      return null
    }
    if (inlineEdit) {
      return InputComponent({ saving })
    }
    return (
      <TextButton onClick={() => setOpen(true)} disabled={disabled || open}>
        Edit
      </TextButton>
    )
  }

  return (
    <Container>
      <StructuredSettingBox
        onSubmit={(e) => {
          e.preventDefault()
          handleSave()
        }}
        $inlineEdit={inlineEdit}
        $compact={compact}
        $showEditOnHover={showEditOnHover}
        $gap={gap}
        $open={open}
        onKeyDown={(e) => {
          if (preventSubmitOnEnter && e.key === "Enter") {
            e.preventDefault()
          }
        }}
      >
        <SettingTitle>
          <MText variant={titleProps.type} color={titleProps.color}>
            {title}
          </MText>
          {featureBlocker?.showIcon && (
            <UpgradeIconButton context="settings-generic" />
          )}
          {infoTooltip && <MInfo {...infoTooltip} />}
        </SettingTitle>

        {description && (
          <SettingDescription variant="body">{description}</SettingDescription>
        )}

        <SettingsEdit $showEditOnHover={showEditOnHover} $open={open}>
          {Edit()}
        </SettingsEdit>

        <SettingsChildren hidden={inlineEdit} $compact={compact}>
          {open ? (
            <>
              {InputComponent?.({ saving })}
              {error && (
                <ErrorBox type="error" fullWidth>
                  {error}
                </ErrorBox>
              )}
            </>
          ) : (
            <MText
              variant={displayValueProps.type}
              color={displayValueProps.color}
            >
              {displayValue}
            </MText>
          )}
        </SettingsChildren>

        <SettingsActionRow $open={open}>
          <MButtonLegacy
            variant="text"
            onClick={() => {
              setOpen(false)
              setError("")
              onClose?.()
            }}
            disabled={saving}
          >
            Cancel
          </MButtonLegacy>
          <MButtonLegacy loading={saving} disabled={hasError} type="submit">
            Save
          </MButtonLegacy>
        </SettingsActionRow>

        {featureBlocker?.showDialog && featureBlocker.dialog}
      </StructuredSettingBox>
    </Container>
  )
}

const gridDefault = css`
  grid-template-areas:
    "title edit"
    "description edit"
    "child child"
    "action action";
`

const gridInlineEdit = css`
  grid-template-areas:
    "title edit"
    "description edit";
`

const gridCompact = css`
  grid-template-areas:
    "title child edit"
    "description child edit";
`

const gridTempColsCompact = css`
  grid-template-columns: 35% 1fr auto;
`

const gridTempColsDefault = css`
  grid-template-columns: 1fr auto;
`

const compactCSS = css<{ $open?: boolean }>`
  @container (min-width: ${COMPACT_MIN_WIDTH}) {
    ${({ $open }) => {
      if ($open) {
        return `${gridDefault};${gridTempColsDefault};`
      }

      return `${gridCompact};${gridTempColsCompact};`
    }}
  }
`

const Container = styled.div`
  container-type: inline-size;
`

const StructuredSettingBox = styled.form<{
  $inlineEdit?: boolean
  $gap: spacing
  $open?: boolean
  $compact?: boolean
  $showEditOnHover?: boolean
}>`
  display: grid;
  ${({ $inlineEdit }) => ($inlineEdit ? gridInlineEdit : gridDefault)}
  ${gridTempColsDefault}

  ${({ $compact, $inlineEdit }) => $compact && !$inlineEdit && compactCSS}

  gap: ${({ $gap }) => $gap}
`

const SettingTitle = styled.div`
  grid-area: title;
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`

const ErrorBox = styled(MBanner)`
  margin-top: ${spacing.M};
  margin-bottom: ${spacing.XS};
`

const SettingDescription = styled(MText).attrs(() => ({ color: "secondary" }))<{
  $hidden?: boolean
}>`
  ${({ $hidden }) => $hidden && "display: none"};
  grid-area: description;
`

const SettingsEdit = styled.div<{ $showEditOnHover?: boolean; $open: boolean }>`
  grid-area: edit;

  ${({ $showEditOnHover, $open }) => {
    if ($showEditOnHover && !$open) {
      return `
      position: absolute;
      right: 0;
      opacity: 0;
      transition: opacity 0.2s;
      background-color: white;
      box-shadow: -8px 0 6px -4px #fff;
      padding-left: ${spacing.XS};
      z-index: 2;

      ${StructuredSettingBox}:hover & {
        opacity: 1;
      }
`
    }
  }}
`

const compactChildren = css`
  @container (min-width: ${COMPACT_MIN_WIDTH}) {
    overflow: hidden;
    white-space: nowrap;
  }
`

const SettingsChildren = styled.div<{ $compact?: boolean }>`
  ${({ $compact }) => $compact && compactChildren}

  color: ${recommendedGray};
  grid-area: child;
`

const SettingsActionRow = styled.div<{ $open: boolean }>`
  grid-area: action;
  display: ${({ $open }) => ($open ? "flex" : "none")};
  gap: ${spacing.S};
  margin-left: auto;
`
