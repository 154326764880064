export function Show({
  if: condition,
  children,
}: {
  if: boolean
  children: React.ReactNode
}) {
  if (!condition) return null
  return <>{children}</>
}
