import styled from "styled-components"

import MuiTab from "@material-ui/core/Tab"

import { fontWeight } from "src/ui/fontWeight"
import { spacing } from "src/ui/spacing"

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  }
}

const StyledTab = styled(MuiTab)`
  min-width: inherit;
  padding-left: 0;
  padding-right: 0;
  margin-right: ${spacing.M};
  text-transform: initial;
  /* font-size: 0.975rem; */
  font-weight: ${fontWeight.semiBold};
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
const Tab = ({ label, index, ...rest }: any) => {
  return <StyledTab label={label} {...a11yProps(index)} {...rest} />
}

// eslint-disable-next-line import/no-default-export
export default Tab

export interface ITab {
  label: string
  id: string
  view: React.ReactNode
  placeholder?: React.ReactNode
  hidden?: boolean
}
