import { useRef, useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { colorsLegacy } from "src/ui/colors"
import CheckIcon from "src/ui/icons/checkmark-base.svg"
import CopyIcon from "src/ui/icons/copy.svg"
import { JsonRenderer } from "src/ui/JsonTree/JsonRenderer"
import { JsonValue } from "src/ui/JsonTree/JsonTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function JsonTree({
  json,
  initializeCollapsed = false,
}: {
  json: JsonValue
  initializeCollapsed?: boolean
}) {
  const [hasCopied, setHasCopied] = useState(false)
  const timerRef = useRef<NodeJS.Timer | null>(null)

  async function copyJSON() {
    await navigator.clipboard.writeText(JSON.stringify(json))
    setHasCopied(true)

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        setHasCopied(false)
        timerRef.current = null
      }, 2000)
    }
  }

  return (
    <Pre>
      <MText variant="bodyS" style={{ lineHeight: 1.5 }}>
        <JsonRenderer
          depth={0}
          value={json}
          objKey={undefined}
          initialCollapsed={initializeCollapsed}
        />
      </MText>
      <StyledIconButton onClick={copyJSON}>
        {hasCopied ? <CheckIcon /> : <CopyIcon />}
      </StyledIconButton>
    </Pre>
  )
}

const Pre = styled.div`
  position: relative;
  font-family: monospace;
  padding: ${spacing.M};
  background: ${colorsLegacy.backgroundGrayV2};
  border-radius: 5px;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 11px;
  right: ${spacing.M};
  background: ${colorsLegacy.backgroundGray};
  padding: ${spacing.XS2};
  border-radius: 5px;
`
