import SearchIcon from "src/ui/icons/search.svg"
import { MTextField, TMTextFieldProps } from "src/ui/MTextField/MTextField"

export function MSearchField({
  value,
  placeholder,
  onChange,
  onClear,
  disabled,
}: TMTextFieldProps) {
  return (
    <MTextField
      value={value}
      onChange={onChange}
      onClear={onClear}
      placeholder={placeholder}
      disabled={disabled}
      endAdornment={<SearchIcon width={18} color="unset" />}
      size="small"
      rounded
    />
  )
}
