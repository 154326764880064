import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { userKeys, useUserCache } from "src/data/user/queries/userQueryCache"
import {
  IPasswordValidity,
  IPasswordValidityError,
} from "src/data/user/types/passwordTypes"
import { TUser, TUserRequest } from "src/data/user/user"
import { getBrowserClockType } from "src/utils/l10n"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

/**
 * Update a user object
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Users/paths/~1users~1{user_id}/put
 */
export function usePutUser() {
  const { updateCachedUser } = useUserCache()

  async function putUser({
    userId,
    userData,
  }: {
    userId: string
    userData: Partial<TUser>
  }): Promise<TUser> {
    const response = await minutApiHttpClient.put(
      `${API_DEFAULT}/users/${userId}`,
      userData
    )

    return response.data
  }

  return useMutation(putUser, {
    onSuccess: (user) => {
      updateCachedUser(user.user_id, () => user)
    },
  })
}

/**
 * Get a user with userid
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Users/paths/~1users~1{user_id}/get
 */
export function useFetchUser({
  userId,
  options,
}: {
  userId: string | undefined
  options?: UseQueryOptions<
    TUser,
    AxiosError,
    TUser,
    ReturnType<typeof userKeys.user>
  >
}) {
  async function fetchUser({ user_id }: { user_id: string }): Promise<TUser> {
    const response = await minutApiHttpClient.get<TUserRequest>(
      `${API_DEFAULT}/users/${user_id}`
    )
    return {
      ...response.data,
      // If the clock type isn't set on the user object we fallback to guessing
      // it based on their locale:
      clock_type: response.data.clock_type ?? getBrowserClockType(),
    }
  }
  // Ensure query is always disabled if userId is unset:
  const enabled = options?.enabled ? !!(options.enabled && userId) : !!userId

  return useQuery(
    userKeys.user(userId ?? ""),
    () => fetchUser({ user_id: userId ?? "" }),
    {
      ...options,
      enabled,
    }
  )
}

export function usePostPasswordValidity() {
  async function postPasswordValidity({
    password,
  }: {
    password: string
  }): Promise<IPasswordValidity> {
    return await minutApiHttpClient.post(
      `${API_DEFAULT}/users/password/validity`,
      {
        password,
      }
    )
  }

  return useMutation<
    IPasswordValidity,
    AxiosError<IPasswordValidityError>,
    { password: string }
  >(({ password }) => postPasswordValidity({ password }))
}

/**
 * Update a user object
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Users/paths/~1users~1{user_id}/put
 */
export function useDeleteUser() {
  async function deleteUser({ userId }: { userId: string }): Promise<TUser> {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/users/${userId}`
    )
    return response.data
  }

  return useMutation(deleteUser)
}
