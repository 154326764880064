import { useState } from "react"

import { IndoorClimateIcon } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimateIcon"
import { IndoorClimatePopover } from "src/components/Homes/HomeDetails/IndoorClimate/IndoorClimatePopover"
import { usePostIndoorClimatePopupOpened } from "src/data/analytics/queries/homeOverviewCardsAnalyticsQueries"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import {
  MonitoringButton,
  OnOffStatusLabel,
} from "src/ui/MonitoringButton/MonitoringButton"
import { MTooltip } from "src/ui/MTooltip/MTooltip"

export function IndoorClimateButton({
  homeId,
  indoorClimateMonitoring,
  toggleAllowed,
  hideStatus,
}: {
  homeId: string
  indoorClimateMonitoring: TIndoorClimateMonitoring | undefined
  toggleAllowed: boolean
  hideStatus?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isEnabled = indoorClimateMonitoring?.state === "enabled"

  const postIndoorClimatePopupOpened = usePostIndoorClimatePopupOpened()

  function handleClickToggle(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
    trackIndoorClimatePopupOpened()
  }

  function trackIndoorClimatePopupOpened() {
    const trackedData = {
      context: "homes list",
      icm_enabled: indoorClimateMonitoring?.state === "enabled" ? true : false,
      temperature_enabled: !!indoorClimateMonitoring?.temperature_enabled,
      humidity_enabled: !!indoorClimateMonitoring?.humidity_enabled,
    }

    postIndoorClimatePopupOpened.mutate(trackedData)
  }

  const tooltipText = toggleAllowed ? "" : t(langKeys.not_enough_permissions)

  return (
    <div>
      <MTooltip title={tooltipText} placement="left">
        <div>
          <MonitoringButton
            label={<OnOffStatusLabel isOn={isEnabled} />}
            labelType={isEnabled ? "good" : "default"}
            row={true}
            icon={
              <IndoorClimateIcon
                state={indoorClimateMonitoring?.state}
                status={indoorClimateMonitoring?.status}
                iconProps={{ width: 30, height: 30 }}
              />
            }
            onClick={handleClickToggle}
            disabled={!toggleAllowed}
            hideStatus={hideStatus}
          />
        </div>
      </MTooltip>

      {open && (
        <IndoorClimatePopover
          homeId={homeId}
          indoorClimateMonitoring={indoorClimateMonitoring}
          anchorEl={anchorEl}
          onClose={handleClose}
        />
      )}
    </div>
  )
}
