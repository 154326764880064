import styled from "styled-components"

import { HumidityStatus } from "src/components/Devices/IndoorClimateStatus/HumidityStatus"
import { MoldRiskStatus } from "src/components/Devices/IndoorClimateStatus/MoldRiskStatus"
import { TemperatureStatus } from "src/components/Devices/IndoorClimateStatus/TemperatureStatus"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { SVGElement } from "src/utils/tsUtil"

export function IndoorClimateStatus({
  device,
  indoorClimateMonitoring,
}: {
  device: TDevice
  indoorClimateMonitoring?: TIndoorClimateMonitoring
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <MTooltip title={t(langKeys.temperature)} placement="top">
        <div>
          <TemperatureStatus
            device={device}
            indoorClimateMonitoring={indoorClimateMonitoring}
          />
        </div>
      </MTooltip>

      <MTooltip title={t(langKeys.humidity)} placement="top">
        <div>
          <HumidityStatus
            device={device}
            indoorClimateMonitoring={indoorClimateMonitoring}
          />
        </div>
      </MTooltip>

      <MTooltip title={t(langKeys.risk_of_mold)} placement="top">
        <div>
          <MoldRiskStatus device={device} />
        </div>
      </MTooltip>
    </Box>
  )
}

export function StatusDisplay({
  color,
  reading,
  icon,
}: {
  color: string
  reading: React.ReactNode
  icon: SVGElement
}) {
  const StatusIcon = icon

  return (
    <MText variant="subtitleS">
      <ReactionBox $color={color}>
        <StatusIcon color={color} width={24} height={24} />
        {reading}
      </ReactionBox>
    </MText>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${spacing.L};
  white-space: nowrap;
`

const ReactionBox = styled.div<{ $color: string }>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: ${spacing.XS2};
  color: ${({ $color }) => $color};
`
