import styled from "styled-components"

import { Switch } from "@material-ui/core"

import {
  usePostHumidityToggled,
  usePostTemperatureToggled,
} from "src/data/analytics/queries/homeOverviewCardsAnalyticsQueries"
import { TIndoorClimateMonitoring } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchIndoorClimateMonitoring } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function IndoorClimateSettings({
  homeId,
  indoorClimateMonitoring,
}: {
  homeId: string
  indoorClimateMonitoring: TIndoorClimateMonitoring | undefined
}) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()

  const patchIndoorClimateMonitoring = usePatchIndoorClimateMonitoring({
    orgId,
  })

  const postTemperatureToggled = usePostTemperatureToggled()
  const postHumidityToggled = usePostHumidityToggled()

  function handleTemperatureToggle() {
    patchIndoorClimateMonitoring.mutate(
      {
        homeId,
        data: {
          temperature_enabled: !indoorClimateMonitoring?.temperature_enabled,
        },
      },
      {
        onSuccess: (vars) => {
          postTemperatureToggled.mutate({
            icm_enabled: vars.state === "enabled" ? true : false,
            humidity_enabled: vars.humidity_enabled,
            new_toggle_status: vars.temperature_enabled
              ? "enabled"
              : "disabled",
          })
        },
      }
    )
  }

  function handleHumidityToggle() {
    patchIndoorClimateMonitoring.mutate(
      {
        homeId,
        data: {
          humidity_enabled: !indoorClimateMonitoring?.humidity_enabled,
        },
      },
      {
        onSuccess: (vars) => {
          postHumidityToggled.mutate({
            icm_enabled: vars.state === "enabled" ? true : false,
            temperature_enabled: vars.temperature_enabled,
            new_toggle_status: vars.humidity_enabled ? "enabled" : "disabled",
          })
        },
      }
    )
  }

  return (
    <Box>
      <Top>
        <MText variant="subtitle">
          {t(langKeys.indoor_climate_settings_title)}
        </MText>

        <MText variant="bodyS" color="tertiary">
          {t(langKeys.indoor_climate_settings_body)}
        </MText>
      </Top>

      <ToggleBox>
        <StyledMText variant="subtitle">
          {t(langKeys.temperature)}{" "}
          <Switch
            checked={indoorClimateMonitoring?.temperature_enabled}
            onChange={handleTemperatureToggle}
            disabled={patchIndoorClimateMonitoring.isLoading}
          />
        </StyledMText>

        <StyledMText variant="subtitle">
          {t(langKeys.humidity)}{" "}
          <Switch
            checked={indoorClimateMonitoring?.humidity_enabled}
            onChange={handleHumidityToggle}
            disabled={patchIndoorClimateMonitoring.isLoading}
          />
        </StyledMText>

        {patchIndoorClimateMonitoring.isError && (
          <MBanner type="error" fullWidth>
            {`${t(langKeys.failed_something_went_wrong)}`}
          </MBanner>
        )}
      </ToggleBox>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const Top = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const ToggleBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const StyledMText = styled(MText)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.M};
`
