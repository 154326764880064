import { TDevice } from "src/data/devices/types/deviceTypes"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"
import { SVGProps } from "src/utils/tsUtil"

import { CellularIcon } from "./CellularIcon"
import { EthernetIcon } from "./EthernetIcon"

export function NetworkIcon({
  device,
  ...props
}: {
  device?: TDevice
} & SVGProps) {
  switch (device?.network_interface) {
    case "wifi":
      return (
        <WifiIcon
          quality={device.wlan?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "cellular":
      return (
        <CellularIcon
          quality={device.cellular?.signal_quality}
          offline={device.offline}
          {...props}
        />
      )
    case "ethernet":
      return <EthernetIcon offline={device.offline} {...props} />

    case "burstmesh":
      // FW-985: We don't have a good signal quality measurement yet,
      // for now just show "good" to not alarm/confuse the user.
      return <WifiIcon quality={3} offline={false} {...props} />

    default:
      // If device is undefined or network interface not handled
      return <WifiIcon quality={0} offline={false} {...props} />
  }
}
