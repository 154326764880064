import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import LightbulbIcon from "src/ui/icons/lightbulb.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function InfoBox({
  title,
  children,
  type = "info",
}: {
  title: React.ReactNode
  children?: React.ReactNode
  type?: "info" | "warning"
}) {
  return (
    <StyledMAlert type={type}>
      <MText variant="subtitle" color="unset" marginBottom={spacing.XS}>
        {type === "info" ? (
          <LightbulbIcon
            width={24}
            style={{ marginRight: spacing.XS }}
            color={mColors.textPrimary}
          />
        ) : (
          <ErrorIcon
            state="warning"
            svgProps={{ width: 24 }}
            style={{ marginRight: spacing.XS }}
          />
        )}
        {title}
      </MText>

      {children && (
        <MText variant="body" color="unset">
          {children}
        </MText>
      )}
    </StyledMAlert>
  )
}

const StyledMAlert = styled(MBanner)`
  padding: ${spacing.M} ${spacing.L};
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`
