import {
  IVirtualEnvironmentRequest,
  usePostVirtualDeviceEvent,
} from "src/data/homes/queries/virtualDeviceQueries"
import { MButton } from "src/ui/Button/MButton"

export function EventButton({
  eventType,
  orgId,
  deviceId,
  homeId,
  label,
  disabled,
}: {
  eventType: IVirtualEnvironmentRequest["event_type"]
  orgId: string
  deviceId: string
  homeId: string
  label: string
  disabled?: boolean
}) {
  const postVirtualDeviceEvent = usePostVirtualDeviceEvent()

  return (
    <MButton
      size="small"
      onClick={() => {
        postVirtualDeviceEvent.mutate({
          orgId,
          deviceId,
          homeId,
          eventType,
        })
      }}
      variant="subtle"
      loading={postVirtualDeviceEvent.isLoading}
      disabled={disabled}
    >
      {label}
    </MButton>
  )
}
