import { IHomeFilterBody } from "src/data/homes/types/homeQueryTypes"

import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TExportEventTypes = "Export Property Data Confirmed"

function usePostExportTrackingEvent<TEventProperties>(
  event: TExportEventTypes
) {
  return usePostTrackEventWithParams<TExportEventTypes, TEventProperties>({
    event,
  })
}

export function usePostExportConfirmed() {
  return usePostExportTrackingEvent<{
    filters: IHomeFilterBody
  }>("Export Property Data Confirmed")
}
