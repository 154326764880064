import styled from "styled-components"

import Pagination from "@material-ui/lab/Pagination"

import { primaryTint } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"

export function Pager({
  limit,
  offset,
  setOffset,
  totalCount,
  scrollOnClick = true,
}: {
  limit: number
  offset: number
  setOffset: (offset: number) => void
  totalCount: number | undefined
  scrollOnClick?: boolean
}) {
  const nbrPages = totalCount ? Math.ceil(totalCount / limit) : undefined
  const multiplePages = !!totalCount && limit < totalCount
  const page = Math.round(offset / limit) + 1

  function handleChange(event: object, page: number) {
    scrollTop()
    setOffset(Math.max(page - 1, 0) * limit)
  }

  function scrollTop() {
    !!scrollOnClick && window.scrollTo({ top: 0 })
  }

  if (!multiplePages) {
    return null
  }

  return (
    <Box>
      <PageButtonBox>
        <StyledPagination
          count={nbrPages}
          page={page}
          onChange={handleChange}
          color="secondary"
        />
      </PageButtonBox>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-template-areas: "any any pagebuttons";
  justify-content: space-between;
  align-items: center;
`

const PageButtonBox = styled.span`
  grid-area: pagebuttons;
`

const StyledPagination = styled(Pagination)`
  .Mui-selected {
    color: ${primaryTint};
    font-weight: ${fontWeight.semiBold};
    background: none;
  }
`
