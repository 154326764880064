import { TDateISO } from "src/data/devices/types/deviceTypes"

export const eventKeys = {
  /** Use to target *all* event data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["events"] as const
  },

  /** Use to target list data in cache*/
  list(filter: {
    orgId: string
    homeIds?: string[]
    limit?: number
    offset?: number
    events?: string[]
    startAt?: TDateISO
    endAt?: TDateISO
  }) {
    return [...this.all(), "list", filter] as const
  },
}
