import styled, { keyframes } from "styled-components"

import { useFlags } from "src/hooks/useFlags"
import { backgroundColor } from "src/ui/colors"
import Favicon from "src/ui/icons/minut-favicon.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LoadingScreen({ debugInfo }: { debugInfo: React.ReactNode }) {
  const { debug: showDebugInfo } = useFlags()

  return (
    <Box>
      <LogoBox>
        <Favicon />
      </LogoBox>

      {showDebugInfo && debugInfo && (
        <MText variant="bodyS" color="secondary">
          {debugInfo}
        </MText>
      )}
    </Box>
  )
}

const Box = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacing.M};
  background-color: ${backgroundColor};
`

const pulseAnimation = keyframes`
  0% {
    opacity: 100%;
  }
  50% {
    opacity: 20%;
  }
  100% {
    opacity: 100%;
  }
`

const LogoBox = styled.div`
  width: 80px;
  margin-bottom: ${spacing.XL};
  animation: ${pulseAnimation} 2s infinite;
`
