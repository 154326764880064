import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  orgsKeys,
  useOrgQueryCache,
} from "src/data/organizations/queries/organizationQueryCache"
import {
  IFetchOrganizations,
  IOrganization,
  IOrganizationGetParams as IOrganizationsGetParams,
  IOrganizationResponse,
  TOrganizationNbrResponse,
  TOrganizationPatch,
  TOrganizationUpdateTagsBody,
} from "src/data/organizations/types/organizationTypes"
import { debug } from "src/utils/logger"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
import { Maybe, Optional } from "src/utils/tsUtil"

async function fetchOrganization(id: string): Promise<IOrganizationResponse> {
  const response = await minutApiHttpClient.get<IOrganizationResponse>(
    `${API_DEFAULT}/organizations/${id}`
  )
  return response.data
}

/**
 * Get an organization by id.
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations~1{organization_id}/get
 */
export function useFetchOrganization({
  id,
  options,
}: {
  id: string
  options?: UseQueryOptions<
    IOrganization,
    AxiosError,
    IOrganization,
    ReturnType<typeof orgsKeys.org>
  >
}) {
  const queryClient = useQueryClient()

  return useQuery(orgsKeys.org(id), () => fetchOrganization(id), {
    initialData: () => {
      const cachedOrgs = queryClient.getQueryData<IFetchOrganizations>(
        orgsKeys.orgList()
      )
      const org = cachedOrgs?.organizations?.find((o) => o.id === id)
      !!org && debug.log(`Using cached org data as initialData`)
      return org
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 403) {
        // If the user has been removed from the organization, refetch all orgs
        // to ensure cache is frech:
        console.log("got 403, refetching orgs") // eslint-disable-line no-console
        queryClient.invalidateQueries(orgsKeys.all())
      }
    },
    ...options,
  })
}

async function fetchOrganizations(
  params?: IOrganizationsGetParams
): Promise<IFetchOrganizations> {
  const response = await minutApiHttpClient.get<IFetchOrganizations>(
    `${API_DEFAULT}/organizations`,
    { params }
  )
  return response.data
}

/**
 * Returns a list of all organizations the current user is a member of.
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations/get
 */
export function useFetchOrganizations<
  TQueryFnData = IFetchOrganizations,
>(props?: {
  options?: UseQueryOptions<
    IFetchOrganizations,
    AxiosError,
    TQueryFnData,
    ReturnType<typeof orgsKeys.orgList>
  >
  params?: IOrganizationsGetParams
}) {
  return useQuery(
    orgsKeys.orgList(props?.params),
    () => fetchOrganizations(props?.params),
    {
      keepPreviousData: true,
      ...props?.options,
    }
  )
}

export function useFetchNbrOrganizations({
  userId,
  options,
}: {
  userId: string | undefined
  options?: UseQueryOptions<
    TOrganizationNbrResponse,
    AxiosError,
    TOrganizationNbrResponse,
    ReturnType<typeof orgsKeys.orgNbr>
  >
}) {
  return useQuery({
    queryKey: orgsKeys.orgNbr({ userId }),
    queryFn: async () => {
      // We do 2 requests due to API is paginated and is repondes with all organizations and not only owned ones
      const [totalData, ownedData] = await Promise.all([
        fetchOrganizations({
          limit: 1,
        }),
        fetchOrganizations({
          owner_id: userId,
        }),
      ])

      return {
        total: totalData.paging.total_count,
        owned: ownedData.paging.total_count,
      }
    },
    staleTime: 5000, // 5 seconds
    ...options,
  })
}

/** Create organization
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations/post
 *
 * Responses
 * 201 Created => IOrganizationResponse
 * 400 Field is missing
 * 401 Unauthorized
 * 403 Forbidden
 */
export function usePostOrganization() {
  const queryClient = useQueryClient()

  async function postOrganization({ name }: { name: string }) {
    const response = await minutApiHttpClient.post<IOrganizationResponse>(
      `${API_DEFAULT}/organizations`,
      {
        name,
      }
    )
    const newOrg = response.data
    return newOrg
  }

  return useMutation(postOrganization, {
    onSuccess: async () => {
      return await queryClient.invalidateQueries(orgsKeys.orgLists())
    },
  })
}

/** Update an organization
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations~1{organization_id}/patch
 * Responses
 * 200 OK => IOrganizationResponse
 */
export function usePatchOrganization() {
  const queryClient = useQueryClient()
  const { updateCachedOrgList, updateCachedOrgDetail } = useOrgQueryCache()

  async function patchOrganization({
    orgId,
    body,
  }: {
    orgId: string
    body: TOrganizationPatch
  }) {
    const response = await minutApiHttpClient.patch(
      `${API_DEFAULT}/organizations/${orgId}`,
      body
    )
    const rule: IOrganizationResponse = response.data
    return rule
  }

  return useMutation(patchOrganization, {
    onSuccess: (newOrg) => {
      queryClient.invalidateQueries(orgsKeys.orgLists())
      updateCachedOrgDetail(newOrg.id, () => newOrg)

      queryClient.invalidateQueries(orgsKeys.activeOrg())

      updateCachedOrgList((cache) => {
        const newOrgs = (cache.organizations ?? []).map((org) => {
          if (org.id === newOrg.id) {
            return newOrg
          }
          return org
        })
        return { ...cache, organizations: newOrgs }
      })
    },
  })
}

/** Delete an organization
 *
 * https://api.staging.minut.com/latest/docs/internal#tag/Organizations/paths/~1organizations~1{organization_id}/delete
 *
 * Responses
 * 204 OK
 * 401
 * 403
 * 404
 */
export function useDeleteOrganization() {
  const queryClient = useQueryClient()

  const { removeCachedOrg } = useOrgQueryCache()

  async function deleteOrganization({ orgId }: { orgId: string }) {
    debug.log(`Deleting org ${orgId}`)
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}`
    )
    return response
  }

  return useMutation(deleteOrganization, {
    onSuccess: async (_, deletedOrg) => {
      removeCachedOrg(deletedOrg.orgId)
      return await Promise.all([
        queryClient.invalidateQueries(orgsKeys.orgLists()),
        queryClient.invalidateQueries(orgsKeys.activeOrg()),
      ])
    },
  })
}

/**
 * Fetch an organization by id; will fallback to returning the first available
 * organization on error. Callers should use onFallback to define what to do
 * when falling back to a default, e.g. updating the storage and param state.
 */
export function useFetchActiveOrganization({
  orgId,
  onFallback,
  options,
}: {
  orgId: Optional<string>
  onFallback: (fallbackId: string | null) => void
  options?: UseQueryOptions<
    Maybe<IOrganization>,
    AxiosError,
    IOrganization,
    ReturnType<typeof orgsKeys.activeOrg>
  >
}) {
  async function fetchActiveOrg() {
    if (!orgId) {
      debug.log("fetchActiveOrg: No org id supplied, fetching first available")
      return await fetchFallbackOrg()
    }

    try {
      debug.log(`fetchActiveOrg: Fetching stored org ${orgId}`)
      const org = await fetchOrganization(orgId)
      debug.log(`fetchActiveOrg: fetched ${org?.name} (${org?.id})`)
      return org
    } catch (error) {
      debug.log(`fetchActiveOrg: Cannot fetch ${orgId}, fetching fallback`)
      return await fetchFallbackOrg()
    }
  }

  async function fetchFallbackOrg() {
    const organizationsResponse = await fetchOrganizations({ limit: 1 })
    const fallbackOrg = organizationsResponse.organizations.at(0) ?? null

    if (!fallbackOrg) {
      debug.log(
        `fetchActiveOrg: tried to fetch fallback, but got nothing ${JSON.stringify(
          organizationsResponse
        )}`
      )
      onFallback(null)
    } else {
      debug.log(
        `fetchActiveOrg: fetched fallback ${fallbackOrg?.name} (${fallbackOrg?.id})`
      )
      onFallback(fallbackOrg?.id)
    }

    return fallbackOrg
  }

  return useQuery({
    ...options,
    // Note: If a fallback org has been fetched due to the orgId not being valid,
    // the queryKey in the cache will not be corresponding to the actual
    // organization that is active.
    queryKey: orgsKeys.activeOrg(orgId),
    queryFn: fetchActiveOrg,
  })
}

// ts-prune-ignore-next
export function usePutOrganizationTags() {
  const queryClient = useQueryClient()
  const { updateCachedOrgList, updateCachedOrgDetail } = useOrgQueryCache()

  async function putOrganizationTags({
    orgId,
    body,
  }: {
    orgId: string
    body: TOrganizationUpdateTagsBody
  }) {
    const response = await minutApiHttpClient.put<IOrganizationResponse>(
      `${API_DEFAULT}/organizations/${orgId}/tags`,
      body
    )

    return response.data
  }

  return useMutation({
    mutationFn: putOrganizationTags,
    onSuccess: (newOrg) => {
      queryClient.invalidateQueries(orgsKeys.orgLists())
      updateCachedOrgDetail(newOrg.id, () => newOrg)

      queryClient.invalidateQueries(orgsKeys.activeOrg())

      updateCachedOrgList((cache) => {
        const newOrgs = (cache.organizations ?? []).map((org) => {
          if (org.id === newOrg.id) {
            return newOrg
          }
          return org
        })
        return { ...cache, organizations: newOrgs }
      })
    },
  })
}
