import { useQueryClient } from "@tanstack/react-query"

import {
  IFetchHomeGroupHomes,
  IFetchHomeGroupMembers,
  IFetchHomeGroups,
  IHomeGroupHome,
  IHomeGroupHomesFilter,
  IHomeGroupMember,
  THomeGroupFilter,
} from "src/data/homeGroups/types/homeGroupTypes"
import { IPagingFilter } from "src/data/pagination/types/paginationTypes"

export const homeGroupKeys = {
  orgs(orgId: string) {
    return ["organizations", orgId] as const
  },

  homeGroups(orgId: string) {
    return [...this.orgs(orgId), "homeGroups"] as const
  },

  homeGroupsList(orgId: string, filter: THomeGroupFilter) {
    return [...this.homeGroups(orgId), filter] as const
  },

  homeGroup(orgId: string, homeGroupId: string) {
    return [...this.homeGroups(orgId), homeGroupId] as const
  },

  membersList(orgId: string, homeGroupId: string, filter?: IPagingFilter) {
    if (!filter) {
      return [...this.homeGroup(orgId, homeGroupId), "members"] as const
    }
    return [...this.homeGroup(orgId, homeGroupId), "members", filter] as const
  },

  memberDetail(orgId: string, homeGroupId: string, memberId: string) {
    return [...this.membersList(orgId, homeGroupId), memberId] as const
  },

  homeGroupHomes(
    orgId: string,
    homeGroupId: string,
    filter?: IHomeGroupHomesFilter
  ) {
    if (!filter) {
      return [...this.homeGroup(orgId, homeGroupId), "homes"] as const
    }
    return [...this.homeGroup(orgId, homeGroupId), "homes", filter] as const
  },
} as const

// ts-prune-ignore-next
export function useUpdateHomeGroupCache() {
  const queryClient = useQueryClient()

  function deleteCachedHomeGroup(orgId: string, hgId: string) {
    queryClient.setQueryData<IFetchHomeGroups | undefined>(
      homeGroupKeys.homeGroups(orgId),
      (h) => {
        if (!h) return
        const home_groups = h?.homegroups.filter((hg) => hg.id !== hgId) ?? []
        return { ...h, homegroups: home_groups }
      }
    )
    queryClient.invalidateQueries(homeGroupKeys.homeGroups(orgId))
  }
  return { deleteCachedHomeGroup }
}

export function useUpdateHomeGroupMembersCache() {
  const queryClient = useQueryClient()

  function addCachedHomeGroupMember(
    orgId: string,
    hgId: string,
    updater: (m?: IHomeGroupMember | undefined) => IHomeGroupMember
  ) {
    queryClient.setQueriesData<IFetchHomeGroupMembers | undefined>(
      homeGroupKeys.membersList(orgId, hgId),
      (membersData) => {
        if (!membersData) {
          return {
            paging: { total_count: 0, limit: 10, offset: 0 },
            members: [updater()],
          }
        }
        const cachedMembersList = membersData.members ?? []
        const newMembersData = {
          ...membersData,
          members: [...cachedMembersList, updater()],
        }
        return newMembersData
      }
    )
  }

  function updateCachedHomeGroupMember(
    orgId: string,
    hgId: string,
    memberId: string,
    updater: (m: IHomeGroupMember) => IHomeGroupMember
  ) {
    queryClient.setQueriesData<IFetchHomeGroupMembers | undefined>(
      homeGroupKeys.membersList(orgId, hgId),
      (membersData) => {
        if (!membersData) {
          return
        }
        const cachedMembersList = membersData.members ?? []
        const newMembersData = {
          ...membersData,
          members: cachedMembersList.map((m) =>
            m.member_id === memberId ? updater(m) : m
          ),
        }
        return newMembersData
      }
    )
  }

  function removeCachedHomeGroupMember(
    orgId: string,
    homeGroupId: string,
    memberId: string
  ) {
    queryClient.setQueriesData<IFetchHomeGroupMembers | undefined>(
      homeGroupKeys.membersList(orgId, homeGroupId),
      (hgmData) => {
        if (!hgmData) return
        const filteredMembers =
          hgmData?.members.filter((hg) => hg.member_id !== memberId) ?? []
        return { ...hgmData, members: filteredMembers }
      }
    )
  }

  return {
    addCachedHomeGroupMember,
    updateCachedHomeGroupMember,
    removeCachedHomeGroupMember,
  }
}

export function useHomeGroupHomesCache() {
  const queryClient = useQueryClient()

  function addCachedHomeGroupHome(
    orgId: string,
    hgId: string,
    updater: (home?: IHomeGroupHome) => IHomeGroupHome
  ) {
    queryClient.setQueriesData<IFetchHomeGroupHomes | undefined>(
      homeGroupKeys.homeGroupHomes(orgId, hgId),
      (cache) => {
        if (!cache) return

        const cachedHomesList = cache.homes ?? []
        const newHomesData = {
          ...cache,
          homes: [...cachedHomesList, updater()],
        }
        return newHomesData
      }
    )
  }

  function removeCachedHomeGroupHome(
    orgId: string,
    homeGroupId: string,
    homeId: string
  ) {
    queryClient.setQueriesData<IFetchHomeGroupHomes | undefined>(
      homeGroupKeys.homeGroupHomes(orgId, homeGroupId),
      (cache) => {
        if (!cache) return
        const filteredHomes =
          cache?.homes.filter((home) => home.id !== homeId) ?? []
        return { ...cache, homes: filteredHomes }
      }
    )
  }

  return {
    addCachedHomeGroupHome,
    removeCachedHomeGroupHome,
  }
}
