import { SVGProps } from "react"
import styled from "styled-components"

import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type EmptyStateWithImageProps = {
  Image: React.FC<SVGProps<SVGSVGElement>>
  title: string
  description?: React.ReactNode
  content?: React.ReactNode
  imageSpacing?: `-${spacing}` | `${spacing}`
}

export function EmptyStateWithImage({
  Image,
  title,
  description,
  content,
  imageSpacing = `-${spacing.M}`,
}: EmptyStateWithImageProps) {
  return (
    <div>
      <ImageBox>
        <Image />
      </ImageBox>
      <ContentWrapper $spacing={imageSpacing}>
        <MText variant="heading2" textAlign="center" marginBottom={spacing.XS}>
          {title}
        </MText>
        <MText
          variant="bodyS"
          textAlign="center"
          marginBottom={spacing.L}
          color="secondary"
        >
          {description}
        </MText>
        <CenterWrapper>{content}</CenterWrapper>
      </ContentWrapper>
    </div>
  )
}

const ContentWrapper = styled.div<{ $spacing: number | string }>`
  margin-top: ${({ $spacing }) => $spacing};
  margin-inline: auto;
  max-width: 600px;
  text-wrap: pretty;
`

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ImageBox = styled.div`
  max-width: 800px;
  margin: 0 auto;

  > svg {
    width: 100%;
    height: 100%;
  }
`
