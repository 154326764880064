import { createGlobalStyle } from "styled-components"

import { mColors } from "src/ui/colors"

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Figtree', sans-serif;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale;
    color: ${mColors.textPrimary};
  }

  button {
    font-family: inherit;
  }

  textarea {
    font-family: inherit;
  }
`
