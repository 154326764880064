import { BillingPeriodUnit } from "src/components/Account/types"
import {
  hasIndoorClimateSupport,
  isCigaretteDevice,
  isEurekaDevice,
  isMonitoringDevice,
} from "src/data/devices/logic/deviceLogic"
import { TDevice } from "src/data/devices/types/deviceTypes"
import {
  getDeviceOutageState,
  getOfflineDeviceOverrides,
} from "src/data/homes/logic/partialOutage"
import {
  IStatefulHome,
  IStatefulHomeWithDevices,
  MonitoringType,
  THome,
} from "src/data/homes/types/homeTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction } from "src/i18n/useTranslate"

export function getNbrPages(limit: number, total: number) {
  return Math.ceil(total / limit)
}

export function getOffsetsFromPages(limit: number, nbrPages: number): number[] {
  const offsets = []
  for (let i = 0; i < nbrPages; i++) {
    offsets.push(i * limit)
  }
  return offsets
}

export function generateOffsets(
  limit: number,
  total: number,
  startPage: number = 0
): number[] {
  const nbrPages = getNbrPages(limit, total)
  const offsets = []
  for (let i = startPage; i < nbrPages; i++) {
    offsets.push(i * limit)
  }
  return offsets
}

export function monitoringAvailable({
  type,
  home,
  devices,
}: {
  type: MonitoringType
  home: THomeMonitoringAvailability
  devices: TDevice[]
}): boolean {
  switch (type) {
    case MonitoringType.SOUND: {
      return devices.length > 0 && devices.some((d) => isMonitoringDevice(d))
    }
    case MonitoringType.MOTION: {
      return devices.some((d) => !d.placed_outdoors && isMonitoringDevice(d))
    }
    case MonitoringType.OCCUPANCY: {
      return devices.some((d) => !d.placed_outdoors && isMonitoringDevice(d))
    }
    case MonitoringType.TEMPERATURE: {
      return devices.some(isMonitoringDevice)
    }
    case MonitoringType.HUMIDITY: {
      return devices.some(isMonitoringDevice)
    }
    case MonitoringType.MOLD: {
      return devices.some((d) => !d.placed_outdoors)
    }
    case MonitoringType.FIRECO: {
      return (
        (!!home.carbon_monoxide_alarm?.available ||
          !!home.fire_alarm?.available) &&
        devices.some(isEurekaDevice)
      )
    }
    case MonitoringType.CIGARETTE: {
      return (
        !!home.smoking_detection?.available &&
        devices.some((d) => !d.placed_outdoors && isCigaretteDevice(d))
      )
    }

    case MonitoringType.INDOOR_CLIMATE: {
      const hasSupportedDevices =
        devices.filter(hasIndoorClimateSupport).length > 0

      return hasSupportedDevices && !!home.indoor_climate_monitoring
    }

    default: {
      return false
    }
  }
}

interface IHomeMonitoringCardVisibility {
  showNoiseMonitoringCard: boolean
  showCrowdDetectionCard: boolean
  showSmokingDetectionCard: boolean
  showSecurityAlarmCard: boolean
  showCOCard: boolean
  showIndoorClimateMonitoringCard: boolean
}

export type THomeMonitoringAvailability = Pick<
  THome,
  | "smoking_detection"
  | "fire_alarm"
  | "carbon_monoxide_alarm"
  | "indoor_climate_monitoring"
>
export function getHomeMonitoringCardVisibility({
  home,
  devices,
}: {
  home: THomeMonitoringAvailability
  devices: TDevice[]
}): IHomeMonitoringCardVisibility {
  const deviceOutageState = getDeviceOutageState(devices)
  const {
    hideNoiseMonitoringCard,
    hideCrowdDetectionCard,
    hideSecurityAlarmCard,
    hideSmokingDetectionCard,
  } = getOfflineDeviceOverrides(deviceOutageState)

  const showNoiseMonitoringCard =
    monitoringAvailable({ type: MonitoringType.SOUND, home, devices }) &&
    !hideNoiseMonitoringCard

  const showCrowdDetectionCard =
    monitoringAvailable({ type: MonitoringType.OCCUPANCY, home, devices }) &&
    !hideCrowdDetectionCard

  const showSmokingDetectionCard =
    monitoringAvailable({
      type: MonitoringType.CIGARETTE,
      home,
      devices,
    }) && !hideSmokingDetectionCard

  const showSecurityAlarmCard =
    monitoringAvailable({ type: MonitoringType.MOTION, home, devices }) &&
    !hideSecurityAlarmCard

  const showCOCard = !!(
    home.fire_alarm?.available && home.carbon_monoxide_alarm?.available
  )

  const showIndoorClimateMonitoringCard = monitoringAvailable({
    type: MonitoringType.INDOOR_CLIMATE,
    home,
    devices,
  })

  return {
    showNoiseMonitoringCard,
    showCrowdDetectionCard,
    showSmokingDetectionCard,
    showSecurityAlarmCard,
    showCOCard,
    showIndoorClimateMonitoringCard,
  }
}

// TODO: WEB-569 Move to a hook that fetches both devices and homes and stiches
// together instead.
export function getStatefulHomesWithDevices(
  homes: IStatefulHome[],
  devices: TDevice[]
): IStatefulHomeWithDevices[] {
  return homes
    .map((h) => {
      return {
        ...h,
        devices: devices.filter((d) => d.home === h.home_id),
      }
    })
    .filter((h) => h.devices.length > 0)
}

type TBillingPeriod = `${BillingPeriodUnit}`

/**
 * Takes a string (usually a formatted amount, e.g. `$10`) and the billing
 * period and returns a localized string with the period cost, e.g. as `$10/month`
 */
export function getLocalizedPeriodCost({
  formattedAmount,
  billingPeriod,
  t,
}: {
  formattedAmount: string
  billingPeriod: TBillingPeriod
  t: TTranslateFunction
}) {
  switch (billingPeriod) {
    case "month": {
      return `${formattedAmount}/${t(langKeys.month).toLowerCase()}`
    }
    case "year": {
      return `${formattedAmount}/${t(langKeys.year).toLowerCase()}`
    }
  }
}
