import { getUrlParamValue } from "src/data/filters/filtersUrl"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

export enum OrganizationParams {
  INVITATION_CODE = "invitationCode",
}

export function getInviteCodeFromUrl() {
  const inviteCode = getUrlParamValue(OrganizationParams.INVITATION_CODE)
  return inviteCode
}

interface IHomeLocation extends ILocation<{ orgId: string; homeId: string }> {}

const ORG_ROUTE = `/organization`

const Organization = createBasicLocation(ORG_ROUTE)

const Organizations = createBasicLocation("/organizations")

const OrgGuestCom = createBasicLocation(`/guest-connect`)

const OrgGuestComCreateRule: ILocation<string> = {
  location(ruleTemplateId) {
    return {
      pathname: `${OrgGuestCom.location().pathname}/create/${ruleTemplateId}`,
    }
  },
}

const OrgGuestComEditRule: ILocation<string> = {
  location(ruleId) {
    return {
      pathname: `${OrgGuestCom.location().pathname}/edit/${ruleId}`,
    }
  },
}

const OrgMemberAdd: ILocation<string> = {
  location(orgId) {
    return { pathname: `${ORG_ROUTE}/${orgId}/members/add` }
  },
}

const OrgHomeNewMemberAdd: IHomeLocation = {
  location({ orgId, homeId }: { orgId: string; homeId: string }) {
    const pathname = `${ORG_ROUTE}/${orgId}/${homeId}/member/add-new`
    return { pathname }
  },
}

const OrgHomeExistingMemberAdd: IHomeLocation = {
  location({ orgId, homeId }: { orgId: string; homeId: string }) {
    const pathname = `${ORG_ROUTE}/${orgId}/${homeId}/member/add-existing`
    return { pathname }
  },
}

const OrgIntegrations = createBasicLocation(`/integrations`)

const OrgIntegration: ILocation<string> = {
  location(integrationId) {
    return {
      pathname: `${OrgIntegrations.location().pathname}/${integrationId}`,
    }
  },
}

const OrgIntegrationSettingsTab: ILocation<string> = {
  location(integrationId) {
    return {
      pathname: `${OrgIntegration.location(integrationId).pathname}`,
      hash: "settings",
    }
  },
}

const OrgCreate = createBasicLocation(`${ORG_ROUTE}/create`)

export const OrganizationRoutes = {
  OrgIntegrationSettingsTab,
  OrgGuestCom,
  OrgGuestComCreateRule,
  OrgGuestComEditRule,
  OrgMemberAdd,
  OrgHomeNewMemberAdd,
  OrgHomeExistingMemberAdd,
  Organization,
  Organizations,
  OrgIntegrations,
  OrgIntegration,
  OrgCreate,
} as const
