import { useCallback, useState } from "react"

import { IStorage } from "src/utils/storageUtil"
import { Optional } from "src/utils/tsUtil"

/** A hook that will keep local/session storage in sync with React.
 *
 * If the `initialValue` prop is truthy, it will override whatever might be
 * currently stored.
 */
export function useStorage<T = string>({
  storage,
  initialValue,
}: {
  storage: IStorage<T>
  initialValue?: Optional<NonNullable<T>>
}) {
  const [data, _setData] = useState<T | null>(() => {
    if (initialValue) {
      storage.set(initialValue)
    }
    return storage.get()
  })

  /** Set state and stored data in one go data */
  const setData: IStorage<T>["set"] = useCallback(
    (data) => {
      storage.set(data)
      _setData(data)
    },
    [storage]
  )

  const clear = useCallback(() => {
    storage.clear()
    _setData(null)
  }, [storage])

  return { data, set: setData, clear }
}
