// The code in this module is used to determine the user's operating system and
// browser.
//
// It's currently a quick'n'dirty hack that gets the job done, but it should
// probably be improved at a later date.

function isWindows() {
  return /Win/.test(navigator.platform)
}

function isMac() {
  return /Mac/.test(navigator.platform)
}

function isLinux() {
  return /Linux/.test(navigator.platform)
}

function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  )
}

function isEdge() {
  return /Edg/.test(navigator.userAgent)
}

function isFirefox() {
  return /Firefox/.test(navigator.userAgent)
}

function isSafari() {
  return (
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor)
  )
}

function getOsInfo(): TSystem {
  if (isWindows()) {
    return "Windows"
  } else if (isMac()) {
    return "Mac"
  } else if (isLinux()) {
    return "Linux"
  } else {
    return "Unknown"
  }
}

function getBrowser(): TBrowser {
  if (isEdge()) {
    return "Edge"
  } else if (isChrome()) {
    return "Chrome"
  } else if (isFirefox()) {
    return "Firefox"
  } else if (isSafari()) {
    return "Safari"
  } else {
    return "Unknown"
  }
}

type TBrowser = "Chrome" | "Edge" | "Firefox" | "Safari" | "Unknown"
type TSystem = "Windows" | "Mac" | "Linux" | "Unknown"
type TBrowserSystemInfo = { browser: TBrowser; os: TSystem }

/** @deprecated: This should be merged/replaced with src/data/analytics/userAgentParser.ts */
export function getBrowserSystemInfo(): TBrowserSystemInfo {
  return { browser: getBrowser(), os: getOsInfo() }
}

export function hasNativeNotificationSound() {
  const { browser, os } = getBrowserSystemInfo()
  if (os === "Linux") return false // Linux never seems to have a native sound
  if (browser === "Firefox") return true
  if (browser === "Edge") return true

  return false // default is that the browser/OS doesn't have a native sound
}
