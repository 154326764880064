import { ISmokingDetection } from "src/data/homes/types/homeTypes"
import TurnOffOutlinedIcon from "src/ui/icons/cigarette-detection-outlined-off.svg"
import TurnOffOutlinedActiveIcon from "src/ui/icons/cigarette-detection-outlined-off-active.svg"
import TurnOnOutlinedIcon from "src/ui/icons/cigarette-detection-outlined-on.svg"
import TurnOnOutlinedActiveIcon from "src/ui/icons/cigarette-detection-outlined-on-active.svg"
import { SVGProps } from "src/utils/tsUtil"

export function SmokingDetectionToggleActiveIcon({
  toggleState,
  state,
  iconProps,
}: {
  toggleState: "on" | "off"
  state: ISmokingDetection["state"]
  iconProps?: SVGProps
}) {
  if (toggleState === "on") {
    if (state === "enabled") {
      return <TurnOnOutlinedActiveIcon {...iconProps} />
    }

    return <TurnOnOutlinedIcon {...iconProps} />
  }

  if (state === "enabled") {
    return <TurnOffOutlinedIcon {...iconProps} />
  }

  return <TurnOffOutlinedActiveIcon {...iconProps} />
}
