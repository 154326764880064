import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { MText } from "src/ui/MText"

export function ParadiseOrderTimeline() {
  return (
    <div>
      <ParadiseDetailsSection title="Timeline" />

      <MText variant="bodyS" color="secondary">
        Implement me
      </MText>
    </div>
  )
}
