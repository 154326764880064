/**
 * For usage and examples, check `routeTypes.ts`.
 */
import { createBrowserHistory } from "history"

import { AccountRoutes } from "src/router/accountRoutes"
import { CallAssistRoutes } from "src/router/callAssistRoutes"
import { GuestAccessRoutes } from "src/router/guestAccessRoutes"
import { HomeGroupRoutes } from "src/router/homeGroupRoutes"
import { HomeRoutes } from "src/router/homeRoutes"
import { OrganizationRoutes } from "src/router/organizationRoutes"
import { ParadiseRoutes } from "src/router/ParadiseRoutes"
import { ResponseServiceRoutes } from "src/router/responseServiceRoutes"
import { createBasicLocation } from "src/router/routeTypes"
import { SensorRoutes } from "src/router/sensorRoutes"
import { SettingsRoutes } from "src/router/settingsRoutes"
import { SignupLoginRoutes } from "src/router/signupLoginRoutes"

const Dashboard = createBasicLocation("/dashboard")
const Onboarding = createBasicLocation("/onboarding")
const OnboardingCreateOrganization = createBasicLocation(
  "/onboarding/create-organization"
)
const OnboardingJoinOrganization = createBasicLocation(
  "/onboarding/join-organization"
)
const PlateStore = createBasicLocation("/order/mounting-plate")

const Reports = createBasicLocation("/reports")

const ReferralProgram = createBasicLocation("/refer-a-friend")

export const Routes = {
  Dashboard,
  Onboarding,
  OnboardingCreateOrganization,
  OnboardingJoinOrganization,
  PlateStore,
  Reports,
  ReferralProgram,
  ...HomeRoutes,
  ...OrganizationRoutes,
  ...HomeGroupRoutes,
  ...ResponseServiceRoutes,
  ...AccountRoutes,
  ...SensorRoutes,
  ...SignupLoginRoutes,
  ...GuestAccessRoutes,
  ...SettingsRoutes,
  ...CallAssistRoutes,
  ...ParadiseRoutes,
} as const

export const browserHistory = createBrowserHistory()
