import {
  WidgetEmptyState,
  WidgetPopover,
  WidgetSkeleton,
} from "src/components/Dashboard/DashboardWidgets/WidgetPopover"
import { HomeTableMini } from "src/components/Homes/HomeList/HomeTableMini"
import { IStatefulHomeWithDevices } from "src/data/homes/types/homeTypes"

export function HomeListPopover({
  title,
  emptyStateDescription,
  homesWithDevices,
  loading,
  anchorEl,
  linkTo,
  onClose,
}: {
  title: React.ReactNode
  emptyStateDescription: React.ReactNode
  homesWithDevices: IStatefulHomeWithDevices[]
  loading: boolean
  anchorEl: HTMLElement | null
  linkTo: string | null
  onClose?: () => void
}) {
  if (loading) {
    return (
      <WidgetPopover anchorEl={anchorEl} onClose={onClose}>
        <WidgetSkeleton title={title} />
      </WidgetPopover>
    )
  }

  if (homesWithDevices.length === 0) {
    return (
      <WidgetPopover anchorEl={anchorEl} onClose={onClose}>
        <WidgetEmptyState description={emptyStateDescription} />
      </WidgetPopover>
    )
  }

  return (
    <WidgetPopover anchorEl={anchorEl} onClose={onClose}>
      <HomeTableMini
        title={title}
        homesWithDevices={homesWithDevices}
        linkTo={linkTo}
      />
    </WidgetPopover>
  )
}
