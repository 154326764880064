import { TClockTypeMaybe } from "src/data/user/user"
import { formatDate } from "src/utils/l10n"

export function localizedDate(
  date: string,
  clockType: TClockTypeMaybe,
  timezone?: string
) {
  let formattedDate
  try {
    formattedDate = String(formatDate({ date, clockType, timezone }))
  } catch (err) {
    formattedDate = String(date)
  }
  formattedDate = String(formatDate({ date, clockType, timezone }))
  return formattedDate
}
