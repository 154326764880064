import {
  HomeGroupRank,
  THomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"

type HomeGroupRole = THomeGroup["user_role"]

/** Get the access rank of a role */
export function getHomeGroupAccessRank(
  role: HomeGroupRole | undefined
): HomeGroupRank {
  switch (role) {
    case "admin":
      return HomeGroupRank.ADMIN
    default:
      return HomeGroupRank.MEMBER
  }
}

export function hasHomeGroupAccess(
  role: HomeGroupRole | undefined,
  minLevel: HomeGroupRole = "member"
): boolean {
  if (role === undefined) {
    return false
  }
  const rank = getHomeGroupAccessRank(role)
  const minRank = getHomeGroupAccessRank(minLevel)
  return rank >= minRank
}
