import { useState } from "react"

import { DeviceListPopover } from "src/components/Dashboard/DashboardWidgets/DeviceListPopover"
import { WidgetCard } from "src/components/Dashboard/DashboardWidgets/WidgetCard"
import { DEVICE_LIST_LIMIT_MAX } from "src/data/devices/logic/deviceConstants"
import { hasIndoorClimateSupport } from "src/data/devices/logic/deviceLogic"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { IndoorClimateFilter } from "src/data/filters/filtersHome"
import { getStatefulHomesWithDevices } from "src/data/homes/logic/homeUtil"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"

export function WidgetIndoorClimate() {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()
  const { isSuspended } = useDunning()

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const limit = 4

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const fetchHomes = useFetchHomes({
    orgId,
    filters: { indoor_climate_ongoing_alert_filter: true },
    options: { enabled: indoorClimateAvailable },
  })

  const homes = fetchHomes.data?.homes || []

  const homesTotalCount = fetchHomes.data?.paging.total_count ?? 0
  const level = homesTotalCount > 0 ? "red" : "green"

  const fetchDevices = useFetchDevices({
    orgId: orgId,
    filter: {
      home_ids: homes?.map((h) => h.home_id),
      limit: DEVICE_LIST_LIMIT_MAX,
    },
    options: {
      enabled: homesTotalCount > 0,
    },
  })

  const devices = fetchDevices.data?.devices || []

  const filteredDevices = devices.filter(hasIndoorClimateSupport)

  const homesWithDevices = getStatefulHomesWithDevices(homes, filteredDevices)

  const linkTo =
    homesTotalCount > limit
      ? Routes.Homes.location({
          [IndoorClimateFilter.id]: !!IndoorClimateFilter.value,
        })
      : null

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOpen(true)
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <div>
      <WidgetCard
        alertLevel={level}
        number={homesWithDevices.length}
        title={t(langKeys.ongoing_indoor_climate_events)}
        icon={IndoorClimateIcon}
        onClick={handleClick}
        disabled={isSuspended}
      />

      {open && (
        <DeviceListPopover
          title={t(langKeys.ongoing_indoor_climate_events)}
          emptyStateDescription={t(
            langKeys.ongoing_indoor_climate_events_empty_state
          )}
          homesWithDevices={homesWithDevices}
          loading={fetchHomes.isInitialLoading || fetchDevices.isInitialLoading}
          anchorEl={anchorEl}
          onClose={handleClose}
          showIndoorClimateDevices={true}
          linkTo={linkTo}
        />
      )}
    </div>
  )
}
