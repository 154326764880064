import styled from "styled-components"

import { WidgetFireCo } from "src/components/Dashboard/DashboardWidgets/WidgetFireCo"
import { WidgetIndoorClimate } from "src/components/Dashboard/DashboardWidgets/WidgetIndoorClimate"
import { WidgetUnmonitored } from "src/components/Dashboard/DashboardWidgets/WidgetUnmonitored"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { spacing } from "src/ui/spacing"

import { WidgetCrowd } from "./WidgetCrowd"
import { WidgetLowBattery } from "./WidgetLowBattery"
import { WidgetNoiseEvents } from "./WidgetNoiseEvents"
import { WidgetSmokingDetection } from "./WidgetSmokingDetection"

export function DashboardWidgets() {
  const cigaretteSmokeAvailability = useFeatureAvailability({
    feature: "cigarette_smoke_detection",
  })
  const fireCoAvailable = useFeatureAvailability({
    feature: "eureka",
  }).available

  const indoorClimateAvailable = useFeatureAvailability({
    feature: "indoor_climate_monitoring",
  }).available

  const showSmokingDetectionCard =
    (cigaretteSmokeAvailability.ready &&
      cigaretteSmokeAvailability.available) ||
    fireCoAvailable

  return (
    <CardGrid>
      <WidgetUnmonitored />
      <WidgetLowBattery />
      <WidgetNoiseEvents />
      <WidgetCrowd />
      {showSmokingDetectionCard && <WidgetSmokingDetection />}
      {fireCoAvailable && <WidgetFireCo />}
      {indoorClimateAvailable && <WidgetIndoorClimate />}
    </CardGrid>
  )
}

const CardGrid = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 1fr;
`
