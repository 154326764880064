import { IFilter, IURLQueryParam } from "src/data/filters/types/filterTypes"
import { debug } from "src/utils/logger"
import { Maybe, Optional } from "src/utils/tsUtil"

export function getFiltersInUrl(
  filters: IFilter[],
  search?: string
): IURLQueryParam[] {
  const matches: IURLQueryParam[] = []
  const searchParams = new URLSearchParams(search || window.location.search)
  searchParams.forEach((value, key) => {
    const match = filters.find((f) => f.id === key)
    if (match) {
      matches.push({ ...match, value })
    }
  })
  return matches
}

export function removeUrlFilters(
  filters: IFilter[],
  setter: (s: string) => void
) {
  const searchParams = new URLSearchParams(window.location.search)
  filters.forEach((f): void => {
    searchParams.delete(f.id)
  })
  return setter(`?${searchParams}`)
}

/** Return the first match for `key` in the Url params */
export function getUrlParamValue<ValueType = string>(
  key: string,
  search?: string
): Maybe<ValueType> {
  const searchParams = new URLSearchParams(search || window.location.search)
  return searchParams.get(key) as Maybe<ValueType>
}

/** Update URL params via setter; values of null, undefined or '' are removed */
export function setUrlParam<
  ValueType extends Optional<string | number | boolean> = string,
>(
  key: string,
  value: ValueType,
  setter: (newParamString: string) => void,
  options?: {
    search?: string
    unique?: boolean
  }
) {
  setUrlParams(
    [
      {
        id: key,
        value,
        name: () => "",
        unique: options?.unique ?? true /* true is default */,
      },
    ],
    setter,
    options?.search
  )
}

/** Update URL params via setter; values of null, undefined or '' are removed */
export function setUrlParams(
  params: IFilter[],
  setter: (newParamString: string) => void,
  searchCurrent?: string
) {
  const searchParams = new URLSearchParams(
    searchCurrent || window.location.search
  )
  const _params = Array.isArray(params) ? params : [params]

  _params.forEach((p) => {
    if ((p.value ?? "") === "") {
      debug.log(`[setUrlParams]: Removing ${p.value}`)
      searchParams.delete(p.id) // Remove params that are null, undefined or ''
    } else {
      debug.log(
        `[setUrlParams]: Setting or appending ${p.id} to ${p.value} (unique: ${p.unique})`
      )
      !!p.unique
        ? searchParams.set(p.id, String(p.value))
        : searchParams.append(p.id, String(p.value))
    }
  })
  setter(searchParams.toString())
}
