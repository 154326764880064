export const langKeys = {
  accept_terms_of_service_web: "accept_terms_of_service_web",
  access_title: "access_title",
  account: "account",
  activate: "activate",
  activate_call_assist_about_body_1: "activate_call_assist_about_body_1",
  activate_call_assist_about_body_2: "activate_call_assist_about_body_2",
  activate_call_assist_about_title: "activate_call_assist_about_title",
  activate_call_assist_added_montly_cost:
    "activate_call_assist_added_montly_cost",
  activate_call_assist_body: "activate_call_assist_body",
  activate_call_assist_error_already_active:
    "activate_call_assist_error_already_active",
  activate_call_assist_error_title: "activate_call_assist_error_title",
  activate_call_assist_for_homes_count: "activate_call_assist_for_homes_count",
  activate_call_assist_guard_and_call_assist_simultaneously_warning:
    "activate_call_assist_guard_and_call_assist_simultaneously_warning",
  activate_call_assist_no_homes_warning:
    "activate_call_assist_no_homes_warning",
  activate_call_assist_only_organization_owner_can_activate_warning:
    "activate_call_assist_only_organization_owner_can_activate_warning",
  activate_call_assist_success_body: "activate_call_assist_success_body",
  activate_call_assist_success_title: "activate_call_assist_success_title",
  activate_call_assist_title: "activate_call_assist_title",
  activate_call_assist_upgrade_plan_body:
    "activate_call_assist_upgrade_plan_body",
  activate_call_assist_upgrade_plan_title:
    "activate_call_assist_upgrade_plan_title",
  activated: "activated",
  activating_call_assist_body: "activating_call_assist_body",
  activating_pre_paid_home_title: "activating_pre_paid_home_title",
  active: "active",
  add: "add",
  add_filter: "add_filter",
  add_guest: "add_guest",
  add_home: "add_home",
  add_home_upfront_disclaimer: "add_home_upfront_disclaimer",
  add_stay_dialog_date_error: "add_stay_dialog_date_error",
  add_stay_dialog_imported_from: "add_stay_dialog_imported_from",
  add_stay_dialog_phone_number_error: "add_stay_dialog_phone_number_error",
  add_stay_dialog_title_edit: "add_stay_dialog_title_edit",
  add_stay_dialog_visit_source: "add_stay_dialog_visit_source",
  adding_call_assist_title: "adding_call_assist_title",
  adding_new_home_token_info: "adding_new_home_token_info",
  adding_new_home_token_info_plural: "adding_new_home_token_info_plural",
  additional_home_info: "additional_home_info",
  addon_undo_scheduled_cancellation: "addon_undo_scheduled_cancellation",
  addon_will_automatically_renew_on_date:
    "addon_will_automatically_renew_on_date",
  addons: "addons",
  address: "address",
  address_company: "address_company",
  address_form_city: "address_form_city",
  address_form_country: "address_form_country",
  address_form_postcode: "address_form_postcode",
  address_form_state: "address_form_state",
  address_form_street_name_1: "address_form_street_name_1",
  address_form_street_name_2: "address_form_street_name_2",
  address_state: "address_state",
  adjust: "adjust",
  agree: "agree",
  air_too_dry_action_0: "air_too_dry_action_0",
  air_too_dry_body: "air_too_dry_body",
  air_too_dry_title: "air_too_dry_title",
  air_too_humid_action_0: "air_too_humid_action_0",
  air_too_humid_body: "air_too_humid_body",
  air_too_humid_title: "air_too_humid_title",
  airbnb_extended_permission_todo_body: "airbnb_extended_permission_todo_body",
  airbnb_extended_permission_todo_title:
    "airbnb_extended_permission_todo_title",
  alarm_detection_test_action_0: "alarm_detection_test_action_0",
  alarm_detection_test_body: "alarm_detection_test_body",
  alarm_detection_test_title: "alarm_detection_test_title",
  alarm_end_of_life_short_description: "alarm_end_of_life_short_description",
  alarm_grace_period_expired_body: "alarm_grace_period_expired_body",
  alarm_grace_period_expired_title: "alarm_grace_period_expired_title",
  alarm_heard_body: "alarm_heard_body",
  alarm_heard_title: "alarm_heard_title",
  alarm_muted_button_press_body: "alarm_muted_button_press_body",
  alarm_muted_button_press_title: "alarm_muted_button_press_title",
  alarm_off_automatically_body: "alarm_off_automatically_body",
  alarm_on_automatically_body: "alarm_on_automatically_body",
  alarm_recognition_setting_body: "alarm_recognition_setting_body",
  alarm_recognition_setting_title: "alarm_recognition_setting_title",
  alarm_silenced_body: "alarm_silenced_body",
  alarm_silenced_title: "alarm_silenced_title",
  alarm_test_failed_body: "alarm_test_failed_body",
  alarm_test_failed_short_description: "alarm_test_failed_short_description",
  alarm_test_failed_title: "alarm_test_failed_title",
  alarm_test_success_title: "alarm_test_success_title",
  alarm_turned_off_button_press_title: "alarm_turned_off_button_press_title",
  all_data_and_sensors_will_be_deleted: "all_data_and_sensors_will_be_deleted",
  almost_freezing_action_0: "almost_freezing_action_0",
  almost_freezing_body: "almost_freezing_body",
  almost_freezing_details: "almost_freezing_details",
  almost_freezing_title: "almost_freezing_title",
  api_clients_body: "api_clients_body",
  api_clients_client: "api_clients_client",
  api_clients_client_credentials_title: "api_clients_client_credentials_title",
  api_clients_client_id: "api_clients_client_id",
  api_clients_client_secret: "api_clients_client_secret",
  api_clients_create_body: "api_clients_create_body",
  api_clients_create_client: "api_clients_create_client",
  api_clients_create_title: "api_clients_create_title",
  api_clients_delete_body: "api_clients_delete_body",
  api_clients_delete_confirm_body: "api_clients_delete_confirm_body",
  api_clients_delete_title: "api_clients_delete_title",
  api_clients_edit_title: "api_clients_edit_title",
  api_clients_empty_body: "api_clients_empty_body",
  api_clients_empty_title: "api_clients_empty_title",
  api_clients_form_add_another_uri: "api_clients_form_add_another_uri",
  api_clients_form_name: "api_clients_form_name",
  api_clients_redirect_uri: "api_clients_redirect_uri",
  api_clients_title: "api_clients_title",
  area_type_backyard: "area_type_backyard",
  area_type_balcony: "area_type_balcony",
  area_type_corridor: "area_type_corridor",
  area_type_garage: "area_type_garage",
  area_type_garden: "area_type_garden",
  area_type_hot_tub: "area_type_hot_tub",
  area_type_lounge: "area_type_lounge",
  area_type_other_indoor_area: "area_type_other_indoor_area",
  area_type_other_outdoor_area: "area_type_other_outdoor_area",
  area_type_patio: "area_type_patio",
  area_type_pool_area: "area_type_pool_area",
  area_type_staircase: "area_type_staircase",
  area_type_study: "area_type_study",
  area_type_terrace: "area_type_terrace",
  attic: "attic",
  automatic: "automatic",
  automatic_alarm_mobile_app_only: "automatic_alarm_mobile_app_only",
  average: "average",
  avg_sound_high_body: "avg_sound_high_body",
  avg_sound_high_title: "avg_sound_high_title",
  back: "back",
  badge_custom_plan: "badge_custom_plan",
  basement: "basement",
  bathroom: "bathroom",
  battery: "battery",
  battery_charging_complete_body: "battery_charging_complete_body",
  battery_empty_body: "battery_empty_body",
  battery_empty_title: "battery_empty_title",
  battery_low_body: "battery_low_body",
  battery_low_title: "battery_low_title",
  bedroom: "bedroom",
  bedroom_too_warm_action_0: "bedroom_too_warm_action_0",
  bedroom_too_warm_body: "bedroom_too_warm_body",
  bedroom_too_warm_details: "bedroom_too_warm_details",
  bedroom_too_warm_title: "bedroom_too_warm_title",
  beta: "beta",
  billed_as: "billed_as",
  billed_now: "billed_now",
  billing_add_vat_number: "billing_add_vat_number",
  billing_address: "billing_address",
  billing_address_payment_method_match: "billing_address_payment_method_match",
  billing_event_plan_renewal_breadcrumb:
    "billing_event_plan_renewal_breadcrumb",
  billing_event_plan_renewal_title: "billing_event_plan_renewal_title",
  billing_history_account_balance: "billing_history_account_balance",
  billing_history_account_balance_tooltip:
    "billing_history_account_balance_tooltip",
  billing_history_credit_debit: "billing_history_credit_debit",
  billing_history_credit_debit_tooltip_description:
    "billing_history_credit_debit_tooltip_description",
  billing_history_event: "billing_history_event",
  billing_history_homegroup: "billing_history_homegroup",
  billing_history_modified_by: "billing_history_modified_by",
  billing_history_note: "billing_history_note",
  billing_history_past_events_description:
    "billing_history_past_events_description",
  billing_history_payment: "billing_history_payment",
  billing_history_payment_note_link_label:
    "billing_history_payment_note_link_label",
  billing_history_payment_refunded_note_link_label:
    "billing_history_payment_refunded_note_link_label",
  billing_history_plan_renewal_note: "billing_history_plan_renewal_note",
  billing_history_title: "billing_history_title",
  billing_location_verification_error: "billing_location_verification_error",
  billing_page_feedback_body: "billing_page_feedback_body",
  billing_page_feedback_title: "billing_page_feedback_title",
  billing_period: "billing_period",
  billing_tax_exempt: "billing_tax_exempt",
  billing_total_includes: "billing_total_includes",
  billing_vat: "billing_vat",
  booking_dates: "booking_dates",
  building_details: "building_details",
  buildings_empty_state_description: "buildings_empty_state_description",
  buildings_empty_state_description_copy:
    "buildings_empty_state_description_copy",
  buildings_empty_state_title: "buildings_empty_state_title",
  ca_configure_guests: "ca_configure_guests",
  ca_table_missing_phone_number: "ca_table_missing_phone_number",
  call_and_guard_assist: "call_and_guard_assist",
  call_and_guard_assist_description: "call_and_guard_assist_description",
  call_assist: "call_assist",
  call_assist_description: "call_assist_description",
  call_assist_title_description: "call_assist_title_description",
  cancel: "cancel",
  cancel_addon_body: "cancel_addon_body",
  cancel_addon_title: "cancel_addon_title",
  cancel_plan: "cancel_plan",
  cancel_plan_additional_placeholder: "cancel_plan_additional_placeholder",
  cancel_plan_body: "cancel_plan_body",
  cancel_plan_come_back_question: "cancel_plan_come_back_question",
  cancel_plan_experience_rating_excellent:
    "cancel_plan_experience_rating_excellent",
  cancel_plan_experience_rating_poor: "cancel_plan_experience_rating_poor",
  cancel_plan_experience_rating_question:
    "cancel_plan_experience_rating_question",
  cancel_plan_need_help: "cancel_plan_need_help",
  cancel_plan_need_help_body: "cancel_plan_need_help_body",
  cancel_plan_please_specify_below: "cancel_plan_please_specify_below",
  cancel_plan_reason_additional_feeback:
    "cancel_plan_reason_additional_feeback",
  cancel_plan_reason_alternative_service:
    "cancel_plan_reason_alternative_service",
  cancel_plan_reason_body: "cancel_plan_reason_body",
  cancel_plan_reason_need_more_features:
    "cancel_plan_reason_need_more_features",
  cancel_plan_reason_not_hosting: "cancel_plan_reason_not_hosting",
  cancel_plan_reason_not_meet_expectations:
    "cancel_plan_reason_not_meet_expectations",
  cancel_plan_reason_not_need_monitoring:
    "cancel_plan_reason_not_need_monitoring",
  cancel_plan_reason_not_useful: "cancel_plan_reason_not_useful",
  cancel_plan_reason_other: "cancel_plan_reason_other",
  cancel_plan_reason_tech_problems: "cancel_plan_reason_tech_problems",
  cancel_plan_reason_title: "cancel_plan_reason_title",
  cancel_plan_reasons_requirement: "cancel_plan_reasons_requirement",
  cancel_plan_return_probability_very_likely:
    "cancel_plan_return_probability_very_likely",
  cancel_plan_return_probability_very_unlikely:
    "cancel_plan_return_probability_very_unlikely",
  cancel_plan_title: "cancel_plan_title",
  cancel_plan_what_could_we_have_done_differently:
    "cancel_plan_what_could_we_have_done_differently",
  cancel_reason_missing_feature: "cancel_reason_missing_feature",
  cancel_reason_switching_to_another_solution:
    "cancel_reason_switching_to_another_solution",
  cancel_reason_too_expensive: "cancel_reason_too_expensive",
  cancelled: "cancelled",
  card_ending_in: "card_ending_in",
  celsius: "celsius",
  celsius_long: "celsius_long",
  change_name: "change_name",
  change_password: "change_password",
  change_plan: "change_plan",
  change_plan_add_payment_method_first: "change_plan_add_payment_method_first",
  change_plan_annual_disclaimer: "change_plan_annual_disclaimer",
  change_plan_annual_savings: "change_plan_annual_savings",
  change_plan_breakdown: "change_plan_breakdown",
  change_plan_cancel: "change_plan_cancel",
  change_plan_charged_with_card: "change_plan_charged_with_card",
  change_plan_charged_with_credits: "change_plan_charged_with_credits",
  change_plan_contact_sales_cta: "change_plan_contact_sales_cta",
  change_plan_credit_adjustment_body: "change_plan_credit_adjustment_body",
  change_plan_credit_adjustment_title: "change_plan_credit_adjustment_title",
  change_plan_current_plan_pill: "change_plan_current_plan_pill",
  change_plan_custom_plan_blocker_body: "change_plan_custom_plan_blocker_body",
  change_plan_custom_plan_blocker_title:
    "change_plan_custom_plan_blocker_title",
  change_plan_description: "change_plan_description",
  change_plan_future: "change_plan_future",
  change_plan_has_scheduled_changes: "change_plan_has_scheduled_changes",
  change_plan_no_charge: "change_plan_no_charge",
  change_plan_no_charge_credits: "change_plan_no_charge_credits",
  change_plan_non_renewing: "change_plan_non_renewing",
  change_plan_paused: "change_plan_paused",
  change_plan_renews: "change_plan_renews",
  change_plan_select_title: "change_plan_select_title",
  change_plan_subscribe_pre_paid_homes_description:
    "change_plan_subscribe_pre_paid_homes_description",
  change_plan_subscribe_pre_paid_homes_title:
    "change_plan_subscribe_pre_paid_homes_title",
  change_plan_success_body: "change_plan_success_body",
  change_plan_success_title: "change_plan_success_title",
  change_plan_time_of_change: "change_plan_time_of_change",
  change_plan_time_of_change_title: "change_plan_time_of_change_title",
  change_plan_to: "change_plan_to",
  change_plan_update_billing_period: "change_plan_update_billing_period",
  change_plan_used_credits: "change_plan_used_credits",
  charge: "charge",
  charger_connected_body: "charger_connected_body",
  charger_disconnected_body: "charger_disconnected_body",
  check_in_instructions: "check_in_instructions",
  choose_your_plan_text: "choose_your_plan_text",
  city: "city",
  clear_filters: "clear_filters",
  clear_selection: "clear_selection",
  click_here: "click_here",
  close: "close",
  co_alarm: "co_alarm",
  co_alarm_triggered_title: "co_alarm_triggered_title",
  configure: "configure",
  confirm: "confirm",
  confirm_cancel: "confirm_cancel",
  confirm_discard_changes_body: "confirm_discard_changes_body",
  confirm_discard_changes_cancel: "confirm_discard_changes_cancel",
  confirm_discard_changes_discard: "confirm_discard_changes_discard",
  confirm_discard_changes_title: "confirm_discard_changes_title",
  confirm_place_order: "confirm_place_order",
  confirm_reactivation: "confirm_reactivation",
  connect: "connect",
  contact_sales: "contact_sales",
  contact_support: "contact_support",
  continue: "continue",
  copied: "copied",
  copy: "copy",
  copy_noun: "copy_noun",
  country: "country",
  create_an_account: "create_an_account",
  create_home: "create_home",
  create_home_confirm_step_billing_body_1:
    "create_home_confirm_step_billing_body_1",
  create_home_confirm_step_billing_body_2:
    "create_home_confirm_step_billing_body_2",
  create_home_confirm_step_billing_body_3:
    "create_home_confirm_step_billing_body_3",
  create_home_confirm_step_billing_title:
    "create_home_confirm_step_billing_title",
  create_home_confirm_step_title: "create_home_confirm_step_title",
  create_home_confirmation_accept_body: "create_home_confirmation_accept_body",
  create_home_confirmation_accept_error:
    "create_home_confirmation_accept_error",
  create_home_confirmation_activating_home:
    "create_home_confirmation_activating_home",
  create_home_confirmation_creating_home:
    "create_home_confirmation_creating_home",
  create_home_confirmation_home_details_title:
    "create_home_confirmation_home_details_title",
  create_home_confirmation_terms: "create_home_confirmation_terms",
  create_home_contract_limit_banner_body:
    "create_home_contract_limit_banner_body",
  create_home_contract_limit_banner_title:
    "create_home_contract_limit_banner_title",
  create_home_enter_address_manually: "create_home_enter_address_manually",
  create_home_information_step_title: "create_home_information_step_title",
  create_home_settings_profile_step_body:
    "create_home_settings_profile_step_body",
  create_home_settings_profile_step_title:
    "create_home_settings_profile_step_title",
  create_organization_how_many_units: "create_organization_how_many_units",
  create_organization_name_placeholder: "create_organization_name_placeholder",
  create_organization_portfolio_size_error_text:
    "create_organization_portfolio_size_error_text",
  create_organization_reason_custom_placeholder:
    "create_organization_reason_custom_placeholder",
  create_organization_reason_for_minut: "create_organization_reason_for_minut",
  create_organization_reason_local_regulations:
    "create_organization_reason_local_regulations",
  create_organization_reason_lower_smoking_risk:
    "create_organization_reason_lower_smoking_risk",
  create_organization_reason_maintenance_cost:
    "create_organization_reason_maintenance_cost",
  create_organization_reason_mold_free: "create_organization_reason_mold_free",
  create_organization_reason_other: "create_organization_reason_other",
  create_organization_reason_prevent_unwanted_parties:
    "create_organization_reason_prevent_unwanted_parties",
  create_organization_reason_reduce_noise_complaints:
    "create_organization_reason_reduce_noise_complaints",
  create_organization_reason_save_utility_bills:
    "create_organization_reason_save_utility_bills",
  create_organization_role_ceo_vp: "create_organization_role_ceo_vp",
  create_organization_role_city_site_manager:
    "create_organization_role_city_site_manager",
  create_organization_role_custom_placeholder:
    "create_organization_role_custom_placeholder",
  create_organization_role_error_text: "create_organization_role_error_text",
  create_organization_role_host_owner: "create_organization_role_host_owner",
  create_organization_role_other: "create_organization_role_other",
  create_organization_role_regional_manager:
    "create_organization_role_regional_manager",
  create_organization_service_apartment_hotel:
    "create_organization_service_apartment_hotel",
  create_organization_service_custom_placeholder:
    "create_organization_service_custom_placeholder",
  create_organization_service_hotel: "create_organization_service_hotel",
  create_organization_service_multi_family_residential:
    "create_organization_service_multi_family_residential",
  create_organization_service_other: "create_organization_service_other",
  create_organization_service_role_guest_communications_frontdesk:
    "create_organization_service_role_guest_communications_frontdesk",
  create_organization_service_senior_living:
    "create_organization_service_senior_living",
  create_organization_service_social_housing:
    "create_organization_service_social_housing",
  create_organization_service_student_housing:
    "create_organization_service_student_housing",
  create_organization_service_vacation_rentals:
    "create_organization_service_vacation_rentals",
  create_organization_services_error_text:
    "create_organization_services_error_text",
  create_organization_to_continue_message:
    "create_organization_to_continue_message",
  create_organization_to_continue_title:
    "create_organization_to_continue_title",
  create_organization_what_is_the_name_placeholder:
    "create_organization_what_is_the_name_placeholder",
  create_organization_what_is_your_role:
    "create_organization_what_is_your_role",
  create_organization_what_type_of_services:
    "create_organization_what_type_of_services",
  create_report: "create_report",
  credit_card: "credit_card",
  crowd_detect_active_emergency_body: "crowd_detect_active_emergency_body",
  crowd_detect_active_emergency_title: "crowd_detect_active_emergency_title",
  crowd_detect_active_normal_title: "crowd_detect_active_normal_title",
  crowd_detect_alert_title: "crowd_detect_alert_title",
  crowd_detect_all_fields_not_set: "crowd_detect_all_fields_not_set",
  crowd_detect_analyzing_short_description:
    "crowd_detect_analyzing_short_description",
  crowd_detect_analyzing_title: "crowd_detect_analyzing_title",
  crowd_detect_battery_life_disclaimer: "crowd_detect_battery_life_disclaimer",
  crowd_detect_capacity_short_description:
    "crowd_detect_capacity_short_description",
  crowd_detect_capacity_title: "crowd_detect_capacity_title",
  crowd_detect_chart_threshold_label: "crowd_detect_chart_threshold_label",
  crowd_detect_chart_title: "crowd_detect_chart_title",
  crowd_detect_crowd_risk_description: "crowd_detect_crowd_risk_description",
  crowd_detect_crowd_risk_subtitle: "crowd_detect_crowd_risk_subtitle",
  crowd_detect_crowd_risk_title: "crowd_detect_crowd_risk_title",
  crowd_detect_current_number_of_devices_at:
    "crowd_detect_current_number_of_devices_at",
  crowd_detect_device_threshold_read_more:
    "crowd_detect_device_threshold_read_more",
  crowd_detect_device_threshold_short_description:
    "crowd_detect_device_threshold_short_description",
  crowd_detect_device_threshold_title: "crowd_detect_device_threshold_title",
  crowd_detect_short_description: "crowd_detect_short_description",
  crowd_detect_title: "crowd_detect_title",
  customize_noise_report_body: "customize_noise_report_body",
  customize_noise_report_title: "customize_noise_report_title",
  dashboard: "dashboard",
  dashboard_card_add_member_description:
    "dashboard_card_add_member_description",
  date: "date",
  days: "days",
  decibel_label_short: "decibel_label_short",
  delete: "delete",
  delete_account: "delete_account",
  delete_account_body: "delete_account_body",
  delete_account_title: "delete_account_title",
  delete_generic_question: "delete_generic_question",
  delete_multiple_rental_units: "delete_multiple_rental_units",
  delete_multiple_rental_units_plural: "delete_multiple_rental_units_plural",
  delete_multiple_rental_units_description:
    "delete_multiple_rental_units_description",
  description: "description",
  desktop_notifications_description: "desktop_notifications_description",
  desktop_notifications_supported: "desktop_notifications_supported",
  desktop_notifications_title: "desktop_notifications_title",
  device_detail_graph_multiple_motion_events:
    "device_detail_graph_multiple_motion_events",
  device_detail_graph_single_motion_event:
    "device_detail_graph_single_motion_event",
  device_details_motion_detail: "device_details_motion_detail",
  device_details_motion_detail_with_instantly_turn_on_alarm_off:
    "device_details_motion_detail_with_instantly_turn_on_alarm_off",
  device_home_transfer_setting_body: "device_home_transfer_setting_body",
  device_install_body: "device_install_body",
  device_install_title: "device_install_title",
  device_offline_body: "device_offline_body",
  device_offline_eureka_body: "device_offline_eureka_body",
  device_offline_eureka_title: "device_offline_eureka_title",
  device_offline_suffix: "device_offline_suffix",
  device_offline_title: "device_offline_title",
  device_online_body: "device_online_body",
  device_online_eureka_body: "device_online_eureka_body",
  device_online_eureka_title: "device_online_eureka_title",
  device_online_title: "device_online_title",
  device_power_off_body: "device_power_off_body",
  device_power_off_title: "device_power_off_title",
  device_report_rate_setting_body: "device_report_rate_setting_body",
  device_report_rate_setting_title: "device_report_rate_setting_title",
  device_settings_above: "device_settings_above",
  device_settings_advanced_settings_title:
    "device_settings_advanced_settings_title",
  device_settings_below: "device_settings_below",
  device_settings_firmware_version: "device_settings_firmware_version",
  device_settings_glassbreak: "device_settings_glassbreak",
  device_settings_glassbreak_detail: "device_settings_glassbreak_detail",
  device_settings_humidity_description: "device_settings_humidity_description",
  device_settings_humidity_detail_description:
    "device_settings_humidity_detail_description",
  device_settings_mac_address: "device_settings_mac_address",
  device_settings_nightlight: "device_settings_nightlight",
  device_settings_nightlight_detail: "device_settings_nightlight_detail",
  device_settings_noise: "device_settings_noise",
  device_settings_remove_point: "device_settings_remove_point",
  device_settings_remove_point_alert_body:
    "device_settings_remove_point_alert_body",
  device_settings_remove_point_alert_title:
    "device_settings_remove_point_alert_title",
  device_settings_room_type: "device_settings_room_type",
  device_settings_temperature_description:
    "device_settings_temperature_description",
  device_settings_temperature_detail_description:
    "device_settings_temperature_detail_description",
  device_settings_threshold_sound_duration_description:
    "device_settings_threshold_sound_duration_description",
  device_settings_threshold_sound_duration_title:
    "device_settings_threshold_sound_duration_title",
  device_settings_threshold_sound_night_switch_title:
    "device_settings_threshold_sound_night_switch_title",
  device_settings_threshold_sound_switch_title:
    "device_settings_threshold_sound_switch_title",
  device_settings_threshold_switch_title:
    "device_settings_threshold_switch_title",
  device_settings_will_be_updated_notification:
    "device_settings_will_be_updated_notification",
  device_threshold_noise_notifications_switch_state_always:
    "device_threshold_noise_notifications_switch_state_always",
  device_threshold_noise_notifications_switch_state_only_noise_monitoring:
    "device_threshold_noise_notifications_switch_state_only_noise_monitoring",
  devices_list_empty_description: "devices_list_empty_description",
  devices_list_empty_title: "devices_list_empty_title",
  disabled: "disabled",
  discard_changes_dialog_cancel: "discard_changes_dialog_cancel",
  discard_changes_dialog_confirm: "discard_changes_dialog_confirm",
  discard_changes_dialog_description: "discard_changes_dialog_description",
  discard_changes_dialog_title: "discard_changes_dialog_title",
  disconnect: "disconnect",
  dismiss: "dismiss",
  disturbance_alarm_control_short_description:
    "disturbance_alarm_control_short_description",
  disturbance_dismissed_body: "disturbance_dismissed_body",
  disturbance_dismissed_title: "disturbance_dismissed_title",
  disturbance_dispatch_manually_action: "disturbance_dispatch_manually_action",
  disturbance_dispatch_manually_dialog_message:
    "disturbance_dispatch_manually_dialog_message",
  disturbance_dispatch_manually_dialog_title:
    "disturbance_dispatch_manually_dialog_title",
  disturbance_dispatched_automatically_body:
    "disturbance_dispatched_automatically_body",
  disturbance_dispatched_automatically_title:
    "disturbance_dispatched_automatically_title",
  disturbance_dispatched_manually_body: "disturbance_dispatched_manually_body",
  disturbance_dispatched_manually_title:
    "disturbance_dispatched_manually_title",
  disturbance_ended_body: "disturbance_ended_body",
  disturbance_ended_title: "disturbance_ended_title",
  disturbance_first_notice_body: "disturbance_first_notice_body",
  disturbance_first_notice_response_service_body:
    "disturbance_first_notice_response_service_body",
  disturbance_first_notice_response_service_title:
    "disturbance_first_notice_response_service_title",
  disturbance_first_notice_title: "disturbance_first_notice_title",
  disturbance_level_3_automatically_body:
    "disturbance_level_3_automatically_body",
  disturbance_level_3_automatically_title:
    "disturbance_level_3_automatically_title",
  disturbance_level_3_manually_body: "disturbance_level_3_manually_body",
  disturbance_level_3_manually_title: "disturbance_level_3_manually_title",
  disturbance_level_4_automatically_body:
    "disturbance_level_4_automatically_body",
  disturbance_level_4_automatically_title:
    "disturbance_level_4_automatically_title",
  disturbance_level_4_manually_body: "disturbance_level_4_manually_body",
  disturbance_level_4_manually_title: "disturbance_level_4_manually_title",
  disturbance_on_idle_title: "disturbance_on_idle_title",
  disturbance_second_notice_body: "disturbance_second_notice_body",
  disturbance_second_notice_response_service_body:
    "disturbance_second_notice_response_service_body",
  disturbance_second_notice_response_service_title:
    "disturbance_second_notice_response_service_title",
  disturbance_second_notice_title: "disturbance_second_notice_title",
  disturbance_snoozed_body: "disturbance_snoozed_body",
  disturbance_snoozed_title: "disturbance_snoozed_title",
  disturbance_third_notice_body: "disturbance_third_notice_body",
  disturbance_third_notice_response_service_body:
    "disturbance_third_notice_response_service_body",
  disturbance_third_notice_response_service_title:
    "disturbance_third_notice_response_service_title",
  disturbance_third_notice_title: "disturbance_third_notice_title",
  disturbance_warning_state_title: "disturbance_warning_state_title",
  dog_bark_body: "dog_bark_body",
  dog_bark_title: "dog_bark_title",
  done: "done",
  download: "download",
  download_csv: "download_csv",
  download_csv_homes_content: "download_csv_homes_content",
  download_incident_report_button_action:
    "download_incident_report_button_action",
  download_incident_report_duration_error:
    "download_incident_report_duration_error",
  download_report: "download_report",
  dunning_banner_action: "dunning_banner_action",
  dunning_banner_day_0_body_member: "dunning_banner_day_0_body_member",
  dunning_banner_day_0_body_owner: "dunning_banner_day_0_body_owner",
  dunning_banner_day_0_title: "dunning_banner_day_0_title",
  dunning_banner_pre_suspended_body_member:
    "dunning_banner_pre_suspended_body_member",
  dunning_banner_pre_suspended_body_owner:
    "dunning_banner_pre_suspended_body_owner",
  dunning_banner_pre_suspended_title_member:
    "dunning_banner_pre_suspended_title_member",
  dunning_banner_pre_suspended_title_owner:
    "dunning_banner_pre_suspended_title_owner",
  dunning_banner_suspended_body_member: "dunning_banner_suspended_body_member",
  dunning_banner_suspended_body_owner: "dunning_banner_suspended_body_owner",
  dunning_banner_suspended_title_member:
    "dunning_banner_suspended_title_member",
  dunning_banner_suspended_title_owner: "dunning_banner_suspended_title_owner",
  dunning_billing_alert_pre_suspended: "dunning_billing_alert_pre_suspended",
  dunning_billing_alert_suspended: "dunning_billing_alert_suspended",
  dunning_suspended_pill: "dunning_suspended_pill",
  duplicate: "duplicate",
  duration: "duration",
  edit: "edit",
  edit_home_information: "edit_home_information",
  edit_settings: "edit_settings",
  email: "email",
  email_notifications: "email_notifications",
  email_notifications_info: "email_notifications_info",
  enabled: "enabled",
  end_time: "end_time",
  enter_the_invite_code: "enter_the_invite_code",
  enter_your_invitation_code: "enter_your_invitation_code",
  event: "event",
  event_plural: "event_plural",
  event_filter_alarm_recognition_label: "event_filter_alarm_recognition_label",
  event_filter_button_pressed_label: "event_filter_button_pressed_label",
  event_filter_contacts_alerted_label: "event_filter_contacts_alerted_label",
  event_filter_crowd_detect_label: "event_filter_crowd_detect_label",
  event_filter_first_noise_alert_label: "event_filter_first_noise_alert_label",
  event_filter_high_humidity_label: "event_filter_high_humidity_label",
  event_filter_high_temperature_label: "event_filter_high_temperature_label",
  event_filter_low_battery_label: "event_filter_low_battery_label",
  event_filter_low_humidity_label: "event_filter_low_humidity_label",
  event_filter_low_temperature_label: "event_filter_low_temperature_label",
  event_filter_mold_risk_label: "event_filter_mold_risk_label",
  event_filter_noise_issue_solved_label:
    "event_filter_noise_issue_solved_label",
  event_filter_out_of_battery_label: "event_filter_out_of_battery_label",
  event_filter_responder_arrived_label: "event_filter_responder_arrived_label",
  event_filter_responder_sent_label: "event_filter_responder_sent_label",
  event_filter_response_partners_callout_label:
    "event_filter_response_partners_callout_label",
  event_filter_second_noise_alert_label:
    "event_filter_second_noise_alert_label",
  event_filter_security_alarm_label: "event_filter_security_alarm_label",
  event_filter_sensor_attached_label: "event_filter_sensor_attached_label",
  event_filter_sensor_installed_label: "event_filter_sensor_installed_label",
  event_filter_sensor_offline_label: "event_filter_sensor_offline_label",
  event_filter_sensor_online_label: "event_filter_sensor_online_label",
  event_filter_sensor_removed_label: "event_filter_sensor_removed_label",
  event_filter_sensor_swap_label: "event_filter_sensor_swap_label",
  event_filter_sensor_turned_off_label: "event_filter_sensor_turned_off_label",
  event_filter_third_noise_alert_label: "event_filter_third_noise_alert_label",
  event_filter_window_break_label: "event_filter_window_break_label",
  event_log_call_assist_cta: "event_log_call_assist_cta",
  event_maximum_events_reached_error_text:
    "event_maximum_events_reached_error_text",
  events_empty_state_description: "events_empty_state_description",
  events_empty_state_title: "events_empty_state_title",
  events_latest: "events_latest",
  events_more: "events_more",
  experimental: "experimental",
  exploration_card_charging_body: "exploration_card_charging_body",
  exploration_card_guest_communication_body:
    "exploration_card_guest_communication_body",
  exploration_card_marijuana_detection_body:
    "exploration_card_marijuana_detection_body",
  exploration_card_noise_thresholds_body:
    "exploration_card_noise_thresholds_body",
  exploration_card_notifications_body: "exploration_card_notifications_body",
  exploration_card_quiet_hours_body: "exploration_card_quiet_hours_body",
  exploration_card_referral_body: "exploration_card_referral_body",
  exploration_card_referral_link: "exploration_card_referral_link",
  exploration_card_sensor_swap_body: "exploration_card_sensor_swap_body",
  exploration_card_team_members_body: "exploration_card_team_members_body",
  explore_minut: "explore_minut",
  export: "export",
  fahrenheit: "fahrenheit",
  fahrenheit_long: "fahrenheit_long",
  failed_contact_support: "failed_contact_support",
  failed_contact_support_to_resolve: "failed_contact_support_to_resolve",
  failed_general_error_body: "failed_general_error_body",
  failed_general_error_title: "failed_general_error_title",
  failed_general_error_try_refreshing_page:
    "failed_general_error_try_refreshing_page",
  failed_something_went_wrong: "failed_something_went_wrong",
  failed_to_add: "failed_to_add",
  failed_to_create: "failed_to_create",
  failed_to_delete: "failed_to_delete",
  failed_to_dismiss: "failed_to_dismiss",
  failed_to_load: "failed_to_load",
  failed_to_process_card: "failed_to_process_card",
  failed_to_remove: "failed_to_remove",
  failed_to_save: "failed_to_save",
  failed_to_update: "failed_to_update",
  feedback_action: "feedback_action",
  feedback_dont_know: "feedback_dont_know",
  filter: "filter",
  filter_event_type: "filter_event_type",
  fire_alarm_triggered_title: "fire_alarm_triggered_title",
  fire_and_co_alarm_automated_tests_footer:
    "fire_and_co_alarm_automated_tests_footer",
  fire_and_co_alarm_automated_tests_title:
    "fire_and_co_alarm_automated_tests_title",
  fire_and_co_alarm_events_title: "fire_and_co_alarm_events_title",
  fire_and_co_alarm_title: "fire_and_co_alarm_title",
  fire_and_co_automated_tests_off_short_description:
    "fire_and_co_automated_tests_off_short_description",
  fire_and_co_automated_tests_on_short_description:
    "fire_and_co_automated_tests_on_short_description",
  fire_and_co_control_screen_footer: "fire_and_co_control_screen_footer",
  fire_and_co_filter: "fire_and_co_filter",
  fire_and_co_short_description: "fire_and_co_short_description",
  first_heartbeat_body: "first_heartbeat_body",
  first_heartbeat_title: "first_heartbeat_title",
  first_morning_body: "first_morning_body",
  first_morning_title: "first_morning_title",
  first_name: "first_name",
  flat_or_suite: "flat_or_suite",
  floor: "floor",
  forgot_password: "forgot_password",
  free: "free",
  from_price: "from_price",
  garage: "garage",
  get_started: "get_started",
  glassbreak_body: "glassbreak_body",
  glassbreak_title: "glassbreak_title",
  go_to_dashboard: "go_to_dashboard",
  go_to_generic_text: "go_to_generic_text",
  go_to_homes: "go_to_homes",
  go_to_presets: "go_to_presets",
  go_to_settings: "go_to_settings",
  go_to_store: "go_to_store",
  go_to_the_store: "go_to_the_store",
  groups_pending_invite: "groups_pending_invite",
  groups_pending_invite_plural: "groups_pending_invite_plural",
  groups_settings: "groups_settings",
  guard_assist_automatic_callout: "guard_assist_automatic_callout",
  guard_assist_description: "guard_assist_description",
  guard_assist_enabled: "guard_assist_enabled",
  guard_assist_title_description: "guard_assist_title_description",
  guard_assist_turn_off_automatic_callout:
    "guard_assist_turn_off_automatic_callout",
  guard_assist_turn_on_automatic_callout:
    "guard_assist_turn_on_automatic_callout",
  guest: "guest",
  guest_plural: "guest_plural",
  guest_check_in: "guest_check_in",
  guest_check_out: "guest_check_out",
  guest_communication: "guest_communication",
  guest_communication_add_short_code: "guest_communication_add_short_code",
  guest_communication_create_new: "guest_communication_create_new",
  guest_connect_autocall_message_header:
    "guest_connect_autocall_message_header",
  guest_connect_autocall_short_description:
    "guest_connect_autocall_short_description",
  guest_connect_autocall_title: "guest_connect_autocall_title",
  guest_connect_sensor_reaction_body: "guest_connect_sensor_reaction_body",
  guest_connect_sensor_reaction_title: "guest_connect_sensor_reaction_title",
  guest_connect_sms_guest_description: "guest_connect_sms_guest_description",
  guest_connect_sms_guest_message_header:
    "guest_connect_sms_guest_message_header",
  guest_connect_sms_guest_title: "guest_connect_sms_guest_title",
  guest_connect_title: "guest_connect_title",
  guest_phone_not_vaild: "guest_phone_not_vaild",
  guest_source: "guest_source",
  guest_terms_and_conditions: "guest_terms_and_conditions",
  guests_group_settings_description: "guests_group_settings_description",
  hallway: "hallway",
  home: "home",
  home_plural: "home_plural",
  home_access_single_home_description: "home_access_single_home_description",
  home_access_title: "home_access_title",
  home_add_address: "home_add_address",
  home_address_wizard_auto_complete_label:
    "home_address_wizard_auto_complete_label",
  home_address_wizard_auto_complete_title:
    "home_address_wizard_auto_complete_title",
  home_address_wizard_home_address_responder_body:
    "home_address_wizard_home_address_responder_body",
  home_address_wizard_home_address_title:
    "home_address_wizard_home_address_title",
  home_address_wizard_map_description: "home_address_wizard_map_description",
  home_address_wizard_map_title: "home_address_wizard_map_title",
  home_address_wizard_response_service_not_available_description:
    "home_address_wizard_response_service_not_available_description",
  home_address_wizard_response_service_not_available_title:
    "home_address_wizard_response_service_not_available_title",
  home_alarm_offline_all_devices: "home_alarm_offline_all_devices",
  home_change_address: "home_change_address",
  home_create_new_home: "home_create_new_home",
  home_create_select_preset_description:
    "home_create_select_preset_description",
  home_create_select_preset_title: "home_create_select_preset_title",
  home_details_give_feedback: "home_details_give_feedback",
  home_details_sensor_is_offline: "home_details_sensor_is_offline",
  home_details_switch_to_original_version:
    "home_details_switch_to_original_version",
  home_emergency_contact_body: "home_emergency_contact_body",
  home_emergency_contact_title: "home_emergency_contact_title",
  home_group_building: "home_group_building",
  home_group_building_plural: "home_group_building_plural",
  home_group_building_create: "home_group_building_create",
  home_group_building_description: "home_group_building_description",
  home_group_building_goto: "home_group_building_goto",
  home_group_buildings_homes_empty_state_action:
    "home_group_buildings_homes_empty_state_action",
  home_group_buildings_homes_empty_state_body:
    "home_group_buildings_homes_empty_state_body",
  home_group_buildings_homes_empty_state_title:
    "home_group_buildings_homes_empty_state_title",
  home_group_location_create: "home_group_location_create",
  home_group_location_description: "home_group_location_description",
  home_group_location_goto: "home_group_location_goto",
  home_indoor_label: "home_indoor_label",
  home_invitation_alert_action_accept: "home_invitation_alert_action_accept",
  home_leave_home: "home_leave_home",
  home_name: "home_name",
  home_remove_home: "home_remove_home",
  home_remove_home_confirm_body: "home_remove_home_confirm_body",
  home_remove_home_interrogation: "home_remove_home_interrogation",
  home_security_alarm_on_body: "home_security_alarm_on_body",
  home_security_alarm_short_description:
    "home_security_alarm_short_description",
  home_security_alarm_title: "home_security_alarm_title",
  home_sensor_more_data: "home_sensor_more_data",
  home_settings: "home_settings",
  home_settings_missing_address_info: "home_settings_missing_address_info",
  home_settings_missing_location_info: "home_settings_missing_location_info",
  home_settings_set_location_title: "home_settings_set_location_title",
  home_settings_silent_alarm_description:
    "home_settings_silent_alarm_description",
  home_short_codes_description: "home_short_codes_description",
  home_token_convert_notice: "home_token_convert_notice",
  home_token_expiration_banner_body: "home_token_expiration_banner_body",
  home_token_expiration_banner_body_plural:
    "home_token_expiration_banner_body_plural",
  home_token_expiration_expandable_body:
    "home_token_expiration_expandable_body",
  home_token_expiration_expandable_body_plural:
    "home_token_expiration_expandable_body_plural",
  home_token_expiration_expandable_title:
    "home_token_expiration_expandable_title",
  home_token_expiration_expandable_title_plural:
    "home_token_expiration_expandable_title_plural",
  home_unmonitored: "home_unmonitored",
  home_unmonitored_all_sensors_offline_description:
    "home_unmonitored_all_sensors_offline_description",
  home_unmonitored_no_alerts_title: "home_unmonitored_no_alerts_title",
  homegroup_building_access_title: "homegroup_building_access_title",
  homegroup_building_wizard_address_step_title:
    "homegroup_building_wizard_address_step_title",
  homegroup_building_wizard_name_step_title:
    "homegroup_building_wizard_name_step_title",
  homegroup_building_wizard_title: "homegroup_building_wizard_title",
  homegroup_location_address: "homegroup_location_address",
  homegroup_location_address_edit: "homegroup_location_address_edit",
  homegroups_create_building_button_label:
    "homegroups_create_building_button_label",
  homes_created: "homes_created",
  homes_created_plural: "homes_created_plural",
  homes_list_empty_state_body: "homes_list_empty_state_body",
  homes_list_empty_state_title: "homes_list_empty_state_title",
  homes_to_create: "homes_to_create",
  homes_to_create_plural: "homes_to_create_plural",
  hour: "hour",
  hours: "hours",
  humidity: "humidity",
  humidity_dropped_normal_body: "humidity_dropped_normal_body",
  humidity_high_body: "humidity_high_body",
  humidity_high_title: "humidity_high_title",
  humidity_low_body: "humidity_low_body",
  humidity_low_title: "humidity_low_title",
  humidity_risen_normal_body: "humidity_risen_normal_body",
  if_you_proceed: "if_you_proceed",
  including_taxes: "including_taxes",
  indoor_climate: "indoor_climate",
  indoor_climate_all_notifications_off_error:
    "indoor_climate_all_notifications_off_error",
  indoor_climate_dashboard_card_description:
    "indoor_climate_dashboard_card_description",
  indoor_climate_freezing_alerts_body: "indoor_climate_freezing_alerts_body",
  indoor_climate_freezing_alerts_title: "indoor_climate_freezing_alerts_title",
  indoor_climate_mold_alerts_body: "indoor_climate_mold_alerts_body",
  indoor_climate_mold_alerts_title: "indoor_climate_mold_alerts_title",
  indoor_climate_report_body: "indoor_climate_report_body",
  indoor_climate_report_title: "indoor_climate_report_title",
  indoor_climate_safety_alerts_body: "indoor_climate_safety_alerts_body",
  indoor_climate_safety_alerts_title: "indoor_climate_safety_alerts_title",
  indoor_climate_settings_body: "indoor_climate_settings_body",
  indoor_climate_settings_title: "indoor_climate_settings_title",
  indoor_noise: "indoor_noise",
  input_optional: "input_optional",
  install_minut: "install_minut",
  instantly_turn_on_alarm_footer: "instantly_turn_on_alarm_footer",
  instantly_turn_on_alarm_off_hint: "instantly_turn_on_alarm_off_hint",
  instantly_turn_on_alarm_title: "instantly_turn_on_alarm_title",
  integration_airbnb_accept_body: "integration_airbnb_accept_body",
  integration_airbnb_update_banner_body:
    "integration_airbnb_update_banner_body",
  integration_airbnb_update_banner_header:
    "integration_airbnb_update_banner_header",
  integration_airbnb_update_button: "integration_airbnb_update_button",
  integration_airbnb_update_dialog_body:
    "integration_airbnb_update_dialog_body",
  integration_airbnb_update_dialog_header:
    "integration_airbnb_update_dialog_header",
  integration_dialog_accept_title: "integration_dialog_accept_title",
  integration_entity_text: "integration_entity_text",
  integration_error_accounts_do_not_match:
    "integration_error_accounts_do_not_match",
  integration_hvac_accept_body: "integration_hvac_accept_body",
  integration_hvac_devices_inactive_error_subtitle:
    "integration_hvac_devices_inactive_error_subtitle",
  integration_hvac_devices_inactive_error_title:
    "integration_hvac_devices_inactive_error_title",
  integration_hvac_devices_inactive_error_title_plural:
    "integration_hvac_devices_inactive_error_title_plural",
  integration_lock_accept_body: "integration_lock_accept_body",
  integration_no_devices: "integration_no_devices",
  integration_no_homes_linked: "integration_no_homes_linked",
  integration_no_homes_linked_action: "integration_no_homes_linked_action",
  integration_number_of_inactive_devices:
    "integration_number_of_inactive_devices",
  integration_number_of_inactive_devices_plural:
    "integration_number_of_inactive_devices_plural",
  integration_pms_accept_body: "integration_pms_accept_body",
  integration_thermostat_device_count: "integration_thermostat_device_count",
  integration_thermostat_device_count_plural:
    "integration_thermostat_device_count_plural",
  integrations_about_integration: "integrations_about_integration",
  integrations_account_name: "integrations_account_name",
  integrations_add_integration_access_code_to_checkin_description:
    "integrations_add_integration_access_code_to_checkin_description",
  integrations_airbnb_disclose_body: "integrations_airbnb_disclose_body",
  integrations_airbnb_disclose_title: "integrations_airbnb_disclose_title",
  integrations_broken_connection: "integrations_broken_connection",
  integrations_broken_link_alert_text: "integrations_broken_link_alert_text",
  integrations_connect_description: "integrations_connect_description",
  integrations_connected: "integrations_connected",
  integrations_connection_broken_alert_text:
    "integrations_connection_broken_alert_text",
  integrations_connection_broken_alert_text_no_action:
    "integrations_connection_broken_alert_text_no_action",
  integrations_connection_broken_banner_cta:
    "integrations_connection_broken_banner_cta",
  integrations_connection_broken_banner_details:
    "integrations_connection_broken_banner_details",
  integrations_connection_broken_banner_generic:
    "integrations_connection_broken_banner_generic",
  integrations_connection_broken_banner_title:
    "integrations_connection_broken_banner_title",
  integrations_connection_error_invalid_credentials:
    "integrations_connection_error_invalid_credentials",
  integrations_connection_error_properties_required_to_connect:
    "integrations_connection_error_properties_required_to_connect",
  integrations_connection_error_secret_already_used:
    "integrations_connection_error_secret_already_used",
  integrations_create_homes_to_get_started_link:
    "integrations_create_homes_to_get_started_link",
  integrations_description: "integrations_description",
  integrations_dialog_agree_and_continue:
    "integrations_dialog_agree_and_continue",
  integrations_did_not_see_integrations_question:
    "integrations_did_not_see_integrations_question",
  integrations_disconnect: "integrations_disconnect",
  integrations_disconnect_account: "integrations_disconnect_account",
  integrations_disconnect_account_and_unlink_description:
    "integrations_disconnect_account_and_unlink_description",
  integrations_disconnect_account_and_unlink_description_plural:
    "integrations_disconnect_account_and_unlink_description_plural",
  integrations_disconnect_confirm_body: "integrations_disconnect_confirm_body",
  integrations_disconnect_confirm_body_airbnb:
    "integrations_disconnect_confirm_body_airbnb",
  integrations_disconnect_confirm_title:
    "integrations_disconnect_confirm_title",
  integrations_external_connection_status_text:
    "integrations_external_connection_status_text",
  integrations_filter_by_name_label: "integrations_filter_by_name_label",
  integrations_group_custom_description:
    "integrations_group_custom_description",
  integrations_group_custom_title: "integrations_group_custom_title",
  integrations_group_gxp_platforms_description:
    "integrations_group_gxp_platforms_description",
  integrations_group_gxp_platforms_title:
    "integrations_group_gxp_platforms_title",
  integrations_group_hvac_description: "integrations_group_hvac_description",
  integrations_group_hvac_title: "integrations_group_hvac_title",
  integrations_group_lock_description: "integrations_group_lock_description",
  integrations_group_lock_title: "integrations_group_lock_title",
  integrations_group_ota_description: "integrations_group_ota_description",
  integrations_group_ota_title: "integrations_group_ota_title",
  integrations_group_pms_description: "integrations_group_pms_description",
  integrations_group_pms_title: "integrations_group_pms_title",
  integrations_home_link_relink_action: "integrations_home_link_relink_action",
  integrations_home_links_broken_alert_text:
    "integrations_home_links_broken_alert_text",
  integrations_homes_linked_text: "integrations_homes_linked_text",
  integrations_homes_not_linked_alert_text:
    "integrations_homes_not_linked_alert_text",
  integrations_integration_home: "integrations_integration_home",
  integrations_link_account: "integrations_link_account",
  integrations_link_create: "integrations_link_create",
  integrations_link_created_successfully:
    "integrations_link_created_successfully",
  integrations_link_entities_title: "integrations_link_entities_title",
  integrations_link_home: "integrations_link_home",
  integrations_link_home_body: "integrations_link_home_body",
  integrations_link_home_picker_empty_value:
    "integrations_link_home_picker_empty_value",
  integrations_link_home_picker_title: "integrations_link_home_picker_title",
  integrations_link_home_with_entity: "integrations_link_home_with_entity",
  integrations_link_homes_to_import_data:
    "integrations_link_homes_to_import_data",
  integrations_link_homes_to_sync_data_alert_text:
    "integrations_link_homes_to_sync_data_alert_text",
  integrations_link_integration_for_home:
    "integrations_link_integration_for_home",
  integrations_link_remove: "integrations_link_remove",
  integrations_link_remove_confirm: "integrations_link_remove_confirm",
  integrations_link_remove_confirm_airbnb:
    "integrations_link_remove_confirm_airbnb",
  integrations_link_remove_confirm_disclaimer:
    "integrations_link_remove_confirm_disclaimer",
  integrations_link_remove_confirm_disclaimer_airbnb_specific:
    "integrations_link_remove_confirm_disclaimer_airbnb_specific",
  integrations_link_remove_confirm_title:
    "integrations_link_remove_confirm_title",
  integrations_linked_accounts_add_instructions:
    "integrations_linked_accounts_add_instructions",
  integrations_linked_accounts_description:
    "integrations_linked_accounts_description",
  integrations_linked_accounts_title: "integrations_linked_accounts_title",
  integrations_linked_homes_empty_state:
    "integrations_linked_homes_empty_state",
  integrations_manage_external_integrations_text:
    "integrations_manage_external_integrations_text",
  integrations_minut_api_connection_status_text:
    "integrations_minut_api_connection_status_text",
  integrations_multi_account_before_you_reconnect_alert:
    "integrations_multi_account_before_you_reconnect_alert",
  integrations_multi_account_connection_broken_alert:
    "integrations_multi_account_connection_broken_alert",
  integrations_multi_account_connection_broken_alert_no_action:
    "integrations_multi_account_connection_broken_alert_no_action",
  integrations_multi_account_reconnect_account_description:
    "integrations_multi_account_reconnect_account_description",
  integrations_multi_account_reconnect_account_title:
    "integrations_multi_account_reconnect_account_title",
  integrations_multiaccount_banner_link:
    "integrations_multiaccount_banner_link",
  integrations_multiaccount_banner_title:
    "integrations_multiaccount_banner_title",
  integrations_not_connected: "integrations_not_connected",
  integrations_ota_accept_body: "integrations_ota_accept_body",
  integrations_other_integrations_title:
    "integrations_other_integrations_title",
  integrations_reconnect_integration_description:
    "integrations_reconnect_integration_description",
  integrations_reconnect_integration_title:
    "integrations_reconnect_integration_title",
  integrations_select_entity_to_connect_to_home:
    "integrations_select_entity_to_connect_to_home",
  integrations_select_entity_to_connect_to_home_airbnb:
    "integrations_select_entity_to_connect_to_home_airbnb",
  integrations_thermostat_edit_thermostat_settings:
    "integrations_thermostat_edit_thermostat_settings",
  integrations_thermostat_mode_cool: "integrations_thermostat_mode_cool",
  integrations_thermostat_mode_description:
    "integrations_thermostat_mode_description",
  integrations_thermostat_mode_details: "integrations_thermostat_mode_details",
  integrations_thermostat_mode_heat: "integrations_thermostat_mode_heat",
  integrations_thermostat_mode_heatcool:
    "integrations_thermostat_mode_heatcool",
  integrations_thermostat_mode_title: "integrations_thermostat_mode_title",
  integrations_thermostat_no_thermostats_linked:
    "integrations_thermostat_no_thermostats_linked",
  integrations_thermostat_precool_heat_description:
    "integrations_thermostat_precool_heat_description",
  integrations_thermostat_settings_dialog_description:
    "integrations_thermostat_settings_dialog_description",
  integrations_thermostat_settings_dialog_title:
    "integrations_thermostat_settings_dialog_title",
  integrations_thermostat_settings_preheat_cool_time:
    "integrations_thermostat_settings_preheat_cool_time",
  integrations_thermostat_some_devices_inactive:
    "integrations_thermostat_some_devices_inactive",
  integrations_thermostat_temperature_description:
    "integrations_thermostat_temperature_description",
  integrations_time_before_stay_begins: "integrations_time_before_stay_begins",
  integrations_title: "integrations_title",
  integrations_turn_thermostat_to_title:
    "integrations_turn_thermostat_to_title",
  integrations_turn_thermostat_to_tooltip:
    "integrations_turn_thermostat_to_tooltip",
  integrations_type_lock: "integrations_type_lock",
  introduction_day_0_immediate_body: "introduction_day_0_immediate_body",
  introduction_day_0_immediate_title: "introduction_day_0_immediate_title",
  introduction_day_1_body: "introduction_day_1_body",
  introduction_day_1_title: "introduction_day_1_title",
  introduction_day_2_device_installed_body:
    "introduction_day_2_device_installed_body",
  introduction_day_2_device_installed_title:
    "introduction_day_2_device_installed_title",
  introduction_day_2_no_device_body: "introduction_day_2_no_device_body",
  introduction_day_2_no_device_title: "introduction_day_2_no_device_title",
  introduction_day_7_body: "introduction_day_7_body",
  introduction_day_7_title: "introduction_day_7_title",
  invalid_invitation_code: "invalid_invitation_code",
  invalid_phone: "invalid_phone",
  invitation: "invitation",
  invitation_plural: "invitation_plural",
  invitation_accepted_to_home_invitee_view_body:
    "invitation_accepted_to_home_invitee_view_body",
  invitation_accepted_to_home_invitee_view_title:
    "invitation_accepted_to_home_invitee_view_title",
  invitation_accepted_to_home_members_view_body:
    "invitation_accepted_to_home_members_view_body",
  invitation_accepted_to_home_members_view_title:
    "invitation_accepted_to_home_members_view_title",
  invitation_code: "invitation_code",
  invite_already_accepted: "invite_already_accepted",
  invoice: "invoice",
  invoice_amount: "invoice_amount",
  invoice_amount_due: "invoice_amount_due",
  invoice_empty_state_body: "invoice_empty_state_body",
  invoice_empty_state_title: "invoice_empty_state_title",
  invoice_is_overdue: "invoice_is_overdue",
  invoice_next_retry: "invoice_next_retry",
  invoice_pay_now: "invoice_pay_now",
  invoice_payment_due: "invoice_payment_due",
  join_organization: "join_organization",
  join_organization_role_finance: "join_organization_role_finance",
  join_organization_role_maintenance: "join_organization_role_maintenance",
  join_organization_role_security: "join_organization_role_security",
  kitchen: "kitchen",
  last_day: "last_day",
  last_month: "last_month",
  last_name: "last_name",
  last_updated: "last_updated",
  last_week: "last_week",
  last_year: "last_year",
  learn_more: "learn_more",
  learn_more_subscription_terms: "learn_more_subscription_terms",
  leave: "leave",
  let_us_know: "let_us_know",
  lets_get_started: "lets_get_started",
  link_homes: "link_homes",
  listing: "listing",
  living_room: "living_room",
  load_more: "load_more",
  loading: "loading",
  locations_empty_state_description: "locations_empty_state_description",
  locations_empty_state_title: "locations_empty_state_title",
  lock_access_code: "lock_access_code",
  lock_access_code_description: "lock_access_code_description",
  log: "log",
  log_feedback_event_cigarette_detection:
    "log_feedback_event_cigarette_detection",
  log_feedback_event_reported_correctly:
    "log_feedback_event_reported_correctly",
  low_battery: "low_battery",
  low_battery_empty_state: "low_battery_empty_state",
  low_battery_in_prefix: "low_battery_in_prefix",
  manage: "manage",
  marijuana_detection: "marijuana_detection",
  marketing_consent: "marketing_consent",
  max_temperature: "max_temperature",
  member: "member",
  member_arrived_home_title: "member_arrived_home_title",
  member_left_home_title: "member_left_home_title",
  members: "members",
  message: "message",
  messaging_description: "messaging_description",
  min_temperature: "min_temperature",
  minut_home: "minut_home",
  minut_response_service: "minut_response_service",
  monitoring: "monitoring",
  month: "month",
  monthly: "monthly",
  motion: "motion",
  motion_graph_unit: "motion_graph_unit",
  motion_graph_unit_plural: "motion_graph_unit_plural",
  mounting_plate: "mounting_plate",
  mounting_plate_plural: "mounting_plate_plural",
  mounting_plate_6_pack: "mounting_plate_6_pack",
  mounting_plate_order_email_on_shipping:
    "mounting_plate_order_email_on_shipping",
  mounting_plate_order_thanks: "mounting_plate_order_thanks",
  name: "name",
  need_more_sensors_question: "need_more_sensors_question",
  new_badge_title: "new_badge_title",
  next: "next",
  no: "no",
  no_guests_found: "no_guests_found",
  no_name_found_generic: "no_name_found_generic",
  no_payment_method_instructions: "no_payment_method_instructions",
  no_sensor: "no_sensor",
  no_sensor_add_sensor_from_mobile_app: "no_sensor_add_sensor_from_mobile_app",
  noise_graph_incident_report_body: "noise_graph_incident_report_body",
  noise_incident_report_body: "noise_incident_report_body",
  noise_incident_report_header: "noise_incident_report_header",
  noise_incident_report_title: "noise_incident_report_title",
  noise_monitoring_presets_title: "noise_monitoring_presets_title",
  noise_monitoring_short_description: "noise_monitoring_short_description",
  noise_monitoring_summary_body: "noise_monitoring_summary_body",
  noise_monitoring_summary_header: "noise_monitoring_summary_header",
  noise_monitoring_threshold_guide_body:
    "noise_monitoring_threshold_guide_body",
  noise_monitoring_threshold_guide_title:
    "noise_monitoring_threshold_guide_title",
  noise_monitoring_unit_description: "noise_monitoring_unit_description",
  noise_report_body: "noise_report_body",
  noise_report_error_invalid_date_range:
    "noise_report_error_invalid_date_range",
  noise_report_pro_body: "noise_report_pro_body",
  noise_report_title: "noise_report_title",
  noise_thresholds: "noise_thresholds",
  noise_thresholds_moved_description: "noise_thresholds_moved_description",
  noise_thresholds_moved_title: "noise_thresholds_moved_title",
  non_existing_user_invited_to_home_body:
    "non_existing_user_invited_to_home_body",
  non_existing_user_invited_to_home_title:
    "non_existing_user_invited_to_home_title",
  none: "none",
  not_enough_permissions: "not_enough_permissions",
  not_enough_permissions_description: "not_enough_permissions_description",
  not_now: "not_now",
  not_set: "not_set",
  notification: "notification",
  notification_plural: "notification_plural",
  notifications: "notifications",
  number_of_minutes: "number_of_minutes",
  number_of_minutes_plural: "number_of_minutes_plural",
  occupancy_scanned_device_limit_exceeded_body:
    "occupancy_scanned_device_limit_exceeded_body",
  occupancy_scanned_device_limit_exceeded_title:
    "occupancy_scanned_device_limit_exceeded_title",
  occupancy_scanned_device_limit_subceeded_body:
    "occupancy_scanned_device_limit_subceeded_body",
  occupancy_scanned_device_limit_subceeded_title:
    "occupancy_scanned_device_limit_subceeded_title",
  off: "off",
  office: "office",
  offline: "offline",
  offline_just_now: "offline_just_now",
  offline_sensors: "offline_sensors",
  offline_since: "offline_since",
  offline_since_days_ago: "offline_since_days_ago",
  offline_since_days_ago_plural: "offline_since_days_ago_plural",
  offline_since_min_ago: "offline_since_min_ago",
  ok: "ok",
  on: "on",
  on_hold: "on_hold",
  onboarding_add_friends_title: "onboarding_add_friends_title",
  onboarding_connect_integrations_action:
    "onboarding_connect_integrations_action",
  onboarding_connect_integrations_description:
    "onboarding_connect_integrations_description",
  onboarding_connect_integrations_title:
    "onboarding_connect_integrations_title",
  onboarding_create_home_description: "onboarding_create_home_description",
  onboarding_create_home_title: "onboarding_create_home_title",
  onboarding_install_device_description_web:
    "onboarding_install_device_description_web",
  onboarding_install_device_title: "onboarding_install_device_title",
  onboarding_no_permission_to_add_devices:
    "onboarding_no_permission_to_add_devices",
  onboarding_no_permission_to_add_homes:
    "onboarding_no_permission_to_add_homes",
  onboarding_no_permission_to_connect_integrations:
    "onboarding_no_permission_to_connect_integrations",
  onboarding_organization_accept_invitation:
    "onboarding_organization_accept_invitation",
  onboarding_organization_name_error_text:
    "onboarding_organization_name_error_text",
  onboarding_portfolios_body: "onboarding_portfolios_body",
  onboarding_portfolios_error_text: "onboarding_portfolios_error_text",
  onboarding_portfolios_title: "onboarding_portfolios_title",
  onboarding_subtitle: "onboarding_subtitle",
  onboarding_welcome: "onboarding_welcome",
  ongoing_crowd_detect_events: "ongoing_crowd_detect_events",
  ongoing_crowd_detect_events_empty_state:
    "ongoing_crowd_detect_events_empty_state",
  ongoing_fire_and_co_events_empty_state:
    "ongoing_fire_and_co_events_empty_state",
  ongoing_indoor_climate_events: "ongoing_indoor_climate_events",
  ongoing_indoor_climate_events_empty_state:
    "ongoing_indoor_climate_events_empty_state",
  ongoing_noise_events: "ongoing_noise_events",
  ongoing_noise_events_empty_state: "ongoing_noise_events_empty_state",
  ongoing_smoking_detection_events: "ongoing_smoking_detection_events",
  ongoing_smoking_detection_events_empty_state:
    "ongoing_smoking_detection_events_empty_state",
  or: "or",
  order_summary: "order_summary",
  organization: "organization",
  organization_access_title: "organization_access_title",
  organization_create: "organization_create",
  organization_create_home_price_summary_text:
    "organization_create_home_price_summary_text",
  organization_create_home_using_tokens:
    "organization_create_home_using_tokens",
  organization_create_home_using_tokens_plural:
    "organization_create_home_using_tokens_plural",
  organization_has_no_homes: "organization_has_no_homes",
  organization_homes_create_unprepaid_home_info:
    "organization_homes_create_unprepaid_home_info",
  organization_homes_creating_unprepaid_home_text:
    "organization_homes_creating_unprepaid_home_text",
  organization_invite_plural: "organization_invite_plural",
  organization_invite_accepted: "organization_invite_accepted",
  organization_join_success_title: "organization_join_success_title",
  organizations_access_all_homes_description:
    "organizations_access_all_homes_description",
  organizations_add_member: "organizations_add_member",
  organizations_add_member_description: "organizations_add_member_description",
  organizations_admin_role_title: "organizations_admin_role_title",
  organizations_all_organization_members_has_access_to_home_hint:
    "organizations_all_organization_members_has_access_to_home_hint",
  organizations_change_organization_name:
    "organizations_change_organization_name",
  organizations_copy_invite_link: "organizations_copy_invite_link",
  organizations_create_action: "organizations_create_action",
  organizations_create_intro_action: "organizations_create_intro_action",
  organizations_create_intro_usp_1_body:
    "organizations_create_intro_usp_1_body",
  organizations_create_intro_usp_1_title:
    "organizations_create_intro_usp_1_title",
  organizations_create_intro_usp_2_body:
    "organizations_create_intro_usp_2_body",
  organizations_create_intro_usp_2_title:
    "organizations_create_intro_usp_2_title",
  organizations_create_intro_usp_3_body:
    "organizations_create_intro_usp_3_body",
  organizations_create_intro_usp_3_title:
    "organizations_create_intro_usp_3_title",
  organizations_create_organization_to_manage_text:
    "organizations_create_organization_to_manage_text",
  organizations_create_short_description:
    "organizations_create_short_description",
  organizations_created_successfully_body:
    "organizations_created_successfully_body",
  organizations_delete_details_body: "organizations_delete_details_body",
  organizations_delete_details_title: "organizations_delete_details_title",
  organizations_delete_invitation_action:
    "organizations_delete_invitation_action",
  organizations_delete_invitation_confirmation_title:
    "organizations_delete_invitation_confirmation_title",
  organizations_delete_organization: "organizations_delete_organization",
  organizations_delete_organization_action:
    "organizations_delete_organization_action",
  organizations_delete_organization_confirmation_message:
    "organizations_delete_organization_confirmation_message",
  organizations_delete_organization_confirmation_title:
    "organizations_delete_organization_confirmation_title",
  organizations_delete_organization_description:
    "organizations_delete_organization_description",
  organizations_delete_organization_details_action:
    "organizations_delete_organization_details_action",
  organizations_delete_team_confirm_title:
    "organizations_delete_team_confirm_title",
  organizations_get_invitations_question:
    "organizations_get_invitations_question",
  organizations_get_invitations_response:
    "organizations_get_invitations_response",
  organizations_go_to_team: "organizations_go_to_team",
  organizations_header: "organizations_header",
  organizations_home_add_member_title: "organizations_home_add_member_title",
  organizations_home_admin_role_short_description:
    "organizations_home_admin_role_short_description",
  organizations_home_group: "organizations_home_group",
  organizations_home_group_plural: "organizations_home_group_plural",
  organizations_home_group_add_stepper_title:
    "organizations_home_group_add_stepper_title",
  organizations_home_group_create_button_label:
    "organizations_home_group_create_button_label",
  organizations_home_group_create_stepper_button_label:
    "organizations_home_group_create_stepper_button_label",
  organizations_home_group_homes_empty_state_action:
    "organizations_home_group_homes_empty_state_action",
  organizations_home_group_homes_empty_state_body:
    "organizations_home_group_homes_empty_state_body",
  organizations_home_group_homes_empty_state_title:
    "organizations_home_group_homes_empty_state_title",
  organizations_home_group_member_add_confirm_title:
    "organizations_home_group_member_add_confirm_title",
  organizations_home_groups_add_home_tooltip_text:
    "organizations_home_groups_add_home_tooltip_text",
  organizations_home_groups_create_title:
    "organizations_home_groups_create_title",
  organizations_home_groups_empty_state_body:
    "organizations_home_groups_empty_state_body",
  organizations_home_groups_empty_state_title:
    "organizations_home_groups_empty_state_title",
  organizations_home_groups_remove_confirm_body:
    "organizations_home_groups_remove_confirm_body",
  organizations_home_invitation_create_title:
    "organizations_home_invitation_create_title",
  organizations_home_invitation_share_description:
    "organizations_home_invitation_share_description",
  organizations_home_member_remove_body:
    "organizations_home_member_remove_body",
  organizations_home_member_remove_confirm_body:
    "organizations_home_member_remove_confirm_body",
  organizations_home_member_role_short_description:
    "organizations_home_member_role_short_description",
  organizations_home_member_select_title:
    "organizations_home_member_select_title",
  organizations_home_members_title: "organizations_home_members_title",
  organizations_home_responder_role_short_description:
    "organizations_home_responder_role_short_description",
  organizations_home_role_title: "organizations_home_role_title",
  organizations_homegroup_access_all_homes_description:
    "organizations_homegroup_access_all_homes_description",
  organizations_homegroup_access_title: "organizations_homegroup_access_title",
  organizations_homegroup_add_existing_member:
    "organizations_homegroup_add_existing_member",
  organizations_homegroup_add_homes_already_added:
    "organizations_homegroup_add_homes_already_added",
  organizations_homegroup_add_homes_title:
    "organizations_homegroup_add_homes_title",
  organizations_homegroup_add_member_via_email:
    "organizations_homegroup_add_member_via_email",
  organizations_homegroup_admin_role_short_description:
    "organizations_homegroup_admin_role_short_description",
  organizations_homegroup_admin_role_title:
    "organizations_homegroup_admin_role_title",
  organizations_homegroup_leave: "organizations_homegroup_leave",
  organizations_homegroup_member_role_short_description:
    "organizations_homegroup_member_role_short_description",
  organizations_homegroup_member_role_title:
    "organizations_homegroup_member_role_title",
  organizations_homegroup_remove: "organizations_homegroup_remove",
  organizations_homegroup_responder_role_short_description:
    "organizations_homegroup_responder_role_short_description",
  organizations_homegroup_responder_role_title:
    "organizations_homegroup_responder_role_title",
  organizations_homegroup_role_title: "organizations_homegroup_role_title",
  organizations_homegroups_add_home_to_homegroup:
    "organizations_homegroups_add_home_to_homegroup",
  organizations_homegroups_add_member_description:
    "organizations_homegroups_add_member_description",
  organizations_homegroups_delete_member_confirm_title:
    "organizations_homegroups_delete_member_confirm_title",
  organizations_homegroups_empty_state_body:
    "organizations_homegroups_empty_state_body",
  organizations_homegroups_empty_state_title:
    "organizations_homegroups_empty_state_title",
  organizations_homegroups_member_delete_confirm_body:
    "organizations_homegroups_member_delete_confirm_body",
  organizations_homegroups_member_select_title:
    "organizations_homegroups_member_select_title",
  organizations_homegroups_self_remove_confirm_body:
    "organizations_homegroups_self_remove_confirm_body",
  organizations_invitation_active: "organizations_invitation_active",
  organizations_invitation_already_accepted_error:
    "organizations_invitation_already_accepted_error",
  organizations_invitation_email_sent_notification:
    "organizations_invitation_email_sent_notification",
  organizations_invitation_link_copied_info:
    "organizations_invitation_link_copied_info",
  organizations_invitation_link_title: "organizations_invitation_link_title",
  organizations_invitation_not_found_error:
    "organizations_invitation_not_found_error",
  organizations_invitation_share_title: "organizations_invitation_share_title",
  organizations_invitation_view_action: "organizations_invitation_view_action",
  organizations_leave_team_body: "organizations_leave_team_body",
  organizations_leave_team_confirm_button_label:
    "organizations_leave_team_confirm_button_label",
  organizations_leave_team_confirm_title:
    "organizations_leave_team_confirm_title",
  organizations_member_role_title: "organizations_member_role_title",
  organizations_name_title: "organizations_name_title",
  organizations_name_your_organization: "organizations_name_your_organization",
  organizations_needs_to_be_home_member_to_share_location:
    "organizations_needs_to_be_home_member_to_share_location",
  organizations_no_current_invitations: "organizations_no_current_invitations",
  organizations_only_editable_by_owner: "organizations_only_editable_by_owner",
  organizations_only_organization_admins_can_add_members:
    "organizations_only_organization_admins_can_add_members",
  organizations_organization_admin_role_short_description:
    "organizations_organization_admin_role_short_description",
  organizations_organization_created: "organizations_organization_created",
  organizations_organization_exists_question:
    "organizations_organization_exists_question",
  organizations_organization_invitation_create_title:
    "organizations_organization_invitation_create_title",
  organizations_organization_invitation_share_description:
    "organizations_organization_invitation_share_description",
  organizations_organization_invite_required_link_text:
    "organizations_organization_invite_required_link_text",
  organizations_organization_member_role_short_description:
    "organizations_organization_member_role_short_description",
  organizations_organization_name: "organizations_organization_name",
  organizations_organization_name_onboarding_details:
    "organizations_organization_name_onboarding_details",
  organizations_organization_name_onboarding_placeholder:
    "organizations_organization_name_onboarding_placeholder",
  organizations_organization_name_onboarding_title:
    "organizations_organization_name_onboarding_title",
  organizations_organization_responder_role_short_description:
    "organizations_organization_responder_role_short_description",
  organizations_organization_role_title:
    "organizations_organization_role_title",
  organizations_organization_settings: "organizations_organization_settings",
  organizations_permissions_read_more_action:
    "organizations_permissions_read_more_action",
  organizations_read_about_permissions: "organizations_read_about_permissions",
  organizations_remove_home_member_confirmation_title:
    "organizations_remove_home_member_confirmation_title",
  organizations_remove_member_action: "organizations_remove_member_action",
  organizations_remove_organization_member_confirmation_title:
    "organizations_remove_organization_member_confirmation_title",
  organizations_responder_role_title: "organizations_responder_role_title",
  organizations_role_home_admin: "organizations_role_home_admin",
  organizations_role_home_member: "organizations_role_home_member",
  organizations_role_home_responder: "organizations_role_home_responder",
  organizations_role_minimum: "organizations_role_minimum",
  organizations_role_minimum_short_description:
    "organizations_role_minimum_short_description",
  organizations_role_team_admin: "organizations_role_team_admin",
  organizations_role_team_member: "organizations_role_team_member",
  organizations_role_team_responder: "organizations_role_team_responder",
  organizations_select_the_invitation_to_accept_hint:
    "organizations_select_the_invitation_to_accept_hint",
  organizations_send_invite: "organizations_send_invite",
  organizations_switch_organization_body:
    "organizations_switch_organization_body",
  organizations_switch_organization_title:
    "organizations_switch_organization_title",
  organizations_team_delete_homegroup_error_text:
    "organizations_team_delete_homegroup_error_text",
  organizations_team_join: "organizations_team_join",
  organizations_team_join_success_body: "organizations_team_join_success_body",
  organizations_team_join_success_button_label:
    "organizations_team_join_success_button_label",
  organizations_team_join_success_title:
    "organizations_team_join_success_title",
  organizations_team_member_remove_confirmation_body:
    "organizations_team_member_remove_confirmation_body",
  organizations_team_settings_profile_title:
    "organizations_team_settings_profile_title",
  organizations_view_all_organizations: "organizations_view_all_organizations",
  outdoor_noise: "outdoor_noise",
  overview: "overview",
  owner: "owner",
  partial_outage_devices_offline_description:
    "partial_outage_devices_offline_description",
  partial_outage_devices_offline_description_plural:
    "partial_outage_devices_offline_description_plural",
  partial_outage_low_battery_devices_description:
    "partial_outage_low_battery_devices_description",
  partial_outage_low_battery_devices_description_plural:
    "partial_outage_low_battery_devices_description_plural",
  partial_outage_title_low_battery_devices:
    "partial_outage_title_low_battery_devices",
  partial_outage_title_low_battery_devices_plural:
    "partial_outage_title_low_battery_devices_plural",
  partial_outage_title_offline_devices: "partial_outage_title_offline_devices",
  partial_outage_title_offline_devices_plural:
    "partial_outage_title_offline_devices_plural",
  password: "password",
  password_too_long: "password_too_long",
  password_too_short: "password_too_short",
  password_too_weak: "password_too_weak",
  paste_the_invite_code: "paste_the_invite_code",
  payment_error_banner_body: "payment_error_banner_body",
  payment_error_banner_button: "payment_error_banner_button",
  payment_error_banner_title: "payment_error_banner_title",
  payment_error_body: "payment_error_body",
  payment_failed: "payment_failed",
  payment_failed_help_funds: "payment_failed_help_funds",
  payment_method: "payment_method",
  payment_method_add: "payment_method_add",
  payment_method_is_expired: "payment_method_is_expired",
  payment_method_is_expiring: "payment_method_is_expiring",
  payment_method_is_invalid: "payment_method_is_invalid",
  payment_method_remove: "payment_method_remove",
  payment_method_remove_confirm: "payment_method_remove_confirm",
  payments: "payments",
  peak_sound_high_body: "peak_sound_high_body",
  peak_sound_high_title: "peak_sound_high_title",
  pending_invitations: "pending_invitations",
  pending_invitations_plural: "pending_invitations_plural",
  permanent_change: "permanent_change",
  permissions_admin: "permissions_admin",
  permissions_home_owner: "permissions_home_owner",
  permissions_member: "permissions_member",
  permissions_responder_description: "permissions_responder_description",
  permissions_role: "permissions_role",
  permissions_team_members_description: "permissions_team_members_description",
  phone: "phone",
  place_order: "place_order",
  placeholder_web: "placeholder_web",
  plan: "plan",
  plan_renewal_breakdown_addon_note: "plan_renewal_breakdown_addon_note",
  plan_renewal_estimate_breakdown_amount:
    "plan_renewal_estimate_breakdown_amount",
  plan_renewal_estimate_breakdown_annual_price:
    "plan_renewal_estimate_breakdown_annual_price",
  plan_renewal_estimate_breakdown_back_breadcrumb:
    "plan_renewal_estimate_breakdown_back_breadcrumb",
  plan_renewal_estimate_breakdown_breadcrumb:
    "plan_renewal_estimate_breakdown_breadcrumb",
  plan_renewal_estimate_breakdown_days: "plan_renewal_estimate_breakdown_days",
  plan_renewal_estimate_breakdown_description:
    "plan_renewal_estimate_breakdown_description",
  plan_renewal_estimate_breakdown_link: "plan_renewal_estimate_breakdown_link",
  plan_renewal_estimate_breakdown_location:
    "plan_renewal_estimate_breakdown_location",
  plan_renewal_estimate_breakdown_no_location:
    "plan_renewal_estimate_breakdown_no_location",
  plan_renewal_estimate_breakdown_note: "plan_renewal_estimate_breakdown_note",
  plan_renewal_estimate_breakdown_period:
    "plan_renewal_estimate_breakdown_period",
  plan_renewal_estimate_breakdown_title:
    "plan_renewal_estimate_breakdown_title",
  plan_renewal_estimate_breakdown_total:
    "plan_renewal_estimate_breakdown_total",
  plan_renewal_estimate_description: "plan_renewal_estimate_description",
  plan_renewal_estimate_description_tooltip:
    "plan_renewal_estimate_description_tooltip",
  postcode: "postcode",
  power_saving_mode_on_in: "power_saving_mode_on_in",
  prepaid: "prepaid",
  prepaid_homes_text: "prepaid_homes_text",
  preset_applied_preset: "preset_applied_preset",
  preset_indoor_climate_description: "preset_indoor_climate_description",
  preset_monitoring_presets: "preset_monitoring_presets",
  preset_monitoring_presets_description_rental_unit:
    "preset_monitoring_presets_description_rental_unit",
  preset_open_preset: "preset_open_preset",
  preset_settings_for: "preset_settings_for",
  presets_add_rental_unit_to_preset: "presets_add_rental_unit_to_preset",
  presets_already_in_preset: "presets_already_in_preset",
  presets_applies_to: "presets_applies_to",
  presets_apply_to_rental_units: "presets_apply_to_rental_units",
  presets_apply_to_rental_units_plural: "presets_apply_to_rental_units_plural",
  presets_bulk_add_all_units: "presets_bulk_add_all_units",
  presets_bulk_add_all_units_matching_search:
    "presets_bulk_add_all_units_matching_search",
  presets_bulk_add_title: "presets_bulk_add_title",
  presets_bulk_confirm_description: "presets_bulk_confirm_description",
  presets_bulk_confirm_title: "presets_bulk_confirm_title",
  presets_create_new_preset: "presets_create_new_preset",
  presets_create_new_preset_action: "presets_create_new_preset_action",
  presets_create_new_preset_based_on: "presets_create_new_preset_based_on",
  presets_create_new_preset_description:
    "presets_create_new_preset_description",
  presets_create_new_preset_name: "presets_create_new_preset_name",
  presets_minutes_shorthand: "presets_minutes_shorthand",
  presets_no_homes_empty_state_button_add:
    "presets_no_homes_empty_state_button_add",
  presets_no_homes_empty_state_description:
    "presets_no_homes_empty_state_description",
  presets_no_homes_empty_state_title: "presets_no_homes_empty_state_title",
  presets_no_rental_units_using: "presets_no_rental_units_using",
  presets_noise_description: "presets_noise_description",
  presets_noise_duration_description: "presets_noise_duration_description",
  presets_noise_duration_title: "presets_noise_duration_title",
  presets_noise_monitoring_title: "presets_noise_monitoring_title",
  presets_search_for_unit_name: "presets_search_for_unit_name",
  presets_seconds_shorthand: "presets_seconds_shorthand",
  presets_security_alarm_settings_body: "presets_security_alarm_settings_body",
  presets_security_alarm_settings_title:
    "presets_security_alarm_settings_title",
  presets_thresholds_and_duration_description:
    "presets_thresholds_and_duration_description",
  presets_thresholds_and_duration_title:
    "presets_thresholds_and_duration_title",
  privacy_policy: "privacy_policy",
  pro: "pro",
  pro_plus: "pro_plus",
  profile_short_description: "profile_short_description",
  profile_title: "profile_title",
  profiles_add_short_description: "profiles_add_short_description",
  profiles_add_title: "profiles_add_title",
  profiles_cant_remove_homes_exists_body:
    "profiles_cant_remove_homes_exists_body",
  profiles_cant_remove_homes_exists_title:
    "profiles_cant_remove_homes_exists_title",
  profiles_cant_turn_on_autocall_body: "profiles_cant_turn_on_autocall_body",
  profiles_cant_turn_on_autocall_title: "profiles_cant_turn_on_autocall_title",
  profiles_cant_turn_on_call_assist_body:
    "profiles_cant_turn_on_call_assist_body",
  profiles_cant_turn_on_call_assist_title:
    "profiles_cant_turn_on_call_assist_title",
  profiles_create_based_on_title: "profiles_create_based_on_title",
  profiles_create_description: "profiles_create_description",
  profiles_create_header: "profiles_create_header",
  profiles_edit_homes_affected: "profiles_edit_homes_affected",
  profiles_edit_homes_affected_plural: "profiles_edit_homes_affected_plural",
  profiles_human_caller_body: "profiles_human_caller_body",
  profiles_human_caller_title: "profiles_human_caller_title",
  profiles_noise_monitoring_sms_guest_message_description:
    "profiles_noise_monitoring_sms_guest_message_description",
  profiles_noise_monitoring_timeline_first_warning_footer:
    "profiles_noise_monitoring_timeline_first_warning_footer",
  profiles_noise_monitoring_timeline_first_warning_header:
    "profiles_noise_monitoring_timeline_first_warning_header",
  profiles_noise_monitoring_timeline_second_warning_header:
    "profiles_noise_monitoring_timeline_second_warning_header",
  profiles_noise_monitoring_timeline_sencond_third_fourth_warning_header:
    "profiles_noise_monitoring_timeline_sencond_third_fourth_warning_header",
  profiles_noise_monitoring_timeline_sencond_third_warning_footer:
    "profiles_noise_monitoring_timeline_sencond_third_warning_footer",
  profiles_noise_monitoring_timeline_third_warning_header:
    "profiles_noise_monitoring_timeline_third_warning_header",
  profiles_notify_responders: "profiles_notify_responders",
  profiles_notify_team: "profiles_notify_team",
  profiles_single_profile_footer: "profiles_single_profile_footer",
  properties: "properties",
  prorated_price_per_home: "prorated_price_per_home",
  quiet_hours: "quiet_hours",
  quiet_hours_description: "quiet_hours_description",
  reactivate_plan: "reactivate_plan",
  reactivate_plan_banner_description: "reactivate_plan_banner_description",
  reactivate_plan_banner_title: "reactivate_plan_banner_title",
  reactivate_plan_checkbox_error_message:
    "reactivate_plan_checkbox_error_message",
  reactivate_plan_checkbox_label: "reactivate_plan_checkbox_label",
  reactivate_plan_checkbox_label_plural:
    "reactivate_plan_checkbox_label_plural",
  reactivate_plan_message: "reactivate_plan_message",
  reactivate_plan_title: "reactivate_plan_title",
  read_more: "read_more",
  receive_credits_for_unused_time: "receive_credits_for_unused_time",
  referral_pending_subscription_activation:
    "referral_pending_subscription_activation",
  referral_program_about_faq_link_label:
    "referral_program_about_faq_link_label",
  referral_program_about_get_description:
    "referral_program_about_get_description",
  referral_program_about_get_note: "referral_program_about_get_note",
  referral_program_about_get_title: "referral_program_about_get_title",
  referral_program_about_gift_description:
    "referral_program_about_gift_description",
  referral_program_about_gift_title: "referral_program_about_gift_title",
  referral_program_about_share_description:
    "referral_program_about_share_description",
  referral_program_about_share_title: "referral_program_about_share_title",
  referral_program_about_title: "referral_program_about_title",
  referral_program_description: "referral_program_description",
  referral_program_history_title: "referral_program_history_title",
  referral_program_how_does_it_work: "referral_program_how_does_it_work",
  referral_program_referrals: "referral_program_referrals",
  referral_program_referrals_completed: "referral_program_referrals_completed",
  referral_program_referrals_history_title:
    "referral_program_referrals_history_title",
  referral_program_referrals_history_tooltip:
    "referral_program_referrals_history_tooltip",
  referral_program_referrals_pending: "referral_program_referrals_pending",
  referral_program_rewards: "referral_program_rewards",
  referral_program_rewards_history_title:
    "referral_program_rewards_history_title",
  referral_program_rewards_history_tooltip:
    "referral_program_rewards_history_tooltip",
  referral_program_share_email: "referral_program_share_email",
  referral_program_share_email_body: "referral_program_share_email_body",
  referral_program_share_email_subject: "referral_program_share_email_subject",
  referral_program_share_facebook: "referral_program_share_facebook",
  referral_program_share_link: "referral_program_share_link",
  referral_program_share_linkedin: "referral_program_share_linkedin",
  referral_program_share_messenger: "referral_program_share_messenger",
  referral_program_share_whatsapp: "referral_program_share_whatsapp",
  referral_program_share_x: "referral_program_share_x",
  referral_program_share_x_text: "referral_program_share_x_text",
  referral_program_subtitle: "referral_program_subtitle",
  referral_program_terms_conditions: "referral_program_terms_conditions",
  referral_program_terms_conditions_default_compliance:
    "referral_program_terms_conditions_default_compliance",
  referral_program_title: "referral_program_title",
  referrals_empty_state_description: "referrals_empty_state_description",
  referrals_empty_state_title: "referrals_empty_state_title",
  remove: "remove",
  remove_home_member_confirm: "remove_home_member_confirm",
  remove_organization_member_confirm: "remove_organization_member_confirm",
  renew: "renew",
  rental_unit_reactivate_dialog_description:
    "rental_unit_reactivate_dialog_description",
  rental_unit_reactivate_dialog_title: "rental_unit_reactivate_dialog_title",
  rental_units_empty_state_description: "rental_units_empty_state_description",
  rental_units_empty_state_title: "rental_units_empty_state_title",
  report_plural: "report_plural",
  report_findings: "report_findings",
  report_it_here: "report_it_here",
  reports_error_home_not_in_correct_state:
    "reports_error_home_not_in_correct_state",
  reports_feedback_body: "reports_feedback_body",
  reports_feedback_title: "reports_feedback_title",
  reports_subtitle: "reports_subtitle",
  reports_title: "reports_title",
  responders_title: "responders_title",
  response_partners_outside_coverage_area:
    "response_partners_outside_coverage_area",
  response_service: "response_service",
  response_service_add_button: "response_service_add_button",
  response_service_body: "response_service_body",
  response_service_by_provider: "response_service_by_provider",
  response_service_description: "response_service_description",
  response_service_disable_callouts: "response_service_disable_callouts",
  response_service_dispatch_arrived_body:
    "response_service_dispatch_arrived_body",
  response_service_dispatch_arrived_push_notification:
    "response_service_dispatch_arrived_push_notification",
  response_service_dispatch_arrived_title:
    "response_service_dispatch_arrived_title",
  response_service_dispatch_cancelled_by_minut_while_in_progress_body:
    "response_service_dispatch_cancelled_by_minut_while_in_progress_body",
  response_service_dispatch_cancelled_by_minut_while_in_progress_title:
    "response_service_dispatch_cancelled_by_minut_while_in_progress_title",
  response_service_dispatch_cancelled_by_minut_while_requested_body:
    "response_service_dispatch_cancelled_by_minut_while_requested_body",
  response_service_dispatch_cancelled_by_minut_while_requested_title:
    "response_service_dispatch_cancelled_by_minut_while_requested_title",
  response_service_dispatch_cancelled_by_provider_body:
    "response_service_dispatch_cancelled_by_provider_body",
  response_service_dispatch_cancelled_by_provider_title:
    "response_service_dispatch_cancelled_by_provider_title",
  response_service_dispatch_cancelled_by_user_body:
    "response_service_dispatch_cancelled_by_user_body",
  response_service_dispatch_cancelled_by_user_title:
    "response_service_dispatch_cancelled_by_user_title",
  response_service_dispatch_completed_body:
    "response_service_dispatch_completed_body",
  response_service_dispatch_completed_title:
    "response_service_dispatch_completed_title",
  response_service_dispatch_in_progress_body:
    "response_service_dispatch_in_progress_body",
  response_service_dispatch_in_progress_title:
    "response_service_dispatch_in_progress_title",
  response_service_dispatch_requested_automatically_body:
    "response_service_dispatch_requested_automatically_body",
  response_service_dispatch_requested_automatically_title:
    "response_service_dispatch_requested_automatically_title",
  response_service_dispatch_requested_manually_body:
    "response_service_dispatch_requested_manually_body",
  response_service_dispatch_requested_manually_title:
    "response_service_dispatch_requested_manually_title",
  response_service_empty_state_available_box_link:
    "response_service_empty_state_available_box_link",
  response_service_empty_state_available_box_title:
    "response_service_empty_state_available_box_title",
  response_service_empty_state_body: "response_service_empty_state_body",
  response_service_empty_state_title: "response_service_empty_state_title",
  response_service_empty_state_unavailable_box_link:
    "response_service_empty_state_unavailable_box_link",
  response_service_empty_state_unavailable_box_title:
    "response_service_empty_state_unavailable_box_title",
  response_service_failed_unsupported_currency:
    "response_service_failed_unsupported_currency",
  response_service_home_note_description:
    "response_service_home_note_description",
  response_service_home_note_description_placeholder:
    "response_service_home_note_description_placeholder",
  response_service_home_note_title: "response_service_home_note_title",
  response_service_home_select_error: "response_service_home_select_error",
  response_service_missing_phone_number_text:
    "response_service_missing_phone_number_text",
  response_service_missing_phone_number_title:
    "response_service_missing_phone_number_title",
  response_service_no_home_info: "response_service_no_home_info",
  response_service_not_available_banner:
    "response_service_not_available_banner",
  response_service_plan_end: "response_service_plan_end",
  response_service_register_interest: "response_service_register_interest",
  response_service_responder_body_1: "response_service_responder_body_1",
  response_service_responder_body_2: "response_service_responder_body_2",
  response_service_responder_body_3: "response_service_responder_body_3",
  response_service_responder_list_1: "response_service_responder_list_1",
  response_service_responder_list_2: "response_service_responder_list_2",
  response_service_responder_list_3: "response_service_responder_list_3",
  response_service_responder_title: "response_service_responder_title",
  response_service_status_no_sensors: "response_service_status_no_sensors",
  response_service_status_noise_monitoring_off:
    "response_service_status_noise_monitoring_off",
  response_service_subscription_cancel: "response_service_subscription_cancel",
  response_service_subscription_renew: "response_service_subscription_renew",
  response_service_try_for_free_asterisk:
    "response_service_try_for_free_asterisk",
  response_service_wizard_add_phone_number_description:
    "response_service_wizard_add_phone_number_description",
  response_service_wizard_add_phone_number_title:
    "response_service_wizard_add_phone_number_title",
  response_service_wizard_complete_app_version_notice:
    "response_service_wizard_complete_app_version_notice",
  response_service_wizard_complete_description:
    "response_service_wizard_complete_description",
  response_service_wizard_complete_info_description:
    "response_service_wizard_complete_info_description",
  response_service_wizard_complete_info_title:
    "response_service_wizard_complete_info_title",
  response_service_wizard_complete_noise_description:
    "response_service_wizard_complete_noise_description",
  response_service_wizard_complete_noise_title:
    "response_service_wizard_complete_noise_title",
  response_service_wizard_complete_responder_description:
    "response_service_wizard_complete_responder_description",
  response_service_wizard_complete_responder_title:
    "response_service_wizard_complete_responder_title",
  response_service_wizard_complete_title:
    "response_service_wizard_complete_title",
  response_service_wizard_enable_asterisk:
    "response_service_wizard_enable_asterisk",
  response_service_wizard_enable_callout:
    "response_service_wizard_enable_callout",
  response_service_wizard_enable_callout_text:
    "response_service_wizard_enable_callout_text",
  response_service_wizard_enable_extra_home:
    "response_service_wizard_enable_extra_home",
  response_service_wizard_enable_free_trial:
    "response_service_wizard_enable_free_trial",
  response_service_wizard_enable_free_trial_description:
    "response_service_wizard_enable_free_trial_description",
  response_service_wizard_enable_responder_title:
    "response_service_wizard_enable_responder_title",
  response_service_wizard_enable_title: "response_service_wizard_enable_title",
  response_service_wizard_enable_trial_asterisk:
    "response_service_wizard_enable_trial_asterisk",
  response_service_wizard_enable_when_trial_ends:
    "response_service_wizard_enable_when_trial_ends",
  response_service_wizard_enable_wrong_location:
    "response_service_wizard_enable_wrong_location",
  response_service_wizard_home_select_dropdown:
    "response_service_wizard_home_select_dropdown",
  response_service_wizard_home_select_title:
    "response_service_wizard_home_select_title",
  review_invites: "review_invites",
  review_payment_method: "review_payment_method",
  risk_of_mold: "risk_of_mold",
  risk_of_mold_analyzing: "risk_of_mold_analyzing",
  risk_of_mold_graph_body_analyzing: "risk_of_mold_graph_body_analyzing",
  risk_of_mold_graph_body_high: "risk_of_mold_graph_body_high",
  risk_of_mold_graph_body_medium: "risk_of_mold_graph_body_medium",
  risk_of_mold_high: "risk_of_mold_high",
  risk_of_mold_low: "risk_of_mold_low",
  risk_of_mold_medium: "risk_of_mold_medium",
  risk_of_mold_unknown: "risk_of_mold_unknown",
  risk_of_mould_action_0: "risk_of_mould_action_0",
  risk_of_mould_body: "risk_of_mould_body",
  risk_of_mould_details: "risk_of_mould_details",
  risk_of_mould_title: "risk_of_mould_title",
  room_too_warm_action_0: "room_too_warm_action_0",
  room_too_warm_body: "room_too_warm_body",
  room_too_warm_title: "room_too_warm_title",
  save: "save",
  search: "search",
  search_did_not_match_anything: "search_did_not_match_anything",
  search_for_home: "search_for_home",
  search_for_integration: "search_for_integration",
  search_no_matches_found: "search_no_matches_found",
  search_nothing_found: "search_nothing_found",
  second_heartbeat_sound_summary_body: "second_heartbeat_sound_summary_body",
  second_heartbeat_sound_summary_title: "second_heartbeat_sound_summary_title",
  seconds: "seconds",
  security_alarm_grace_period_title: "security_alarm_grace_period_title",
  security_alarm_timeline_first_warning_header:
    "security_alarm_timeline_first_warning_header",
  security_alarm_timeline_second_warning_footer:
    "security_alarm_timeline_second_warning_footer",
  security_alarm_timeline_second_warning_header:
    "security_alarm_timeline_second_warning_header",
  security_alarm_timeline_trigger_footer:
    "security_alarm_timeline_trigger_footer",
  security_alarm_timeline_trigger_header:
    "security_alarm_timeline_trigger_header",
  see_all_homes_text: "see_all_homes_text",
  see_breakdown: "see_breakdown",
  send_feedback: "send_feedback",
  sensor: "sensor",
  sensor_plural: "sensor_plural",
  sensor_charging: "sensor_charging",
  sensor_data_charging_placeholder: "sensor_data_charging_placeholder",
  sensor_fully_charged: "sensor_fully_charged",
  sensor_status_offline: "sensor_status_offline",
  sensor_status_online: "sensor_status_online",
  sensor_swap: "sensor_swap",
  sensor_swap_description: "sensor_swap_description",
  sensor_swap_failed_body: "sensor_swap_failed_body",
  sensor_swap_failed_title: "sensor_swap_failed_title",
  sensor_swap_succeeded_body: "sensor_swap_succeeded_body",
  sensor_swap_succeeded_title: "sensor_swap_succeeded_title",
  sensors_list_empty_state_body: "sensors_list_empty_state_body",
  sensors_list_empty_state_title: "sensors_list_empty_state_title",
  sensors_offline: "sensors_offline",
  sensors_offline_empty_state: "sensors_offline_empty_state",
  sensors_online: "sensors_online",
  sent: "sent",
  set_location: "set_location",
  set_up: "set_up",
  settings: "settings",
  settings_applying_changes: "settings_applying_changes",
  settings_auto_detect: "settings_auto_detect",
  settings_billing: "settings_billing",
  settings_error_updating_device: "settings_error_updating_device",
  settings_general: "settings_general",
  settings_language: "settings_language",
  settings_language_region: "settings_language_region",
  settings_noise_alert_first_trigger: "settings_noise_alert_first_trigger",
  settings_noise_alerts_autocall_description:
    "settings_noise_alerts_autocall_description",
  settings_noise_alerts_autocall_message:
    "settings_noise_alerts_autocall_message",
  settings_noise_alerts_autocall_title: "settings_noise_alerts_autocall_title",
  settings_noise_alerts_call_assist_learn_more:
    "settings_noise_alerts_call_assist_learn_more",
  settings_noise_alerts_call_assist_solve:
    "settings_noise_alerts_call_assist_solve",
  settings_noise_alerts_call_assist_trained_expert:
    "settings_noise_alerts_call_assist_trained_expert",
  settings_noise_alerts_description: "settings_noise_alerts_description",
  settings_noise_alerts_edit_duration: "settings_noise_alerts_edit_duration",
  settings_noise_alerts_first_alert_description:
    "settings_noise_alerts_first_alert_description",
  settings_noise_alerts_first_alert_title:
    "settings_noise_alerts_first_alert_title",
  settings_noise_alerts_flash_and_sound_description:
    "settings_noise_alerts_flash_and_sound_description",
  settings_noise_alerts_flash_and_sound_title:
    "settings_noise_alerts_flash_and_sound_title",
  settings_noise_alerts_second_alert_description:
    "settings_noise_alerts_second_alert_description",
  settings_noise_alerts_second_alert_title:
    "settings_noise_alerts_second_alert_title",
  settings_noise_alerts_sms_description:
    "settings_noise_alerts_sms_description",
  settings_noise_alerts_sms_message: "settings_noise_alerts_sms_message",
  settings_noise_alerts_sms_title: "settings_noise_alerts_sms_title",
  settings_noise_alerts_third_alert_description:
    "settings_noise_alerts_third_alert_description",
  settings_noise_alerts_third_alert_title:
    "settings_noise_alerts_third_alert_title",
  settings_noise_alerts_title: "settings_noise_alerts_title",
  settings_time_format: "settings_time_format",
  settings_time_format_12_hour: "settings_time_format_12_hour",
  settings_time_format_24_hour: "settings_time_format_24_hour",
  share_feedback: "share_feedback",
  shipping: "shipping",
  shipping_address: "shipping_address",
  shipping_method: "shipping_method",
  shipping_policy: "shipping_policy",
  short_button_press_title: "short_button_press_title",
  shortcodes: "shortcodes",
  shortcodes_tip_body: "shortcodes_tip_body",
  shortcodes_tip_title: "shortcodes_tip_title",
  "Sign up": "Sign up",
  sign_in: "sign_in",
  sign_in_failed: "sign_in_failed",
  sign_in_meta_description: "sign_in_meta_description",
  sign_in_meta_title: "sign_in_meta_title",
  sign_in_no_account: "sign_in_no_account",
  sign_in_sign_up_link: "sign_in_sign_up_link",
  sign_out: "sign_out",
  sign_up_meta_description: "sign_up_meta_description",
  sign_up_meta_title: "sign_up_meta_title",
  sign_up_redirect_to_sign_in: "sign_up_redirect_to_sign_in",
  signal: "signal",
  signal_excellent: "signal_excellent",
  signal_fair: "signal_fair",
  signal_good: "signal_good",
  signal_none: "signal_none",
  signal_okay: "signal_okay",
  signal_weak: "signal_weak",
  silent_alarm_title: "silent_alarm_title",
  skip_for_now: "skip_for_now",
  smoke_detector_test_reminder_title: "smoke_detector_test_reminder_title",
  smoking_detection_alert_title: "smoking_detection_alert_title",
  smoking_detection_calibrating_body: "smoking_detection_calibrating_body",
  smoking_detection_calibrating_title: "smoking_detection_calibrating_title",
  smoking_detection_calibration_done_body:
    "smoking_detection_calibration_done_body",
  smoking_detection_calibration_done_title:
    "smoking_detection_calibration_done_title",
  smoking_detection_cigarette_smoke_detected:
    "smoking_detection_cigarette_smoke_detected",
  smoking_detection_cigarette_smoke_info:
    "smoking_detection_cigarette_smoke_info",
  smoking_detection_disclaimer: "smoking_detection_disclaimer",
  smoking_detection_feedback_comment_hint:
    "smoking_detection_feedback_comment_hint",
  smoking_detection_feedback_detection_correct:
    "smoking_detection_feedback_detection_correct",
  smoking_detection_feedback_detection_missed_alert:
    "smoking_detection_feedback_detection_missed_alert",
  smoking_detection_feedback_title: "smoking_detection_feedback_title",
  smoking_detection_short_description: "smoking_detection_short_description",
  smoking_detection_smoking_detected_body:
    "smoking_detection_smoking_detected_body",
  smoking_detection_smoking_detected_details:
    "smoking_detection_smoking_detected_details",
  smoking_detection_smoking_detected_title:
    "smoking_detection_smoking_detected_title",
  smoking_detection_title: "smoking_detection_title",
  sms_restriction_email_backup_text: "sms_restriction_email_backup_text",
  sound: "sound",
  sound_level_dropped_normal_body: "sound_level_dropped_normal_body",
  sound_noise_monitoring: "sound_noise_monitoring",
  standard: "standard",
  start_time: "start_time",
  state: "state",
  status: "status",
  stay_importer_browse: "stay_importer_browse",
  stay_importer_date_format_dialog_subtitle:
    "stay_importer_date_format_dialog_subtitle",
  stay_importer_date_format_dialog_title:
    "stay_importer_date_format_dialog_title",
  stay_importer_drag_instruction: "stay_importer_drag_instruction",
  stay_importer_error_file_not_readable:
    "stay_importer_error_file_not_readable",
  stay_importer_error_file_too_large: "stay_importer_error_file_too_large",
  stay_importer_error_generic_content: "stay_importer_error_generic_content",
  stay_importer_error_generic_title: "stay_importer_error_generic_title",
  stay_importer_error_incorrectly_formatted:
    "stay_importer_error_incorrectly_formatted",
  stay_importer_error_line: "stay_importer_error_line",
  stay_importer_error_multiple_files_content:
    "stay_importer_error_multiple_files_content",
  stay_importer_error_multiple_files_title:
    "stay_importer_error_multiple_files_title",
  stay_importer_file_requirements_subtitle:
    "stay_importer_file_requirements_subtitle",
  stay_importer_file_requirements_title:
    "stay_importer_file_requirements_title",
  stay_importer_format_email: "stay_importer_format_email",
  stay_importer_format_end_date: "stay_importer_format_end_date",
  stay_importer_format_first_name_md: "stay_importer_format_first_name_md",
  stay_importer_format_full_name_md: "stay_importer_format_full_name_md",
  stay_importer_format_last_name_md: "stay_importer_format_last_name_md",
  stay_importer_format_phone_dialog_content_md:
    "stay_importer_format_phone_dialog_content_md",
  stay_importer_format_phone_dialog_title:
    "stay_importer_format_phone_dialog_title",
  stay_importer_format_phone_number: "stay_importer_format_phone_number",
  stay_importer_format_rental_unit_name:
    "stay_importer_format_rental_unit_name",
  stay_importer_format_start_date: "stay_importer_format_start_date",
  stay_importer_header_required: "stay_importer_header_required",
  stay_importer_import_button: "stay_importer_import_button",
  stay_importer_import_success: "stay_importer_import_success",
  stay_importer_import_success_content: "stay_importer_import_success_content",
  stay_importer_import_success_link: "stay_importer_import_success_link",
  stay_importer_instructions: "stay_importer_instructions",
  stay_importer_instructions_title: "stay_importer_instructions_title",
  stay_importer_partial_fail_title: "stay_importer_partial_fail_title",
  stay_importer_ready_for_import: "stay_importer_ready_for_import",
  stay_importer_subtitle: "stay_importer_subtitle",
  stay_importer_supported_columns: "stay_importer_supported_columns",
  stay_importer_supported_columns_alternatives:
    "stay_importer_supported_columns_alternatives",
  stay_importer_supported_columns_description:
    "stay_importer_supported_columns_description",
  stay_importer_supported_columns_header:
    "stay_importer_supported_columns_header",
  stay_importer_title: "stay_importer_title",
  store: "store",
  stored_billing_address: "stored_billing_address",
  submit: "submit",
  subscription_account_balance_title: "subscription_account_balance_title",
  subscription_assist_activation_blocker_description:
    "subscription_assist_activation_blocker_description",
  subscription_available_prepaid_homes_description:
    "subscription_available_prepaid_homes_description",
  subscription_cancelled_banner_action: "subscription_cancelled_banner_action",
  subscription_cancelled_banner_action_secondary:
    "subscription_cancelled_banner_action_secondary",
  subscription_cancelled_banner_body_member:
    "subscription_cancelled_banner_body_member",
  subscription_cancelled_banner_body_owner:
    "subscription_cancelled_banner_body_owner",
  subscription_cancelled_banner_body_owner_reactivation:
    "subscription_cancelled_banner_body_owner_reactivation",
  subscription_cancelled_banner_title: "subscription_cancelled_banner_title",
  subscription_change_plan: "subscription_change_plan",
  subscription_credit_balance: "subscription_credit_balance",
  subscription_credit_balance_description:
    "subscription_credit_balance_description",
  subscription_description_more_info: "subscription_description_more_info",
  subscription_dialog_positive_action: "subscription_dialog_positive_action",
  subscription_free: "subscription_free",
  subscription_has_scheduled_changes: "subscription_has_scheduled_changes",
  subscription_is_future: "subscription_is_future",
  subscription_is_non_renewing: "subscription_is_non_renewing",
  subscription_is_not_active: "subscription_is_not_active",
  subscription_manage_subscription: "subscription_manage_subscription",
  subscription_no_plan_title: "subscription_no_plan_title",
  subscription_pro_description: "subscription_pro_description",
  subscription_pro_feature_list: "subscription_pro_feature_list",
  subscription_pro_legacy_description: "subscription_pro_legacy_description",
  subscription_pro_legacy_description_monthly:
    "subscription_pro_legacy_description_monthly",
  subscription_pro_plus_feature_list: "subscription_pro_plus_feature_list",
  subscription_remove_scheduled_cancellation:
    "subscription_remove_scheduled_cancellation",
  subscription_remove_scheduled_cancellation_body:
    "subscription_remove_scheduled_cancellation_body",
  subscription_remove_scheduled_changes:
    "subscription_remove_scheduled_changes",
  subscription_renewal: "subscription_renewal",
  subscription_sign_up_failed_token_mismatch:
    "subscription_sign_up_failed_token_mismatch",
  subscription_standard_description: "subscription_standard_description",
  subscription_standard_feature_list: "subscription_standard_feature_list",
  subscription_standard_legacy_description:
    "subscription_standard_legacy_description",
  subscription_standard_legacy_description_monthly:
    "subscription_standard_legacy_description_monthly",
  subscription_starter_description: "subscription_starter_description",
  subscription_store_blocker_already_purchased_info:
    "subscription_store_blocker_already_purchased_info",
  subscription_store_blocker_already_purchased_title:
    "subscription_store_blocker_already_purchased_title",
  subscription_store_blocker_info: "subscription_store_blocker_info",
  subscription_store_blocker_title: "subscription_store_blocker_title",
  subscription_terms: "subscription_terms",
  subscription_total_cost: "subscription_total_cost",
  subscription_unbilled_charges: "subscription_unbilled_charges",
  subscription_unbilled_charges_description:
    "subscription_unbilled_charges_description",
  subscription_upgrade_blocker_body: "subscription_upgrade_blocker_body",
  subscription_upgrade_blocker_title: "subscription_upgrade_blocker_title",
  subscription_upgrade_blocker_title_non_owner:
    "subscription_upgrade_blocker_title_non_owner",
  subscription_violations_alarm_warning:
    "subscription_violations_alarm_warning",
  subscription_violations_noise_warning:
    "subscription_violations_noise_warning",
  summary: "summary",
  sunset_starter_banner_action: "sunset_starter_banner_action",
  sunset_starter_banner_body: "sunset_starter_banner_body",
  sunset_starter_banner_title: "sunset_starter_banner_title",
  support: "support",
  switch: "switch",
  tamper_attached: "tamper_attached",
  tamper_body: "tamper_body",
  tamper_mounted_body: "tamper_mounted_body",
  tamper_mounted_title: "tamper_mounted_title",
  tamper_removed: "tamper_removed",
  tamper_removed_body: "tamper_removed_body",
  tamper_removed_title: "tamper_removed_title",
  tamper_title: "tamper_title",
  tamper_unknown: "tamper_unknown",
  tamper_unsupported: "tamper_unsupported",
  team: "team",
  team_add_member: "team_add_member",
  team_contacts_home_note_description: "team_contacts_home_note_description",
  team_contacts_home_note_title: "team_contacts_home_note_title",
  team_members: "team_members",
  team_members_plural: "team_members_plural",
  temperature: "temperature",
  temperature_dropped_normal_body: "temperature_dropped_normal_body",
  temperature_high_body: "temperature_high_body",
  temperature_high_title: "temperature_high_title",
  temperature_low_body: "temperature_low_body",
  temperature_low_title: "temperature_low_title",
  temperature_rapid_rise_body: "temperature_rapid_rise_body",
  temperature_rapid_rise_title: "temperature_rapid_rise_title",
  temperature_risen_normal_body: "temperature_risen_normal_body",
  terms_of_service: "terms_of_service",
  thermostat_settings_heat_and_cool_temperature_description:
    "thermostat_settings_heat_and_cool_temperature_description",
  thermostat_settings_temperature_delta:
    "thermostat_settings_temperature_delta",
  time: "time",
  todos: "todos",
  total_includes_vat: "total_includes_vat",
  transactions_are_secure_encrypted: "transactions_are_secure_encrypted",
  transfer_legacy_plan_description: "transfer_legacy_plan_description",
  trigger: "trigger",
  try_reconnecting: "try_reconnecting",
  turn_off: "turn_off",
  turn_off_alarm_reminder: "turn_off_alarm_reminder",
  turn_on: "turn_on",
  understand_irreversible_action: "understand_irreversible_action",
  undo_action: "undo_action",
  unit: "unit",
  unit_plural: "unit_plural",
  unknown: "unknown",
  unlink: "unlink",
  unmonitored: "unmonitored",
  unmonitored_homes: "unmonitored_homes",
  unmonitored_homes_plural: "unmonitored_homes_plural",
  update: "update",
  update_and_continue: "update_and_continue",
  update_your_plan: "update_your_plan",
  updated_at: "updated_at",
  updated_days_ago: "updated_days_ago",
  updated_days_ago_plural: "updated_days_ago_plural",
  updated_just_now: "updated_just_now",
  updated_min_ago: "updated_min_ago",
  upgrade_cost_dialog_body: "upgrade_cost_dialog_body",
  upgrade_cost_dialog_title: "upgrade_cost_dialog_title",
  use_different_card_instructions: "use_different_card_instructions",
  use_invitation_code: "use_invitation_code",
  use_stored_payment_method: "use_stored_payment_method",
  user_arrived_home_title: "user_arrived_home_title",
  user_invited_another_user_to_home_body:
    "user_invited_another_user_to_home_body",
  user_invited_another_user_to_home_title:
    "user_invited_another_user_to_home_title",
  user_left_home_title: "user_left_home_title",
  user_received_invitation_to_other_home_action_0:
    "user_received_invitation_to_other_home_action_0",
  user_received_invitation_to_other_home_body:
    "user_received_invitation_to_other_home_body",
  user_received_invitation_to_other_home_title:
    "user_received_invitation_to_other_home_title",
  value: "value",
  water_leak_detected_card_description: "water_leak_detected_card_description",
  water_leak_detected_card_title: "water_leak_detected_card_title",
  water_leak_detection_title: "water_leak_detection_title",
  welcome: "welcome",
  welcome_aboard: "welcome_aboard",
  when_is_night_time: "when_is_night_time",
  wifi_reconfigured_body: "wifi_reconfigured_body",
  wine_cellar: "wine_cellar",
  wired_network: "wired_network",
  wont_receive_credits_for_unused_time: "wont_receive_credits_for_unused_time",
  year: "year",
  yearly: "yearly",
  yes: "yes",
  yesterday: "yesterday",
  your_plan: "your_plan",
} as const

export type LangType = {
  accept_terms_of_service_web: "I have read and accept the <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy> and consent to the processing of personal data in the USA"
  access_title: "Access"
  account: "Account"
  activate: "Activate"
  activate_call_assist_about_body_1: "A trained expert will call your guests when noise is detected in one of your properties."
  activate_call_assist_about_body_2: "Call Assist costs extra {{0}}/month/rental unit in addition to the plan charge."
  activate_call_assist_about_title: "About Call Assist"
  activate_call_assist_added_montly_cost: "Activate Call Assist for {{0}} units"
  activate_call_assist_body: "Have a trained operator call your guests when noise persists in your property. Add Call Assist to your plan at {{0}}/month/rental unit."
  activate_call_assist_error_already_active: "Call Assist is already active."
  activate_call_assist_error_title: "Can’t activate Call Assist"
  activate_call_assist_for_homes_count: "Activate Call Assist for {{0}} units"
  activate_call_assist_guard_and_call_assist_simultaneously_warning: "Guard Assist and Call Assist cannot be enabled for the same account yet - we’re working on it! Contact us at hello@minut.com for more information."
  activate_call_assist_no_homes_warning: "You need to create a rental unit before you can activate Call Assist."
  activate_call_assist_only_organization_owner_can_activate_warning: "Ready to get started with Call Assist? Ask your organization owner to activate it."
  activate_call_assist_success_body: "Call Assist was turned on for all the rental units on your account. You can toggle it on and off in Settings > Monitoring > Noise Monitoring."
  activate_call_assist_success_title: "Call Assist activated!"
  activate_call_assist_title: "Activate Call Assist for this account?"
  activate_call_assist_upgrade_plan_body: "Call Assist is not available on legacy plans. To activate Call Assist on your account, you need to upgrade first."
  activate_call_assist_upgrade_plan_title: "Upgrade to activate Call Assist"
  activated: "Activated"
  activating_call_assist_body: "Add a new rental unit at {{yearlyCost}} to your bill. If you have credits on your account, they will be used up first."
  activating_pre_paid_home_title: "Activating 1 unit"
  active: "Active"
  add: "Add"
  add_filter: "Add filter"
  add_guest: "Add stay"
  add_home: "Add rental unit"
  add_home_upfront_disclaimer: "If you've paid upfront for this rental unit, credits will be used."
  add_stay_dialog_date_error: "End date must come after start date"
  add_stay_dialog_imported_from: "Imported from"
  add_stay_dialog_phone_number_error: "Phone number must begin with '+' and contain 4-20 numbers"
  add_stay_dialog_title_edit: "Edit stay"
  add_stay_dialog_visit_source: "Visit {{sourceName}} to make changes to this stay."
  adding_call_assist_title: "Adding Call Assist"
  adding_new_home_token_info: "You’re activating a pre-paid unit. You have {{tokens}} pre-paid unit on your account."
  adding_new_home_token_info_plural: "You’re activating a pre-paid unit. You have {{tokens}} pre-paid units on your account."
  additional_home_info: "The cost of an additional rental unit will be added to your bill."
  addon_undo_scheduled_cancellation: "Undo scheduled cancellation"
  addon_will_automatically_renew_on_date: "{{addonName}} will automatically renew at the end of your current billing period on {{billingPeriodEndDate}}."
  addons: "Add-ons"
  address: "Address"
  address_company: "Company"
  address_form_city: "City"
  address_form_country: "Country"
  address_form_postcode: "Postcode"
  address_form_state: "State"
  address_form_street_name_1: "Street"
  address_form_street_name_2: "Flat, suite or floor (Optional)"
  address_state: "State"
  adjust: "Adjust"
  agree: "Agree"
  air_too_dry_action_0: "Read more"
  air_too_dry_body: "The sensor has detected that the humidity is very low."
  air_too_dry_title: "Air may be too dry"
  air_too_humid_action_0: "Read more"
  air_too_humid_body: "The sensor has detected that the humidity is very high."
  air_too_humid_title: "Air may be too humid"
  airbnb_extended_permission_todo_body: "Airbnb integration can now automatically disclose Minut on the listing under the “Noise decibel monitors” category."
  airbnb_extended_permission_todo_title: "Update Airbnb integration"
  alarm_detection_test_action_0: "Run test"
  alarm_detection_test_body: "Activate your home’s smoke alarm or old security system for at least 10 seconds. This is normally done by pushing the test button on the alarm. You and your family members will be notified when Minut detects the alarm sound."
  alarm_detection_test_title: "Detect other alarms in your home"
  alarm_end_of_life_short_description: "This alarm has reached the end of its life and needs to be replaced."
  alarm_grace_period_expired_body: "The sensor detected motion."
  alarm_grace_period_expired_title: "Motion detected"
  alarm_heard_body: "The sensor detected an alarm sounding in your property."
  alarm_heard_title: "Alarm sound detected!"
  alarm_muted_button_press_body: "Alarm was muted by pushing the button on the sensor."
  alarm_muted_button_press_title: "Alarm muted"
  alarm_off_automatically_body: "You came home – alarm automatically off."
  alarm_on_automatically_body: "You left home – alarm automatically on."
  alarm_recognition_setting_body: "Detect other alarms going off in your property."
  alarm_recognition_setting_title: "Alarm recognition"
  alarm_silenced_body: "Alarm silenced by {{0}}."
  alarm_silenced_title: "Alarm silenced"
  alarm_test_failed_body: "If the device is sounding and blinking, use the manual to identify the problem. If you can't spot any issues, test again by pressing the button on the device."
  alarm_test_failed_short_description: "Alarm test failed."
  alarm_test_failed_title: "Alarm test failed on {{testedAt}}"
  alarm_test_success_title: "Alarm last tested on {{testedAt}}"
  alarm_turned_off_button_press_title: "Alarm turned off"
  all_data_and_sensors_will_be_deleted: "All devices and data belonging to this rental unit will be permanently deleted."
  almost_freezing_action_0: "Read more"
  almost_freezing_body: "The temperature has been below {{0}} for over 5 hours. When indoor temperature reaches freezing degrees, it can cause damage to the property."
  almost_freezing_details: "What happened?\nThe temperature in the room has been below 5°C/41°F for over 5 hours.\n\nWhat does it mean?\nIndoor temperatures at these levels may damage the property. If it gets colder, water in pipes or radiators may start to freeze. The ice can break the pipes and cause severe water damages.\n\nWhat can I do?\nTurn up the heat to make it warmer. Another option could be to empty pipes containing water, such as incoming water or radiators. Adding anti-freeze substance to the radiators can also prevent damages."
  almost_freezing_title: "Almost freezing"
  api_clients_body: "Use API clients to communicate with the Minut API to write custom integrations with your own applications."
  api_clients_client: "Client"
  api_clients_client_credentials_title: "API client credentials"
  api_clients_client_id: "Client ID"
  api_clients_client_secret: "Client secret"
  api_clients_create_body: "After creating the API client, a Client ID and Client secret will be generated."
  api_clients_create_client: "Create client"
  api_clients_create_title: "New API client"
  api_clients_delete_body: "This API client will be permanently deleted and any client that is currently using it will stop working."
  api_clients_delete_confirm_body: "Please type the name of the api client to confirm. This action cannot be undone."
  api_clients_delete_title: "Are you sure you want to delete this client?"
  api_clients_edit_title: "Edit API client"
  api_clients_empty_body: "Create your first client to start using the Minut API."
  api_clients_empty_title: "No clients created yet"
  api_clients_form_add_another_uri: "Add another URI"
  api_clients_form_name: "Name"
  api_clients_redirect_uri: "Redirect URI"
  api_clients_title: "API clients"
  area_type_backyard: "Backyard"
  area_type_balcony: "Balcony"
  area_type_corridor: "Corridor"
  area_type_garage: "Garage"
  area_type_garden: "Garden"
  area_type_hot_tub: "Hot tub"
  area_type_lounge: "Lounge"
  area_type_other_indoor_area: "Other indoor area"
  area_type_other_outdoor_area: "Other outdoor area"
  area_type_patio: "Patio"
  area_type_pool_area: "Pool area"
  area_type_staircase: "Staircase"
  area_type_study: "Study"
  area_type_terrace: "Terrace"
  attic: "Attic"
  automatic: "Automatic"
  automatic_alarm_mobile_app_only: "The automatic alarm can only be activated from the Minut mobile app"
  average: "Average"
  avg_sound_high_body: "Sound level is above the custom threshold."
  avg_sound_high_title: "High sound levels!"
  back: "Back"
  badge_custom_plan: "Custom"
  basement: "Basement"
  bathroom: "Bathroom"
  battery: "Battery"
  battery_charging_complete_body: "The sensor is fully charged and can be put back to its mounting plate."
  battery_empty_body: "Charge your sensor with the included cable to protect your property."
  battery_empty_title: "Out of battery"
  battery_low_body: "The sensor is low on battery. Use the included cable to charge."
  battery_low_title: "Battery low"
  bedroom: "Bedroom"
  bedroom_too_warm_action_0: "Read more"
  bedroom_too_warm_body: "The sensor detected that it may be too warm at night."
  bedroom_too_warm_details: "Some research show that sleep quality can be improved by lowering the temperature in the bedroom. One recommendation is to not exceed 18°C/65°F."
  bedroom_too_warm_title: "Bedroom may be too warm"
  beta: "Beta"
  billed_as: "Billed as"
  billed_now: "Billed now"
  billing_add_vat_number: "Add EU VAT number"
  billing_address: "Billing address"
  billing_address_payment_method_match: "Your billing address should match your payment method, and will be included on your future invoices."
  billing_event_plan_renewal_breadcrumb: "Details of the plan renewal"
  billing_event_plan_renewal_title: "Details of your plan renewal on {{date}}"
  billing_history_account_balance: "Account Balance"
  billing_history_account_balance_tooltip: "The account balance reflects your total available credit minus any unbilled charges on your account."
  billing_history_credit_debit: "Credit/Debit"
  billing_history_credit_debit_tooltip_description: "Credits are automatically applied to invoices, so the billed amount and the invoice amount can differ."
  billing_history_event: "Event"
  billing_history_homegroup: "Location"
  billing_history_modified_by: "Modified by"
  billing_history_note: "Note"
  billing_history_past_events_description: "Past events are not available."
  billing_history_payment: "Payment"
  billing_history_payment_note_link_label: "Download receipt"
  billing_history_payment_refunded_note_link_label: "Download credit note"
  billing_history_plan_renewal_note: "<breakdownLink>See breakdown</breakdownLink> for individual properties."
  billing_history_title: "Billing history"
  billing_location_verification_error: "We were unable to verify your location. Due to EU tax rules you must use a credit card that has been issued in the same country as the billing address. Please update your payment method to keep the service running."
  billing_page_feedback_body: "Help us make it better by sharing your feedback."
  billing_page_feedback_title: "What do you think about the billing page?"
  billing_period: "Billing period"
  billing_tax_exempt: "Tax exempt"
  billing_total_includes: "Total includes"
  billing_vat: "VAT"
  booking_dates: "Dates"
  building_details: "Building details"
  buildings_empty_state_description: "Buildings group together units that share the same address. Examples include hotels and multi-family homes. If you’re looking to group units within a community or region, use Locations."
  buildings_empty_state_description_copy: "Buildings group together units that share the same address. Examples include hotels and multi-family homes. If you’re looking to group units within a community or region, use Locations."
  buildings_empty_state_title: "Organize your team and properties with buildings"
  ca_configure_guests: "To configure, add stays to the properties that have Call Assist enabled."
  ca_table_missing_phone_number: "Phone number is needed for Call Assist to work. Create a second entry with the correct phone number in the Stays tab of the corresponding rental unit."
  call_and_guard_assist: "Call & Guard Assist"
  call_and_guard_assist_description: "Persistent noise issues? Have our agent call your guest or send a responder to the property."
  call_assist: "Call Assist"
  call_assist_description: "Let Minut call your guests when noise issues are detected so you and your team can relax and focus on scaling your business."
  call_assist_title_description: "No more on-call duty to solve noise issues"
  cancel: "Cancel"
  cancel_addon_body: "You're about to cancel {{addon}}. You'll be able to use it until the end of your current billing period on {{date}}."
  cancel_addon_title: "Cancel {{addon}}"
  cancel_plan: "Cancel plan"
  cancel_plan_additional_placeholder: "Let us know how we can improve"
  cancel_plan_body: "You’re about to cancel your Minut plan. You’ll be able to use Minut until the end of your current billing period on {{date}}."
  cancel_plan_come_back_question: "How likely are you to use Minut again in the future?"
  cancel_plan_experience_rating_excellent: "Excellent"
  cancel_plan_experience_rating_poor: "Poor"
  cancel_plan_experience_rating_question: "Rate your overall experience with Minut"
  cancel_plan_need_help: "Need help?"
  cancel_plan_need_help_body: "We'd love to look into this for you. Please reach out to us at support@minut.com"
  cancel_plan_please_specify_below: "please specify below"
  cancel_plan_reason_additional_feeback: "Any additional feedback?"
  cancel_plan_reason_alternative_service: "I found an alternative service"
  cancel_plan_reason_body: "Help us make Minut better by sharing your feedback. Please select your main reason."
  cancel_plan_reason_need_more_features: "I need more features"
  cancel_plan_reason_not_hosting: "I don't host anymore"
  cancel_plan_reason_not_meet_expectations: "Minut didn't meet my expectations"
  cancel_plan_reason_not_need_monitoring: "I no longer need monitoring"
  cancel_plan_reason_not_useful: "I didn't find it useful"
  cancel_plan_reason_other: "Other reason"
  cancel_plan_reason_tech_problems: "I have technical problems"
  cancel_plan_reason_title: "Why do you want to cancel?"
  cancel_plan_reasons_requirement: "Choose at least one"
  cancel_plan_return_probability_very_likely: "Very likely"
  cancel_plan_return_probability_very_unlikely: "Very unlikely"
  cancel_plan_title: "Cancel plan"
  cancel_plan_what_could_we_have_done_differently: "What could we have done differently?"
  cancel_reason_missing_feature: "Minut is missing features that I need"
  cancel_reason_switching_to_another_solution: "I'm switching to another solution"
  cancel_reason_too_expensive: "Minut is too expensive"
  cancelled: "Cancelled"
  card_ending_in: "Card ending in {{digits}}"
  celsius: "°C"
  celsius_long: "Celsius"
  change_name: "Change name"
  change_password: "Change password"
  change_plan: "Change plan"
  change_plan_add_payment_method_first: "You need to add a payment method first. Go to the billing page to complete the set up."
  change_plan_annual_disclaimer: "Annual plans require a one-year non-cancellable commitment."
  change_plan_annual_savings: "Save up to {{savings}} annually"
  change_plan_breakdown: "Change plan breakdown"
  change_plan_cancel: "Your plan will be cancelled at the end of your current term {{date}}. Any additional properties, team members and data history will be lost."
  change_plan_charged_with_card: "You will be charged {{amount}}  for pro-rated charges via your card ending in {{last4}}"
  change_plan_charged_with_credits: "You will be charged {{amount}} using your account credits, for pro-rated charges."
  change_plan_contact_sales_cta: "Interested in monitoring more than 20 units?"
  change_plan_credit_adjustment_body: "{{value}} will be added as credits to your account and automatically used on any future invoices."
  change_plan_credit_adjustment_title: "Credit balance"
  change_plan_current_plan_pill: "Current plan"
  change_plan_custom_plan_blocker_body: "Contact the Minut support team or your Customer Success Manager to make changes to it."
  change_plan_custom_plan_blocker_title: "This account is on a custom plan"
  change_plan_description: "Visit our pricing page to learn more about our plans. "
  change_plan_future: "Your plan has not been activated yet. Add your first rental unit to activate it."
  change_plan_has_scheduled_changes: "Your plan is already scheduled to be changed."
  change_plan_no_charge: "You will not be charged for this change."
  change_plan_no_charge_credits: "You will not be charged for this change and {{amount}} will be added as credits to your account."
  change_plan_non_renewing: "Your plan is scheduled to be cancelled. If you wish to change your plan instead, please remove the scheduled cancellation first."
  change_plan_paused: "Your plan is currently paused. To be able to make changes to it you'll first need to activate it."
  change_plan_renews: "Renews"
  change_plan_select_title: "Choose your plan"
  change_plan_subscribe_pre_paid_homes_description: "You have {{quantity}} pre-paid units on your account, your final charges will be reduced accordingly."
  change_plan_subscribe_pre_paid_homes_title: "You have pre-paid units on your account"
  change_plan_success_body: "It may take a minute before the changes are visible in your app."
  change_plan_success_title: "Your plan has been updated"
  change_plan_time_of_change: "The change will be applied when your plan renews on {{date}}."
  change_plan_time_of_change_title: "Change will be applied at renewal"
  change_plan_to: "Change plan to {{plan_name}}"
  change_plan_update_billing_period: "Update billing period"
  change_plan_used_credits: "Credits applied"
  charge: "charge"
  charger_connected_body: "The sensor connected to the charger."
  charger_disconnected_body: "The sensor disconnected from the charger."
  check_in_instructions: "Instructions for the stay"
  choose_your_plan_text: "Choose your plan"
  city: "City"
  clear_filters: "Clear filters"
  clear_selection: "Clear selection"
  click_here: "Click here"
  close: "Close"
  co_alarm: "CO Alarm"
  co_alarm_triggered_title: "CO alarm triggered"
  configure: "Configure"
  confirm: "Confirm"
  confirm_cancel: "Confirm cancellation"
  confirm_discard_changes_body: "If you leave now, your updates will be lost."
  confirm_discard_changes_cancel: "Keep editing"
  confirm_discard_changes_discard: "Leave and discard changes"
  confirm_discard_changes_title: "You have unsaved changes"
  confirm_place_order: "Confirm & place order"
  confirm_reactivation: "Confirm re-activation"
  connect: "Connect"
  contact_sales: "Contact sales"
  contact_support: "Contact support"
  continue: "Continue"
  copied: "Copied"
  copy: "Copy"
  copy_noun: "Copy"
  country: "Country"
  create_an_account: "Create an account"
  create_home: "Create rental unit"
  create_home_confirm_step_billing_body_1: "We automatically update your billing as you add and remove rental units."
  create_home_confirm_step_billing_body_2: "Adding 1 new rental unit will add another unit at {{price}} / {{billingPeriod}} to your bill."
  create_home_confirm_step_billing_body_3: "If you have paid upfront for this rental unit, credits will be used."
  create_home_confirm_step_billing_title: "Billing update"
  create_home_confirm_step_title: "Summary"
  create_home_confirmation_accept_body: "I understand deleting this rental unit within the first year will result in losing any unused time (it can't be credited or transferred to another unit)."
  create_home_confirmation_accept_error: "You need to accept terms and conditions to create a rental unit."
  create_home_confirmation_activating_home: "Activating 1 unit"
  create_home_confirmation_creating_home: "Creating 1 unit"
  create_home_confirmation_home_details_title: "Unit details"
  create_home_confirmation_terms: "You will be charged {{price}} upfront. If you have credits on your account, they will be used first."
  create_home_contract_limit_banner_body: "You have 0 pre-paid rental units available. Remove existing rental unit or add more rental unit by contacting your Customer Success Manager or [sales@minut.com](mailto:sales@minut.com)."
  create_home_contract_limit_banner_title: "Insufficient pre-paid rental units."
  create_home_enter_address_manually: "Enter address manually"
  create_home_information_step_title: "Add a rental unit to {{organization}}"
  create_home_settings_profile_step_body: "Monitoring profiles contain predefined settings that can be shared between rental units. This selection can be changed after the unit has been created."
  create_home_settings_profile_step_title: "Select a monitoring profile for this unit"
  create_organization_how_many_units: "How many rental units does this organization manage?"
  create_organization_name_placeholder: "e.g. Julia's Rentals"
  create_organization_portfolio_size_error_text: "Please select an option before proceeding."
  create_organization_reason_custom_placeholder: "Enter reasons"
  create_organization_reason_for_minut: "What will you use Minut for?"
  create_organization_reason_local_regulations: "Comply with local regulations"
  create_organization_reason_lower_smoking_risk: "Lower the risk of smoking indoors"
  create_organization_reason_maintenance_cost: "Reduce maintenance costs"
  create_organization_reason_mold_free: "Keep property mold free"
  create_organization_reason_other: "Other"
  create_organization_reason_prevent_unwanted_parties: "Prevent unwanted parties"
  create_organization_reason_reduce_noise_complaints: "Reduce noise complaints"
  create_organization_reason_save_utility_bills: "Save on utility bills"
  create_organization_role_ceo_vp: "CEO/VP (C-suite)"
  create_organization_role_city_site_manager: "City/Site Manager"
  create_organization_role_custom_placeholder: "Enter role"
  create_organization_role_error_text: "Please select an option before proceeding."
  create_organization_role_host_owner: "Host/Owner"
  create_organization_role_other: "Other"
  create_organization_role_regional_manager: "Regional Manager"
  create_organization_service_apartment_hotel: "Apartment hotel"
  create_organization_service_custom_placeholder: "Enter type of services"
  create_organization_service_hotel: "Hotel"
  create_organization_service_multi_family_residential: "Multi-family residential"
  create_organization_service_other: "Other"
  create_organization_service_role_guest_communications_frontdesk: "Customer Communications/Frontdesk"
  create_organization_service_senior_living: "Senior living"
  create_organization_service_social_housing: "Social housing"
  create_organization_service_student_housing: "Student housing"
  create_organization_service_vacation_rentals: "Vacation rentals"
  create_organization_services_error_text: "Please select one or more options."
  create_organization_to_continue_message: "You can either join an existing organization through an invite or create a new one to continue."
  create_organization_to_continue_title: "It seems like you are not a member of an existing organization"
  create_organization_what_is_the_name_placeholder: "e.g. Julia’s Rentals"
  create_organization_what_is_your_role: "What is your role in this organization?"
  create_organization_what_type_of_services: "What types of services does this organization provide?"
  create_report: "Create report"
  credit_card: "Credit card"
  crowd_detect_active_emergency_body: "The number of devices is above the threshold"
  crowd_detect_active_emergency_title: "High crowd risk, at {{0}}"
  crowd_detect_active_normal_title: "Low crowd risk, at {{0}}"
  crowd_detect_alert_title: "Number of devices is above the threshold"
  crowd_detect_all_fields_not_set: "Set the unit capacity before turning on crowd detect."
  crowd_detect_analyzing_short_description: "This normally takes around 1 hour"
  crowd_detect_analyzing_title: "Analyzing devices nearby"
  crowd_detect_battery_life_disclaimer: "Turning on this feature affects the sensor's battery life."
  crowd_detect_capacity_short_description: "The number of people this unit accommodates."
  crowd_detect_capacity_title: "Rental unit capacity"
  crowd_detect_chart_threshold_label: "Threshold"
  crowd_detect_chart_title: "Mobile devices detected"
  crowd_detect_crowd_risk_description: "Crowd detect analyzes the number of iOS devices in your rental unit through monitoring their Bluetooth signals. The number of devices detected is used as an indication of a crowd gathering."
  crowd_detect_crowd_risk_subtitle: "iOS devices detected"
  crowd_detect_crowd_risk_title: "Crowd risk"
  crowd_detect_current_number_of_devices_at: "devices at {{0}}"
  crowd_detect_device_threshold_read_more: "How is the mobile device threshold calculated?"
  crowd_detect_device_threshold_short_description: "This is the estimated number of mobile devices that can indicate a crowd in your unit. It's based on the number of iOS devices with bluetooth an average person carries and the rental unit capacity."
  crowd_detect_device_threshold_title: "Threshold"
  crowd_detect_short_description: "Get notified when there’s a high risk of a crowd gathering."
  crowd_detect_title: "Crowd detect"
  customize_noise_report_body: "Pick specific dates or locations."
  customize_noise_report_title: "Customize your report"
  dashboard: "Dashboard"
  dashboard_card_add_member_description: "Invite your team members and key partners to make sure everyone has access to the data they need, securely and conveniently."
  date: "Date"
  days: "days"
  decibel_label_short: "dB"
  delete: "Delete"
  delete_account: "Delete account"
  delete_account_body: "You will not receive any notifications from your Minut device and your account will no longer be accessible."
  delete_account_title: "This will delete your account"
  delete_generic_question: "Delete {{name}}?"
  delete_multiple_rental_units: "Delete 1 rental unit?"
  delete_multiple_rental_units_plural: "Delete {{count}} rental units?"
  delete_multiple_rental_units_description: "All devices and data belonging to these units will be permanently deleted. This action is irreversible."
  description: "Description"
  desktop_notifications_description: "Receive web app notifications as long as your browser is open and you’re logged into the Minut web app (even when the web app is closed)."
  desktop_notifications_supported: "Supported on Chrome (Windows, macOS) and Edge (Windows)."
  desktop_notifications_title: "Web app notifications"
  device_detail_graph_multiple_motion_events: "{{0}} motion events"
  device_detail_graph_single_motion_event: "1 motion event"
  device_details_motion_detail: "A maximum of 1 motion event per 45 s is displayed."
  device_details_motion_detail_with_instantly_turn_on_alarm_off: "A maximum of 1 motion event per 45 s is displayed.\n\nIt may take up to 1 hour for motion events to be displayed. Motion can be viewed without delay by activating 'Instantly turn on alarm' from Settings."
  device_home_transfer_setting_body: "Transfer device without losing collected data."
  device_install_body: "Your sensor is now up and running."
  device_install_title: "Good job!"
  device_offline_body: "Last time we heard something was at {{0}}. Make sure the sensor is connected to the internet and charged."
  device_offline_eureka_body: "The alarm has been offline since {{time}}. If it detects smoke or high CO levels, the siren will still sound, but you won't receive an alert. Make sure the alarm is connected to the internet and has battery to bring the alarm back online."
  device_offline_eureka_title: "Alarm is offline"
  device_offline_suffix: "is offline"
  device_offline_title: "Sensor is offline"
  device_online_body: "The sensor is online. It was offline for {{0}} hours."
  device_online_eureka_body: "The alarm is online. It was offline for {{hours}} hours."
  device_online_eureka_title: "Alarm back online"
  device_online_title: "Sensor back online"
  device_power_off_body: "The sensor was turned off. This could be due to low battery, or because it was switched off using the power button."
  device_power_off_title: "Sensor turned off"
  device_report_rate_setting_body: "Detect if your sensor is offline faster. Will consume more battery when enabled."
  device_report_rate_setting_title: "More frequent sensor updates"
  device_settings_above: "{{0}} above"
  device_settings_advanced_settings_title: "Advanced"
  device_settings_below: "{{0}} below"
  device_settings_firmware_version: "Firmware version"
  device_settings_glassbreak: "Window break detection"
  device_settings_glassbreak_detail: "Get notified if the glass in a window breaks. Deactivate to increase battery life."
  device_settings_humidity_description: "Monitor humidity to keep your property safe and comfortable. It’s usually recommended to keep indoor humidity between 40% and 60%."
  device_settings_humidity_detail_description: "Monitor humidity to keep your property safe and comfortable. It’s usually recommended to keep indoor humidity between 40% and 60%."
  device_settings_mac_address: "MAC address"
  device_settings_nightlight: "Nightlight"
  device_settings_nightlight_detail: "The sensor lights up when motion is detected at night."
  device_settings_noise: "Noise"
  device_settings_remove_point: "Remove device"
  device_settings_remove_point_alert_body: "You are about to remove a Minut device and all its data. If you proceed, you won't be able to get the data back."
  device_settings_remove_point_alert_title: "Are you sure you want to remove this device?"
  device_settings_room_type: "Room"
  device_settings_temperature_description: "Temperature can be used as a measure of the comfort level in a propety. It is the most important factor determining energy consumption."
  device_settings_temperature_detail_description: "Temperature can be used as a measure of the comfort level in a property. It is the most important factor determining energy consumption."
  device_settings_threshold_sound_duration_description: "The amount of time noise needs to be above your set threshold for you to be notified. This setting helps you avoid false alarms."
  device_settings_threshold_sound_duration_title: "Noise duration"
  device_settings_threshold_sound_night_switch_title: "Different threshold during quiet hours"
  device_settings_threshold_sound_switch_title: "Notify me about noise"
  device_settings_threshold_switch_title: "Get notified about {{0}} changes"
  device_settings_will_be_updated_notification: "Device settings will be updated within 1 hour."
  device_threshold_noise_notifications_switch_state_always: "Always"
  device_threshold_noise_notifications_switch_state_only_noise_monitoring: "Only when noise monitoring is active"
  devices_list_empty_description: "Monitor your properties for noise, occupancy, and smoking with ease. Add your device via Minut mobile app."
  devices_list_empty_title: "Install a Minut device"
  disabled: "Disabled"
  discard_changes_dialog_cancel: "Keep editing"
  discard_changes_dialog_confirm: "Discard changes"
  discard_changes_dialog_description: "You've made changes that haven't been saved."
  discard_changes_dialog_title: "Discard changes?"
  disconnect: "Disconnect"
  dismiss: "Dismiss"
  disturbance_alarm_control_short_description: "Get notified when loud noise is above the threshold for a set duration."
  disturbance_dismissed_body: "Noise event has been dismissed. If it becomes noisy again, you will receive a new noise event."
  disturbance_dismissed_title: "Noise event dismissed"
  disturbance_dispatch_manually_action: "Send guard"
  disturbance_dispatch_manually_dialog_message: "{{0}} will send a guard to check in on your property. You will receive a report once the callout is complete."
  disturbance_dispatch_manually_dialog_title: "Send a Guard to {{0}}"
  disturbance_dispatched_automatically_body: "It's been noisy for {{0}} minutes.  {{1}}  is finding a guard."
  disturbance_dispatched_automatically_title: "Finding guard"
  disturbance_dispatched_manually_body: "We received your callout request.  {{0}}  is finding a guard."
  disturbance_dispatched_manually_title: "Guard sent"
  disturbance_ended_body: "Noise levels are below the set threshold of {{0}}. If it becomes noisy again, you will receive a new noise event."
  disturbance_ended_title: "It’s quiet"
  disturbance_first_notice_body: "Noise level has been above the set threshold of {{0}} for {{1}} minutes and is now {{2}}. You will be updated if it’s noisy or quiet again at {{3}}."
  disturbance_first_notice_response_service_body: "The noise level has been above the threshold ({{0}}) for {{1}} minutes. We will ask {{3}} to send a guard at {{2}} if the noise isn't resolved by then."
  disturbance_first_notice_response_service_title: "It's been noisy for {{0}} minutes"
  disturbance_first_notice_title: "It's been noisy for {{0}} minutes"
  disturbance_level_3_automatically_body: "The noise level has been above the threshold ({{0}}) for {{1}} minutes."
  disturbance_level_3_automatically_title: "It’s been noisy for {{0}} minutes."
  disturbance_level_3_manually_body: "Noise event has been sent to contacts by {{0}}. You will be notified again when it's quiet."
  disturbance_level_3_manually_title: "Noise event sent to contacts"
  disturbance_level_4_automatically_body: "You will be notified when it’s quiet again."
  disturbance_level_4_automatically_title: "It's been noisy for {{0}} minutes."
  disturbance_level_4_manually_body: "Noise event has been sent to contacts by {{0}}. You will be notified again when it's quiet."
  disturbance_level_4_manually_title: "Noise event sent to contacts"
  disturbance_on_idle_title: "Noise level is ok"
  disturbance_second_notice_body: "Noise level has been above the set threshold of {{0}} for {{1}} minutes and is now {{2}}. You will be updated if it’s noisy or quiet again at {{3}}."
  disturbance_second_notice_response_service_body: "The noise level has been above the threshold ({{0}}) for {{1}} minutes. We will ask {{3}} to send a guard at {{2}} if the noise isn't resolved by then."
  disturbance_second_notice_response_service_title: "It's been noisy for {{0}} minutes"
  disturbance_second_notice_title: "It's been noisy for {{0}} minutes"
  disturbance_snoozed_body: "You will not receive any noise events during this period."
  disturbance_snoozed_title: "Noise events snoozed for 1 hour"
  disturbance_third_notice_body: "Noise level has been above the set threshold of {{0}} for {{1}} minutes and is now {{2}}. You will be notified when it’s quiet again."
  disturbance_third_notice_response_service_body: "Noise level has been above the set threshold of {{0}} for {{1}} minutes and is now {{2}}. A guard will be sent to the property at {{3}} unless the noise event is dismissed."
  disturbance_third_notice_response_service_title: "Sending a guard soon"
  disturbance_third_notice_title: "It's been noisy for {{0}} minutes"
  disturbance_warning_state_title: "It's been noisy for {{0}} minutes"
  dog_bark_body: "A sound has been recognized that may be a dog barking."
  dog_bark_title: "Dog bark"
  done: "Done"
  download: "Download"
  download_csv: "Download CSV"
  download_csv_homes_content: "Download a CSV file listing the {{nbrHomes}} rental units displayed in the table?"
  download_incident_report_button_action: "Download an incident report"
  download_incident_report_duration_error: "The maximum duration for an incident report is 24 hours. Edit duration and try downloading the report again."
  download_report: "Download report"
  dunning_banner_action: "Go to billing page"
  dunning_banner_day_0_body_member: "There was an issue when renewing the plan. To avoid service interruption, please ask the organization owner to check payment details."
  dunning_banner_day_0_body_owner: "There was an issue when renewing your plan. Go to the billing page to check your card details and retry payment to avoid service interruption."
  dunning_banner_day_0_title: "Payment failed"
  dunning_banner_pre_suspended_body_member: "There was an issue when renewing the plan. To avoid suspension, please ask the organization owner to check payment details."
  dunning_banner_pre_suspended_body_owner: "There was an issue when renewing your plan. Go to the billing page to check your card details and retry payment to avoid suspension."
  dunning_banner_pre_suspended_title_member: "This organization will be suspended within {{daysUntilSuspension}} days"
  dunning_banner_pre_suspended_title_owner: "This account will be suspended within {{daysUntilSuspension}} days"
  dunning_banner_suspended_body_member: "We've paused your service, including all notifications to you and the people staying in your property. To restore full functionality and avoid cancellation, please ask the organization owner to check payment details before {{willBeCancelledAtDate}}."
  dunning_banner_suspended_body_owner: "We've paused your service, including all notifications to you and the people staying in your property. To restore full functionality and avoid cancellation, go to the billing page to check your card details and retry payment before {{willBeCancelledAtDate}}."
  dunning_banner_suspended_title_member: "This organization is suspended"
  dunning_banner_suspended_title_owner: "This account is suspended"
  dunning_billing_alert_pre_suspended: "Renewal failed. Check your card details and retry payment below to avoid suspension."
  dunning_billing_alert_suspended: "We've paused your service, including all notifications to you and the people staying in your property. To restore full functionality and avoid cancellation, check your card details and retry payment below before {{willBeCancelledAtDate}}."
  dunning_suspended_pill: "Suspended"
  duplicate: "Duplicate"
  duration: "Duration"
  edit: "Edit"
  edit_home_information: "Edit unit information"
  edit_settings: "Edit settings"
  email: "Email"
  email_notifications: "Email notifications"
  email_notifications_info: "Receive email notifications about noise, crowd detect, tamper and temperature events."
  enabled: "Enabled"
  end_time: "End time"
  enter_the_invite_code: "Enter the code or URL you received in the invitation."
  enter_your_invitation_code: "Enter invitation code"
  event: "Event"
  event_plural: "Events"
  event_filter_alarm_recognition_label: "Alarm recognition"
  event_filter_button_pressed_label: "Button pressed"
  event_filter_contacts_alerted_label: "Contacts alerted"
  event_filter_crowd_detect_label: "Crowd detect"
  event_filter_first_noise_alert_label: "First noise alert"
  event_filter_high_humidity_label: "High humidity"
  event_filter_high_temperature_label: "High temperature"
  event_filter_low_battery_label: "Low battery"
  event_filter_low_humidity_label: "Low humidity"
  event_filter_low_temperature_label: "Low temperature"
  event_filter_mold_risk_label: "Mold risk"
  event_filter_noise_issue_solved_label: "Noise issue solved"
  event_filter_out_of_battery_label: "Out of battery"
  event_filter_responder_arrived_label: "Guard arrived"
  event_filter_responder_sent_label: "Guard sent"
  event_filter_response_partners_callout_label: "Guard Assist callout"
  event_filter_second_noise_alert_label: "Second noise alert"
  event_filter_security_alarm_label: "Security alarm"
  event_filter_sensor_attached_label: "Device attached"
  event_filter_sensor_installed_label: "Device installed"
  event_filter_sensor_offline_label: "Device offline"
  event_filter_sensor_online_label: "Device online"
  event_filter_sensor_removed_label: "Device removed"
  event_filter_sensor_swap_label: "Sensor swap"
  event_filter_sensor_turned_off_label: "Device turned off"
  event_filter_third_noise_alert_label: "Third noise alert"
  event_filter_window_break_label: "Window break"
  event_log_call_assist_cta: "Let us handle persistent noise issues"
  event_maximum_events_reached_error_text: "Maximum number of events reached"
  events_empty_state_description: "Monitor your properties for noise, occupancy and smoking with ease. Add your device via Minut mobile app and events will show up here."
  events_empty_state_title: "You have not received any events yet"
  events_latest: "Latest events"
  events_more: "See all events"
  experimental: "Experimental"
  exploration_card_charging_body: "Learn when to charge your devices so that your properties never go unmonitored."
  exploration_card_guest_communication_body: "Save time and mitigate persistent noise issues by sending automated messages to the people staying in your property."
  exploration_card_marijuana_detection_body: "Our AI-powered smoking detection feature now also monitors for marijuana! Protect your property with real-time alerts."
  exploration_card_noise_thresholds_body: "How loud is too loud? Choose a threshold suitable for your property while considering neighbors and local regulations."
  exploration_card_notifications_body: "Receive push notifications and/or emails alerting you to ongoing events."
  exploration_card_quiet_hours_body: "Set different thresholds for daytime and nighttime monitoring. Pick when your quiet hours are and how strict you want to be."
  exploration_card_referral_body: "Earn $15 for every new customer you refer and give them two months free on their first Minut order."
  exploration_card_referral_link: "Get your unique link"
  exploration_card_sensor_swap_body: "Replace a depleted Minut sensor with a fully charged one without reinstalling the device or losing any data."
  exploration_card_team_members_body: "Share access to your Minut account with key team members and partners."
  explore_minut: "Explore"
  export: "Export"
  fahrenheit: "°F"
  fahrenheit_long: "Fahrenheit"
  failed_contact_support: "Please try again, or contact support at hello@minut.com if the problem remains."
  failed_contact_support_to_resolve: "Please contact us at hello@minut.com so that we can help you resolve the problem."
  failed_general_error_body: "We're having issues loading this page."
  failed_general_error_title: "Something went wrong"
  failed_general_error_try_refreshing_page: "Something went wrong. Try refreshing the page."
  failed_something_went_wrong: "Something went wrong. Please try again, or contact us at hello@minut.com if the problem persists."
  failed_to_add: "Failed to add"
  failed_to_create: "Failed to create"
  failed_to_delete: "Failed to delete"
  failed_to_dismiss: "Failed to dismiss"
  failed_to_load: "Failed to load"
  failed_to_process_card: "Your card couln't be processed"
  failed_to_remove: "Failed to remove"
  failed_to_save: "Failed to save"
  failed_to_update: "Failed to update"
  feedback_action: "Share feedback"
  feedback_dont_know: "Don't know"
  filter: "Filter"
  filter_event_type: "Event type"
  fire_alarm_triggered_title: "Smoke alarm triggered"
  fire_and_co_alarm_automated_tests_footer: "Schedule your Minut A1 alarm to test itself every first Monday of the month at noon. If there’s a stay during the scheduled time, the test will be skipped automatically."
  fire_and_co_alarm_automated_tests_title: "Automated tests"
  fire_and_co_alarm_events_title: "Smoke & CO alarm events"
  fire_and_co_alarm_title: "Smoke & CO alarm"
  fire_and_co_automated_tests_off_short_description: "Automated testing is off. You can turn it on under Settings > Monitoring."
  fire_and_co_automated_tests_on_short_description: "Automated tests are scheduled for every first Monday of the month at noon. If there's a booking during that time, the test will be skipped automatically."
  fire_and_co_control_screen_footer: "You can't turn Smoke & CO alarm off. Press the button on the device to test it manually."
  fire_and_co_filter: "Smoke & CO alarm events"
  fire_and_co_short_description: "Get notified when there’s smoke or high CO levels in your property."
  first_heartbeat_body: "Minut will keep you updated on your property's environment. Right now it is {{0}} and the humidity is {{1}}."
  first_heartbeat_title: "First report just in!"
  first_morning_body: "Here's a summary of the temperature during your first time with Minut. Max at {{0}} and min {{1}}."
  first_morning_title: "Good morning!"
  first_name: "First name"
  flat_or_suite: "Flat or suite (optional)"
  floor: "Floor (optional)"
  forgot_password: "Forgot your password?"
  free: "Free"
  from_price: "from {{price}}"
  garage: "Garage"
  get_started: "Get started"
  glassbreak_body: "The sensor detected the sound of window glass breaking."
  glassbreak_title: "Window break detected"
  go_to_dashboard: "Go to dashboard"
  go_to_generic_text: "Go to {{name}}"
  go_to_homes: "Go to properties"
  go_to_presets: "Go to presets"
  go_to_settings: "Go to Settings"
  go_to_store: "Go to store"
  go_to_the_store: "Go to the store"
  groups_pending_invite: "Pending invite"
  groups_pending_invite_plural: "Pending invites"
  groups_settings: "Rental unit members"
  guard_assist_automatic_callout: "Automatic callout"
  guard_assist_description: "Minut integrates with security companies around the world to provide a complete solution for dealing with noise issues 24/7."
  guard_assist_enabled: "Guard assist enabled"
  guard_assist_title_description: "Connect Minut to a professional security company"
  guard_assist_turn_off_automatic_callout: "Turn off automatic callout"
  guard_assist_turn_on_automatic_callout: "Turn on automatic callout"
  guest: "Stay"
  guest_plural: "Stays"
  guest_check_in: "Start date"
  guest_check_out: "End date"
  guest_communication: "Messaging"
  guest_communication_add_short_code: "Add shortcode"
  guest_communication_create_new: "New message"
  guest_connect_autocall_message_header: "Call message"
  guest_connect_autocall_short_description: "Automatically call the people staying in your property when noise is above the threshold."
  guest_connect_autocall_title: "Autocall"
  guest_connect_sensor_reaction_body: "The Minut sensor will flash and play a sound when noise levels go above set thresholds."
  guest_connect_sensor_reaction_title: "Flash & sound on sensor"
  guest_connect_sms_guest_description: "Automatically send SMS to guests when they are too loud."
  guest_connect_sms_guest_message_header: "SMS message"
  guest_connect_sms_guest_title: "SMS"
  guest_connect_title: "Messaging"
  guest_phone_not_vaild: "It looks like the phone number is invalid. The person staying in your property might not receive automated texts or phone calls."
  guest_source: "Source"
  guest_terms_and_conditions: "I understand that I should inform the people staying in my property about the Minut device. I acknowledge that Minut may contact them on my behalf."
  guests_group_settings_description: "Add information about the people staying in your property so that Minut can automatically contact them on your behalf. You can configure communications in the Settings and Messaging tabs. You can also import stays by integrating Minut with your PMS. Details of the stay will be removed 14 days after its end date."
  hallway: "Hallway"
  home: "Rental unit"
  home_plural: "Rental units"
  home_access_single_home_description: "Access to a single rental unit"
  home_access_title: "Rental unit access"
  home_add_address: "Add address"
  home_address_wizard_auto_complete_label: "Unit address"
  home_address_wizard_auto_complete_title: "Where is your unit located?"
  home_address_wizard_home_address_responder_body: "Make sure the guard can access your unit"
  home_address_wizard_home_address_title: "Confirm unit address"
  home_address_wizard_map_description: "If needed, adjust the map"
  home_address_wizard_map_title: "Is the pin in the right place?"
  home_address_wizard_response_service_not_available_description: "This address is not currently supported by Guard Assist. Remove Guard Assist from this rental unit to change its address."
  home_address_wizard_response_service_not_available_title: "Guard Assist is currently not available in this area"
  home_alarm_offline_all_devices: "All sensors offline"
  home_change_address: "Change address"
  home_create_new_home: "Create a new rental unit"
  home_create_select_preset_description: "Monitoring presets contain pre-configured settings. This selection can be changed after the unit has been created."
  home_create_select_preset_title: "Select monitoring presets for this unit"
  home_details_give_feedback: "Give feedback"
  home_details_sensor_is_offline: "Sensor is offline"
  home_details_switch_to_original_version: "Switch to original version"
  home_emergency_contact_body: "The contact can call this number if they can’t resolve the issue on their own. (For example, when they can’t access the property or the people staying in the property don’t comply.)"
  home_emergency_contact_title: "Confirm emergency phone number"
  home_group_building: "Building"
  home_group_building_plural: "Buildings"
  home_group_building_create: "Create new building"
  home_group_building_description: "A building is usually a hotel, hostel, aparthotel, multi-family home, or an apartment building. It groups together units that share the same address.\n\nIf you're looking to group units within a community, neighborhood, city, country or region, use Locations. "
  home_group_building_goto: "Go to Buildings"
  home_group_buildings_homes_empty_state_action: "Add units to building"
  home_group_buildings_homes_empty_state_body: "Add units to this building to start monitoring it. Members in this building will get access to these units."
  home_group_buildings_homes_empty_state_title: "Start monitoring this building"
  home_group_location_create: "Create new location"
  home_group_location_description: "A location is usually a community, neighborhood, city, country or region.\n\nIf you're looking to group units within a building (such as a hotel or an apartment building), use Buildings. "
  home_group_location_goto: "Go to Locations"
  home_indoor_label: "Indoor only"
  home_invitation_alert_action_accept: "Join rental unit"
  home_leave_home: "Leave rental unit"
  home_name: "Unit name"
  home_remove_home: "Delete rental unit"
  home_remove_home_confirm_body: "All the data for this rental unit and its devices will be permanently deleted. This action cannot be reversed."
  home_remove_home_interrogation: "Delete rental unit?"
  home_security_alarm_on_body: "No motion detected"
  home_security_alarm_short_description: "Get notified when motion is detected and trigger the siren on your sensor."
  home_security_alarm_title: "Security alarm"
  home_sensor_more_data: "More sensor data"
  home_settings: "Unit settings"
  home_settings_missing_address_info: "Missing address information"
  home_settings_missing_location_info: "Missing location information"
  home_settings_set_location_title: "Unit location"
  home_settings_silent_alarm_description: "Get notified immediately when movement is detected without triggering the siren."
  home_short_codes_description: "These shortcodes define information specific to this rental unit. They can be inserted in messages to provide the people staying in your propety with details concerning their stay."
  home_token_convert_notice: "Your pre-paid units will be converted to the {{plan}} plan. You will not receive credits for the conversion."
  home_token_expiration_banner_body: "You have {{count}} pre-paid rental unit expiring soon. Be sure to use them before {{date}}."
  home_token_expiration_banner_body_plural: "You have {{count}} pre-paid rental units expiring soon. Be sure to use them before {{date}}."
  home_token_expiration_expandable_body: "Create your rental unit before {{date}}. Any unused pre-paid unit will be permanently deleted."
  home_token_expiration_expandable_body_plural: "Create your rental units before {{date}}. Any unused pre-paid units will be permanently deleted."
  home_token_expiration_expandable_title: "You have {{count}} pre-paid rental unit expiring soon."
  home_token_expiration_expandable_title_plural: "You have {{count}} pre-paid rental units expiring soon."
  home_unmonitored: "This rental unit is unmonitored"
  home_unmonitored_all_sensors_offline_description: "All your sensors are offline. Bring them back online to resume monitoring."
  home_unmonitored_no_alerts_title: "You will not receive alerts about:"
  homegroup_building_access_title: "Building access"
  homegroup_building_wizard_address_step_title: "Confirm building address"
  homegroup_building_wizard_name_step_title: "Create a building in {{name}}"
  homegroup_building_wizard_title: "Create building"
  homegroup_location_address: "Location address"
  homegroup_location_address_edit: "Edit Location address"
  homegroups_create_building_button_label: "Create building"
  homes_created: "rental unit created"
  homes_created_plural: "rental units created"
  homes_list_empty_state_body: "Add a rental unit and it will show up here."
  homes_list_empty_state_title: "No rental units added yet"
  homes_to_create: "rental unit to create"
  homes_to_create_plural: "rental units to create"
  hour: "Hour"
  hours: "Hours"
  humidity: "Humidity"
  humidity_dropped_normal_body: "Humidity is back within your threshold."
  humidity_high_body: "Humidity is {{0}}, which is above your threshold."
  humidity_high_title: "High humidity!"
  humidity_low_body: "Humidity is {{0}}, which is below your threshold."
  humidity_low_title: "Low humidity!"
  humidity_risen_normal_body: "Humidity is back within your threshold."
  if_you_proceed: "If you proceed:"
  including_taxes: "including {{taxes}} in taxes"
  indoor_climate: "Indoor climate"
  indoor_climate_all_notifications_off_error: "Both temperature and humidity monitoring are off."
  indoor_climate_dashboard_card_description: "Monitor temperature, humidity and mold risk to ensure a comfortable stay, prevent property damage and save energy."
  indoor_climate_freezing_alerts_body: "You will be notified when temperature is near freezing."
  indoor_climate_freezing_alerts_title: "Freezing alerts"
  indoor_climate_mold_alerts_body: "You will be notified when mold risk is detected."
  indoor_climate_mold_alerts_title: "Mold risk alerts"
  indoor_climate_report_body: "Get an overview of temperature and humidity of your properties over the past 6 months."
  indoor_climate_report_title: "Indoor climate monitoring report"
  indoor_climate_safety_alerts_body: "You will be notified when mold risk is detected, or the temperature is near freezing."
  indoor_climate_safety_alerts_title: "Safety alerts"
  indoor_climate_settings_body: "Pick what you would like to monitor. You can change your thresholds in device settings."
  indoor_climate_settings_title: "Indoor climate settings"
  indoor_noise: "Indoor noise"
  input_optional: "Optional"
  install_minut: "Install a device"
  instantly_turn_on_alarm_footer: "Consumes more battery. Instantly turn on the security alarm and view motion events without delay in the graphs."
  instantly_turn_on_alarm_off_hint: "'Instantly turn on alarm' is off — activating security alarm may take up to 1 hour. Turn on this feature from Settings."
  instantly_turn_on_alarm_title: "Instantly turn on alarm"
  integration_airbnb_accept_body: "Minut will collect booking information for linked rental units, including guest name and dates of stay. Minut uses this data to message guests on your behalf. Of course, we'll keep you in the loop too.\n\nMinut will also be automatically disclosed on your Airbnb listing, under “Noise decibel monitors” category.\n\nBy proceeding, you acknowledge that Minut may contact guests on your behalf and that you should inform your guests about the Minut device installed in your property."
  integration_airbnb_update_banner_body: "It includes performance and visual improvements as well as the ability to connect multiple Airbnb accounts."
  integration_airbnb_update_banner_header: "New integration update"
  integration_airbnb_update_button: "Update integration"
  integration_airbnb_update_dialog_body: "The update includes performance and visual improvements as well as the ability to connect multiple Airbnb accounts."
  integration_airbnb_update_dialog_header: "Update Airbnb integration"
  integration_dialog_accept_title: "Integrate Minut with {{name}}?"
  integration_entity_text: "Integration entity"
  integration_error_accounts_do_not_match: "Wrong {{name}} account used when reconnecting. Try again."
  integration_hvac_accept_body: "Minut will be able to access and control {{name}} devices."
  integration_hvac_devices_inactive_error_subtitle: "Some devices are inactive. For more information, check your {{integrationName}} app."
  integration_hvac_devices_inactive_error_title: "{{inactiveDevicesCount}} inactive device"
  integration_hvac_devices_inactive_error_title_plural: "{{inactiveDevicesCount}} inactive devices"
  integration_lock_accept_body: "Minut will collect access information for linked rental units, including the lock code, and share it with the people staying in your property."
  integration_no_devices: "No devices linked"
  integration_no_homes_linked: "No rental units linked"
  integration_no_homes_linked_action: "Tap + to link a unit"
  integration_number_of_inactive_devices: "{{count}} inactive device"
  integration_number_of_inactive_devices_plural: "{{count}} inactive devices"
  integration_pms_accept_body: "Minut will collect booking information for linked rental units, including guest name, phone number, and dates of stay. Minut uses this data to message guests on your behalf. Of course, we'll keep you in the loop too.\n\nBy proceeding, you acknowledge that Minut may contact guests on your behalf and that you should inform your guests about the Minut device installed in your property."
  integration_thermostat_device_count: "{{count}} thermostat"
  integration_thermostat_device_count_plural: "{{count}} thermostats"
  integrations_about_integration: "About integration"
  integrations_account_name: "Account name"
  integrations_add_integration_access_code_to_checkin_description: "You can now add the {{integrationName}} access code to instructions for the stay in the rental unit shortcodes section, or insert it directly in your messages."
  integrations_airbnb_disclose_body: "This integration will now automatically disclose Minut on the Airbnb listing under the “Noise decibel monitors” category."
  integrations_airbnb_disclose_title: "Automatically disclose Minut on your listing"
  integrations_broken_connection: "Broken connection"
  integrations_broken_link_alert_text: "Broken link"
  integrations_connect_description: "Begin by connecting this integration."
  integrations_connected: "Connected"
  integrations_connection_broken_alert_text: "There are issues with syncing data. Try reconnecting."
  integrations_connection_broken_alert_text_no_action: "This integration isn't syncing any data."
  integrations_connection_broken_banner_cta: "Reconnect integration"
  integrations_connection_broken_banner_details: "No data is synced for the {{email}} account. Make sure you’re logged into {{integration_name}} with {{email}}, then come back here to reconnect."
  integrations_connection_broken_banner_generic: "This integration isn’t syncing any data. Try reconnecting it."
  integrations_connection_broken_banner_title: "Broken connection"
  integrations_connection_error_invalid_credentials: "Your connection details (like an API key, secret, username or password) are incorrect or outdated. Verify them in {{name}}, then try again. If it still doesn't work, get new connection details from {{name}}."
  integrations_connection_error_properties_required_to_connect: "You have no rental units on {{name}}. Create a unit in {{name}}, then try again."
  integrations_connection_error_secret_already_used: "Your connection details (like an API key, secret, username or password) have already been used. Get new connection details from {{name}}"
  integrations_create_homes_to_get_started_link: "Create your first rental unit to get started."
  integrations_description: "Go above and beyond with our seamless integrations."
  integrations_dialog_agree_and_continue: "Agree and continue"
  integrations_did_not_see_integrations_question: "Don’t see the integration you’re looking for?"
  integrations_disconnect: "Disconnect integration"
  integrations_disconnect_account: "Disconnect account"
  integrations_disconnect_account_and_unlink_description: "This account will be disconnected, and {{count}} unit will be unlinked."
  integrations_disconnect_account_and_unlink_description_plural: "This account will be disconnected, and {{count}} units will be unlinked."
  integrations_disconnect_confirm_body: "This integration will be disconnected, and all units will be unlinked."
  integrations_disconnect_confirm_body_airbnb: "This integration will be disconnected, and all units will be unlinked. Your Airbnb listings will be updated to no longer disclose the use of noise decibel monitors."
  integrations_disconnect_confirm_title: "Disconnect {{integration}}?"
  integrations_external_connection_status_text: "This integration is connected through {{name}}. Visit their home monitoring page to see which units are linked and manage the integration."
  integrations_filter_by_name_label: "Filter by name"
  integrations_group_custom_description: "Connect Minut with your other apps and automate workflows."
  integrations_group_custom_title: "Custom automations"
  integrations_group_gxp_platforms_description: "Set up guest experiences using Minut events as triggers."
  integrations_group_gxp_platforms_title: "Guest experience platforms"
  integrations_group_hvac_description: "Automatically set your thermostat to save energy between stays."
  integrations_group_hvac_title: "Climate control"
  integrations_group_lock_description: "Get access code from your smart lock and automatically share it with the people staying in your property when their stay is about to start."
  integrations_group_lock_title: "Smart locks"
  integrations_group_ota_description: "Sync booking information and send the people staying in your property an automated message when noise is above the threshold."
  integrations_group_ota_title: "Online travel agencies"
  integrations_group_pms_description: "Cannot send SMS to this country. Add email address so that the person staying in this property can be contacted."
  integrations_group_pms_title: "Property management systems"
  integrations_home_link_relink_action: "Relink unit"
  integrations_home_links_broken_alert_text: "Some unit links are broken. Relink them to sync data again."
  integrations_homes_linked_text: "rental units linked"
  integrations_homes_not_linked_alert_text: "This integration isn't syncing any data yet. Link rental units to finish set-up."
  integrations_integration_home: "{{name}} unit"
  integrations_link_account: "Connect another account"
  integrations_link_create: "Link unit"
  integrations_link_created_successfully: "Link successfully created!"
  integrations_link_entities_title: "Link your Minut units"
  integrations_link_home: "Link unit"
  integrations_link_home_body: "Link a Minut rental unit with a corresponding unit on the integration partner's side."
  integrations_link_home_picker_empty_value: "Pick unit"
  integrations_link_home_picker_title: "{{integrationName}} unit"
  integrations_link_home_with_entity: "Linking <bold>{{home}}</bold> with <bold>{{entity}}</bold>"
  integrations_link_homes_to_import_data: "You need to link rental units to sync data."
  integrations_link_homes_to_sync_data_alert_text: "Link units between Minut and {{name}} to start syncing data."
  integrations_link_integration_for_home: "Link integration for {{name}}"
  integrations_link_remove: "Unlink unit"
  integrations_link_remove_confirm: "Are you sure you want to unlink the Minut unit {{minut_home}} from {{integration_entity}}?"
  integrations_link_remove_confirm_airbnb: "Are you sure you want to unlink the Minut unit {{minut_home}} from {{integration_entity}}? Your Airbnb listing will be updated to no longer disclose the use of a noise decibel monitor."
  integrations_link_remove_confirm_disclaimer: "Data will no longer be synced between the selected units."
  integrations_link_remove_confirm_disclaimer_airbnb_specific: "Your Airbnb listing will be updated to no longer disclose the use of a noise decibel monitor."
  integrations_link_remove_confirm_title: "Unlink rental unit?"
  integrations_linked_accounts_add_instructions: "To add a new account, first open {{name}} and log out. Then come back to this page and click “Connect another account.”"
  integrations_linked_accounts_description: "Managing multiple {{name}} accounts? Connect all of them to Minut to sync data for your entire portfolio."
  integrations_linked_accounts_title: "Connected accounts"
  integrations_linked_homes_empty_state: "You need to create at least one Minut rental unit to link to it."
  integrations_manage_external_integrations_text: "Manage through {{name}}"
  integrations_minut_api_connection_status_text: "To configure the Minut API integration, go to the developer settings page."
  integrations_multi_account_before_you_reconnect_alert: "Before you reconnect, make sure you’re logged into your {{name}} account with this email address."
  integrations_multi_account_connection_broken_alert: "No data is synced for the {{account}} account. Try reconnecting."
  integrations_multi_account_connection_broken_alert_no_action: "No data is synced for the {{account}} account."
  integrations_multi_account_reconnect_account_description: "Reconnect the account to restore data syncing for this integration. Units will relink automatically."
  integrations_multi_account_reconnect_account_title: "Reconnect account?"
  integrations_multiaccount_banner_link: "Add another one in Settings."
  integrations_multiaccount_banner_title: "Managing multiple {{name}} accounts?"
  integrations_not_connected: "Not connected"
  integrations_ota_accept_body: "Minut will collect booking information for linked rental units, including guest name, phone number, and dates of stay. Minut uses this data to message guests on your behalf. Of course, we'll keep you in the loop too.\n\nBy proceeding, you acknowledge that Minut may contact guests on your behalf and that you should inform your guests about the Minut device installed in your property."
  integrations_other_integrations_title: "Other integrations"
  integrations_reconnect_integration_description: "Reconnect the integration to restore syncing data. Links between rental units will be retained, so you won't need to redo them."
  integrations_reconnect_integration_title: "Reconnect integration?"
  integrations_select_entity_to_connect_to_home: "Select which {{entity}} unit you want to link to {{home}}"
  integrations_select_entity_to_connect_to_home_airbnb: "If you're a Co-Host, only some parts of the integration may work for you."
  integrations_thermostat_edit_thermostat_settings: "Edit thermostat settings"
  integrations_thermostat_mode_cool: "Cool"
  integrations_thermostat_mode_description: 'Choose which mode to set the thermostat to before the next stay starts. When the stay ends, the mode will be set to "Eco”.'
  integrations_thermostat_mode_details: 'When the stay ends, the thermostat mode is set to "Off". Pick which mode the thermostat should be set to before the next stay starts.'
  integrations_thermostat_mode_heat: "Heat"
  integrations_thermostat_mode_heatcool: "Heat/Cool"
  integrations_thermostat_mode_title: "Thermostat mode"
  integrations_thermostat_no_thermostats_linked: "Some of your rental units don’t have any thermostats linked. Add a device to your {{name}} unit to save energy between bookings."
  integrations_thermostat_precool_heat_description: "Pick when the thermostat mode and temperature should be changed, based on how fast the property heats up or cools down."
  integrations_thermostat_settings_dialog_description: "Select what mode and temperature to turn the thermostat to before the next stay begins. You can also pick when these changes should take place."
  integrations_thermostat_settings_dialog_title: "Thermostat settings during the stay"
  integrations_thermostat_settings_preheat_cool_time: "Pre-heat/Cool time"
  integrations_thermostat_some_devices_inactive: "Some devices are inactive. For more information, check your {{name}} app."
  integrations_thermostat_temperature_description: "Choose a comfortable temperature range for the stay. The thermostat will be set to these temperatures before the stay begins."
  integrations_time_before_stay_begins: "{{time}}h before the stay begins"
  integrations_title: "Integrations"
  integrations_turn_thermostat_to_title: "Turn thermostat to"
  integrations_turn_thermostat_to_tooltip: 'These thermostat settings will be applied before a new stay starts. You can edit them for each rental unit by clicking the "..." icon.'
  integrations_type_lock: "Lock"
  introduction_day_0_immediate_body: "Minut reports information about your home environment regularly. If something is amiss, you will get a notification and it will show up in this timeline. You can configure which notifications are relevant to you in threshold settings."
  introduction_day_0_immediate_title: "Keep track of your home"
  introduction_day_1_body: "We're stoked that you are on your way with Minut. This is only the beginning and we're working hard every day to enable you to get the most out of your Minut."
  introduction_day_1_title: "Thank you!"
  introduction_day_2_device_installed_body: "The sensor is up and running. Environment data keeps rolling in every hour and if something is out of the ordinary you will be the first to know."
  introduction_day_2_device_installed_title: "Hey, you're doing great!"
  introduction_day_2_no_device_body: "We can't see that you have installed a device yet. Find the in-app support in the main menu if you have any questions."
  introduction_day_2_no_device_title: "Uh oh! Where's your sensor?"
  introduction_day_7_body: "Here's what we've learned so far:"
  introduction_day_7_title: "A week already!"
  invalid_invitation_code: "The invitation code you entered is invalid"
  invalid_phone: "Phone number is invalid"
  invitation: "Invitation"
  invitation_plural: "Invitations"
  invitation_accepted_to_home_invitee_view_body: "You are now a member of {{0}}."
  invitation_accepted_to_home_invitee_view_title: "Invitation accepted"
  invitation_accepted_to_home_members_view_body: "{{0}} is now a member of your rental unit."
  invitation_accepted_to_home_members_view_title: "Invitation accepted"
  invitation_code: "Invitation code"
  invite_already_accepted: "Invitation has already been accepted"
  invoice: "Invoice"
  invoice_amount: "Amount"
  invoice_amount_due: "Amount due"
  invoice_empty_state_body: "Future payments and invoices will appear in this section."
  invoice_empty_state_title: "No invoices here yet!"
  invoice_is_overdue: "This invoice has overdue payments."
  invoice_next_retry: "Next retry"
  invoice_pay_now: "Pay now"
  invoice_payment_due: "Payment due"
  join_organization: "Join organization"
  join_organization_role_finance: "Finance"
  join_organization_role_maintenance: "Maintenance"
  join_organization_role_security: "Security"
  kitchen: "Kitchen"
  last_day: "Last day"
  last_month: "Last month"
  last_name: "Last name"
  last_updated: "Last updated"
  last_week: "Last week"
  last_year: "Last year"
  learn_more: "Learn more"
  learn_more_subscription_terms: "Learn more about our subscription terms."
  leave: "Leave"
  let_us_know: "Let us know"
  lets_get_started: "Let's get started!"
  link_homes: "Link units"
  listing: "Listing"
  living_room: "Living room"
  load_more: "Load more"
  loading: "Loading..."
  locations_empty_state_description: "Locations group together units that are within the same community, city or region. If you’re looking to group units within a building, use Buildings."
  locations_empty_state_title: "Organize your team and properties with locations"
  lock_access_code: "Access code"
  lock_access_code_description: "Access code for your property from your integrated smart locks or keyboxes."
  log: "Log"
  log_feedback_event_cigarette_detection: "Report your findings"
  log_feedback_event_reported_correctly: "Was this event reported correctly?"
  low_battery: "Low battery"
  low_battery_empty_state: "There are currently no devices with low battery."
  low_battery_in_prefix: "Low battery in"
  manage: "Manage"
  marijuana_detection: "Marijuana detection"
  marketing_consent: "Yes please, keep me updated with Minut news, events and offers."
  max_temperature: "Max temperature"
  member: "Member"
  member_arrived_home_title: "{{0}} came home"
  member_left_home_title: "{{0}} left home"
  members: "Members"
  message: "Message"
  messaging_description: "Automatically send a welcome message, Wi-Fi details, instructions (and more) to the people staying in your property. \nTo contact them when noise levels have been exceeded visit"
  min_temperature: "Min temperature"
  minut_home: "Minut unit"
  minut_response_service: "Guard Assist"
  monitoring: "Monitoring"
  month: "Month"
  monthly: "Monthly"
  motion: "Motion"
  motion_graph_unit: "motion event"
  motion_graph_unit_plural: "motion events"
  mounting_plate: "Mounting plate"
  mounting_plate_plural: "Mounting plates"
  mounting_plate_6_pack: "Mounting plates 6-pack"
  mounting_plate_order_email_on_shipping: "We will process and send your order shortly."
  mounting_plate_order_thanks: "Thanks for your order!"
  name: "Name"
  need_more_sensors_question: "Need more devices?"
  new_badge_title: "New"
  next: "Next"
  no: "No"
  no_guests_found: "No stays found"
  no_name_found_generic: "No {{name}} found"
  no_payment_method_instructions: "No payment method stored on your account. To proceed with the order, add a card in your payment settings."
  no_sensor: "No device"
  no_sensor_add_sensor_from_mobile_app: "No devices added yet. Start monitoring your property by adding a device from the Minut mobile app."
  noise_graph_incident_report_body: "Documenting noise levels? Download an incident report."
  noise_incident_report_body: "Document noise levels and communication for a claim or noise complaint."
  noise_incident_report_header: "Download noise incident report"
  noise_incident_report_title: "Noise incident report"
  noise_monitoring_presets_title: "Noise monitoring presets"
  noise_monitoring_short_description: "In general, a dinner party is around 60-70 dB, a party is 70-80 dB and a loud party is 80-90 dB. "
  noise_monitoring_summary_body: "Get an overview of noise data for your organization."
  noise_monitoring_summary_header: "Download noise monitoring summary"
  noise_monitoring_threshold_guide_body: "1. Play your favorite music on a speaker. Increase the volume until it reaches the level you consider noise.\n\n2. Let the music play at this level for a few minutes.\n\n3. Update the sensor by pushing its button.\n\n4. Check noise graphs for the decibel reading. Use it as your new threshold."
  noise_monitoring_threshold_guide_title: "How to find the right noise threshold for your property"
  noise_monitoring_unit_description: "Sound is measured in decibels (dB)."
  noise_report_body: "Get an overview of noise data for your organization for the last 30 days."
  noise_report_error_invalid_date_range: "Invalid date range. Maximum allowed is 6 months."
  noise_report_pro_body: "Get an overview of noise data for your organization."
  noise_report_title: "Noise monitoring summary"
  noise_thresholds: "Noise thresholds"
  noise_thresholds_moved_description: "Find them under Settings > Monitoring presets."
  noise_thresholds_moved_title: "Noise thresholds have moved "
  non_existing_user_invited_to_home_body: "Email sent to {{0}} with an invitation to join your rental unit. You will be notified when they accept it."
  non_existing_user_invited_to_home_title: "Invite sent"
  none: "None"
  not_enough_permissions: "Not enough permissions"
  not_enough_permissions_description: "You don't have enough permissions to perform this action."
  not_now: "Not now"
  not_set: "Not set"
  notification: "Notification"
  notification_plural: "Notifications"
  notifications: "Notifications"
  number_of_minutes: "{{number}} minute"
  number_of_minutes_plural: "{{number}} minutes"
  occupancy_scanned_device_limit_exceeded_body: "The number of mobile devices is above the crowd risk threshold."
  occupancy_scanned_device_limit_exceeded_title: "High crowd risk"
  occupancy_scanned_device_limit_subceeded_body: "The number of devices is back below the threshold."
  occupancy_scanned_device_limit_subceeded_title: "Low crowd risk"
  off: "Off"
  office: "Office"
  offline: "Offline"
  offline_just_now: "Offline just now"
  offline_sensors: "Offline sensors:"
  offline_since: "Offline since {{0}}"
  offline_since_days_ago: "Offline since {{0}} day ago"
  offline_since_days_ago_plural: "Offline since {{0}} days ago"
  offline_since_min_ago: "Offline since {{0}} min ago"
  ok: "OK"
  on: "On"
  on_hold: "On hold"
  onboarding_add_friends_title: "Add contacts"
  onboarding_connect_integrations_action: "Go to integrations"
  onboarding_connect_integrations_description: "Connect Minut with your PMS to save time, streamline operations and provide a better guest experience."
  onboarding_connect_integrations_title: "Connect integrations"
  onboarding_create_home_description: "Create and set up each of your rental units in Minut. This activates your subscription."
  onboarding_create_home_title: "Create your first rental unit"
  onboarding_install_device_description_web: "Install a device in the Minut mobile app to start protecting your property."
  onboarding_install_device_title: "Install a Minut device"
  onboarding_no_permission_to_add_devices: "You need to have admin access to add devices."
  onboarding_no_permission_to_add_homes: "You need to have admin access to add rental units."
  onboarding_no_permission_to_connect_integrations: "You need to have admin access to connect integrations."
  onboarding_organization_accept_invitation: "Accept invitation"
  onboarding_organization_name_error_text: "Organization name is required."
  onboarding_portfolios_body: "We will use this information to customize your Minut experience."
  onboarding_portfolios_error_text: "Please enter a value."
  onboarding_portfolios_title: "How many rental units does this organization manage?"
  onboarding_subtitle: "Here's how to get started:"
  onboarding_welcome: "Welcome to Minut!"
  ongoing_crowd_detect_events: "Crowd detect events"
  ongoing_crowd_detect_events_empty_state: "There are currently no crowd detect events in any of your properties."
  ongoing_fire_and_co_events_empty_state: "There are currently no Smoke & CO alarm events in any of your properties."
  ongoing_indoor_climate_events: "Indoor climate events"
  ongoing_indoor_climate_events_empty_state: "There are currently no indoor climate events in any of your properties."
  ongoing_noise_events: "Noise events"
  ongoing_noise_events_empty_state: "There are currently no noise events in any of your properties."
  ongoing_smoking_detection_events: "Smoking detection events"
  ongoing_smoking_detection_events_empty_state: "There are currently no smoking detection events in any of your properties."
  or: "or"
  order_summary: "Order summary"
  organization: "Organization"
  organization_access_title: "Organization access"
  organization_create: "Create organization"
  organization_create_home_price_summary_text: "Add a new unit at {{price}} to your bill. If you have credits on your account, they will be used up first."
  organization_create_home_using_tokens: "Using 1 of {{tokens}} pre-paid unit"
  organization_create_home_using_tokens_plural: "Using 1 of {{tokens}} pre-paid units"
  organization_has_no_homes: "This organization has no rental units."
  organization_homes_create_unprepaid_home_info: "You’re creating a new unit at {{price}}/month. You’ll be billed for the first year upfront."
  organization_homes_creating_unprepaid_home_text: "Creating a new rental unit"
  organization_invite_plural: "Invites"
  organization_invite_accepted: "Accepted"
  organization_join_success_title: "Welcome to {{0}}"
  organizations_access_all_homes_description: "All rental units in the organization"
  organizations_add_member: "Add member"
  organizations_add_member_description: "Send an invite to your team member. They will need to create an account to join your organization."
  organizations_admin_role_title: "Organization admin"
  organizations_all_organization_members_has_access_to_home_hint: "All members of {{0}} can access this rental unit."
  organizations_change_organization_name: "Change the name of your organization"
  organizations_copy_invite_link: "Copy invite link"
  organizations_create_action: "Create new organization"
  organizations_create_intro_action: "Set up organization"
  organizations_create_intro_usp_1_body: "Invite members to all or select rental units."
  organizations_create_intro_usp_1_title: "Add members"
  organizations_create_intro_usp_2_body: "Choose the right level of access for each member."
  organizations_create_intro_usp_2_title: "Set individual permissions"
  organizations_create_intro_usp_3_body: "Each member uses their own account and login information."
  organizations_create_intro_usp_3_title: "Secure your account"
  organizations_create_organization_to_manage_text: "Create organization to manage your properties and team."
  organizations_create_short_description: "Easily add members and manage more efficiently with organizations."
  organizations_created_successfully_body: "You have now successfully created {{name}}. Next, let's invite some of your members to your organization."
  organizations_delete_details_body: "This organization will be permanently deleted, including:"
  organizations_delete_details_title: 'Delete the organization "{{0}}"'
  organizations_delete_invitation_action: "Delete invitation"
  organizations_delete_invitation_confirmation_title: "Delete invitation?"
  organizations_delete_organization: "Delete organization"
  organizations_delete_organization_action: "Permanently delete organization"
  organizations_delete_organization_confirmation_message: "Please type the name of your organization to confirm. This action cannot be undone."
  organizations_delete_organization_confirmation_title: "Are you sure you want to permanently delete this organization?"
  organizations_delete_organization_description: "This action cannot be undone. This will permanently delete this organization workspace, including all device data, rental units and members."
  organizations_delete_organization_details_action: "Permanently delete organization"
  organizations_delete_team_confirm_title: "Are you sure?"
  organizations_get_invitations_question: "How can I get an invitation?"
  organizations_get_invitations_response: "Ask your organization owner or admin to add you as a member. The invitation will be sent to you in an email and also show up here."
  organizations_go_to_team: "Go to team"
  organizations_header: "Organizations"
  organizations_home_add_member_title: "Add rental unit member"
  organizations_home_admin_role_short_description: "Full access to this rental unit, including unit and device settings."
  organizations_home_group: "Location"
  organizations_home_group_plural: "Locations"
  organizations_home_group_add_stepper_title: "Add location"
  organizations_home_group_create_button_label: "Create location"
  organizations_home_group_create_stepper_button_label: "Create"
  organizations_home_group_homes_empty_state_action: "Add units"
  organizations_home_group_homes_empty_state_body: "Members in this location will have access to these units."
  organizations_home_group_homes_empty_state_title: "Add units to location"
  organizations_home_group_member_add_confirm_title: "Add location member"
  organizations_home_groups_add_home_tooltip_text: "Remove this rental unit from its location to add it here"
  organizations_home_groups_create_title: "Give this location a name"
  organizations_home_groups_empty_state_body: "Group your properties by location to control which rental units your team members have access to."
  organizations_home_groups_empty_state_title: "Organize your team and rental units with locations"
  organizations_home_groups_remove_confirm_body: "This will permanently remove {{homegroup}}"
  organizations_home_invitation_create_title: "Select rental unit role"
  organizations_home_invitation_share_description: "Send this invitation link to your team member. They will need to create an account to join your rental unit."
  organizations_home_member_remove_body: "You will no longer have access to this rental unit."
  organizations_home_member_remove_confirm_body: "This will permanently remove {{user}} from this rental unit"
  organizations_home_member_role_short_description: "Manage noise and security features, view data and install devices."
  organizations_home_member_select_title: "Select members to add to rental unit"
  organizations_home_members_title: "Rental unit members"
  organizations_home_responder_role_short_description: "Can only receive noise and security alarms."
  organizations_home_role_title: "Rental unit role"
  organizations_homegroup_access_all_homes_description: "Access to all rental units in a location"
  organizations_homegroup_access_title: "Location access"
  organizations_homegroup_add_existing_member: "Add existing member"
  organizations_homegroup_add_homes_already_added: "Already in a location"
  organizations_homegroup_add_homes_title: "Select rental units to add to {{name}}"
  organizations_homegroup_add_member_via_email: "Add via email"
  organizations_homegroup_admin_role_short_description: "Manage all rental units and members in this location"
  organizations_homegroup_admin_role_title: "Location admin"
  organizations_homegroup_leave: "Leave location"
  organizations_homegroup_member_role_short_description: "Manage noise and security features, view data and install devices"
  organizations_homegroup_member_role_title: "Location member"
  organizations_homegroup_remove: "Remove from location"
  organizations_homegroup_responder_role_short_description: "Can only receive noise and security alerts"
  organizations_homegroup_responder_role_title: "Location contact"
  organizations_homegroup_role_title: "Location role"
  organizations_homegroups_add_home_to_homegroup: "Add to location"
  organizations_homegroups_add_member_description: "Location members can receive notifications and manage all the rental units in this location."
  organizations_homegroups_delete_member_confirm_title: "Remove from location"
  organizations_homegroups_empty_state_body: "Added members will show up here."
  organizations_homegroups_empty_state_title: "Add members to start using locations"
  organizations_homegroups_member_delete_confirm_body: "{{member}} will no longer have access to the rental units in this location."
  organizations_homegroups_member_select_title: "Select members to add to location"
  organizations_homegroups_self_remove_confirm_body: "You will no longer have access to the rental units in this location."
  organizations_invitation_active: "An invitation for {{email}} is already active"
  organizations_invitation_already_accepted_error: "Invitation already accepted."
  organizations_invitation_email_sent_notification: "Invite sent to {{email}}"
  organizations_invitation_link_copied_info: "Link copied"
  organizations_invitation_link_title: "Invitation link"
  organizations_invitation_not_found_error: "Couldn't find invitation. Make sure you're logged in with the correct email address and that the invitation didn't expire."
  organizations_invitation_share_title: "Invitation created"
  organizations_invitation_view_action: "View invitations"
  organizations_leave_team_body: "You will no longer have access to this organization."
  organizations_leave_team_confirm_button_label: "Leave organization"
  organizations_leave_team_confirm_title: "Are you sure?"
  organizations_member_role_title: "Organization member"
  organizations_name_title: "Name"
  organizations_name_your_organization: "What’s the name of your organization?"
  organizations_needs_to_be_home_member_to_share_location: "You have to be added as a home member before you can share home/away status in this home."
  organizations_no_current_invitations: "You currently don't have any invitations."
  organizations_only_editable_by_owner: "Only editable by owner"
  organizations_only_organization_admins_can_add_members: "Only organization admins can add new members"
  organizations_organization_admin_role_short_description: "Manage team, all properties and all features."
  organizations_organization_created: "Organization created"
  organizations_organization_exists_question: "Is your organization already on Minut?"
  organizations_organization_invitation_create_title: "Select organization role"
  organizations_organization_invitation_share_description: "Send this invitation link to your team member. They will need to create an account to join your organization."
  organizations_organization_invite_required_link_text: "You need an invite to join it."
  organizations_organization_member_role_short_description: "View all properties, add devices, turn features on and off."
  organizations_organization_name: "Organization name"
  organizations_organization_name_onboarding_details: "Choose something that your team will recognize."
  organizations_organization_name_onboarding_placeholder: "Ex. {{0}} rentals, Sunny homes"
  organizations_organization_name_onboarding_title: "What is the name of your organization?"
  organizations_organization_responder_role_short_description: "Receive alerts from all properties."
  organizations_organization_role_title: "Role"
  organizations_organization_settings: "Organization settings"
  organizations_permissions_read_more_action: "Read more about permissions"
  organizations_read_about_permissions: "Read more about permissions"
  organizations_remove_home_member_confirmation_title: "Do you want to remove this member?"
  organizations_remove_member_action: "Remove member"
  organizations_remove_organization_member_confirmation_title: "Do you want to remove this member?"
  organizations_responder_role_title: "Organization contact"
  organizations_role_home_admin: "Rental unit admin"
  organizations_role_home_member: "Rental unit member"
  organizations_role_home_responder: "Rental unit contact"
  organizations_role_minimum: "Limited organization access"
  organizations_role_minimum_short_description: "Only access to their rental unit or location."
  organizations_role_team_admin: "Organization admin"
  organizations_role_team_member: "Organization member"
  organizations_role_team_responder: "Organization contact"
  organizations_select_the_invitation_to_accept_hint: "Select the organization you want to join."
  organizations_send_invite: "Send invite"
  organizations_switch_organization_body: "Switch to organization {{name}}?"
  organizations_switch_organization_title: "Switch organization"
  organizations_team_delete_homegroup_error_text: "Cannot delete location with members"
  organizations_team_join: "Login to join this organization"
  organizations_team_join_success_body: "You have now successfully joined {{team}}."
  organizations_team_join_success_button_label: "Go to organization"
  organizations_team_join_success_title: "Organization joined!"
  organizations_team_member_remove_confirmation_body: "This will permanently remove {{user}} from this organization."
  organizations_team_settings_profile_title: "Settings for {{name}}"
  organizations_view_all_organizations: "View all organizations"
  outdoor_noise: "Outdoor noise"
  overview: "Overview"
  owner: "Owner"
  partial_outage_devices_offline_description: "Bring this sensor back online by making sure it’s turned on, charged and connected to Wi-Fi."
  partial_outage_devices_offline_description_plural: "Bring these sensors back online by making sure they’re turned on, charged and connected to Wi-Fi."
  partial_outage_low_battery_devices_description: "Charge this sensor to keep monitoring without interruption."
  partial_outage_low_battery_devices_description_plural: "Charge these sensors to keep monitoring without interruption."
  partial_outage_title_low_battery_devices: "{{0}} sensor needs charging"
  partial_outage_title_low_battery_devices_plural: "{{0}} sensors need charging"
  partial_outage_title_offline_devices: "{{0}} area isn’t monitored"
  partial_outage_title_offline_devices_plural: "{{0}} areas aren’t monitored"
  password: "Password"
  password_too_long: "Password length must be shorter than 50"
  password_too_short: "Password length must be at least 8"
  password_too_weak: "Password too weak"
  paste_the_invite_code: "Paste the invite code"
  payment_error_banner_body: "Your plan failed to renew. Update your payment information to avoid disruption."
  payment_error_banner_button: "Update details"
  payment_error_banner_title: "Payment failed"
  payment_error_body: "Payment failed. Update card details and try again."
  payment_failed: "Payment failed"
  payment_failed_help_funds: "Make sure you have sufficient funds on your credit card."
  payment_method: "Payment method"
  payment_method_add: "Add payment method"
  payment_method_is_expired: "Your card has expired. Remove this card and add a new one to make sure the service can continue to run."
  payment_method_is_expiring: "Your card is about to expire. Add a new card to make sure the service continue to run after this billing period."
  payment_method_is_invalid: "Your card is invalid. Add a new card to make sure the service continue\n        to run after this billing period."
  payment_method_remove: "Remove card"
  payment_method_remove_confirm: "Are you sure you want to remove the card ending in {{last4}}?"
  payments: "Payments"
  peak_sound_high_body: "Sound level is above the custom threshold."
  peak_sound_high_title: "High sound levels!"
  pending_invitations: "Pending invitation"
  pending_invitations_plural: "Pending invitations"
  permanent_change: "The change will be permanent."
  permissions_admin: "Admin"
  permissions_home_owner: "Rental unit owner"
  permissions_member: "Member"
  permissions_responder_description: "Contacts are automatically notified of noise and security alarms if no team member is available to respond. Contacts do not get access to device data."
  permissions_role: "Role"
  permissions_team_members_description: "Team members can control the alarm and get event notifcations. Admins can modify settings."
  phone: "Phone number"
  place_order: "Place order"
  placeholder_web: "{{text}}"
  plan: "Plan"
  plan_renewal_breakdown_addon_note: "Add-on charges and credits are excluded from the details."
  plan_renewal_estimate_breakdown_amount: "Amount"
  plan_renewal_estimate_breakdown_annual_price: "Annual price"
  plan_renewal_estimate_breakdown_back_breadcrumb: "Plan, Billing & Payments"
  plan_renewal_estimate_breakdown_breadcrumb: "Details of the upcoming plan renewal"
  plan_renewal_estimate_breakdown_days: "Days"
  plan_renewal_estimate_breakdown_description: "The estimate will update if changes are made before the renewal."
  plan_renewal_estimate_breakdown_link: "Open details"
  plan_renewal_estimate_breakdown_location: "Location"
  plan_renewal_estimate_breakdown_no_location: "Not assigned to a location"
  plan_renewal_estimate_breakdown_note: "Note"
  plan_renewal_estimate_breakdown_period: "Period"
  plan_renewal_estimate_breakdown_title: "Details of your upcoming plan renewal on {{date}}"
  plan_renewal_estimate_breakdown_total: "Total"
  plan_renewal_estimate_description: "Estimated renewal amount"
  plan_renewal_estimate_description_tooltip: "This estimated amount may change if any credits are applied or if any changes are made to the account before the renewal."
  postcode: "Postcode"
  power_saving_mode_on_in: "On in: {{0}}"
  prepaid: "pre-paid"
  prepaid_homes_text: "Pre-paid rental units"
  preset_applied_preset: "Applied preset"
  preset_indoor_climate_description: "Edit your humidity and temperature thresholds."
  preset_monitoring_presets: "Monitoring presets"
  preset_monitoring_presets_description_rental_unit: "Monitoring presets contain pre-configured settings and can be applied to a subset of rental units."
  preset_open_preset: "Open preset"
  preset_settings_for: "Settings for"
  presets_add_rental_unit_to_preset: "Add rental units to preset"
  presets_already_in_preset: "Already in the preset"
  presets_applies_to: "Applies to"
  presets_apply_to_rental_units: "Settings apply to {{number}} rental unit."
  presets_apply_to_rental_units_plural: "Settings apply to {{number}} rental units."
  presets_bulk_add_all_units: "Add all units ({{number}})"
  presets_bulk_add_all_units_matching_search: "Add all units matching search ({{number}})"
  presets_bulk_add_title: 'Select rental units to add to the "{{presetName}}" preset'
  presets_bulk_confirm_description: "All of the {{number}} selected units will start using the settings in the {{name}} preset. You can’t undo this action, but you can always change the preset the units use."
  presets_bulk_confirm_title: 'Are you sure you want to add these units to the "{{name}}" preset?'
  presets_create_new_preset: "Create a new preset"
  presets_create_new_preset_action: "Create"
  presets_create_new_preset_based_on: "Based on"
  presets_create_new_preset_description: "Settings will be copied from an existing preset. You will be able to change them afterwards."
  presets_create_new_preset_name: "Preset name"
  presets_minutes_shorthand: "min"
  presets_no_homes_empty_state_button_add: "Add rental units to preset"
  presets_no_homes_empty_state_description: "Done tailoring your noise thresholds and messaging? Start using this preset by adding rental units."
  presets_no_homes_empty_state_title: "Start using this preset"
  presets_no_rental_units_using: "No rental units are using this preset"
  presets_noise_description: "Tweak your thresholds, quiet hours and noise alerts."
  presets_noise_duration_description: "Select how long the noise should last before it triggers an alert."
  presets_noise_duration_title: "Noise duration"
  presets_noise_monitoring_title: "Noise monitoring"
  presets_search_for_unit_name: "Search for unit name"
  presets_seconds_shorthand: "sec"
  presets_security_alarm_settings_body: "Set up your security alarm to trigger within a specified grace period."
  presets_security_alarm_settings_title: "Security alarm settings"
  presets_thresholds_and_duration_description: "Select when you want to be alerted to noise."
  presets_thresholds_and_duration_title: "Thresholds and duration"
  privacy_policy: "Privacy Policy"
  pro: "Pro"
  pro_plus: "Pro+"
  profile_short_description: "Monitoring profiles contain configurations for multiple settings, such as noise monitoring and security alarm."
  profile_title: "Monitoring profile"
  profiles_add_short_description: "Create a new monitoring profile to apply different settings to a subset of rental units."
  profiles_add_title: "Add monitoring profile"
  profiles_cant_remove_homes_exists_body: "You still have rental units with this profile. Assign a different profile to these units before removing."
  profiles_cant_remove_homes_exists_title: "Can't remove monitoring profile"
  profiles_cant_turn_on_autocall_body: "Autocall and Call Assist cannot be enabled for the same alert. To turn on Autocall, first toggle off Call Assist."
  profiles_cant_turn_on_autocall_title: "Can’t turn on Autocall"
  profiles_cant_turn_on_call_assist_body: "Autocall and Call Assist cannot be enabled for the same alert. To turn on Call Assist, first toggle off Autocall."
  profiles_cant_turn_on_call_assist_title: "Can’t turn on Call Assist"
  profiles_create_based_on_title: "Based on"
  profiles_create_description: "Use the new monitoring profile to apply different settings to a subset of rental units."
  profiles_create_header: "Create a new monitoring profile"
  profiles_edit_homes_affected: "Changes here will affect {{count}} rental unit."
  profiles_edit_homes_affected_plural: "Changes here will affect {{count}} rental units."
  profiles_human_caller_body: "Let a trained expert call your guests when noise issues are detected."
  profiles_human_caller_title: "Call Assist"
  profiles_noise_monitoring_sms_guest_message_description: "Changes to this message will be applied to all profiles."
  profiles_noise_monitoring_timeline_first_warning_footer: "Select what you want to happen after noise has been above threshold for the set duration."
  profiles_noise_monitoring_timeline_first_warning_header: "First alert"
  profiles_noise_monitoring_timeline_second_warning_header: "Second alert"
  profiles_noise_monitoring_timeline_sencond_third_fourth_warning_header: "Above threshold for the set duration + {{0}} minutes"
  profiles_noise_monitoring_timeline_sencond_third_warning_footer: "Select what you want to happen {{0}} minutes after noise has been above threshold for the set duration."
  profiles_noise_monitoring_timeline_third_warning_header: "Third alert"
  profiles_notify_responders: "Notify contacts"
  profiles_notify_team: "Notify members"
  profiles_single_profile_footer: "These settings affect all your rental units."
  properties: "Properties"
  prorated_price_per_home: "Prorated price/rental unit"
  quiet_hours: "Quiet hours"
  quiet_hours_description: "Select how loud the noise needs to be to trigger an alert during your quiet hours."
  reactivate_plan: "Re-activate plan"
  reactivate_plan_banner_description: "You can delete the units you no longer manage and reactive the rest."
  reactivate_plan_banner_title: "Want to reactivate your plan with fewer rental units?"
  reactivate_plan_checkbox_error_message: "You need to accept terms and conditions to sign up to a plan and reactivate your rental units."
  reactivate_plan_checkbox_label: "I understand that reactivating 1 rental unit and deleting it within the first year will result in losing any unused time (which can’t be credited or transferred to another unit)."
  reactivate_plan_checkbox_label_plural: "I understand that reactivating {{homesCount}} rental units and deleting them within the first year will result in losing any unused time (which can’t be credited or transferred to another unit)."
  reactivate_plan_message: "Your plan is set to be canceled. You need to remove the scheduled cancellation before you can make changes to your plan."
  reactivate_plan_title: "Reactivate your plan"
  read_more: "Read more"
  receive_credits_for_unused_time: "You will only receive credits for unused time past the first year from rental unit creation. Unused time from the first pre-paid year will be lost."
  referral_pending_subscription_activation: "Pending subscription activation"
  referral_program_about_faq_link_label: "See FAQs for more."
  referral_program_about_get_description: "Once your referral successfully activates their Minut plan, you'll get a $15 reward, up to $1,000 a year*!"
  referral_program_about_get_note: "*Reward currency is displayed in USD. For rewards outside of the US, the equivalent amount will be issued. Rewards are powered by a third-party provider with options from Paypal, gift cards, and more, available in 200+ regions."
  referral_program_about_get_title: "Get $15 for every referral"
  referral_program_about_gift_description: "Your friends get two months free on their first order when they checkout using your link."
  referral_program_about_gift_title: "Gift your friends 2 months free"
  referral_program_about_share_description: "Get your unique referral link, and start sharing."
  referral_program_about_share_title: "Share your link"
  referral_program_about_title: "How it works?"
  referral_program_description: "You earn $15 for every new customer you refer and your friend gets two months free. "
  referral_program_history_title: "Your referrals and rewards"
  referral_program_how_does_it_work: "How does it work"
  referral_program_referrals: "Referrals"
  referral_program_referrals_completed: "Completed"
  referral_program_referrals_history_title: "Referral history"
  referral_program_referrals_history_tooltip: "Your referrals will remain pending until their Minut plan is successfully activated. "
  referral_program_referrals_pending: "Pending"
  referral_program_rewards: "Rewards"
  referral_program_rewards_history_title: "Reward history"
  referral_program_rewards_history_tooltip: "Your rewards will remain on hold until your referral's Minut subscription has been active for 30 days"
  referral_program_share_email: "Share with email"
  referral_program_share_email_body: "Have you heard about Minut? It’s the property operations platform that helps you prevent excessive noise, parties, smoking, and more. It has given me peace of mind and I think you could benefit from it, too! Use the link below to get two months off the first year of your subscription\n\n{{link}}"
  referral_program_share_email_subject: "{{name}} has invited you to try Minut"
  referral_program_share_facebook: "Post to Facebook"
  referral_program_share_link: "Share link"
  referral_program_share_linkedin: "Share with LinkedIn"
  referral_program_share_messenger: "Share with Messenger"
  referral_program_share_whatsapp: "Share with Whatsapp"
  referral_program_share_x: "Post to X"
  referral_program_share_x_text: "Rent out your property with confidence. Use my link to try @MinutHQ and get two months off the first year of your subscription:\n"
  referral_program_subtitle: "Invite friends and family to try Minut and earn up to $1,000 a year"
  referral_program_terms_conditions: "Terms and conditions"
  referral_program_terms_conditions_default_compliance: "By sharing the code you agree to the Refer a Friend program"
  referral_program_title: "Refer a friend"
  referrals_empty_state_description: "Make sharing a win-win. Earn $15 for every new customer you refer. Plus, they’ll get 2 months free too!"
  referrals_empty_state_title: "Share your link to get started"
  remove: "Remove"
  remove_home_member_confirm: "Are you sure you want to remove this member?"
  remove_organization_member_confirm: "Are you sure you want to remove this member?"
  renew: "Renew"
  rental_unit_reactivate_dialog_description: 'To access your rental units, you need to reactive your account. Want to reactivate with fewer units? Select the units you want to delete by ticking the checkbox, then press "Delete" above the table.'
  rental_unit_reactivate_dialog_title: "Reactivate your account"
  rental_units_empty_state_description: "Start monitoring your properties for noise, occupancy and smoking. Reduce maintenance and upkeep to improve turnover times."
  rental_units_empty_state_title: "Set up your first property"
  report_plural: "Reports"
  report_findings: "Report findings"
  report_it_here: "Report it here."
  reports_error_home_not_in_correct_state: "In order to generate the report, an address for this rental unit is needed. Add the address, then try again."
  reports_feedback_body: "Helps us make reports better by sharing your feedback."
  reports_feedback_title: "What do you think about reports?"
  reports_subtitle: "Analyze monitoring data for your organization."
  reports_title: "Reports"
  responders_title: "Contacts"
  response_partners_outside_coverage_area: "Outside coverage area"
  response_service: "Guard Assist"
  response_service_add_button: "Set up your first rental unit"
  response_service_body: "Send a responder to your property when there's persistent noise."
  response_service_by_provider: "by {{provider}}"
  response_service_description: "Our partner network of leading security companies allows you to send out certified guards when noise persists in your property."
  response_service_disable_callouts: "Temporarily disable callouts by turning off noise monitoring."
  response_service_dispatch_arrived_body: "They’re working on resolving the problem. We will let you know once they complete the callout."
  response_service_dispatch_arrived_push_notification: "Responder arrived and is working on resolving the problem."
  response_service_dispatch_arrived_title: "Responder arrived"
  response_service_dispatch_cancelled_by_minut_while_in_progress_body: "{{0}} took too long to send a responder. You won’t be charged for this callout."
  response_service_dispatch_cancelled_by_minut_while_in_progress_title: "Callout failed"
  response_service_dispatch_cancelled_by_minut_while_requested_body: "{{0}} couldn’t find a responder. You won’t be charged for this callout."
  response_service_dispatch_cancelled_by_minut_while_requested_title: "Callout failed"
  response_service_dispatch_cancelled_by_provider_body: "{{0}} canceled this callout. You won’t be charged."
  response_service_dispatch_cancelled_by_provider_title: "Callout failed"
  response_service_dispatch_cancelled_by_user_body: "Callout canceled by you or your team member."
  response_service_dispatch_cancelled_by_user_title: "Callout canceled"
  response_service_dispatch_completed_body: " {{0}}  will send you an email with the report."
  response_service_dispatch_completed_title: "Callout complete"
  response_service_dispatch_in_progress_body: "We will let you know when they arrive."
  response_service_dispatch_in_progress_title: "Guard on the way"
  response_service_dispatch_requested_automatically_body: "It's been noisy for {{0}} minutes. {{1}} is initiating the callout."
  response_service_dispatch_requested_automatically_title: "Callout initiated"
  response_service_dispatch_requested_manually_body: "We received your callout request. {{0}} is initiating the callout."
  response_service_dispatch_requested_manually_title: "Callout requested"
  response_service_empty_state_available_box_link: "Currently only available in the UK. Interested in another region?"
  response_service_empty_state_available_box_title: "Add a rental unit to activate Guard Assist"
  response_service_empty_state_body: "Our partner network of leading security companies allows you to send out certified guards when noise persists in your property."
  response_service_empty_state_title: "In-person response when there's a loud party in your property"
  response_service_empty_state_unavailable_box_link: "Register your interest and we'll let you know when we launch in your region."
  response_service_empty_state_unavailable_box_title: "Looks like Guard Assist isn't available in your area yet."
  response_service_failed_unsupported_currency: "This currency is currently not supported. Please contact our Support team at support@minut.com and we will help you add Guard Assist."
  response_service_home_note_description: "Provide detailed access instructions to the guard like the intercom code, key box location, name on the door, etc."
  response_service_home_note_description_placeholder: 'e.g.: "Get the key from the key box located next to the front door. The code is 1111" or "Call [phone number] if you can\'t access the property."'
  response_service_home_note_title: "Add instructions to guards"
  response_service_home_select_error: "Selected rental unit is in an area where Guard Assist isn't available."
  response_service_missing_phone_number_text: "Guards will call this phone number if they need to contact you."
  response_service_missing_phone_number_title: "The organization owner needs to fill in their phone number before you can activate Guard Assist."
  response_service_no_home_info: "Add a rental unit to see if Guard Assist is available in your region"
  response_service_not_available_banner: "Do you have properties in the UK? Update the rental unit's address to activate Guard Assist"
  response_service_plan_end: "Plan ends on {{date}}"
  response_service_register_interest: "Register your interest"
  response_service_responder_body_1: "Persistent noise is detected in your property."
  response_service_responder_body_2: "Our security partner sends a certified guard to your property to make contact with the guest(s)."
  response_service_responder_body_3: "When the callout is complete, you receive a report."
  response_service_responder_list_1: "Configure your properties to use Guard Assist."
  response_service_responder_list_2: "Select one of Minut’s partners to provide the in-person response"
  response_service_responder_list_3: "When noise persists, a guard from your selected partner is sent to your property to resolve the situation."
  response_service_responder_title: "How does Guard Assist work?"
  response_service_status_no_sensors: "No sensor installed"
  response_service_status_noise_monitoring_off: "Noise monitoring off"
  response_service_subscription_cancel: "If you confirm and end your plan now, you can still access it until the end of your plans term."
  response_service_subscription_renew: "The Guard Assist plan for your rental unit will auto-renew at the end of your term."
  response_service_try_for_free_asterisk: "*The Guard Assist plan is free during the trial, guard callout charges still apply."
  response_service_wizard_add_phone_number_description: "Guards will call this phone number in case a situation arises in which they need to contact you. (For example, when they can't access the property.)"
  response_service_wizard_add_phone_number_title: "Add your phone number"
  response_service_wizard_complete_app_version_notice: "Please make sure you have Minut app version 4.5.6 (iOS, Android) or above to fully use Guard Assist"
  response_service_wizard_complete_description: "Here is what will happen when Minut detects noise:"
  response_service_wizard_complete_info_description: "You can also automatically contact guests via SMS and/or Autocall and ask them to keep the noise down."
  response_service_wizard_complete_info_title: "You will receive notifications about noise in the mobile app"
  response_service_wizard_complete_noise_description: "The incident report contains detailed information on the callout."
  response_service_wizard_complete_noise_title: "A report will be sent to your email"
  response_service_wizard_complete_responder_description: "A guard will be automatically sent to your property if noise isn't resolved 20 minutes after the first alert. You can choose to cancel the callout or send guards to the property earlier."
  response_service_wizard_complete_responder_title: "Our Response Partner will send a guard if the noise isn't resolved"
  response_service_wizard_complete_title: "Guard Assist has been activated for this rental unit!"
  response_service_wizard_enable_asterisk: "*Your billing period will be aligned with your existing Minut plan. For example, if you have 6 months left on your plan, your first Guard Assist charge will be for those 6 months."
  response_service_wizard_enable_callout: "callout"
  response_service_wizard_enable_callout_text: "This fee will be added to your bill if a responder is sent to your property."
  response_service_wizard_enable_extra_home: " +1 rental unit with Guard Assist"
  response_service_wizard_enable_free_trial: "Free trial until {{date}}"
  response_service_wizard_enable_free_trial_description: "The Guard Assist plan is free during the trial, callout charges still apply."
  response_service_wizard_enable_responder_title: "Responder callout"
  response_service_wizard_enable_title: "You are ready to activate Guard Assist for this rental unit"
  response_service_wizard_enable_trial_asterisk: "**Callouts will be instantly charged."
  response_service_wizard_enable_when_trial_ends: "{{price}} when trial ends*"
  response_service_wizard_enable_wrong_location: "The area you have selected is not supported by Guard Assist. Change your address for this rental unit and try again."
  response_service_wizard_home_select_dropdown: "Select rental unit"
  response_service_wizard_home_select_title: "Which rental unit would you like to add Guard Assist to?"
  review_invites: "Review invites"
  review_payment_method: "Review payment method"
  risk_of_mold: "Mold risk"
  risk_of_mold_analyzing: "Analyzing"
  risk_of_mold_graph_body_analyzing: "It takes one week for Minut to analyze humidity and temperature levels in order to determine the mold risk level."
  risk_of_mold_graph_body_high: "Temperature and humidity measured over time in this room indicate a high risk for mold growth."
  risk_of_mold_graph_body_medium: "Temperature and humidity measured over time in this room indicate an elevated risk for mold growth."
  risk_of_mold_high: "High"
  risk_of_mold_low: "Low"
  risk_of_mold_medium: "Elevated"
  risk_of_mold_unknown: "Unknown"
  risk_of_mould_action_0: "Read more"
  risk_of_mould_body: "Temperature and humidity were favorable for mold growth last week."
  risk_of_mould_details: "What happened?\nThe combination of temperature and humidity reached the zone favorable for mold growth.\n\nWhat does it mean?\nIf temperature and relative humidity remains in the zone favorable for mold for 4-8 weeks or more the risk of growth increases further. Mold may cause bad smells and has negative health implications.\n\nWhat can I do?\nBad ventilation, the number of people, and activity in a room all affect relative humidity. Depending on where you live, different solutions may be applicable. You can increase the temperature to lower the relative humidity, improve ventilation or install a dehumidifier."
  risk_of_mould_title: "Mold risk detected"
  room_too_warm_action_0: "Read more"
  room_too_warm_body: "The sensor has detected that the room is very warm."
  room_too_warm_title: "Room may be too warm"
  save: "Save"
  search: "Search"
  search_did_not_match_anything: "{{search}} did not match anything"
  search_for_home: "Search for rental unit"
  search_for_integration: "Search for integration"
  search_no_matches_found: "No matches found"
  search_nothing_found: "No search results found"
  second_heartbeat_sound_summary_body: "Minut helps you track noise levels. Check the 'Devices' tab to see what it looks like."
  second_heartbeat_sound_summary_title: "Keep track of the noise activity"
  seconds: "Seconds"
  security_alarm_grace_period_title: "Grace period"
  security_alarm_timeline_first_warning_header: "When security alarm triggers"
  security_alarm_timeline_second_warning_footer: "This will happen 45 seconds after the security alarm has triggered if no team member opens the app."
  security_alarm_timeline_second_warning_header: "45 seconds after security alarm triggered"
  security_alarm_timeline_trigger_footer: "The security alarm will trigger after the grace period has expired."
  security_alarm_timeline_trigger_header: "Triggers on"
  see_all_homes_text: "See all rental units"
  see_breakdown: "See breakdown"
  send_feedback: "Send feedback"
  sensor: "Device"
  sensor_plural: "Devices"
  sensor_charging: "Charging"
  sensor_data_charging_placeholder: "Off while charging"
  sensor_fully_charged: "Fully charged"
  sensor_status_offline: "Offline"
  sensor_status_online: "Online"
  sensor_swap: "Sensor swap"
  sensor_swap_description: "Enable swapping a sensor that's low on battery with a newly-charged one without losing any data."
  sensor_swap_failed_body: "If you’d like to retry swapping, follow these steps:\n1. Check if the old sensor is connected to the internet by pressing the button. It will light up green if it’s online.\n2. Turn the old sensor off and back on.\n3. Perform factory reset on the new sensor.\n4. Attach the old sensor to the mounting plate and restart Sensor swap.\n\nIf you require further assistance, contact us at hello@minut.com."
  sensor_swap_failed_title: "Sensor swap failed"
  sensor_swap_succeeded_body: "The new sensor will now monitor this rental unit."
  sensor_swap_succeeded_title: "Sensor swapped"
  sensors_list_empty_state_body: "Add a device from the Minut mobile app and it will show here."
  sensors_list_empty_state_title: "No devices added yet"
  sensors_offline: "Devices offline"
  sensors_offline_empty_state: "There are currently no devices offline."
  sensors_online: "Devices online"
  sent: "Sent"
  set_location: "Set location"
  set_up: "Set up"
  settings: "Settings"
  settings_applying_changes: "Applying changes to {{amount}} devices"
  settings_auto_detect: "Auto-detect"
  settings_billing: "Plan, Billing & Payments"
  settings_error_updating_device: "Error updating device {{name}}"
  settings_general: "General"
  settings_language: "Language"
  settings_language_region: "Language and region"
  settings_noise_alert_first_trigger: "First alert is only triggered when the noise goes on for your set duration of time."
  settings_noise_alerts_autocall_description: "Place an automated phone call to the people staying in your property."
  settings_noise_alerts_autocall_message: "Autocall message"
  settings_noise_alerts_autocall_title: "Autocall"
  settings_noise_alerts_call_assist_learn_more: "Learn more about Call Assist."
  settings_noise_alerts_call_assist_solve: "No more on-call duty to solve noise issues."
  settings_noise_alerts_call_assist_trained_expert: "Have a trained expert call your guests"
  settings_noise_alerts_description: "Select how to contact the people staying in your property when noise is detected. You and your team will be notified automatically."
  settings_noise_alerts_edit_duration: "Edit duration in device settings."
  settings_noise_alerts_first_alert_description: "Select how to contact the people staying in your property when noise is detected. You and your team will be notified automatically."
  settings_noise_alerts_first_alert_title: "First alert"
  settings_noise_alerts_flash_and_sound_description: "The sensor will flash and play a sound."
  settings_noise_alerts_flash_and_sound_title: "Flash & sound"
  settings_noise_alerts_second_alert_description: "Select how to contact the people staying in your property 10 minutes after the first alert. You and your team will be notified automatically."
  settings_noise_alerts_second_alert_title: "Second alert"
  settings_noise_alerts_sms_description: "Automatically send an SMS to the people staying in your property."
  settings_noise_alerts_sms_message: "SMS message"
  settings_noise_alerts_sms_title: "SMS"
  settings_noise_alerts_third_alert_description: "Select how to contact the people staying in your property 20 minutes after the first alert. You and your team will be notified automatically."
  settings_noise_alerts_third_alert_title: "Third alert"
  settings_noise_alerts_title: "Noise alerts"
  settings_time_format: "Time format"
  settings_time_format_12_hour: "12-hour"
  settings_time_format_24_hour: "24-hour"
  share_feedback: "Share feedback."
  shipping: "Shipping"
  shipping_address: "Shipping address"
  shipping_method: "Shipping method"
  shipping_policy: "Shipping policy"
  short_button_press_title: "Button on the sensor pressed!"
  shortcodes: "Shortcodes"
  shortcodes_tip_body: "Add unique information such as access code for this rental unit (lock integration required) with shortcodes."
  shortcodes_tip_title: "Tip: Using shortcodes"
  "Sign up": "Sign up"
  sign_in: "Sign in"
  sign_in_failed: "Failed to sign in"
  sign_in_meta_description: "Sign in to the minut web application to monitor and configure your Minut devices. Log in or create a new account to get started."
  sign_in_meta_title: "Login"
  sign_in_no_account: "Don't have a Minut account?"
  sign_in_sign_up_link: "Sign up"
  sign_out: "Sign out"
  sign_up_meta_description: "Sign up to the minut web application to monitor and configure all your Minut devices. Create a new account to get started."
  sign_up_meta_title: "Sign up"
  sign_up_redirect_to_sign_in: "Already have an account?"
  signal: "Signal"
  signal_excellent: "Excellent"
  signal_fair: "Fair"
  signal_good: "Good"
  signal_none: "No signal"
  signal_okay: "Okay"
  signal_weak: "Weak"
  silent_alarm_title: "Silent alarm"
  skip_for_now: "Skip for now"
  smoke_detector_test_reminder_title: "Detect your other alarms"
  smoking_detection_alert_title: "Elevated risk of smoking in your property"
  smoking_detection_calibrating_body: "This will take around two weeks. We’ll let you know when it’s complete."
  smoking_detection_calibrating_title: "Calibrating the device"
  smoking_detection_calibration_done_body: "You will get alerted when an elevated risk of smoking is detected."
  smoking_detection_calibration_done_title: "Calibration complete"
  smoking_detection_cigarette_smoke_detected: "Elevated risk of smoking in your property"
  smoking_detection_cigarette_smoke_info: "Check for signs of smoking, then report your findings."
  smoking_detection_disclaimer: "This feature uses AI to detect tobacco and marijuana smoke. It will keep getting better over time as it learns from real-world data.\n\nIt does not detect smoke from a fire, so make sure to have a smoke alarm installed for fire detection."
  smoking_detection_feedback_comment_hint: "If you know what caused this alert, comment here (e.g. tobacco, marijuana, etc.)"
  smoking_detection_feedback_detection_correct: "Got an alert? Let us know if it was correct by clicking on it in the log."
  smoking_detection_feedback_detection_missed_alert: "There was smoking, but no alert?"
  smoking_detection_feedback_title: "Help us improve by sharing your feedback"
  smoking_detection_short_description: "Get notified when we detect smoking in your property."
  smoking_detection_smoking_detected_body: "Elevated risk of smoking detected. Check for signs of smoking in the room where this device is located."
  smoking_detection_smoking_detected_details: "This feature uses AI to detect tobacco and marijuana smoke. It will keep getting better over time as it learns from your real-world data. Help us make sure it’s accurate by sharing your feedback."
  smoking_detection_smoking_detected_title: "Risk of smoking"
  smoking_detection_title: "Smoking detection"
  sms_restriction_email_backup_text: "Email is used as a backup for people from {{0}}, due to SMS regulations"
  sound: "Sound"
  sound_level_dropped_normal_body: "Sound level is back down to normal."
  sound_noise_monitoring: "Noise monitoring"
  standard: "Standard"
  start_time: "Start time"
  state: "state"
  status: "Status"
  stay_importer_browse: "browse"
  stay_importer_date_format_dialog_subtitle: "The stays importer supports the following date and time formats."
  stay_importer_date_format_dialog_title: "Supported date and time formats"
  stay_importer_drag_instruction: "Drag a CSV file here or"
  stay_importer_error_file_not_readable: "There was an issue reading the file. Make sure it hasn't been moved or changed, and try again."
  stay_importer_error_file_too_large: "Your file exceeds the maximum limit of {{max}} stays. Review your CSV and try again."
  stay_importer_error_generic_content: "Make sure the uploaded file is a CSV and follows the format specified under 'File Requirements'."
  stay_importer_error_generic_title: "Couldn't process the file."
  stay_importer_error_incorrectly_formatted: "Incorrectly formatted. Make sure the file follows the format specified under 'File Requirements'."
  stay_importer_error_line: "Line {{line}} - {{error}}"
  stay_importer_error_multiple_files_content: "Select one file and try again. Keep in mind that uploading a new file will replace previously-imported stays."
  stay_importer_error_multiple_files_title: "Multiple files detected"
  stay_importer_file_requirements_subtitle: "Only CSV files are accepted. Special characters, spaces and extra columns are ignored."
  stay_importer_file_requirements_title: "File requirements"
  stay_importer_format_email: "The email address of the person staying. This information will be used for automated messaging."
  stay_importer_format_end_date: "The date and time when the stay ends. If no time is specified, it will default to 10:00 AM."
  stay_importer_format_first_name_md: "The name of the person staying in the property. \\\n\\* This is required if the file does not have **Full name** column."
  stay_importer_format_full_name_md: "The name of the person staying in the property. \\\n\\* This is required if the file does not have **First name** and **Last name** columns."
  stay_importer_format_last_name_md: "The name of the person staying in the property. \\\n\\* This is required if the file does not have **Full name** column."
  stay_importer_format_phone_dialog_content_md: 'A phone number in full international format includes a plus sign (+)  followed by the country code, city code, and local phone number.\n\nFor example, if the person staying in the property has a US phone number (country code "1") with the area code "408" and phone number "XXX-XXXX", you\'d enter +1408 XXX XXXX.'
  stay_importer_format_phone_dialog_title: "Supported phone number format"
  stay_importer_format_phone_number: "The phone number of the person staying. This information will be used for automated messaging. Make sure to follow the international format."
  stay_importer_format_rental_unit_name: "The name of the rental unit. Make sure it matched one of the rental unit names on your account."
  stay_importer_format_start_date: "The date and time when the stay begins. If no time is specified, it will default to 3:00 PM."
  stay_importer_header_required: "Required"
  stay_importer_import_button: "Import file"
  stay_importer_import_success: "Successfully imported {{count}} stays."
  stay_importer_import_success_content: "Information has been added to all rental units with upcoming stays."
  stay_importer_import_success_link: "View properties"
  stay_importer_instructions: "Add upcoming stays by uploading a CSV file below. Keep in mind that uploading a new file will replace previously-imported stays."
  stay_importer_instructions_title: "Import stays"
  stay_importer_partial_fail_title: "{{failed}} of {{total}} stays failed to import. Review your CSV file and try again."
  stay_importer_ready_for_import: "{{fileName}} ready for import."
  stay_importer_subtitle: "Easily manage your stays by uploading a CSV file."
  stay_importer_supported_columns: "Supported columns"
  stay_importer_supported_columns_alternatives: "Alternative headers"
  stay_importer_supported_columns_description: "Description"
  stay_importer_supported_columns_header: "Header"
  stay_importer_title: "Stay importer"
  store: "Store"
  stored_billing_address: "Stored billing address"
  submit: "Submit"
  subscription_account_balance_title: "Your account balance"
  subscription_assist_activation_blocker_description: "Contact our team for more information on how to activate it on your properties."
  subscription_available_prepaid_homes_description: "Automatically used when you create a new rental unit."
  subscription_cancelled_banner_action: "Reactivate account"
  subscription_cancelled_banner_action_secondary: "Manage properties"
  subscription_cancelled_banner_body_member: "This organization is no longer active. Please ask the organization owner to reactivate the account."
  subscription_cancelled_banner_body_owner: "Reactivate your account to resume monitoring your properties."
  subscription_cancelled_banner_body_owner_reactivation: "Reactivate your account to monitor your properties. If you are managing fewer rental units, you can delete the units you no longer need and reactivate the rest."
  subscription_cancelled_banner_title: "Welcome back!"
  subscription_change_plan: "Change plan"
  subscription_credit_balance: "Credit balance"
  subscription_credit_balance_description: "Credits are automatically applied on your upcoming invoices, and reduces the total cost of the invoice."
  subscription_description_more_info: "For a detailed list of all included features and to see our other plans, visit our pricing page."
  subscription_dialog_positive_action: "Upgrade plan"
  subscription_free: "Free"
  subscription_has_scheduled_changes: "Your plan is scheduled to be changed at next renewal. If you have any questions regarding this, contact support at hello@minut.com."
  subscription_is_future: "Your plan has not been activated yet. It will be activated when you add your first rental unit."
  subscription_is_non_renewing: "Your plan is scheduled to be cancelled on {{date}}. Any additional rental units, team members and data history will be lost."
  subscription_is_not_active: "Your plan has not been activated yet."
  subscription_manage_subscription: "Manage plan"
  subscription_no_plan_title: "Sign up to a plan"
  subscription_pro_description: "Manage your properties efficiently with powerful integrations."
  subscription_pro_feature_list: "- All of Standard\n- Integrations: Airbnb, PMS & more.\n- Zapier automation.\n- Advanced reports."
  subscription_pro_legacy_description: "You are on an earlier version of the Pro plan. You will be upgraded to the new Minut Pro on your next renewal date."
  subscription_pro_legacy_description_monthly: "You are on an earlier version of the Pro plan."
  subscription_pro_plus_feature_list: "- All of Pro\n- Smart thermostat integrations.\n- Smart lock integrations."
  subscription_remove_scheduled_cancellation: "Remove scheduled cancellation"
  subscription_remove_scheduled_cancellation_body: "Would you like to undo the cancellation and continue your existing plan?"
  subscription_remove_scheduled_changes: "Remove scheduled changes"
  subscription_renewal: "Plan renews on"
  subscription_sign_up_failed_token_mismatch: "You have pre-paid units on your account that were purchased on a different plan. To reactivate your subscription, contact us at hello@minut.com."
  subscription_standard_description: "Protect your properties with our robust monitoring and automation solutions."
  subscription_standard_feature_list: "- Noise, occupancy and environment monitoring, security alarm & more.\n- Automated messaging.\n- Basic reports."
  subscription_standard_legacy_description: "You are on an earlier version of the Standard plan. You will be upgraded to the new Minut Standard on your next renewal date. Upgrade to the new Standard plan to use automated messaging (SMS, Autocall and Flash and sound)."
  subscription_standard_legacy_description_monthly: "You are on an earlier version of the Standard plan. Upgrade to the new Standard plan to use automated messaging (SMS, Autocall and Flash and sound)."
  subscription_starter_description: "No matter how many properties you own or manage, we have a plan to fit your needs."
  subscription_store_blocker_already_purchased_info: "Log in using the email you used in the store. If someone else bought the plan, ask them to invite you to their organization"
  subscription_store_blocker_already_purchased_title: "Already bought a plan but it's not appearing here?"
  subscription_store_blocker_info: "Protect your properties with our robust monitoring and automation solutions. Get Minut devices and plans from our store."
  subscription_store_blocker_title: "Start monitoring your properties with Minut"
  subscription_terms: "Subscription terms"
  subscription_total_cost: "Total"
  subscription_unbilled_charges: "Unbilled charges"
  subscription_unbilled_charges_description: "Fees for additional rental units added in the middle of your billing period are added to your next bill. Charges are billed the day after they are incurred."
  subscription_upgrade_blocker_body: "Upgrade your plan for full access to our monitoring and automation solutions."
  subscription_upgrade_blocker_title: "Upgrade your plan to use this feature"
  subscription_upgrade_blocker_title_non_owner: "Contact your organization owner to upgrade this plan."
  subscription_violations_alarm_warning: "Update your plan - the security alarm can not be used."
  subscription_violations_noise_warning: "Update your plan - noise monitoring can not be used."
  summary: "Summary"
  sunset_starter_banner_action: "Upgrade now"
  sunset_starter_banner_body: "Starting January 1, 2024 you need to be on a paid plan to continue using Minut. Upgrade your plan before December 31 to enjoy a {{discount}} discount on your first year with the Minut Standard or Pro plan, effective from the date of your upgrade."
  sunset_starter_banner_title: "Upgrade your plan with {{discount}} discount to keep using Minut"
  support: "Support"
  switch: "Switch"
  tamper_attached: "Attached to mounting plate"
  tamper_body: "The sensor was removed from or put back on its mounting plate."
  tamper_mounted_body: "The sensor was attached to its mounting plate."
  tamper_mounted_title: "Sensor attached"
  tamper_removed: "Removed from mounting plate"
  tamper_removed_body: "The sensor was removed from its mounting plate."
  tamper_removed_title: "Sensor removed"
  tamper_title: "Sensor moved"
  tamper_unknown: "Unknown if attached to mounting plate"
  tamper_unsupported: "Mounting plate status unsupported"
  team: "Team"
  team_add_member: "Add Team member"
  team_contacts_home_note_description: "Provide detailed access instructions to team contacts like the intercom code, key box location, name on the door, etc."
  team_contacts_home_note_title: "Update instructions to your contacts"
  team_members: "Team member"
  team_members_plural: "Team members"
  temperature: "Temperature"
  temperature_dropped_normal_body: "Temperature is back within your threshold."
  temperature_high_body: "Temperature is {{0}}, which is above your threshold."
  temperature_high_title: "High temperature"
  temperature_low_body: "Temperature is {{0}}, which is below your threshold."
  temperature_low_title: "Low temperature"
  temperature_rapid_rise_body: "The temperature is rising at a rate of {{1}} per minute."
  temperature_rapid_rise_title: "Rapid temperature rise!"
  temperature_risen_normal_body: "Temperature is back within your threshold."
  terms_of_service: "Terms of Service"
  thermostat_settings_heat_and_cool_temperature_description: "Choose a comfortable temperature for the stay. The thermostat will be set to this temperature before the stay begins."
  thermostat_settings_temperature_delta: "Make sure there are at least {{delta}}°{{unit}} between the min and max temperature."
  time: "Time"
  todos: "To-dos"
  total_includes_vat: "Total includes VAT"
  transactions_are_secure_encrypted: "All transactions are secure and encrypted"
  transfer_legacy_plan_description: "We are now phasing out legacy plans. On your upcoming renewal date, your account will be automatically transitioned to the new {{name}} plan. For any inquiries, please contact support on hello@minut.com."
  trigger: "Trigger"
  try_reconnecting: "Click here to reconnect."
  turn_off: "Turn off"
  turn_off_alarm_reminder: "Welcome! Remember to turn off the alarm."
  turn_on: "Turn on"
  understand_irreversible_action: "I understand that this action is irreversible."
  undo_action: "Undo"
  unit: "unit"
  unit_plural: "units"
  unknown: "Unknown"
  unlink: "Unlink"
  unmonitored: "Unmonitored"
  unmonitored_homes: "Unmonitored rental unit"
  unmonitored_homes_plural: "Unmonitored rental units"
  update: "Update"
  update_and_continue: "Update and continue"
  update_your_plan: "Update your plan"
  updated_at: "Updated at {{0}}"
  updated_days_ago: "Updated {{0}} day ago"
  updated_days_ago_plural: "Updated {{0}} days ago"
  updated_just_now: "Updated just now"
  updated_min_ago: "Updated {{0}} min ago"
  upgrade_cost_dialog_body: "Upgrade costs are calculated based on the difference in price between your new and old plans. For rental units that are already activated, you only pay for the days left until plan renewal."
  upgrade_cost_dialog_title: "How do you calculate upgrade costs?"
  use_different_card_instructions: "Want to use a different card? You need to update payment settings on your account"
  use_invitation_code: "Join an existing rental unit"
  use_stored_payment_method: "Use stored payment method"
  user_arrived_home_title: "You are home"
  user_invited_another_user_to_home_body: "{{0}} will be added as a member of your rental unit when the invitation is accepted."
  user_invited_another_user_to_home_title: "Team member invited"
  user_left_home_title: "You left home"
  user_received_invitation_to_other_home_action_0: "Join {{0}}"
  user_received_invitation_to_other_home_body: "{{0}} wants you to join {{1}}."
  user_received_invitation_to_other_home_title: "Invitation to join a rental unit"
  value: "Value"
  water_leak_detected_card_description: "A potential water leak has been detected in your rental unit. Please check the source of the leak and take necessary actions."
  water_leak_detected_card_title: "Water leak detected"
  water_leak_detection_title: "Water leak detected"
  welcome: "Be a good neighbor, \nwherever you are"
  welcome_aboard: "Welcome aboard!"
  when_is_night_time: "When are quiet hours?"
  wifi_reconfigured_body: "Wi-Fi configuration on the device is updated."
  wine_cellar: "Wine cellar"
  wired_network: "Wired"
  wont_receive_credits_for_unused_time: "You will not receive credits for unused time. If you create a new rental unit, you will be charged again for the full year."
  year: "Year"
  yearly: "Annually"
  yes: "Yes"
  yesterday: "Yesterday"
  your_plan: "Your plan"
}

export type LangArguments = {
  activate_call_assist_about_body_2: {
    "0": Date | string | number | null | undefined
  }
  activate_call_assist_added_montly_cost: {
    "0": Date | string | number | null | undefined
  }
  activate_call_assist_body: {
    "0": Date | string | number | null | undefined
  }
  activate_call_assist_for_homes_count: {
    "0": Date | string | number | null | undefined
  }
  activating_call_assist_body: {
    yearlyCost: Date | string | number | null | undefined
  }
  add_stay_dialog_visit_source: {
    sourceName: Date | string | number | null | undefined
  }
  adding_new_home_token_info: {
    tokens: Date | string | number | null | undefined
  }
  adding_new_home_token_info_plural: {
    tokens: Date | string | number | null | undefined
  }
  addon_will_automatically_renew_on_date: {
    addonName: Date | string | number | null | undefined
    billingPeriodEndDate: Date | string | number | null | undefined
  }
  alarm_silenced_body: {
    "0": Date | string | number | null | undefined
  }
  alarm_test_failed_title: {
    testedAt: Date | string | number | null | undefined
  }
  alarm_test_success_title: {
    testedAt: Date | string | number | null | undefined
  }
  almost_freezing_body: {
    "0": Date | string | number | null | undefined
  }
  billing_event_plan_renewal_title: {
    date: Date | string | number | null | undefined
  }
  cancel_addon_body: {
    addon: Date | string | number | null | undefined
    date: Date | string | number | null | undefined
  }
  cancel_addon_title: {
    addon: Date | string | number | null | undefined
  }
  cancel_plan_body: {
    date: Date | string | number | null | undefined
  }
  card_ending_in: {
    digits: Date | string | number | null | undefined
  }
  change_plan_annual_savings: {
    savings: Date | string | number | null | undefined
  }
  change_plan_cancel: {
    date: Date | string | number | null | undefined
  }
  change_plan_charged_with_card: {
    amount: Date | string | number | null | undefined
    last4: Date | string | number | null | undefined
  }
  change_plan_charged_with_credits: {
    amount: Date | string | number | null | undefined
  }
  change_plan_credit_adjustment_body: {
    value: Date | string | number | null | undefined
  }
  change_plan_no_charge_credits: {
    amount: Date | string | number | null | undefined
  }
  change_plan_subscribe_pre_paid_homes_description: {
    quantity: Date | string | number | null | undefined
  }
  change_plan_time_of_change: {
    date: Date | string | number | null | undefined
  }
  change_plan_to: {
    plan_name: Date | string | number | null | undefined
  }
  create_home_confirm_step_billing_body_2: {
    price: Date | string | number | null | undefined
    billingPeriod: Date | string | number | null | undefined
  }
  create_home_confirmation_terms: {
    price: Date | string | number | null | undefined
  }
  create_home_information_step_title: {
    organization: Date | string | number | null | undefined
  }
  crowd_detect_active_emergency_title: {
    "0": Date | string | number | null | undefined
  }
  crowd_detect_active_normal_title: {
    "0": Date | string | number | null | undefined
  }
  crowd_detect_current_number_of_devices_at: {
    "0": Date | string | number | null | undefined
  }
  delete_generic_question: {
    name: Date | string | number | null | undefined
  }
  delete_multiple_rental_units_plural: {
    count: Date | string | number | null | undefined
  }
  device_detail_graph_multiple_motion_events: {
    "0": Date | string | number | null | undefined
  }
  device_offline_body: {
    "0": Date | string | number | null | undefined
  }
  device_offline_eureka_body: {
    time: Date | string | number | null | undefined
  }
  device_online_body: {
    "0": Date | string | number | null | undefined
  }
  device_online_eureka_body: {
    hours: Date | string | number | null | undefined
  }
  device_settings_above: {
    "0": Date | string | number | null | undefined
  }
  device_settings_below: {
    "0": Date | string | number | null | undefined
  }
  device_settings_threshold_switch_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_dispatch_manually_dialog_message: {
    "0": Date | string | number | null | undefined
  }
  disturbance_dispatch_manually_dialog_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_dispatched_automatically_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
  disturbance_dispatched_manually_body: {
    "0": Date | string | number | null | undefined
  }
  disturbance_ended_body: {
    "0": Date | string | number | null | undefined
  }
  disturbance_first_notice_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
    "3": Date | string | number | null | undefined
  }
  disturbance_first_notice_response_service_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
    "3": Date | string | number | null | undefined
  }
  disturbance_first_notice_response_service_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_first_notice_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_level_3_automatically_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
  disturbance_level_3_automatically_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_level_3_manually_body: {
    "0": Date | string | number | null | undefined
  }
  disturbance_level_4_automatically_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_level_4_manually_body: {
    "0": Date | string | number | null | undefined
  }
  disturbance_second_notice_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
    "3": Date | string | number | null | undefined
  }
  disturbance_second_notice_response_service_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
    "3": Date | string | number | null | undefined
  }
  disturbance_second_notice_response_service_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_second_notice_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_third_notice_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
  }
  disturbance_third_notice_response_service_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
    "2": Date | string | number | null | undefined
    "3": Date | string | number | null | undefined
  }
  disturbance_third_notice_title: {
    "0": Date | string | number | null | undefined
  }
  disturbance_warning_state_title: {
    "0": Date | string | number | null | undefined
  }
  download_csv_homes_content: {
    nbrHomes: Date | string | number | null | undefined
  }
  dunning_banner_pre_suspended_title_member: {
    daysUntilSuspension: Date | string | number | null | undefined
  }
  dunning_banner_pre_suspended_title_owner: {
    daysUntilSuspension: Date | string | number | null | undefined
  }
  dunning_banner_suspended_body_member: {
    willBeCancelledAtDate: Date | string | number | null | undefined
  }
  dunning_banner_suspended_body_owner: {
    willBeCancelledAtDate: Date | string | number | null | undefined
  }
  dunning_billing_alert_suspended: {
    willBeCancelledAtDate: Date | string | number | null | undefined
  }
  first_heartbeat_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
  first_morning_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
  from_price: {
    price: Date | string | number | null | undefined
  }
  go_to_generic_text: {
    name: Date | string | number | null | undefined
  }
  home_token_convert_notice: {
    plan: Date | string | number | null | undefined
  }
  home_token_expiration_banner_body: {
    count: Date | string | number | null | undefined
    date: Date | string | number | null | undefined
  }
  home_token_expiration_banner_body_plural: {
    count: Date | string | number | null | undefined
    date: Date | string | number | null | undefined
  }
  home_token_expiration_expandable_body: {
    date: Date | string | number | null | undefined
  }
  home_token_expiration_expandable_body_plural: {
    date: Date | string | number | null | undefined
  }
  home_token_expiration_expandable_title: {
    count: Date | string | number | null | undefined
  }
  home_token_expiration_expandable_title_plural: {
    count: Date | string | number | null | undefined
  }
  homegroup_building_wizard_name_step_title: {
    name: Date | string | number | null | undefined
  }
  humidity_high_body: {
    "0": Date | string | number | null | undefined
  }
  humidity_low_body: {
    "0": Date | string | number | null | undefined
  }
  including_taxes: {
    taxes: Date | string | number | null | undefined
  }
  integration_dialog_accept_title: {
    name: Date | string | number | null | undefined
  }
  integration_error_accounts_do_not_match: {
    name: Date | string | number | null | undefined
  }
  integration_hvac_accept_body: {
    name: Date | string | number | null | undefined
  }
  integration_hvac_devices_inactive_error_subtitle: {
    integrationName: Date | string | number | null | undefined
  }
  integration_hvac_devices_inactive_error_title: {
    inactiveDevicesCount: Date | string | number | null | undefined
  }
  integration_hvac_devices_inactive_error_title_plural: {
    inactiveDevicesCount: Date | string | number | null | undefined
  }
  integration_number_of_inactive_devices: {
    count: Date | string | number | null | undefined
  }
  integration_number_of_inactive_devices_plural: {
    count: Date | string | number | null | undefined
  }
  integration_thermostat_device_count: {
    count: Date | string | number | null | undefined
  }
  integration_thermostat_device_count_plural: {
    count: Date | string | number | null | undefined
  }
  integrations_add_integration_access_code_to_checkin_description: {
    integrationName: Date | string | number | null | undefined
  }
  integrations_connection_broken_banner_details: {
    email: Date | string | number | null | undefined
    integration_name: Date | string | number | null | undefined
  }
  integrations_connection_error_invalid_credentials: {
    name: Date | string | number | null | undefined
  }
  integrations_connection_error_properties_required_to_connect: {
    name: Date | string | number | null | undefined
  }
  integrations_connection_error_secret_already_used: {
    name: Date | string | number | null | undefined
  }
  integrations_disconnect_account_and_unlink_description: {
    count: Date | string | number | null | undefined
  }
  integrations_disconnect_account_and_unlink_description_plural: {
    count: Date | string | number | null | undefined
  }
  integrations_disconnect_confirm_title: {
    integration: Date | string | number | null | undefined
  }
  integrations_external_connection_status_text: {
    name: Date | string | number | null | undefined
  }
  integrations_integration_home: {
    name: Date | string | number | null | undefined
  }
  integrations_link_home_picker_title: {
    integrationName: Date | string | number | null | undefined
  }
  integrations_link_home_with_entity: {
    home: Date | string | number | null | undefined
    entity: Date | string | number | null | undefined
  }
  integrations_link_homes_to_sync_data_alert_text: {
    name: Date | string | number | null | undefined
  }
  integrations_link_integration_for_home: {
    name: Date | string | number | null | undefined
  }
  integrations_link_remove_confirm: {
    minut_home: Date | string | number | null | undefined
    integration_entity: Date | string | number | null | undefined
  }
  integrations_link_remove_confirm_airbnb: {
    minut_home: Date | string | number | null | undefined
    integration_entity: Date | string | number | null | undefined
  }
  integrations_linked_accounts_add_instructions: {
    name: Date | string | number | null | undefined
  }
  integrations_linked_accounts_description: {
    name: Date | string | number | null | undefined
  }
  integrations_manage_external_integrations_text: {
    name: Date | string | number | null | undefined
  }
  integrations_multi_account_before_you_reconnect_alert: {
    name: Date | string | number | null | undefined
  }
  integrations_multi_account_connection_broken_alert: {
    account: Date | string | number | null | undefined
  }
  integrations_multi_account_connection_broken_alert_no_action: {
    account: Date | string | number | null | undefined
  }
  integrations_multiaccount_banner_title: {
    name: Date | string | number | null | undefined
  }
  integrations_select_entity_to_connect_to_home: {
    entity: Date | string | number | null | undefined
    home: Date | string | number | null | undefined
  }
  integrations_thermostat_no_thermostats_linked: {
    name: Date | string | number | null | undefined
  }
  integrations_thermostat_some_devices_inactive: {
    name: Date | string | number | null | undefined
  }
  integrations_time_before_stay_begins: {
    time: Date | string | number | null | undefined
  }
  invitation_accepted_to_home_invitee_view_body: {
    "0": Date | string | number | null | undefined
  }
  invitation_accepted_to_home_members_view_body: {
    "0": Date | string | number | null | undefined
  }
  member_arrived_home_title: {
    "0": Date | string | number | null | undefined
  }
  member_left_home_title: {
    "0": Date | string | number | null | undefined
  }
  no_name_found_generic: {
    name: Date | string | number | null | undefined
  }
  non_existing_user_invited_to_home_body: {
    "0": Date | string | number | null | undefined
  }
  number_of_minutes: {
    number: Date | string | number | null | undefined
  }
  number_of_minutes_plural: {
    number: Date | string | number | null | undefined
  }
  offline_since: {
    "0": Date | string | number | null | undefined
  }
  offline_since_days_ago: {
    "0": Date | string | number | null | undefined
  }
  offline_since_days_ago_plural: {
    "0": Date | string | number | null | undefined
  }
  offline_since_min_ago: {
    "0": Date | string | number | null | undefined
  }
  organization_create_home_price_summary_text: {
    price: Date | string | number | null | undefined
  }
  organization_create_home_using_tokens: {
    tokens: Date | string | number | null | undefined
  }
  organization_create_home_using_tokens_plural: {
    tokens: Date | string | number | null | undefined
  }
  organization_homes_create_unprepaid_home_info: {
    price: Date | string | number | null | undefined
  }
  organization_join_success_title: {
    "0": Date | string | number | null | undefined
  }
  organizations_all_organization_members_has_access_to_home_hint: {
    "0": Date | string | number | null | undefined
  }
  organizations_created_successfully_body: {
    name: Date | string | number | null | undefined
  }
  organizations_delete_details_title: {
    "0": Date | string | number | null | undefined
  }
  organizations_home_groups_remove_confirm_body: {
    homegroup: Date | string | number | null | undefined
  }
  organizations_home_member_remove_confirm_body: {
    user: Date | string | number | null | undefined
  }
  organizations_homegroup_add_homes_title: {
    name: Date | string | number | null | undefined
  }
  organizations_homegroups_member_delete_confirm_body: {
    member: Date | string | number | null | undefined
  }
  organizations_invitation_active: {
    email: Date | string | number | null | undefined
  }
  organizations_invitation_email_sent_notification: {
    email: Date | string | number | null | undefined
  }
  organizations_organization_name_onboarding_placeholder: {
    "0": Date | string | number | null | undefined
  }
  organizations_switch_organization_body: {
    name: Date | string | number | null | undefined
  }
  organizations_team_join_success_body: {
    team: Date | string | number | null | undefined
  }
  organizations_team_member_remove_confirmation_body: {
    user: Date | string | number | null | undefined
  }
  organizations_team_settings_profile_title: {
    name: Date | string | number | null | undefined
  }
  partial_outage_title_low_battery_devices: {
    "0": Date | string | number | null | undefined
  }
  partial_outage_title_low_battery_devices_plural: {
    "0": Date | string | number | null | undefined
  }
  partial_outage_title_offline_devices: {
    "0": Date | string | number | null | undefined
  }
  partial_outage_title_offline_devices_plural: {
    "0": Date | string | number | null | undefined
  }
  payment_method_remove_confirm: {
    last4: Date | string | number | null | undefined
  }
  placeholder_web: {
    text: Date | string | number | null | undefined
  }
  plan_renewal_estimate_breakdown_title: {
    date: Date | string | number | null | undefined
  }
  power_saving_mode_on_in: {
    "0": Date | string | number | null | undefined
  }
  presets_apply_to_rental_units: {
    number: Date | string | number | null | undefined
  }
  presets_apply_to_rental_units_plural: {
    number: Date | string | number | null | undefined
  }
  presets_bulk_add_all_units: {
    number: Date | string | number | null | undefined
  }
  presets_bulk_add_all_units_matching_search: {
    number: Date | string | number | null | undefined
  }
  presets_bulk_add_title: {
    presetName: Date | string | number | null | undefined
  }
  presets_bulk_confirm_description: {
    number: Date | string | number | null | undefined
    name: Date | string | number | null | undefined
  }
  presets_bulk_confirm_title: {
    name: Date | string | number | null | undefined
  }
  profiles_edit_homes_affected: {
    count: Date | string | number | null | undefined
  }
  profiles_edit_homes_affected_plural: {
    count: Date | string | number | null | undefined
  }
  profiles_noise_monitoring_timeline_sencond_third_fourth_warning_header: {
    "0": Date | string | number | null | undefined
  }
  profiles_noise_monitoring_timeline_sencond_third_warning_footer: {
    "0": Date | string | number | null | undefined
  }
  reactivate_plan_checkbox_label_plural: {
    homesCount: Date | string | number | null | undefined
  }
  referral_program_share_email_body: {
    link: Date | string | number | null | undefined
  }
  referral_program_share_email_subject: {
    name: Date | string | number | null | undefined
  }
  response_service_by_provider: {
    provider: Date | string | number | null | undefined
  }
  response_service_dispatch_cancelled_by_minut_while_in_progress_body: {
    "0": Date | string | number | null | undefined
  }
  response_service_dispatch_cancelled_by_minut_while_requested_body: {
    "0": Date | string | number | null | undefined
  }
  response_service_dispatch_cancelled_by_provider_body: {
    "0": Date | string | number | null | undefined
  }
  response_service_dispatch_completed_body: {
    "0": Date | string | number | null | undefined
  }
  response_service_dispatch_requested_automatically_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
  response_service_dispatch_requested_manually_body: {
    "0": Date | string | number | null | undefined
  }
  response_service_plan_end: {
    date: Date | string | number | null | undefined
  }
  response_service_wizard_enable_free_trial: {
    date: Date | string | number | null | undefined
  }
  response_service_wizard_enable_when_trial_ends: {
    price: Date | string | number | null | undefined
  }
  search_did_not_match_anything: {
    search: Date | string | number | null | undefined
  }
  settings_applying_changes: {
    amount: Date | string | number | null | undefined
  }
  settings_error_updating_device: {
    name: Date | string | number | null | undefined
  }
  sms_restriction_email_backup_text: {
    "0": Date | string | number | null | undefined
  }
  stay_importer_error_file_too_large: {
    max: Date | string | number | null | undefined
  }
  stay_importer_error_line: {
    line: Date | string | number | null | undefined
    error: Date | string | number | null | undefined
  }
  stay_importer_import_success: {
    count: Date | string | number | null | undefined
  }
  stay_importer_partial_fail_title: {
    failed: Date | string | number | null | undefined
    total: Date | string | number | null | undefined
  }
  stay_importer_ready_for_import: {
    fileName: Date | string | number | null | undefined
  }
  subscription_is_non_renewing: {
    date: Date | string | number | null | undefined
  }
  sunset_starter_banner_body: {
    discount: Date | string | number | null | undefined
  }
  sunset_starter_banner_title: {
    discount: Date | string | number | null | undefined
  }
  temperature_high_body: {
    "0": Date | string | number | null | undefined
  }
  temperature_low_body: {
    "0": Date | string | number | null | undefined
  }
  temperature_rapid_rise_body: {
    "1": Date | string | number | null | undefined
  }
  thermostat_settings_temperature_delta: {
    delta: Date | string | number | null | undefined
    unit: Date | string | number | null | undefined
  }
  transfer_legacy_plan_description: {
    name: Date | string | number | null | undefined
  }
  updated_at: {
    "0": Date | string | number | null | undefined
  }
  updated_days_ago: {
    "0": Date | string | number | null | undefined
  }
  updated_days_ago_plural: {
    "0": Date | string | number | null | undefined
  }
  updated_min_ago: {
    "0": Date | string | number | null | undefined
  }
  user_invited_another_user_to_home_body: {
    "0": Date | string | number | null | undefined
  }
  user_received_invitation_to_other_home_action_0: {
    "0": Date | string | number | null | undefined
  }
  user_received_invitation_to_other_home_body: {
    "0": Date | string | number | null | undefined
    "1": Date | string | number | null | undefined
  }
}
