import { Helmet } from "react-helmet"

import { purgeEmpty } from "src/utils/genericUtil"

export function DocumentHead({
  title = "Web app",
  metaTags,
}: {
  title?: string
  metaTags?: {
    name?: string
    content?: string
    httpEquiv?: string
    itemProp?: string
  }[]
}) {
  return (
    <Helmet>
      <title>{title} | Minut</title>

      {metaTags?.map((metaTag) => {
        const attributes = purgeEmpty({
          name: metaTag.name,
          content: metaTag.content,
          httpEquiv: metaTag.httpEquiv,
          itemProp: metaTag.itemProp,
        })

        return (
          <meta
            key={`${metaTag.name} ${metaTag.content} ${metaTag.httpEquiv} ${metaTag.itemProp}`}
            {...attributes}
          />
        )
      })}
    </Helmet>
  )
}
