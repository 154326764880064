import { IOccupancyDataRequest } from "src/data/occupancy/types/occupancyTypes"

export const occupancyKeys = {
  /** Use to target *all* occupancy data in the cache */
  all() {
    return ["occupancy"] as const
  },

  /** Use to target occupancy data in the cache */
  occupancy({
    homeId,
    params,
  }: {
    homeId: string
    params?: Partial<IOccupancyDataRequest>
  }) {
    if (!!params) {
      return [...this.all(), homeId, params] as const
    }
    return [...this.all(), homeId] as const
  },

  /** Use to target the threshold data in the cache */
  threshold() {
    return [...this.all(), "thresholds"] as const
  },
}
