import styled from "styled-components"

import { TPartialLocation } from "src/router/routeTypes"
import { fontWeight } from "src/ui/fontWeight"
import ChevronRightSvg from "src/ui/icons/chevron-right.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { BodySMixin, MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface IBreadcrumb {
  to: TPartialLocation
  label: string
}
export function Breadcrumbs({ items }: { items: IBreadcrumb[] }) {
  return (
    <Nav>
      <OrderedList>
        {items.map((item, index) => {
          return (
            <li key={`${item.to.pathname}-${index}`}>
              {index !== items.length - 1 ? (
                <>
                  <BreadcrumbLink key={item.to.pathname} to={item.to}>
                    {item.label}
                  </BreadcrumbLink>

                  {index !== items.length - 1 && <Separator />}
                </>
              ) : (
                <MText variant="bodyS" as="span">
                  {item.label}
                </MText>
              )}
            </li>
          )
        })}
      </OrderedList>
    </Nav>
  )
}

const Nav = styled.nav``

const Separator = styled(ChevronRightSvg)`
  width: ${spacing.S};
  height: ${spacing.S};
  margin: 0 ${spacing.XS};
`

const OrderedList = styled.ol`
  margin: 0;
  padding: 0;

  & > li {
    display: inline-block;
  }
`

const BreadcrumbLink = styled(InternalLink)`
  ${BodySMixin};
  font-weight: ${fontWeight.semiBold};
`
