import { useContext } from "react"

import { RecentlyViewedContext } from "src/components/RecentlyViewed/RecentlyViewedContext"

export function useRecentlyViewedContext() {
  const recentlyViewedContext = useContext(RecentlyViewedContext)

  if (!recentlyViewedContext) {
    throw new Error("useRecentlyViewedContext was called outiside it's context")
  }

  return recentlyViewedContext
}
