import { useEffect } from "react"
import {
  initReactI18next,
  // eslint-disable-next-line no-restricted-imports
  useTranslation,
} from "react-i18next"

import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"

import { useAppData } from "src/context/useAppData"

import { isValidLng } from "./i18nUtil"

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug:
      process.env.NODE_ENV !== "production" &&
      !import.meta.env.REACT_APP_I18N_DEBUG_OFF,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export function I18n({ children }: { children?: React.ReactNode }) {
  const { user } = useAppData()
  const { i18n } = useTranslation()

  // Set language based on users current language if possible
  useEffect(() => {
    if (!user) {
      return
    }
    const currentLanguage = i18n.language
    const preferredLanguage = user?.preferred_language

    if (
      !!preferredLanguage &&
      preferredLanguage !== currentLanguage &&
      isValidLng(preferredLanguage)
    ) {
      i18n.changeLanguage(preferredLanguage)
    }
  }, [i18n, user])

  return <>{children}</>
}
