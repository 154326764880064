import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { UserRoles } from "src/data/user/user"

export function useHomeTokens({
  useCachedData = true,
}: {
  useCachedData?: boolean
} = {}) {
  const user = useGetUser()
  const homeTokensFeature = useFeatureAvailability({
    feature: "home_tokens",
    refresh: !useCachedData,
  })
  const hasHomeTokenRole = user.roles.includes(UserRoles.HOMETOKENS)

  return { hasHomeTokenRole, ...homeTokensFeature }
}
