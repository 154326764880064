import { createTheme as createMuiTheme } from "@material-ui/core/styles"

import { fontWeight } from "src/ui/fontWeight"

import { colorScale, mColors } from "./ui/colors"
import { spacing } from "./ui/spacing"

// eslint-disable-next-line import/no-default-export
export default createMuiTheme({
  palette: {
    primary: { main: mColors.primary },
    secondary: {
      main: mColors.systemInfoLight,
      contrastText: mColors.textContrast,
    },
  },
  typography: {
    fontFamily: "'Figtree', sans-serif",
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
      variant: "contained",
      color: "primary",
      disableElevation: true,
    },
    MuiRadio: {
      color: "primary",
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
      color: "primary",
    },
    MuiPopover: {
      elevation: 5,
    },
    MuiFormControl: {
      variant: "outlined",
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiTextField: {
      variant: "outlined",
    },
    MuiTooltip: {
      placement: "bottom",
      arrow: true,
    },
    MuiBadge: {
      color: "primary",
      overlap: "circular",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.875rem",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "14px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        letterSpacing: "0px",
        borderRadius: "8px",
        fontWeight: fontWeight.semiBold,
      },

      outlinedPrimary: {
        borderColor: mColors.primary,
        borderWidth: "2px",
        "&:hover": {
          borderWidth: "2px",
          borderColor: mColors.primary,
        },
      },
      containedPrimary: {
        '&[emergency="1"]': {
          backgroundColor: mColors.systemError,
          color: "white",
          "&:hover": {
            color: "white",
            backgroundColor: mColors.systemError,
          },
          "&[disabled]": {
            backgroundColor: mColors.systemError,
          },
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: mColors.systemInfoLight,
        },
      },
      contained: {
        backgroundColor: mColors.accentPrimaryLight,
      },
      containedSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
      },
      textSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
      },
      outlinedSizeLarge: {
        paddingTop: spacing.S,
        paddingBottom: spacing.S,
      },
    },
    MuiPaper: {
      root: {
        color: mColors.textPrimary,
      },
      rounded: {
        borderRadius: "8px",
      },
      elevation1: {
        boxShadow:
          "rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px",
      },
    },
    MuiPopover: {
      paper: {
        boxShadow:
          "rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px",
        borderRadius: "8px",
      },
    },
    MuiDialog: {
      paper: {
        "-webkit-overflow-scrolling": "touch",
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: "1.5rem",
        fontWeight: fontWeight.semiBold,
        padding: `${spacing.L}`,
        "@media (min-width: 800px)": {
          padding: `${spacing.XL} ${spacing.XL2} ${spacing.S}`,
        },
      },
    },
    MuiDialogContentText: {
      root: {
        color: "inherit",
        opacity: 0.85,
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${spacing.S} ${spacing.L}`,
        "@media (min-width: 800px)": {
          padding: `${spacing.S} ${spacing.XL2}`,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing.M}`,
        "@media (min-width: 800px)": {
          padding: `${spacing.L} ${spacing.XL2}`,
        },
        flexFlow: "wrap",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: fontWeight.regular,
        color: mColors.textPrimary,
        backgroundColor: mColors.primaryLight,
        boxShadow:
          "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        padding: "0.875rem",
        borderRadius: "16px",
      },
      arrow: {
        color: mColors.primaryLight,
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: "0.875rem",
        fontWeight: fontWeight.semiBold,
        color: mColors.textSecondary,
        padding: `${spacing.M} 0 ${spacing.XS} 0`,
        lineHeight: "normal",
      },
    },
    MuiSwitch: {
      root: {
        width: 51,
        height: 31,
        padding: 0,
      },
      switchBase: {
        padding: 3,
        "&$checked": {
          transform: "translateX(20px)",
          color: "#fff !important",
          "& + $track": {
            opacity: 1,
            backgroundColor: `${mColors.primary} !important`,
            borderColor: `${mColors.primary} !important`,
          },
          "&$disabled + $track": {
            borderColor: `${colorScale.gaff[200]} !important`,
            backgroundColor: `${colorScale.gaff[200]} !important`,
          },
        },
      },
      thumb: {
        width: 25,
        height: 25,
        boxShadow: "none",
        borderColor: `#bdbdbd`,
      },
      track: {
        borderRadius: 31 / 2,
        border: `1px solid #bdbdbd`,
        backgroundColor: "#bdbdbd",
        opacity: 1,
        transition:
          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem",
      },
    },
    MuiBadge: {
      colorPrimary: { backgroundColor: mColors.systemInfoDark },
      colorSecondary: { backgroundColor: mColors.systemInfoDark },
    },
  },
})
