import { paths } from "@minuthq/minut-api-types/schema"

import { Maybe } from "src/utils/tsUtil"

export const DEFAULT_USER_ID = ""

export const PLAN = {
  starter: "starter",
  standard: "standard",
  pro: "pro",
  pro_plus: "pro_plus",
} as const

export type TPlan = (typeof PLAN)[keyof typeof PLAN]

export enum SUBSCRIPTION_STATUS {
  active = "active",
  inactive = "inactive",
  nonRenewing = "non_renewing",
  none = "none",
}

export enum BILLING_PERIOD {
  month = "month",
  year = "year",
}

export enum DUNNING_STAGE {
  NO_DUNNING = 0,
  DUNNING_DAY_0 = 1,
  DUNNING_PRE_SUSPENDED = 2,
  DUNNING_SUSPENDED = 3,
  DUNNING_CANCELLED = 4,
}

export type TUserRequest =
  paths["/users/{user_id}"]["get"]["responses"]["200"]["content"]["application/json"]

export type TUser = TUserRequest & {
  // here we're overriding the backend type in order to guarantee that we always
  // have some kind of clock type set; this is handled in the user fetch query.
  clock_type: TClockType
}

export type TClockType = "12" | "24"
export type TClockTypeMaybe = TClockType | undefined // Kept mostly for legacy reasons

export type TTemperatureUnit = "C" | "F" | undefined

export enum UserRoles {
  HOMELOG = "homelog",
  HOMETOKENS = "home_tokens",
  SUPERADMIN = "superadmin",
  SALES_ADMIN = "sales_admin",
}

export interface IAuthorization {
  access_token: string
  refresh_token: string
  expires_in: number
  user_id: string
  token_type: "Bearer"
}
export type TMaybeAuthorization = Maybe<IAuthorization>
