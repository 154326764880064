import { createContext } from "react"

export type PortState = "closed" | "closing" | "open" | "opening"

export type SerialMessage = {
  value: Uint8Array
  timestamp: number
}

export type SerialMessageCallback = (message: SerialMessage) => void

export const SerialContext = createContext({
  isConnected: false,
  connect: () => Promise.resolve(false),
  disconnect: () => {},
  subscribe: (_id: string, _cb: SerialMessageCallback): string => "",
  unsubscribe: (_id: string) => {},
  get portState(): PortState {
    return "closed"
  },
  get writableStream(): WritableStream<ArrayBuffer> | undefined {
    return undefined
  },

  autoConnectEnabled: false,
  setAutoConnectEnabled: (_state: boolean) => {},
  canUseSerial: false,
  hasTriedAutoconnect: false,
  hasManuallyDisconnected: false,
})
