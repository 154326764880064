import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MBadge, MBadgeProps } from "src/ui/MBadge/MBadge"

export function ParadiseOrderTrackingReferenceBadge({
  trackingReference,
  size = "x-small",
}: {
  trackingReference: TParadiseOrder["tracking_reference"]
  size?: MBadgeProps["size"]
}) {
  if (trackingReference) {
    return (
      <div>
        <MBadge color="good" size={size}>
          {trackingReference}
        </MBadge>
      </div>
    )
  }

  return (
    <div>
      <MBadge color="warning" size={size}>
        Not available
      </MBadge>
    </div>
  )
}
