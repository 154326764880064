import { useCallback, useMemo } from "react"

import { useAppLocation } from "src/hooks/useAppLocation"
import { useRouter } from "src/router/useRouter"
import { Nullable, PartialNullable } from "src/utils/tsUtil"

type TAcceptedTypesLiteral = "string" | "number" | "boolean"

type TSearchParamsByKey = {
  key: string
  type: TAcceptedTypesLiteral
  /**
   * `false`, `0` and `""` will be stored in the URL.
   *
   *  NOTE. `null`, `undefined`, ´NaN´ will not be stored.
   */
  acceptFalsy?: boolean
}

type TMapSearchParamType<Type extends TAcceptedTypesLiteral | undefined> =
  Type extends "boolean" ? boolean : Type extends "number" ? number : string

type TMapSearchParamsByKeys<Keys extends TSearchParamsByKey[]> = {
  [searchParamByKey in Keys[number] as searchParamByKey["key"]]: TMapSearchParamType<
    searchParamByKey["type"]
  >
}

type TSetSearchParamsOptions = { replace?: boolean }

type TSetSearchParamsFn<FormattedSearchParams> = <
  Key extends keyof FormattedSearchParams,
>(
  key: Key,
  value: FormattedSearchParams[Key] | null,
  options?: TSetSearchParamsOptions
) => void

// This will be used by components that take in ´setSearchParams´, will remove ignore once it is used
// ts-prune-ignore-next
export type TSetSearchParamsProp<
  T extends { [key: string]: string | number | boolean | null },
> = TSetSearchParamsFn<T>

export function useSearchParams<
  const Keys extends TSearchParamsByKey[],
  FormattedSearchParams = TMapSearchParamsByKeys<Keys>,
>({ keys }: { keys: Keys }) {
  const location = useAppLocation()
  const { navigate } = useRouter()

  const searchParams = useMemo(() => {
    const currentSearchParams = new URLSearchParams(location.search)

    return generateSearchParamsObject(currentSearchParams, keys)
  }, [location.search, keys])

  /** Use this for atomic updates when you need to set multiple search params
   * simultaneously. */
  const setSearchParamsAll = useCallback(
    (
      params: PartialNullable<FormattedSearchParams>,
      options?: TSetSearchParamsOptions
    ) => {
      // We use `window.location.search` here instead of `location.search` due to ´location.search´
      // being out of sync with the URL when state is updated multiple times in a row, URL is changed but the state is not
      const searchParams = new URLSearchParams(window.location.search)

      for (const [key, value] of Object.entries(params)) {
        const keyConfig = keys.find((k) => k.key === key)
        const keyAcceptsFalsy = !!keyConfig?.acceptFalsy

        const valueIsFalsy = value === false || value === "" || value === 0

        // We have to check for NaN here becuase converted strings passed in to this function can be NaN if the required type is number
        if (
          value === null ||
          Number.isNaN(value) ||
          (!keyAcceptsFalsy && valueIsFalsy)
        ) {
          searchParams.delete(key)
        } else {
          searchParams.set(key, String(value))
        }
      }

      navigate(
        { search: searchParams.toString(), hash: window.location.hash },
        { replace: options?.replace }
      )
    },
    [navigate, keys]
  )

  const setSearchParams: TSetSearchParamsFn<FormattedSearchParams> =
    useCallback(
      (key, value, options) => {
        setSearchParamsAll(
          { [key]: value } as Partial<FormattedSearchParams>,
          options
        )
      },
      [setSearchParamsAll]
    )

  return { searchParams, setSearchParams, setSearchParamsAll } as const
}

function generateSearchParamsObject<Keys extends TSearchParamsByKey[]>(
  currentSearchParams: URLSearchParams,
  keys: Keys
) {
  const searchParamsObjectEntries = keys.map((k) => [
    k.key,
    parseSearchParamValue(currentSearchParams.get(k.key), k.type),
  ])

  return Object.fromEntries(searchParamsObjectEntries) as Nullable<
    TMapSearchParamsByKeys<Keys>
  >
}

function parseSearchParamValue(
  value: string | null,
  type: TAcceptedTypesLiteral
) {
  if (value === null) {
    return null
  }

  if (type === "string") {
    return String(value)
  }

  if (type === "boolean") {
    return value === "true"
  }

  if (type === "number") {
    const parsedNumber = parseInt(value)

    return !isNaN(parsedNumber) ? parsedNumber : null
  }
}
