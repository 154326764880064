import { Location } from "history"

/** Use to define routes/locations.
 *
 * Mixins such as {@link IPathMixin} and {@link ITabsMixin} can be used to
 * extend the ILocation with additional fields.
 */
export interface ILocation<
  PathProps = string,
  LocationExtension extends Partial<MLocation> = object,
> {
  readonly location: (
    navProps: PathProps
  ) => TPartialLocation & LocationExtension
}

/** Intersect ILocation with this to create a route which also supplies tabs */
export interface ITabsMixin<Tabs extends readonly string[] | void = void> {
  tabs: Tabs
}

/** Intersect ILocation with this to create a route which also supplies the
 * legacy `path` function. */
export interface IPathMixin<PathProps = string> {
  path: (navProps: PathProps) => string
}

/**
 * A helper function for creating a basic route location that takes no
 * params/queries.
 *
 * Example:
 * ```
 * const l = createBasicLocation("/foo/bar")
 * b.location().pathname // => "/foo/bar"
 * ```
 */
export function createBasicLocation(pathname: string): ILocation<void> {
  return { location: () => ({ pathname }) }
}

export interface ILocationState {
  from?: Location<ILocationState | undefined>
  // N.B:
  // To prevent infinitely recursive `from` states we can omit the nested
  // from.state attribute:
  // from?: Omit<Location, "state">
  // Keep this in mind if the recursion leads to any problems down the line.
}
export interface MLocation extends Location<ILocationState | undefined> {}
export type TPartialLocation = Partial<MLocation> & { pathname: string }
