import Icon from "src/ui/icons/check-circle.svg"
import { SVGProps } from "src/utils/tsUtil"

export function CheckCircleIcon({
  className,
  svgProps,
  style,
}: {
  className?: string
  svgProps?: SVGProps
  style?: React.CSSProperties
}) {
  return (
    <Icon
      fill="#63B680"
      className={className}
      width={24}
      style={style}
      {...svgProps}
    />
  )
}
