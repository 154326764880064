import { BackendLoginClient } from "src/constants/env"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const Signup = createBasicLocation("/signup")
const Login = createBasicLocation("/login")
const OAuthExchangeCodeForToken = createBasicLocation(
  BackendLoginClient.clientRedirectPath
)

const BELogout: ILocation<void> = {
  location() {
    const redirectUri = window.location.origin + Login.location().pathname
    const pathname = `${BackendLoginClient.backendUri}/account/logout?redirectUrl=${redirectUri}`
    return { pathname }
  },
} as const

const Logout: ILocation<{ reason: string | undefined }> = {
  location({ reason }) {
    return { pathname: "/logout", search: `?reason=${reason}` }
  },
}

export const SignupLoginRoutes = {
  Signup,
  Login,
  BELogout,
  OAuthExchangeCodeForToken,
  /**
   * ### Warning
   *
   * You probably want to use the more dynamic `useLogout.logoutLocation`
   * instead, which allows storing the current location state, purges temporary
   * query params etc.
   *
   * @deprecated Only use this for no frills static routing!
   */
  Logout,
} as const
