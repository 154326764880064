import { useState } from "react"
import styled from "styled-components"

import { VirtualDeviceControlCard } from "src/components/VirtualDeviceControl/VirtualDeviceControlCard"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { useAppData } from "src/context/useAppData"
import { useFlags } from "src/hooks/useFlags"
import { backgroundGrayV2, minimumGray } from "src/ui/colors"
import ArrowUpIcon from "src/ui/icons/up-down-arrow.svg"
import { spacing } from "src/ui/spacing"
import { localStorageFactory } from "src/utils/storageUtil"

export function VirtualDeviceControl({
  children,
}: {
  children: React.ReactNode
}) {
  const [showCard, setShowCard] = useState<boolean>(
    virtualDevicesStorage.get() ?? false
  )
  const { ready } = useAppData()
  const { virtualDevices: virtualDevicesEnabled } = useFlags()

  function handleToggleCard() {
    setShowCard(!showCard)
    virtualDevicesStorage.set(!showCard)
  }

  return (
    <>
      {children}

      {virtualDevicesEnabled && ready && (
        <Box>
          <VirtualDeviceControlCard
            isOpen={showCard}
            onClose={handleToggleCard}
          />
          <VirtualDeviceButton onClick={handleToggleCard}>
            <ArrowUpIcon height={20} />
            Virtual sensors
          </VirtualDeviceButton>
        </Box>
      )}
    </>
  )
}

const virtualDevicesStorage = localStorageFactory<boolean>({
  key: "minut.device_control_open",
})

const Box = styled.div`
  position: fixed;
  bottom: 0;
  right: ${spacing.M};
  z-index: ${Z_INDEX_ABOVE_MODAL};
`

const VirtualDeviceButton = styled.button`
  padding: ${spacing.XS} ${spacing.XS} ${spacing.XS} ${spacing.XS2};
  border-radius: 4px 4px 0 0;
  border: 1px solid #ccc;
  background: ${backgroundGrayV2};
  font-size: 0.8rem;
  color: ${minimumGray};
  display: flex;
  gap: ${spacing.XS2};

  &:hover {
    cursor: pointer;
  }
`
