import { useRef } from "react"

import { Pager } from "src/components/Pager/Pager"
import { useEffectOnce } from "src/hooks/useEffectOnce"

export function ParadisePager({
  limit,
  offset,
  setOffset,
  totalCount,
  scrollOnClick = true,
}: {
  limit: number
  offset: number
  setOffset: (offset: number) => void
  totalCount: number | undefined
  scrollOnClick?: boolean
}) {
  /* 
    This holds previous total count if there is an error when navigating to a page
    Without this the pagination will disappear
   */
  const previousTotalCount = useRef<number>()

  if (totalCount) {
    previousTotalCount.current = totalCount
  }

  // If error happen during initial load, redirect to first page
  useEffectOnce(() => {
    if (!totalCount && !previousTotalCount.current) {
      setOffset(0)
    }
  })

  return (
    <Pager
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      scrollOnClick={scrollOnClick}
      totalCount={previousTotalCount.current}
    />
  )
}
