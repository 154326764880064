import { useAppData } from "src/context/useAppData"
import { getAccessLogic } from "src/data/user/logic/accessLogic"

/** Convenience hook supplying the caller with the current active org, and if its
 * enabled */
export function useOrganization() {
  const { activeOrg: org, refetchActiveOrg } = useAppData()

  if (!org) {
    throw Error("Hook is used in a context where organization is not available")
  }

  const orgAccessLogic = getAccessLogic({ role: org.user_role })

  return {
    refetch: refetchActiveOrg,
    org,
    orgId: org.id,
    orgAccessLogic,
  }
}
