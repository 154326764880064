import { useRef, useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import CopyIcon from "src/ui/icons/copy.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function CopyText({
  value,
  displayText,
  width = 24,
  copiedText,
  /*
   * ´CopyText´ handles click event internally. No need for the caller to copy to clipboard
   */
  button,
}: {
  value: string
  displayText?: React.ReactNode
  width?: number
  copiedText: NonNullable<React.ReactNode>
  button?: React.ReactNode
}) {
  const [hasCopied, setHasCopied] = useState(false)
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  async function handleCopy() {
    await navigator.clipboard.writeText(value)
    setHasCopied(true)

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => {
        setHasCopied(false)
        timerRef.current = null
      }, 2000)
    }
  }

  return (
    <MTooltip title={copiedText} open={hasCopied} anchorEl={anchorRef.current}>
      <StyledBox>
        {displayText}
        <div ref={anchorRef} onClick={handleCopy}>
          {button || (
            <IconButton size="small">
              <CopyIcon width={width} height={width} />
            </IconButton>
          )}
        </div>
      </StyledBox>
    </MTooltip>
  )
}

const StyledBox = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.XS};
`
