import { TInputContainerSize } from "src/ui/InputContainer/InputContainer"

export function canShowLabel(
  size?: TInputContainerSize | undefined
): size is "medium" {
  if (size !== "small") {
    return true
  }

  return false
}
