import { Suspense } from "react"
import { Router } from "react-router-dom"
import { CompatRouter } from "react-router-dom-v5-compat"
import styled from "styled-components"

import { MuiThemeProvider, StylesProvider } from "@material-ui/core"
import { SnackbarProvider } from "notistack"

import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { FlagProvider } from "src/components/Flags/FlagProvider"
import { NotificationProvider } from "src/components/Notifications/NotificationProvider"
import { SerialProvider } from "src/components/RemoteDebugService/SerialProvider"
import { VirtualDeviceControl } from "src/components/VirtualDeviceControl/VirtualDeviceControl"
import { AppDataProvider } from "src/context/AppDataProvider"
import { MQueryClientProvider } from "src/context/MQueryClient"
import { I18n } from "src/i18n/I18n"
import { AppRouter } from "src/router/AppRouter"
import { browserHistory } from "src/router/routes"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"

import { GlobalStyle } from "./ui/globalStyles"
import MuiTheme from "./theme"

import "sanitize.css"

const StyledErrorFallback = styled.div`
  > * {
    min-height: 100vh;
  }
`

function ProviderWrapper({ children }: { children: React.ReactNode }) {
  return (
    <SerialProvider>
      <I18n>
        <MuiThemeProvider theme={MuiTheme}>
          <StylesProvider injectFirst>
            <GlobalStyle />
            <ErrorBoundary wrapper={StyledErrorFallback}>
              <SnackbarProvider
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <VirtualDeviceControl>{children}</VirtualDeviceControl>
              </SnackbarProvider>
            </ErrorBoundary>
          </StylesProvider>
        </MuiThemeProvider>
      </I18n>
    </SerialProvider>
  )
}

function PrimaryWrapper({ children }: { children: React.ReactNode }) {
  return (
    <FlagProvider>
      <MQueryClientProvider>
        <Router history={browserHistory}>
          <CompatRouter>
            <Suspense
              fallback={<LoadingScreen debugInfo={"Loading modules"} />}
            >
              <AppDataProvider>
                <NotificationProvider>{children}</NotificationProvider>
              </AppDataProvider>
            </Suspense>
          </CompatRouter>
        </Router>
      </MQueryClientProvider>
    </FlagProvider>
  )
}
export function App() {
  return (
    <PrimaryWrapper>
      <ProviderWrapper>
        <AppRouter />
      </ProviderWrapper>
    </PrimaryWrapper>
  )
}
