import { forwardRef } from "react"

import { ButtonProps } from "@material-ui/core"

import { MLocation } from "src/router/routeTypes"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"

/** @deprecated use TMButtonProps instead */
export interface IMButtonLegacyProps extends ButtonProps {
  loading?: boolean
  progressSize?: string
  emergency?: boolean
  adornment?: React.ReactNode
  appHref?: string | (Partial<MLocation> & { pathname: string })
}

function _MButton(
  {
    loading,
    disabled,
    adornment: _adornment,
    progressSize: _progressSize,
    color = "primary",
    variant = "contained",
    emergency,
    children,
    appHref,
    ...props
  }: IMButtonLegacyProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  ref: React.Ref<any>
) {
  const newButtonProps: TMButtonProps = {
    variant: newVariant(variant, color),
    color: newColor(emergency),
    size: newSize(props.size),

    disabled,
    loading,
    appHref,
    children,

    style: props.style,
    className: props.className,
    fullWidth: props.fullWidth,
    autoFocus: props.autoFocus,
    form: props.form,
    formAction: props.formAction,
    formEncType: props.formEncType,
    formMethod: props.formMethod,
    formNoValidate: props.formNoValidate,
    formTarget: props.formTarget,
    name: props.name,
    type: props.type,
    onClick: props.onClick,
    value: props.value,
  }

  return (
    <MButton ref={ref} {...newButtonProps}>
      {newButtonProps.children}
    </MButton>
  )
}

function newVariant(
  variant: IMButtonLegacyProps["variant"],
  color: IMButtonLegacyProps["color"]
): NonNullable<TMButtonProps["variant"]> {
  switch (variant) {
    case "outlined":
      return color === "secondary" ? "secondary" : "subtle"
    case "contained":
      return color === "secondary" ? "secondary" : "primary"
    case "text":
      return "minimal"
    default:
      return "primary"
  }
}

function newColor(
  emergency: IMButtonLegacyProps["emergency"]
): NonNullable<TMButtonProps["color"]> {
  return emergency ? "destructive" : "default"
}

function newSize(
  size: IMButtonLegacyProps["size"]
): NonNullable<TMButtonProps["size"]> {
  switch (size) {
    case "large": {
      return "medium"
    }
    case undefined:
      return "medium"
    default:
      return size
  }
}

/** @deprecated use MButton instead */
export const MButtonLegacy = forwardRef(_MButton)
