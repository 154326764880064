import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  deviceKeys,
  useDeviceCache,
} from "src/data/devices/queries/deviceQueryCache"
import {
  DeviceReadingType,
  IDeleteDeviceError,
  IDeviceMotionReadings,
  IDeviceReadings,
  IDevicesFilter,
  IFetchDevices,
  TDevice,
  TExportType,
} from "src/data/devices/types/deviceTypes"
import { IDeviceSettingsPayload } from "src/data/deviceSettings/types/deviceSettingTypes"
import { virtualDevicesKeys } from "src/data/homes/queries/virtualDeviceQueries"
import { purgeEmpty } from "src/utils/genericUtil"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePutDevice(orgId: string) {
  const deviceCache = useDeviceCache(orgId)

  async function putDevice(
    id: string,
    data: IDeviceSettingsPayload
  ): Promise<TDevice> {
    const response = await minutApiHttpClient.put<TDevice>(
      `${API_DEFAULT}/devices/${id}`,
      data
    )
    return response.data
  }

  return useMutation<
    TDevice,
    AxiosError,
    {
      id: string
      data: IDeviceSettingsPayload
    }
  >(({ id, data }) => putDevice(id, data), {
    onSuccess: (updatedDevice, { id }) => {
      deviceCache.setCachedDeviceData(updatedDevice)
      deviceCache.invalidateCachedDevice(id)
    },
  })
}

export function useFetchDevice<D = TDevice>({
  orgId,
  deviceId,
  options,
}: {
  orgId: string
  deviceId: string
  options?: UseQueryOptions<
    TDevice,
    AxiosError,
    D,
    ReturnType<typeof deviceKeys.device>
  >
}) {
  async function fetchDevice(id: string): Promise<TDevice> {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/devices/${id}`
    )
    const home: TDevice = response.data
    return home
  }
  return useQuery(
    deviceKeys.device({ orgId, deviceId }),
    () => fetchDevice(deviceId),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useFetchDevices<TQueryFnData = IFetchDevices>({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: IDevicesFilter
  options?: UseQueryOptions<
    IFetchDevices,
    AxiosError,
    TQueryFnData,
    ReturnType<typeof deviceKeys.devices>
  >
}) {
  async function fetchDevices(params?: IDevicesFilter): Promise<IFetchDevices> {
    const response = await minutApiHttpClient.get<IFetchDevices>(
      `${API_DEFAULT}/organizations/${orgId}/devices`,
      { params }
    )
    return response.data
  }

  return useQuery(
    deviceKeys.devices(orgId, filter),
    () => fetchDevices(filter),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useFetchDeviceCount({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: IDevicesFilter
  options?: UseQueryOptions<
    IFetchDevices,
    AxiosError,
    number,
    ReturnType<typeof deviceKeys.devices>
  >
}) {
  return useFetchDevices<number>({
    orgId,
    filter: { ...filter, limit: 1 },
    options: {
      ...options,
      select: (data) => data.paging.total_count,
    },
  })
}

export function useFetchSensorValuesExport({
  orgId,
  deviceId,
  startDate,
  endDate,
}: {
  orgId: string
  deviceId: string
  startDate: Date
  endDate: Date
}) {
  async function exportSensorValues(sensor: TExportType): Promise<string> {
    const startAt = startDate.toISOString()
    const endAt = endDate.toISOString()
    const resp = await minutApiHttpClient.get<string>(
      `${API_DEFAULT}/organizations/${orgId}/export/sensor_values/${deviceId}`,
      {
        params: {
          sensor,
          start_at: startAt,
          end_at: endAt,
        },
      }
    )
    return resp.data
  }

  return useMutation(exportSensorValues)
}

export function useFetchDeviceReadings({
  orgId,
  deviceId,
  endAt,
  includeMinMax = true,
  startAt,
  timeResolution = 60,
  nullFill,
  type,
  options,
}: {
  orgId: string
  deviceId: string
  endAt: string
  includeMinMax?: boolean
  startAt: string
  timeResolution?: number
  nullFill?: boolean
  type: DeviceReadingType
  options?: UseQueryOptions<
    IDeviceReadings,
    AxiosError,
    IDeviceReadings,
    ReturnType<typeof deviceKeys.readings>
  >
}) {
  async function fetchDeviceReadings({
    deviceId,
    endAt,
    includeMinMax,
    startAt,
    timeResolution,
    type,
  }: {
    deviceId: string
    endAt: string
    includeMinMax?: boolean
    startAt: string
    timeResolution?: number
    type: DeviceReadingType
  }): Promise<IDeviceReadings> {
    const result = await minutApiHttpClient.get<IDeviceReadings>(
      `${API_DEFAULT}/devices/${deviceId}/${type}`,
      {
        params: purgeEmpty({
          include_min_max: includeMinMax,
          start_at: startAt,
          end_at: endAt,
          time_resolution: timeResolution,
          null_fill: nullFill,
        }),
      }
    )
    return result.data
  }

  return useQuery(
    deviceKeys.readings({
      orgId,
      deviceId,
      endAt,
      includeMinMax,
      startAt,
      timeResolution,
      type,
    }),
    () =>
      fetchDeviceReadings({
        deviceId,
        endAt,
        includeMinMax,
        startAt,
        timeResolution,
        type,
      }),
    options
  )
}

export function useFetchDeviceMotionReadings({
  orgId,
  deviceId,
  endAt,
  includeMinMax = true,
  startAt,
  timeResolution = 60,
  nullFill,
  options,
}: {
  orgId: string
  deviceId: string
  endAt: string
  includeMinMax?: boolean
  startAt: string
  timeResolution?: number
  nullFill?: boolean
  options?: UseQueryOptions<
    IDeviceMotionReadings,
    AxiosError,
    IDeviceMotionReadings,
    ReturnType<typeof deviceKeys.readings>
  >
}) {
  async function fetchDeviceReadings({
    deviceId,
    endAt,
    includeMinMax,
    startAt,
    timeResolution,
  }: {
    deviceId: string
    endAt: string
    includeMinMax?: boolean
    startAt: string
    timeResolution?: number
  }) {
    const result = await minutApiHttpClient.get<IDeviceMotionReadings>(
      `${API_DEFAULT}/devices/${deviceId}/motion_events`,
      {
        params: purgeEmpty({
          include_min_max: includeMinMax,
          start_at: startAt,
          end_at: endAt,
          time_resolution: timeResolution,
          null_fill: nullFill,
        }),
      }
    )
    return result.data
  }

  return useQuery(
    deviceKeys.readings({
      orgId,
      deviceId,
      endAt,
      includeMinMax,
      startAt,
      timeResolution,
      type: "motion_events",
    }),
    () =>
      fetchDeviceReadings({
        deviceId,
        endAt,
        includeMinMax,
        startAt,
        timeResolution,
      }),
    options
  )
}

export function useDeleteDevice(orgId: string) {
  const { removeCachedDevice } = useDeviceCache(orgId)
  const queryClient = useQueryClient()

  async function deleteDevice({ deviceId }: { deviceId: string }) {
    return await minutApiHttpClient.delete(`${API_DEFAULT}/devices/${deviceId}`)
  }

  return useMutation<
    AxiosResponse,
    AxiosError<IDeleteDeviceError>,
    { deviceId: string }
  >(deleteDevice, {
    onSuccess: (_, vars) => {
      removeCachedDevice({ queryClient, deviceId: vars.deviceId })
      return Promise.all([queryClient.invalidateQueries(virtualDevicesKeys())])
    },
  })
}
