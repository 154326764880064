import { IShippingAddress } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { useStorage } from "src/data/storage/useStorage"
import {
  localStorageFactory,
  sessionStorageFactory,
} from "src/utils/storageUtil"
import { Optional } from "src/utils/tsUtil"

const storedActiveOrg = localStorageFactory({
  key: "minut.organization.active",
})

export function useStoredActiveOrgId({
  initialValue,
}: {
  initialValue: Optional<string>
}) {
  return useStorage({ storage: storedActiveOrg, initialValue })
}

export const storedShippingAddress = sessionStorageFactory<IShippingAddress>({
  key: "minut.shipping_address",
})
