import {
  IDisturbanceMonitoring,
  TDisturbanceMonitoringActive,
} from "src/data/homes/types/homeTypes"
import { colorScale, mColors } from "src/ui/colors"
import NoiseMonitoringFilledIcon from "src/ui/icons/noise-monitoring-filled.svg"
import { SVGProps } from "src/utils/tsUtil"

export function NoiseMonitoringIcon({
  active,
  state,
  iconProps,
}: {
  active: TDisturbanceMonitoringActive | undefined
  state: IDisturbanceMonitoring["state_v2"]
  iconProps?: SVGProps
}) {
  if (!active) {
    return (
      <NoiseMonitoringFilledIcon
        fill={mColors.neutralDark}
        color={colorScale.koti[400]}
        {...iconProps}
      />
    )
  }

  if (state === "idle") {
    return (
      <NoiseMonitoringFilledIcon
        fill={mColors.systemInfoLight}
        color={mColors.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <NoiseMonitoringFilledIcon
      fill={mColors.systemErrorLight}
      color={mColors.systemErrorDark}
      {...iconProps}
    />
  )
}
