import { useQueryClient } from "@tanstack/react-query"

import {
  IParadiseUserEventFilter,
  IParadiseUsersListFilter,
} from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"

export const paradiseUsersKeys = {
  all() {
    return ["paradiseUsers"] as const
  },

  userLists() {
    return [...this.all(), "list"] as const
  },

  userList(filter?: IParadiseUsersListFilter) {
    if (!filter) {
      return [...this.userLists()] as const
    }

    return [...this.userLists(), filter] as const
  },

  user(userId: string) {
    return [...this.all(), userId] as const
  },

  event(userId: string, filter?: IParadiseUserEventFilter) {
    if (!filter) {
      return [...this.user(userId), "event"] as const
    }

    return [...this.user(userId), "event", filter] as const
  },
} as const

export function useParadiseUsersCache() {
  const queryClient = useQueryClient()

  function invalidateParadiseUser(userId: string) {
    const cacheKey = paradiseUsersKeys.user(userId)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  function invalidateParadiseUserList(filter?: IParadiseUsersListFilter) {
    const cacheKey = paradiseUsersKeys.userList(filter)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  function removeParadiseUser(userId: string) {
    const cacheKey = paradiseUsersKeys.user(userId)

    return queryClient.removeQueries(cacheKey)
  }

  return {
    invalidateParadiseUser,
    invalidateParadiseUserList,
    removeParadiseUser,
  }
}
