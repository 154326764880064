import { DEVICE_LOW_BATTERY_LIMIT } from "src/data/devices/logic/deviceConstants"
import {
  HardwareVersionBoundary,
  TDevice,
} from "src/data/devices/types/deviceTypes"

type TPartialDevice = Partial<TDevice>
type TPartialDeviceRequired = Pick<
  TDevice,
  "battery" | "offline" | "hardware_version" | "placed_outdoors"
>

/** Device data necessary to determine partial outage information */
export type TPartialOutageDevice = TPartialDevice & TPartialDeviceRequired

const isDeviceLowBattery = (d: TPartialDevice) =>
  (d.battery?.percent ?? 0) <= DEVICE_LOW_BATTERY_LIMIT
const isDeviceOffline = (d: TPartialDevice) => !!d.offline
const isDeviceP3 = (d: TPartialDevice) =>
  (d.hardware_version ?? 0) >= HardwareVersionBoundary.P3_BOUNDARY_START
const isPlacedIndoors = (d: TPartialDevice) => !d.placed_outdoors

const checkAllIndoorDevicesOffline = (devices: TPartialOutageDevice[]) =>
  devices.filter(isPlacedIndoors).every(isDeviceOffline)
export const checkAllIndoorP3DevicesOffline = (
  devices: TPartialOutageDevice[]
) => devices.filter(isPlacedIndoors).filter(isDeviceP3).every(isDeviceOffline)

export interface IDeviceOutageState<T extends TPartialDevice = TPartialDevice> {
  offlineDevices: T[]
  nbrOfflineDevices: number
  lowBatteryDevices: T[]
  nbrLowBattery: number
  allDevicesOffline: boolean
  allIndoorOffline: boolean
  allIndoorP3Offline: boolean
}

export function getDeviceOutageState<T extends TPartialOutageDevice>(
  devices: T[]
): IDeviceOutageState<T> {
  // find low battery devices
  const lowBatteryDevices = devices.filter(isDeviceLowBattery)
  const nbrLowBattery = lowBatteryDevices.length

  // find offline devices
  const allDevicesOffline = devices.every((d) => d.offline)
  const offlineDevices = devices.filter(isDeviceOffline)
  const nbrOfflineDevices = offlineDevices.length

  // All indoor sensors offline?
  const allIndoorOffline = checkAllIndoorDevicesOffline(devices)
  // All indoor P3 sensors offline?
  const allIndoorP3Offline = checkAllIndoorP3DevicesOffline(devices)

  return {
    offlineDevices,
    nbrOfflineDevices,
    nbrLowBattery,
    lowBatteryDevices,
    allDevicesOffline,
    allIndoorOffline,
    allIndoorP3Offline,
  }
}

export type TPartialOutageState = "offline_sensors" | "low_battery" | "ok"

export function reduceDeviceOutageState({
  nbrOfflineDevices,
  nbrLowBattery,
}: Pick<
  IDeviceOutageState,
  "nbrLowBattery" | "nbrOfflineDevices"
>): TPartialOutageState {
  if (nbrOfflineDevices) {
    return "offline_sensors"
  } else if (nbrLowBattery) {
    return "low_battery"
  }
  return "ok"
}

export interface IOfflineDeviceOverrides {
  hideNoiseMonitoringCard?: boolean
  hideCrowdDetectionCard?: boolean
  hideSmokingDetectionCard?: boolean
  hideSecurityAlarmCard?: boolean
}

/**
 * Some cards should be hidden depending on device type and their offline status.
 *
 * all indoor sensors are offline:
 * - hide Crowd detect, Cigarette detection & Security alarm
 * all indoor Gen 3 sensors are offline:
 * - hide Cigarette detection
 */
export function getOfflineDeviceOverrides({
  allDevicesOffline,
  allIndoorOffline,
  allIndoorP3Offline,
}: {
  allDevicesOffline: boolean
  allIndoorOffline: boolean
  allIndoorP3Offline: boolean
}): IOfflineDeviceOverrides {
  if (allDevicesOffline) {
    return {
      hideNoiseMonitoringCard: true,
      hideCrowdDetectionCard: true,
      hideSmokingDetectionCard: true,
      hideSecurityAlarmCard: true,
    }
  }

  if (allIndoorOffline) {
    return {
      hideCrowdDetectionCard: true,
      hideSmokingDetectionCard: true,
      hideSecurityAlarmCard: true,
    }
  }

  if (allIndoorP3Offline)
    return {
      hideSmokingDetectionCard: true,
    }

  return {}
}
