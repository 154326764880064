import { useMemo, useState } from "react"
import styled from "styled-components"

import { TextButton } from "src/ui/Button/TextButton"
import { AddFilter } from "src/ui/Filter/AddFilter"
import { FilterChip } from "src/ui/Filter/FilterChip"
import { spacing } from "src/ui/spacing"

export type TFilter = Omit<
  Parameters<typeof FilterChip>[0],
  "children" | "initialOpen"
> & {
  id: string
  render: React.ReactNode
  onAdd?: () => void
  alwaysVisible?: boolean
}

export function Filter({ filters }: { filters: TFilter[] }) {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  function addFilter(filter: TFilter) {
    setSelectedIds((prev) => [...prev, filter.id])

    filter.onAdd?.()
  }

  function removeFilter(filter: TFilter) {
    setSelectedIds((prev) => prev.filter((id) => id !== filter.id))

    filter.onRemove?.()
  }

  function clearFilters() {
    setSelectedIds([])

    filters.forEach((filter) => {
      filter.onRemove?.()
    })
  }

  const availableFilters = useMemo(() => {
    return filters.filter(
      (filter) =>
        !selectedIds.includes(filter.id) &&
        !filter.alwaysVisible &&
        !filter.active
    )
  }, [filters, selectedIds])

  const visibleFilters = useMemo(() => {
    return filters.filter(
      (filter) =>
        selectedIds.includes(filter.id) || filter.alwaysVisible || filter.active
    )
  }, [filters, selectedIds])

  const activeFilters = useMemo(() => {
    return filters.filter((filter) => filter.active)
  }, [filters])

  return (
    <FilterWrapper>
      {visibleFilters.map((filter) => (
        <FilterChip
          key={filter.id}
          label={filter.label}
          value={filter.value}
          onRemove={
            !filter.alwaysVisible ? () => removeFilter(filter) : filter.onRemove
          }
          active={filter.active}
          // Filters that start active or visible should not open initially, only once the user add that filter
          initialOpen={!filter.alwaysVisible && selectedIds.includes(filter.id)}
          removeLabel={filter.removeLabel}
        >
          {filter.render}
        </FilterChip>
      ))}

      <AddFilter
        filters={availableFilters}
        onAddFilter={(filter) => addFilter(filter)}
        disabled={availableFilters.length === 0}
      />
      {activeFilters.length > 0 && (
        <TextButton onClick={() => clearFilters()}>Clear filters</TextButton>
      )}
    </FilterWrapper>
  )
}

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
  flex-wrap: wrap;
`
