import { Helmet } from "react-helmet"
import { Redirect, RouteProps } from "react-router-dom"

import { PrivateRoute } from "src/components/PrivateRoute/PrivateRoute"
import { isParadiseProdEnv } from "src/constants/env"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import {
  isSuperAdmin,
  rolesWithParadiseAccess,
} from "src/data/user/logic/accessLogic"
import { UserRoles } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { MLocation } from "src/router/routeTypes"
import { arrayIncludesValueOfOtherArray } from "src/utils/genericUtil"

const redirectPaths: { roles: UserRoles[]; path: Partial<MLocation> }[] = [
  {
    roles: [UserRoles.SALES_ADMIN],
    path: Routes.ParadiseUsers.location(),
  },
]

/*
  Checks if current user has access to the route by checking its roles compared to "allowedRoles".
  If the user doesn't have access, the user will be redirected to correct route depending the the role or dashboard as default
  NOTE: Users with "superadmin" role always has access to children
*/
function UserRoleRedirectMiddleware({
  children,
  allowedRoles,
}: {
  children: React.ReactNode
  allowedRoles: UserRoles[]
}) {
  const user = useGetUser()

  if (
    !isSuperAdmin(user.roles) &&
    !arrayIncludesValueOfOtherArray(user.roles, allowedRoles)
  ) {
    const foundPath = redirectPaths.find((path) =>
      arrayIncludesValueOfOtherArray(path.roles, user.roles)
    )

    return <Redirect to={foundPath?.path || Routes.Dashboard.location()} />
  }

  return <>{children}</>
}

export function ParadiseRoute({
  children,
  allowedRoles = [UserRoles.SUPERADMIN],
  render,
  ...rest
}: {
  children?: React.ReactNode
  allowedRoles?: [...(typeof rolesWithParadiseAccess)[number][]]
} & RouteProps) {
  return (
    <PrivateRoute
      skipMiddleware={{
        Onboarding: "Is in Paradise",
        Dunning: "Is in Paradise",
        InjectOrgId: "Is in Paradise",
      }}
      render={(props) => {
        return (
          <UserRoleRedirectMiddleware allowedRoles={allowedRoles}>
            <Helmet>
              <title>
                Paradise 2 | {isParadiseProdEnv ? "Production" : "Staging"}
              </title>
              <link
                rel="icon"
                href={
                  isParadiseProdEnv
                    ? "/paradise-production-favicon.ico"
                    : "/paradise-staging-favicon.ico"
                }
              />
            </Helmet>
            {render ? render(props) : children}
          </UserRoleRedirectMiddleware>
        )
      }}
      {...rest}
    ></PrivateRoute>
  )
}
