import styled from "styled-components"

import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { SliderSetting } from "src/components/VirtualDeviceControl/SettingsSlider"
import { VirtualDeviceBatteryIcon } from "src/components/VirtualDeviceControl/VirtualDeviceBatteryIcon"
import { TVirtualDevice } from "src/data/devices/types/deviceTypes"
import {
  usePostVirtualDeviceEvent,
  usePostVirtualEnvironment,
} from "src/data/homes/queries/virtualDeviceQueries"
import { DEFAULT_HOME_ID } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import WifiGoodIcon from "src/ui/icons/wifi-good.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function VirtualWLDDeviceControls({
  simulatedDevice,
}: {
  simulatedDevice: TVirtualDevice
}) {
  const { orgId } = useOrganization()
  const postVirtualDeviceEvent = usePostVirtualDeviceEvent()
  const postVirtualEnvironment = usePostVirtualEnvironment()

  const homeId = simulatedDevice.home || DEFAULT_HOME_ID

  const fetchHome = useFetchHome({ orgId, homeId })

  if (fetchHome.isLoading) {
    return null
  }
  if (fetchHome.isError) {
    return <MBanner>Could not fetch home {homeId}</MBanner>
  }

  if (!simulatedDevice.virtual_environment) {
    return null
  }

  const updating = postVirtualDeviceEvent.isLoading || fetchHome.isLoading

  return (
    <CardWrapper>
      <Column>
        <SliderSetting
          label="%"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={() => (
            <VirtualDeviceBatteryIcon
              voltage={simulatedDevice.battery?.voltage ?? 0}
              device={simulatedDevice}
            />
          )}
          enabled={
            simulatedDevice.offline === false ||
            (simulatedDevice.battery?.percent ?? 0) <= 0
          }
          step={1}
          min={0}
          max={100}
          attribute={"battery_percentage"}
          marks={[
            {
              value: 0,
              label: "Empty",
            },
            {
              value: 25,
              label: "Low",
            },
            {
              value: 100,
              label: "Full",
            },
          ]}
        />

        <SliderSetting
          label="db"
          orgId={orgId}
          simulatedDevice={simulatedDevice}
          Icon={WifiGoodIcon}
          step={1}
          min={-100}
          max={0}
          attribute={"wifi_rssi"}
          marks={[
            { value: -100, label: "Offline" },
            ...[-50, 0].map((value) => ({
              value,
              label: `${value}db`,
            })),
          ]}
          enabled={
            simulatedDevice.offline === false ||
            simulatedDevice.virtual_environment.wifi_rssi <= -100
          } // -100 is the lowest rssi and means virtual device is offline
        />
      </Column>

      <Column>
        <SettingToggle
          title="Water leak detected"
          titleProps={{ type: "body" }}
          disabled={simulatedDevice.offline || updating}
          value={
            simulatedDevice.virtual_environment.water_leak_detected ?? false
          }
          onSave={async (checked) => {
            try {
              await postVirtualEnvironment.mutateAsync({
                orgId,
                deviceId: simulatedDevice.device_id,
                homeId: homeId,
                body: {
                  water_leak_detected: Boolean(checked),
                },
              })
              return { isSuccess: true }
            } catch (e) {
              return { isSuccess: false }
            }
          }}
        />
      </Column>
    </CardWrapper>
  )
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
  gap: ${spacing.XS};
`

const CardWrapper = styled.div`
  display: flex;
  gap: ${spacing.XL};
`
