import { useState } from "react"

import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"

import RentalUnitsEmptyStateImage from "./images/rental-units-empty-state.svg"

export function NoHomes() {
  const [showUpgradeBlockerDialog, setShowUpgradeBlockerDialog] =
    useState(false)

  const { t, langKeys } = useTranslate()
  const { isSuspended } = useDunning()
  const { navigate } = useRouter()

  return (
    <>
      <EmptyStateWithImage
        Image={RentalUnitsEmptyStateImage}
        title={t(langKeys.rental_units_empty_state_title)}
        description={t(langKeys.rental_units_empty_state_description)}
        content={
          <MButton
            onClick={() => {
              if (isSuspended) {
                setShowUpgradeBlockerDialog(true)
              } else {
                navigate(Routes.CreateHome.location())
              }
            }}
          >
            {t(langKeys.create_home)}
          </MButton>
        }
      />
      <UpgradeBlockerDialog
        open={showUpgradeBlockerDialog}
        onClose={() => setShowUpgradeBlockerDialog(false)}
        context="subscription_required"
      />
    </>
  )
}
