import axios, { AxiosError, AxiosResponse } from "axios"
import qs from "qs"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  getStoredRefreshToken,
  setStoredAuthorization,
} from "src/data/auth/authStorage"

const TIMEOUT = 40000

export function paramsSerializer(params: object) {
  return qs.stringify(params, { arrayFormat: "comma" })
}

export const setApiClientAccessToken = (accessToken: string | null) => {
  if (!accessToken) {
    minutApiHttpClient.defaults.headers.common["Authorization"] = false
  }
  minutApiHttpClient.defaults.headers.common["Authorization"] =
    `Bearer ${accessToken}`
}

// Used by token refresh interceptor
let isFetchingAccessToken = false

// List of waiting requests that will retry after the token refresh completes
let subscribers: ((s: string) => void)[] = []

const refreshTokenAndReattemptRequest = async (
  error: AxiosError,
  onFailedRefresh: () => void
) => {
  const { response: errorResponse } = error

  const refreshToken = getStoredRefreshToken()

  try {
    // If we can't refresh, pass the error on.
    if (!refreshToken || !errorResponse) {
      throw error
    }

    /* Token refresh procedure. Creates a new Promise that will retry the
    request, clone the request configuration from the failed request in the
    error object. */
    const retryOriginalRequest = new Promise((resolve) => {
      /* Add the request to the retry queue since there another request that
      already attempt to refresh the token */
      addSubscriber((accessToken: string) => {
        errorResponse.config.headers["Authorization"] = accessToken
          ? `Bearer ${accessToken}`
          : false
        resolve(axios(errorResponse.config))
      })
    })

    if (!isFetchingAccessToken) {
      isFetchingAccessToken = true

      /**
       * Get refresh token. Needs to use the default axios client, since we
       * otherwise would be in a infinite retry loop, if the refresh endpoint
       * returns a 401 Unauthorized with the provided refresh token.
       */
      const response = await axios.post(`${API_DEFAULT}/oauth/token`, {
        client_id: import.meta.env.REACT_APP_CLIENT_ID,
        client_secret: import.meta.env.REACT_APP_CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      })

      if (!response.data) {
        throw Error()
      }

      const { data } = response

      const newAccessToken = data.access_token

      setStoredAuthorization(data)
      setApiClientAccessToken(newAccessToken)

      isFetchingAccessToken = false
      onAccessTokenFetched(newAccessToken)
    }
    return retryOriginalRequest
  } catch (err) {
    if (onFailedRefresh) {
      onFailedRefresh()
    }

    return Promise.reject(err)
  }
}

const onAccessTokenFetched = (accessToken: string) => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => callback(accessToken))
  subscribers = []
}

const addSubscriber = (callback: (s: string) => void) => {
  subscribers.push(callback)
}

export const setTokenExpiredInterceptor = ({
  onFailedRefresh,
}: {
  onFailedRefresh: () => void
}) => {
  minutApiHttpClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError): Promise<unknown> => {
      const errorResponse = error.response

      if (isTokenExpiredError(errorResponse)) {
        return refreshTokenAndReattemptRequest(error, onFailedRefresh)
      }

      // If the error is due to other reasons, just throw it back to axios
      return Promise.reject(error)
    }
  )

  const isTokenExpiredError = (errorResponse: AxiosResponse | undefined) => {
    if (errorResponse && errorResponse.status === 401) {
      return true
    }
    return false
  }
}

export const minutApiHttpClient = axios.create({
  baseURL: import.meta.env.REACT_APP_API_URL,
  timeout: TIMEOUT,
  paramsSerializer: paramsSerializer,
})
