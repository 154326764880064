import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Paper } from "@material-ui/core"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Popper, { PopperPlacementType } from "@material-ui/core/Popper"

import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { getPortalRoot } from "src/ui/BaseModalDialog/baseModalDialogUtils"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import ExpandDownIcon from "src/ui/icons/expand-down.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

interface IDropdownButtonBase extends TMButtonProps {
  label?: string
  displayValue?: React.ReactNode
  placement?: PopperPlacementType
  onClose?: () => void
  onClickAway?: () => void
  disablePortal?: boolean
  children?: ({
    closeMenu,
    onClickAway,
  }: {
    closeMenu: (event?: React.MouseEvent<EventTarget>) => void
    onClickAway: (event?: React.MouseEvent<EventTarget>) => void
  }) => React.ReactNode
}

function DropdownButtonBase({
  label,
  displayValue,
  placement = "bottom",
  onClose,
  onClickAway,
  variant = "secondary",
  size = "small",
  disablePortal,
  onClick,
  children,
  color,
}: IDropdownButtonBase) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event?: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return
    }
    onClose?.()
    setOpen(false)
  }

  function handleClickAway() {
    onClickAway?.()
    handleClose()
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Box>
      {label && (
        <MText variant="subtitleS" marginBottom={spacing.XS2}>
          {label}
        </MText>
      )}

      <MButton
        variant={variant}
        color={color}
        ref={anchorRef}
        size={size}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          onClick?.(e)
          handleToggle()
        }}
        endIcon={<ExpandDownIcon fill="currentColor" width="10" />}
      >
        {displayValue}
      </MButton>

      {!!children && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={disablePortal}
          placement={placement}
          style={{ zIndex: Z_INDEX_ABOVE_MODAL, margin: `${spacing.XS} 0` }}
          container={getPortalRoot()}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement.includes("bottom")
                  ? "center top"
                  : "center bottom",
              }}
            >
              <DropdownContentBox>
                <ClickAwayListener onClickAway={handleClickAway}>
                  {children({
                    closeMenu: handleClose,
                    onClickAway: handleClickAway,
                  })}
                </ClickAwayListener>
              </DropdownContentBox>
            </Grow>
          )}
        </Popper>
      )}
    </Box>
  )
}

const Box = styled.div``

const DropdownContentBox = styled(Paper)`
  min-width: 200px;
`

export function DropdownButton({
  variant = "secondary",
  size = "small",
  ...props
}: IDropdownButtonBase) {
  return <DropdownButtonBase variant={variant} size={size} {...props} />
}

// ts-prune-ignore-next
export function DropdownSelect(props: IDropdownButtonBase) {
  return <DropdownButtonBase variant="secondary" size="small" {...props} />
}
