import { useCallback } from "react"

import { useOrganizationQueryId } from "src/data/organizations/hooks/useOrganizationQueryId"
import { useStoredActiveOrgId } from "src/data/storage/storage"
import { debug } from "src/utils/logger"

/** Helper hook that keeps query param and localStorage org id in sync. */
export function useActiveOrganizationId() {
  const { orgIdParam, replaceOrgIdParam } = useOrganizationQueryId()

  const {
    data: storedActiveOrgId,
    set: setStoredActiveOrgId,
    clear: clearStoredActiveOrgId,
  } = useStoredActiveOrgId({ initialValue: orgIdParam })

  const setActiveOrgId = useCallback(
    (id: string | null, options?: { omitQueryParam?: boolean }) => {
      if (!id) {
        debug.log(`setActiveOrgId: No id specified, clearing`)
        clearStoredActiveOrgId()
        return replaceOrgIdParam(undefined)
      }

      debug.log(`setActiveOrgId: setting org id to ${id}`)
      setStoredActiveOrgId(id)
      !options?.omitQueryParam && replaceOrgIdParam(id)
    },
    [clearStoredActiveOrgId, replaceOrgIdParam, setStoredActiveOrgId]
  )
  // Since we want to be able to clear the query param on logout, but at the
  // same time keep a persistent record of the last active organization, we need
  // to make the stored organization id, and not the query param, the source of
  // truth.
  return { setActiveOrgId, storedActiveOrgId }
}
