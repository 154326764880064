import { useQueryClient } from "@tanstack/react-query"

import {
  TOrganizationHomeTokenQueryParams,
  TUserHomeTokenQueryParams,
} from "src/data/homes/types/homeTokenTypes"

export const homeTokenKeys = {
  all() {
    return ["homeTokens"] as const
  },

  homeTokenList(orgId: string, filter?: TOrganizationHomeTokenQueryParams) {
    if (!filter) {
      return [...this.all(), orgId, "list"] as const
    }

    return [...this.all(), "list", filter] as const
  },

  refundType(props: { orgId: string; homeId: string }) {
    return [...this.all(), "refundType", props] as const
  },

  costEstimate(props: { orgId: string; quantity: number }) {
    return [...this.all(), "costEstimate", props] as const
  },

  userHomeTokenList(userId: string, filter?: TUserHomeTokenQueryParams) {
    if (!filter) {
      return [...this.all(), userId, "userlist"] as const
    }

    return [...this.all(), userId, "userlist", filter] as const
  },
} as const

export function useHomeTokenCache() {
  const queryClient = useQueryClient()

  function invalidateUserHomeTokensList(userId: string) {
    const cacheKey = homeTokenKeys.userHomeTokenList(userId)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  function invalidateOrganizationHomeTokenEstimate(
    orgId: string,
    quantity: number
  ) {
    const cacheKey = homeTokenKeys.costEstimate({ orgId, quantity })
    return queryClient.invalidateQueries({ queryKey: cacheKey })
  }

  return {
    invalidateUserHomeTokensList,
    invalidateOrganizationHomeTokenEstimate,
  }
}
