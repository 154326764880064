import { QUERYPARAM_ACTIVE_ORG } from "src/router/routerQueryParams"
import { Routes } from "src/router/routes"
import {
  ILocationState,
  MLocation,
  TPartialLocation,
} from "src/router/routeTypes"
import { isString } from "src/utils/genericUtil"
import { Optional } from "src/utils/tsUtil"

export function isLocation(
  o: TPartialLocation | string
): o is TPartialLocation {
  if (typeof o !== "string" && o.pathname) {
    return true
  }
  return false
}

export function urlSplit(appUrl: string, state?: ILocationState): MLocation {
  const { pathname, search, hash } = new URL(appUrl, window.location.href)
  return { pathname, search, hash, state }
}

function addQueryParam({
  key,
  slug,
  currentSearch: search,
}: {
  key: string
  slug: Optional<string>
  currentSearch: string | undefined
}): string {
  const u = new URLSearchParams(search)
  if (slug) {
    u.set(key, slug)
  }
  return u.toString()
}

function addOrgIdQueryParam({
  orgId,
  currentSearch,
}: {
  orgId: Optional<string>
  currentSearch: string | undefined
}) {
  return addQueryParam({
    key: QUERYPARAM_ACTIVE_ORG,
    currentSearch,
    slug: orgId,
  })
}

/** Append `orgId` query search param to location object. */
export function locationWithOrgId({
  location,
  orgId,
}: {
  location: Partial<MLocation>
  orgId: Optional<string>
}) {
  const search = addOrgIdQueryParam({ orgId, currentSearch: location.search })
  return { ...location, search }
}

/** Convert a string to a location object. */
export function getLocation(
  to: string | Partial<MLocation>,
  state?: ILocationState
): Partial<MLocation> {
  if (isString(to)) return urlSplit(to, state)
  return { ...to, state }
}

/** Convert a Location object to string; be careful, state metadata will be lost. */
export function getLocationString(to: string | Partial<MLocation>): string {
  if (isString(to)) return to

  const pathname = to.pathname ?? Routes.Dashboard.location().pathname
  const search = to.search ? to.search : ""
  const hash = to.hash ? to.hash : ""
  return `${pathname}${search}${hash}`
}

export function mergeQueryParams(
  q1: string | undefined,
  q2: string | undefined
): string {
  const params1 = new URLSearchParams(q1)
  const params2 = new URLSearchParams(q2)
  for (const [key, val] of params2.entries()) {
    params1.append(key, val)
  }
  return params1.toString()
}
