// Merged country data from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
// with shopify address and state meta data.

import { TCountryDataCodes } from "src/data/countries/countryTypes"

// ts-prune-ignore-next
export const addressFormats = {
  "0": "firstName,lastName|address1|address2|city|country,zip|phone",
  "1": "firstName,lastName|address1|address2|zip,city|country|phone",
  "2": "firstName,lastName|address1|address2|city|country|phone",
  "3": "firstName,lastName|address1|address2|zip,city|province,country|phone",
  "4": "firstName,lastName|address1|address2|city|country,province,zip|phone",
  "5": "firstName,lastName|address1|address2|zip,city|province|country|phone",
  "6": "firstName,lastName|address1|address2|zip,city,country|phone",
  "7": "firstName,lastName|address1|address2|city|country,province|phone",
  "8": "firstName,lastName|address1|address2|city|province,zip|country|phone",
  "9": "firstName,lastName|address1|address2|zip,city,province|country|phone",
  "10": "lastName,firstName|country,zip|province|city|address1|address2|phone",
  "11": "zip,city|address2|address1|firstName,lastName|country|phone",
  "12": "firstName,lastName|address1|address2|zip|city|country|phone",
  "13": "lastName,firstName|zip|country|province,city|address1|address2|phone",
  "14": "zip,city|country|firstName,lastName|address1|address2|phone",
  "15": "firstName,lastName|address1|address2|city|province|country,zip|phone",
}

export function isInEurope(countryCode: string | undefined): boolean {
  return !!europeanCountries.find(
    (c) => c.code === countryCode?.toLocaleUpperCase()
  )
}

export function isEuropeanUnionMemberState(
  countryCode: string | undefined
): boolean {
  return !!europeanUnionMemberStates.find(
    (c) => c.code === countryCode?.toLocaleUpperCase()
  )
}

interface ICountryData {
  address_format: {
    edit: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15
  }
  code: TCountryDataCodes
  label: string
  phone: string
  provinces?: {
    [index: string]: {
      code: string
      alternate_names?: string[]
    }
  }
  labels?: unknown
  suggested?: boolean
}

export function getProvinceArray(
  countryCode: string | undefined
): { label: string; code: string }[] {
  const provinces = countryData.find((c) => c.code === countryCode)?.provinces
  return Object.entries(provinces || {}).map(([key, value]) => ({
    label: key,
    code: value.code,
  }))
}

export const countryData: ICountryData[] = [
  {
    address_format: {
      edit: 0,
    },
    code: "AF",
    label: "Afghanistan",
    phone: "93",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AX",
    label: "Åland Islands",
    phone: "358",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AL",
    label: "Albania",
    phone: "355",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "DZ",
    labels: {
      province: "Province",
    },
    label: "Algeria",
    phone: "213",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AD",
    label: "Andorra",
    phone: "376",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "AO",
    label: "Angola",
    phone: "244",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "AI",
    label: "Anguilla",
    phone: "1-264",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "AR",
    labels: {
      province: "Province",
    },
    provinces: {
      "Buenos Aires": {
        code: "B",
      },
      Catamarca: {
        code: "K",
      },
      Chaco: {
        code: "H",
      },
      Chubut: {
        code: "U",
      },
      "Ciudad Autónoma de Buenos Aires": {
        alternate_names: ["Ciudad Autonoma de Buenos Aires"],
        code: "C",
      },
      Córdoba: {
        alternate_names: ["Cordoba"],
        code: "X",
      },
      Corrientes: {
        code: "W",
      },
      "Entre Ríos": {
        alternate_names: ["Entre Rios"],
        code: "E",
      },
      Formosa: {
        code: "P",
      },
      Jujuy: {
        code: "Y",
      },
      "La Pampa": {
        code: "L",
      },
      "La Rioja": {
        code: "F",
      },
      Mendoza: {
        code: "M",
      },
      Misiones: {
        code: "N",
      },
      Neuquén: {
        alternate_names: ["Neuquen"],
        code: "Q",
      },
      "Río Negro": {
        alternate_names: ["Rio Negro"],
        code: "R",
      },
      Salta: {
        code: "A",
      },
      "San Juan": {
        code: "J",
      },
      "San Luis": {
        code: "D",
      },
      "Santa Cruz": {
        code: "Z",
      },
      "Santa Fe": {
        code: "S",
      },
      "Santiago Del Estero": {
        code: "G",
      },
      "Tierra Del Fuego": {
        code: "V",
      },
      Tucumán: {
        alternate_names: ["Tucuman"],
        code: "T",
      },
    },
    label: "Argentina",
    phone: "54",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AM",
    label: "Armenia",
    phone: "374",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "AW",
    label: "Aruba",
    phone: "297",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "AU",
    labels: {
      city: "Suburb",
      province: "State/territory",
      zip: "Postcode",
    },
    provinces: {
      "Australian Capital Territory": {
        code: "ACT",
      },
      "New South Wales": {
        code: "NSW",
      },
      "Northern Territory": {
        code: "NT",
      },
      Queensland: {
        code: "QLD",
      },
      "South Australia": {
        code: "SA",
      },
      Tasmania: {
        code: "TAS",
      },
      Victoria: {
        code: "VIC",
      },
      "Western Australia": {
        code: "WA",
      },
    },
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AT",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
    label: "Austria",
    phone: "43",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BH",
    label: "Bahrain",
    phone: "973",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BD",
    label: "Bangladesh",
    phone: "880",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BB",
    label: "Barbados",
    phone: "1-246",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BY",
    label: "Belarus",
    phone: "375",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BE",
    label: "Belgium",
    phone: "32",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "BZ",
    label: "Belize",
    phone: "501",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "BJ",
    label: "Benin",
    phone: "229",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BM",
    label: "Bermuda",
    phone: "1-441",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BT",
    label: "Bhutan",
    phone: "975",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "BO",
    label: "Bolivia",
    phone: "591",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
    phone: "599",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BW",
    label: "Botswana",
    phone: "267",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BV",
    label: "Bouvet Island",
    phone: "47",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "BR",
    labels: {
      address1: "Street and house number",
      province: "State",
    },
    provinces: {
      Acre: {
        code: "AC",
      },
      Alagoas: {
        code: "AL",
      },
      Amapá: {
        alternate_names: ["Amapa"],
        code: "AP",
      },
      Amazonas: {
        code: "AM",
      },
      Bahia: {
        code: "BA",
      },
      Ceará: {
        alternate_names: ["Ceara"],
        code: "CE",
      },
      "Distrito Federal": {
        code: "DF",
      },
      "Espírito Santo": {
        alternate_names: ["Espirito Santo"],
        code: "ES",
      },
      Goiás: {
        alternate_names: ["Goias"],
        code: "GO",
      },
      Maranhão: {
        alternate_names: ["Maranhao"],
        code: "MA",
      },
      "Mato Grosso": {
        code: "MT",
      },
      "Mato Grosso do Sul": {
        code: "MS",
      },
      "Minas Gerais": {
        code: "MG",
      },
      Pará: {
        alternate_names: ["Para"],
        code: "PA",
      },
      Paraíba: {
        alternate_names: ["Paraiba"],
        code: "PB",
      },
      Paraná: {
        alternate_names: ["Parana"],
        code: "PR",
      },
      Pernambuco: {
        code: "PE",
      },
      Piauí: {
        alternate_names: ["Piaui"],
        code: "PI",
      },
      "Rio Grande do Norte": {
        code: "RN",
      },
      "Rio Grande do Sul": {
        code: "RS",
      },
      "Rio de Janeiro": {
        code: "RJ",
      },
      Rondônia: {
        alternate_names: ["Rondonia"],
        code: "RO",
      },
      Roraima: {
        code: "RR",
      },
      "Santa Catarina": {
        code: "SC",
      },
      "São Paulo": {
        alternate_names: ["Sao Paulo"],
        code: "SP",
      },
      Sergipe: {
        code: "SE",
      },
      Tocantins: {
        code: "TO",
      },
    },
    label: "Brazil",
    phone: "55",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BN",
    label: "Brunei Darussalam",
    phone: "673",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BG",
    label: "Bulgaria",
    phone: "359",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "BF",
    label: "Burkina Faso",
    phone: "226",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BI",
    label: "Burundi",
    phone: "257",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KH",
    label: "Cambodia",
    phone: "855",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CM",
    label: "Cameroon",
    phone: "237",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "CA",
    labels: {
      province: "Province",
    },
    provinces: {
      Alberta: {
        code: "AB",
      },
      "British Columbia": {
        code: "BC",
      },
      Manitoba: {
        code: "MB",
      },
      "New Brunswick": {
        code: "NB",
      },
      "Newfoundland and Labrador": {
        alternate_names: ["Newfoundland"],
        code: "NL",
      },
      "Northwest Territories": {
        code: "NT",
      },
      "Nova Scotia": {
        code: "NS",
      },
      Nunavut: {
        code: "NU",
      },
      Ontario: {
        code: "ON",
      },
      "Prince Edward Island": {
        code: "PE",
      },
      Quebec: {
        alternate_names: ["Québec"],
        code: "QC",
      },
      Saskatchewan: {
        code: "SK",
      },
      Yukon: {
        code: "YT",
      },
    },
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CV",
    label: "Cape Verde",
    phone: "238",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KY",
    label: "Cayman Islands",
    phone: "1-345",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "TD",
    label: "Chad",
    phone: "235",
  },
  {
    address_format: {
      edit: 15,
    },
    code: "CL",
    provinces: {
      "Arica and Parinacota": {
        alternate_names: [
          "Arica y Parinacota",
          "Región de Arica y Parinacota",
          "Region de Arica y Parinacota",
        ],
        code: "AP",
      },
      Tarapacá: {
        alternate_names: [
          "Tarapaca",
          "Región de Tarapacá",
          "Region de Tarapaca",
        ],
        code: "TA",
      },
      Antofagasta: {
        alternate_names: ["Región de Antofagasta", "Region de Antofagasta"],
        code: "AN",
      },
      Atacama: {
        alternate_names: ["Región de Atacama", "Region de Atacama"],
        code: "AT",
      },
      Coquimbo: {
        alternate_names: ["Región de Coquimbo", "Region de Coquimbo"],
        code: "CO",
      },
      Valparaíso: {
        alternate_names: [
          "Valparaiso",
          "Región de Valparaíso",
          "Region de Valparaiso",
        ],
        code: "VS",
      },
      Santiago: {
        alternate_names: [
          "Región Metropolitana",
          "Region Metropolitana",
          "Región Metropolitana de Santiago",
          "Region Metropolitana de Santiago",
        ],
        code: "RM",
      },
      "O'Higgins": {
        alternate_names: [
          "Región del Libertador General Bernardo O'Higgins",
          "Region del Libertador General Bernardo O'Higgins",
        ],
        code: "LI",
      },
      Maule: {
        alternate_names: ["Región del Maule", "Region del Maule"],
        code: "ML",
      },
      Ñuble: {
        alternate_names: ["Nuble", "Región de Ñuble", "Region de Nuble"],
        code: "NB",
      },
      Biobío: {
        alternate_names: ["Biobio", "Región del Biobío", "Region del Biobio"],
        code: "BI",
      },
      Araucanía: {
        alternate_names: [
          "Araucania",
          "Región de La Araucanía",
          "Region de La Araucania",
        ],
        code: "AR",
      },
      "Los Ríos": {
        alternate_names: [
          "Los Rios",
          "Región de Los Ríos",
          "Region de Los Rios",
        ],
        code: "LR",
      },
      "Los Lagos": {
        alternate_names: ["Región de Los Lagos", "Region de Los Lagos"],
        code: "LL",
      },
      Aysén: {
        alternate_names: [
          "Aysen",
          "Región Aysén del General Carlos Ibáñez del Campo",
          "Region Aysen del General Carlos Ibanez del Campo",
        ],
        code: "AI",
      },
      Magallanes: {
        alternate_names: [
          "Región de Magallanes y de la Antártica Chilena",
          "Region de Magallanes y de la Antartica Chilena",
        ],
        code: "MA",
      },
    },
    label: "Chile",
    phone: "56",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "CN",
    labels: {
      address1: "Full address",
      province: "Province",
    },
    provinces: {
      Anhui: {
        alternate_names: ["安徽"],
        code: "AH",
      },
      Beijing: {
        alternate_names: ["北京"],
        code: "BJ",
      },
      Chongqing: {
        alternate_names: ["重庆"],
        code: "CQ",
      },
      Fujian: {
        alternate_names: ["福建"],
        code: "FJ",
      },
      Gansu: {
        alternate_names: ["甘肃"],
        code: "GS",
      },
      Guangdong: {
        alternate_names: ["广东"],
        code: "GD",
      },
      Guangxi: {
        alternate_names: ["广西"],
        code: "GX",
      },
      Guizhou: {
        alternate_names: ["贵州"],
        code: "GZ",
      },
      Hainan: {
        alternate_names: ["海南"],
        code: "HI",
      },
      Hebei: {
        alternate_names: ["河北"],
        code: "HE",
      },
      Heilongjiang: {
        alternate_names: ["黑龙江"],
        code: "HL",
      },
      Henan: {
        alternate_names: ["河南"],
        code: "HA",
      },
      Hubei: {
        alternate_names: ["湖北"],
        code: "HB",
      },
      Hunan: {
        alternate_names: ["湖南"],
        code: "HN",
      },
      "Inner Mongolia": {
        alternate_names: ["内蒙古", "Nei Mongol"],
        code: "NM",
      },
      Jiangsu: {
        alternate_names: ["江苏"],
        code: "JS",
      },
      Jiangxi: {
        alternate_names: ["江西"],
        code: "JX",
      },
      Jilin: {
        alternate_names: ["吉林"],
        code: "JL",
      },
      Liaoning: {
        alternate_names: ["辽宁"],
        code: "LN",
      },
      Ningxia: {
        alternate_names: ["宁夏"],
        code: "NX",
      },
      Qinghai: {
        alternate_names: ["青海"],
        code: "QH",
      },
      Shaanxi: {
        alternate_names: ["陕西"],
        code: "SN",
      },
      Shandong: {
        alternate_names: ["山东"],
        code: "SD",
      },
      Shanghai: {
        alternate_names: ["上海"],
        code: "SH",
      },
      Shanxi: {
        alternate_names: ["山西"],
        code: "SX",
      },
      Sichuan: {
        alternate_names: ["四川"],
        code: "SC",
      },
      Tianjin: {
        alternate_names: ["天津"],
        code: "TJ",
      },
      Xinjiang: {
        alternate_names: ["新疆"],
        code: "XJ",
      },
      Xizang: {
        alternate_names: ["西藏", "Tibet"],
        code: "YZ",
      },
      Yunnan: {
        alternate_names: ["云南"],
        code: "YN",
      },
      Zhejiang: {
        alternate_names: ["浙江"],
        code: "ZJ",
      },
    },
    label: "China",
    phone: "86",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CX",
    label: "Christmas Island",
    phone: "61",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "CO",
    labels: {
      province: "Province",
    },
    provinces: {
      "Bogotá, D.C.": {
        alternate_names: [
          "Bogota, D.C.",
          "Bogotá",
          "Bogota",
          "Capital District",
          "Distrito Capital de Bogotá",
          "Distrito Capital de Bogota",
        ],
        code: "DC",
      },
      Amazonas: {
        code: "AMA",
      },
      Antioquia: {
        code: "ANT",
      },
      Arauca: {
        code: "ARA",
      },
      Atlántico: {
        alternate_names: ["Atlantico"],
        code: "ATL",
      },
      Bolívar: {
        alternate_names: ["Bolivar"],
        code: "BOL",
      },
      Boyacá: {
        alternate_names: ["Boyaca"],
        code: "BOY",
      },
      Caldas: {
        code: "CAL",
      },
      Caquetá: {
        alternate_names: ["Caqueta"],
        code: "CAQ",
      },
      Casanare: {
        code: "CAS",
      },
      Cauca: {
        code: "CAU",
      },
      Cesar: {
        code: "CES",
      },
      Chocó: {
        alternate_names: ["Choco"],
        code: "CHO",
      },
      Córdoba: {
        alternate_names: ["Cordoba"],
        code: "COR",
      },
      Cundinamarca: {
        code: "CUN",
      },
      Guainía: {
        alternate_names: ["Guainia"],
        code: "GUA",
      },
      Guaviare: {
        code: "GUV",
      },
      Huila: {
        code: "HUI",
      },
      "La Guajira": {
        code: "LAG",
      },
      Magdalena: {
        code: "MAG",
      },
      Meta: {
        code: "MET",
      },
      Nariño: {
        alternate_names: ["Narino"],
        code: "NAR",
      },
      "Norte de Santander": {
        code: "NSA",
      },
      Putumayo: {
        code: "PUT",
      },
      Quindío: {
        alternate_names: ["Quindio"],
        code: "QUI",
      },
      Risaralda: {
        code: "RIS",
      },
      "San Andrés, Providencia y Santa Catalina": {
        alternate_names: [
          "San Andres, Providencia y Santa Catalina",
          "San Andrés y Providencia",
          "San Andres y Providencia",
        ],
        code: "SAP",
      },
      Santander: {
        code: "SAN",
      },
      Sucre: {
        code: "SUC",
      },
      Tolima: {
        code: "TOL",
      },
      "Valle del Cauca": {
        code: "VAC",
      },
      Vaupés: {
        alternate_names: ["Vaupes"],
        code: "VAU",
      },
      Vichada: {
        code: "VID",
      },
    },
    label: "Colombia",
    phone: "57",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KM",
    label: "Comoros",
    phone: "269",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CK",
    label: "Cook Islands",
    phone: "682",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CR",
    label: "Costa Rica",
    phone: "506",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "HR",
    label: "Croatia",
    phone: "385",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CU",
    label: "Cuba",
    phone: "53",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "CW",
    label: "Curacao",
    phone: "599",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CY",
    label: "Cyprus",
    phone: "357",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CI",
    label: "Cote d'Ivoire",
    phone: "225",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "DK",
    labels: {
      address1: "Street and house number",
    },
    label: "Denmark",
    phone: "45",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "DJ",
    label: "Djibouti",
    phone: "253",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "DM",
    label: "Dominica",
    phone: "1-767",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "EC",
    label: "Ecuador",
    phone: "593",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "EG",
    labels: {
      province: "Governorate",
    },
    provinces: {
      "6th of October": {
        alternate_names: ["As Sādis min Uktūbar", "As Sadis min Uktubar"],
        code: "SU",
      },
      "Al Sharqia": {
        alternate_names: ["Ash Sharqīyah", "Ash Sharqiyah"],
        code: "SHR",
      },
      Alexandria: {
        alternate_names: ["Al Iskandarīyah", "Al Iskandariyah"],
        code: "ALX",
      },
      Aswan: {
        alternate_names: ["Aswān"],
        code: "ASN",
      },
      Asyut: {
        alternate_names: ["Asyūţ"],
        code: "AST",
      },
      Beheira: {
        alternate_names: ["Al Buḩayrah", "Al Buayrah"],
        code: "BH",
      },
      "Beni Suef": {
        alternate_names: ["Banī Suwayf", "Bani Suwayf"],
        code: "BNS",
      },
      Cairo: {
        alternate_names: ["Al Qāhirah", "Al Qahirah"],
        code: "C",
      },
      Dakahlia: {
        alternate_names: ["Ad Daqahlīyah", "Ad Daqahliyah"],
        code: "DK",
      },
      Damietta: {
        alternate_names: ["Dumyāţ", "Dumyat"],
        code: "DT",
      },
      Faiyum: {
        alternate_names: ["Al Fayyūm", "Al Fayyum"],
        code: "FYM",
      },
      Gharbia: {
        alternate_names: ["Al Gharbīyah", "Al Gharbiyah"],
        code: "GH",
      },
      Giza: {
        alternate_names: ["Al Jīzah", "Al Jizah"],
        code: "GZ",
      },
      Helwan: {
        alternate_names: ["Ḩulwān", "ulwan"],
        code: "HU",
      },
      Ismailia: {
        alternate_names: ["Al Ismāٰīlīyah", "Al Ismailiyah"],
        code: "IS",
      },
      "Kafr el-Sheikh": {
        alternate_names: ["Kafr ash Shaykh"],
        code: "KFS",
      },
      Luxor: {
        alternate_names: ["Al Uqşur", "Al Uqsur"],
        code: "LX",
      },
      Matrouh: {
        alternate_names: ["Maţrūḩ", "Matru"],
        code: "MT",
      },
      Minya: {
        alternate_names: ["Al Minyā", "Al Minya"],
        code: "MN",
      },
      Monufia: {
        alternate_names: ["Al Minūfīyah", "Al Minufiyah"],
        code: "MNF",
      },
      "New Valley": {
        alternate_names: ["Al Wādī al Jadīd", "Al Wadi al Jadid"],
        code: "WAD",
      },
      "North Sinai": {
        alternate_names: ["Shamāl Sīnā", "Shamal Sina"],
        code: "SIN",
      },
      "Port Said": {
        alternate_names: ["Būr Saٰīd", "Bur Said"],
        code: "PTS",
      },
      Qalyubia: {
        alternate_names: ["Al Qalyūbīyah", "Al Qalyubiyah"],
        code: "KB",
      },
      Qena: {
        alternate_names: ["Qinā", "Qina"],
        code: "KN",
      },
      "Red Sea": {
        alternate_names: ["Al Baḩr al Aḩmar", "Al Bar al Amar"],
        code: "BA",
      },
      Sohag: {
        alternate_names: ["Sūhāj", "Suhaj"],
        code: "SHG",
      },
      "South Sinai": {
        alternate_names: ["Janūb Sīnā", "Janub Sina"],
        code: "JS",
      },
      Suez: {
        alternate_names: ["As Suways"],
        code: "SUZ",
      },
    },
    label: "Egypt",
    phone: "20",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SV",
    label: "El Salvador",
    phone: "503",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GQ",
    label: "Equatorial Guinea",
    phone: "240",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "ER",
    label: "Eritrea",
    phone: "291",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "EE",
    label: "Estonia",
    phone: "372",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "ET",
    label: "Ethiopia",
    phone: "251",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "FO",
    label: "Faroe Islands",
    phone: "298",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "FJ",
    label: "Fiji",
    phone: "679",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "FI",
    label: "Finland",
    phone: "358",
  },
  {
    address_format: {
      edit: 6,
    },
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GF",
    label: "French Guiana",
    phone: "594",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PF",
    label: "French Polynesia",
    phone: "689",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GA",
    label: "Gabon",
    phone: "241",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GM",
    label: "Gambia",
    phone: "220",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GE",
    label: "Georgia",
    phone: "995",
  },
  {
    address_format: {
      edit: 1,
    },
    code: "DE",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  {
    address_format: {
      edit: 2,
    },
    code: "GH",
    label: "Ghana",
    phone: "233",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GI",
    label: "Gibraltar",
    phone: "350",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GR",
    label: "Greece",
    phone: "30",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GL",
    label: "Greenland",
    phone: "299",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GD",
    label: "Grenada",
    phone: "1-473",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GP",
    label: "Guadeloupe",
    phone: "590",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "GT",
    provinces: {
      "Alta Verapaz": {
        code: "AVE",
      },
      "Baja Verapaz": {
        code: "BVE",
      },
      Chimaltenango: {
        code: "CMT",
      },
      Chiquimula: {
        code: "CQM",
      },
      "El Progreso": {
        code: "EPR",
      },
      Escuintla: {
        code: "ESC",
      },
      Guatemala: {
        code: "GUA",
      },
      Huehuetenango: {
        code: "HUE",
      },
      Izabal: {
        code: "IZA",
      },
      Jalapa: {
        code: "JAL",
      },
      Jutiapa: {
        code: "JUT",
      },
      Petén: {
        alternate_names: ["Peten"],
        code: "PET",
      },
      Quetzaltenango: {
        code: "QUE",
      },
      Quiché: {
        alternate_names: ["Quiche"],
        code: "QUI",
      },
      Retalhuleu: {
        code: "RET",
      },
      Sacatepéquez: {
        alternate_names: ["Sacatepequez"],
        code: "SAC",
      },
      "San Marcos": {
        code: "SMA",
      },
      "Santa Rosa": {
        code: "SRO",
      },
      Sololá: {
        alternate_names: ["Solola"],
        code: "SOL",
      },
      Suchitepéquez: {
        alternate_names: ["Suchitepequez"],
        code: "SUC",
      },
      Totonicapán: {
        alternate_names: ["Totonicapan"],
        code: "TOT",
      },
      Zacapa: {
        code: "ZAC",
      },
    },
    label: "Guatemala",
    phone: "502",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GG",
    label: "Guernsey",
    phone: "44",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GN",
    label: "Guinea",
    phone: "224",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GW",
    label: "Guinea-Bissau",
    phone: "245",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GY",
    label: "Guyana",
    phone: "592",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "HT",
    label: "Haiti",
    phone: "509",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "HN",
    label: "Honduras",
    phone: "504",
  },
  {
    address_format: {
      edit: 7,
    },
    code: "HK",
    labels: {
      city: "District",
    },
    provinces: {
      "Hong Kong Island": {
        alternate_names: ["Hong Kong Province", "Hong Kong", "香港", "香港島"],
        code: "HK",
      },
      Kowloon: {
        alternate_names: ["九龍"],
        code: "KL",
      },
      "New Territories": {
        alternate_names: ["新界"],
        code: "NT",
      },
    },
    label: "Hong Kong",
    phone: "852",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "HU",
    label: "Hungary",
    phone: "36",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IS",
    label: "Iceland",
    phone: "354",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "IN",
    labels: {
      province: "State",
      zip: "PIN code",
    },
    provinces: {
      "Andaman and Nicobar Islands": {
        alternate_names: ["Andaman and Nicobar"],
        code: "AN",
      },
      "Andhra Pradesh": {
        code: "AP",
      },
      "Arunachal Pradesh": {
        code: "AR",
      },
      Assam: {
        code: "AS",
      },
      Bihar: {
        code: "BR",
      },
      Chandigarh: {
        code: "CH",
      },
      Chhattisgarh: {
        alternate_names: ["Chattisgarh"],
        code: "CG",
      },
      "Dadra and Nagar Haveli": {
        code: "DN",
      },
      "Daman and Diu": {
        code: "DD",
      },
      Delhi: {
        code: "DL",
      },
      Goa: {
        code: "GA",
      },
      Gujarat: {
        code: "GJ",
      },
      Haryana: {
        code: "HR",
      },
      "Himachal Pradesh": {
        code: "HP",
      },
      "Jammu and Kashmir": {
        code: "JK",
      },
      Jharkhand: {
        code: "JH",
      },
      Karnataka: {
        code: "KA",
      },
      Kerala: {
        code: "KL",
      },
      Ladakh: {
        code: "LA",
      },
      Lakshadweep: {
        code: "LD",
      },
      "Madhya Pradesh": {
        code: "MP",
      },
      Maharashtra: {
        code: "MH",
      },
      Manipur: {
        code: "MN",
      },
      Meghalaya: {
        code: "ML",
      },
      Mizoram: {
        code: "MZ",
      },
      Nagaland: {
        code: "NL",
      },
      Odisha: {
        alternate_names: ["Orissa"],
        code: "OR",
      },
      Puducherry: {
        code: "PY",
      },
      Punjab: {
        code: "PB",
      },
      Rajasthan: {
        code: "RJ",
      },
      Sikkim: {
        code: "SK",
      },
      "Tamil Nadu": {
        code: "TN",
      },
      Telangana: {
        code: "TS",
      },
      Tripura: {
        code: "TR",
      },
      "Uttar Pradesh": {
        code: "UP",
      },
      Uttarakhand: {
        code: "UK",
      },
      "West Bengal": {
        code: "WB",
      },
    },
    label: "India",
    phone: "91",
  },
  {
    address_format: {
      edit: 8,
    },
    code: "ID",
    labels: {
      province: "Province",
    },
    provinces: {
      Aceh: {
        code: "AC",
      },
      Bali: {
        code: "BA",
      },
      "Bangka Belitung": {
        code: "BB",
      },
      Banten: {
        code: "BT",
      },
      Bengkulu: {
        code: "BE",
      },
      Gorontalo: {
        code: "GO",
      },
      Jakarta: {
        code: "JK",
      },
      Jambi: {
        code: "JA",
      },
      "Jawa Barat": {
        code: "JB",
      },
      "Jawa Tengah": {
        code: "JT",
      },
      "Jawa Timur": {
        code: "JI",
      },
      "Kalimantan Barat": {
        code: "KB",
      },
      "Kalimantan Selatan": {
        code: "KS",
      },
      "Kalimantan Tengah": {
        code: "KT",
      },
      "Kalimantan Timur": {
        code: "KI",
      },
      "Kalimantan Utara": {
        code: "KU",
      },
      "Kepulauan Riau": {
        code: "KR",
      },
      Lampung: {
        code: "LA",
      },
      Maluku: {
        code: "MA",
      },
      "Maluku Utara": {
        code: "MU",
      },
      "North Sumatra": {
        alternate_names: ["Sumatra Utara"],
        code: "SU",
      },
      "Nusa Tenggara Barat": {
        code: "NB",
      },
      "Nusa Tenggara Timur": {
        code: "NT",
      },
      Papua: {
        code: "PA",
      },
      "Papua Barat": {
        code: "PB",
      },
      Riau: {
        code: "RI",
      },
      "South Sumatra": {
        alternate_names: ["Sumatra Selatan"],
        code: "SS",
      },
      "Sulawesi Barat": {
        code: "SR",
      },
      "Sulawesi Selatan": {
        code: "SN",
      },
      "Sulawesi Tengah": {
        code: "ST",
      },
      "Sulawesi Tenggara": {
        code: "SG",
      },
      "Sulawesi Utara": {
        code: "SA",
      },
      "West Sumatra": {
        alternate_names: ["Sumatra Barat"],
        code: "SB",
      },
      Yogyakarta: {
        code: "YO",
      },
    },
    label: "Indonesia",
    phone: "62",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IQ",
    label: "Iraq",
    phone: "964",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "IE",
    labels: {
      province: "County",
    },
    provinces: {
      Carlow: {
        alternate_names: [
          "Ceatharlach",
          "Contae Cheatharlach",
          "County Carlow",
          "Co. Carlow",
        ],
        code: "CW",
      },
      Cavan: {
        alternate_names: [
          "An Cabhán",
          "An Cabhan",
          "Contae an Chabháin",
          "Contae an Chabhain",
          "County Cavan",
          "Co. Cavan",
        ],
        code: "CN",
      },
      Clare: {
        alternate_names: [
          "An Clár",
          "An Clar",
          "Contae an Chláir",
          "Contae an Chlair",
          "County Clare",
          "Co. Clare",
        ],
        code: "CE",
      },
      Cork: {
        alternate_names: [
          "Corcaigh",
          "Contae Chorcaí",
          "Contae Chorcai",
          "County Cork",
          "Co. Cork",
        ],
        code: "CO",
      },
      Donegal: {
        alternate_names: [
          "Dún na nGall",
          "Dun na nGall",
          "Contae Dhún na nGall",
          "Contae Dhun na nGall",
          "County Donegal",
          "Co. Donegal",
        ],
        code: "DL",
      },
      Dublin: {
        alternate_names: [
          "Baile Átha Cliath",
          "Baile Atha Cliath",
          "County Dublin",
          "Co. Dublin",
        ],
        code: "D",
      },
      Galway: {
        alternate_names: [
          "Gaillimh",
          "Contae na Gaillimhe",
          "County Galway",
          "Co. Galway",
        ],
        code: "G",
      },
      Kerry: {
        alternate_names: [
          "Ciarraí",
          "Ciarrai",
          "Contae Chiarraí",
          "Contae Chiarrai",
          "County Kerry",
          "Co. Kerry",
        ],
        code: "KY",
      },
      Kildare: {
        alternate_names: [
          "Cill Dara",
          "Contae Chill Dara",
          "County Kildare",
          "Co. Kildare",
        ],
        code: "KE",
      },
      Kilkenny: {
        alternate_names: [
          "Cill Chainnigh",
          "Contae Chill Chainnigh",
          "County Kilkenny",
          "Co. Kilkenny",
        ],
        code: "KK",
      },
      Laois: {
        alternate_names: ["Contae Laoise", "County Laois", "Co. Laois"],
        code: "LS",
      },
      Leitrim: {
        alternate_names: [
          "Liatroim",
          "Contae Liatroma",
          "County Leitrim",
          "Co. Leitrim",
        ],
        code: "LM",
      },
      Limerick: {
        alternate_names: [
          "Luimneach",
          "Contae Luimnigh",
          "County Limerick",
          "Co. Limerick",
        ],
        code: "LK",
      },
      Longford: {
        alternate_names: [
          "An Longfort",
          "Contae an Longfoirt",
          "County Longford",
          "Co. Longford",
        ],
        code: "LD",
      },
      Louth: {
        alternate_names: [
          "Lú",
          "Lu",
          "Contae Lú",
          "Contae Lu",
          "County Louth",
          "Co. Louth",
        ],
        code: "LH",
      },
      Mayo: {
        alternate_names: [
          "Maigh Eo",
          "Contae Mhaigh Eo",
          "County Mayo",
          "Co. Mayo",
        ],
        code: "MO",
      },
      Meath: {
        alternate_names: [
          "An Mhí",
          "An Mhi",
          "Contae na Mí",
          "Contae na Mi",
          "County Meath",
          "Co. Meath",
        ],
        code: "MH",
      },
      Monaghan: {
        alternate_names: [
          "Muineachán",
          "Muineachan",
          "Contae Mhuineacháin",
          "Contae Mhuineachain",
          "County Monaghan",
          "Co. Monaghan",
        ],
        code: "MN",
      },
      Offaly: {
        alternate_names: [
          "Uíbh Fhailí",
          "Uibh Fhaili",
          "Contae Uíbh Fhailí",
          "Contae Uibh Fhaili",
          "County Offaly",
          "Co. Offaly",
        ],
        code: "OY",
      },
      Roscommon: {
        alternate_names: [
          "Ros Comáin",
          "Ros Comain",
          "Contae Ros Comáin",
          "Contae Ros Comain",
          "County Roscommon",
          "Co. Roscommon",
        ],
        code: "RN",
      },
      Sligo: {
        alternate_names: [
          "Sligeach",
          "Contae Shligigh",
          "County Sligo",
          "Co. Sligo",
        ],
        code: "SO",
      },
      Tipperary: {
        alternate_names: [
          "Tiobraid Árann",
          "Tiobraid Arann",
          "Contae Thiobraid Árann",
          "Contae Thiobraid Arann",
          "County Tipperary",
          "Co. Tipperary",
        ],
        code: "TA",
      },
      Waterford: {
        alternate_names: [
          "Port Láirge",
          "Port Lairge",
          "Contae Phort Láirge",
          "Contae Phort Lairge",
          "County Waterford",
          "Co. Waterford",
        ],
        code: "WD",
      },
      Westmeath: {
        alternate_names: [
          "An Iarmhí",
          "An Iarmhi",
          "Contae na hIarmhí",
          "Contae na hIarmhi",
          "County Westmeath",
          "Co. Westmeath",
        ],
        code: "WH",
      },
      Wexford: {
        alternate_names: [
          "Loch Garman",
          "Contae Loch Garman",
          "County Wexford",
          "Co. Wexford",
        ],
        code: "WX",
      },
      Wicklow: {
        alternate_names: [
          "Cill Mhantáin",
          "Cill Mhantain",
          "Contae Chill Mhantáin",
          "Contae Chill Mhantain",
          "County Wicklow",
          "Co. Wicklow",
        ],
        code: "WW",
      },
    },
    label: "Ireland",
    phone: "353",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IM",
    label: "Isle of Man",
    phone: "44",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "IL",
    label: "Israel",
    phone: "972",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "IT",
    labels: {
      address1: "Street and house number",
      province: "Province",
    },
    provinces: {
      Agrigento: {
        code: "AG",
      },
      Alessandria: {
        code: "AL",
      },
      Ancona: {
        code: "AN",
      },
      Aosta: {
        code: "AO",
      },
      Arezzo: {
        code: "AR",
      },
      "Ascoli Piceno": {
        code: "AP",
      },
      Asti: {
        code: "AT",
      },
      Avellino: {
        code: "AV",
      },
      Bari: {
        code: "BA",
      },
      "Barletta-Andria-Trani": {
        code: "BT",
      },
      Belluno: {
        code: "BL",
      },
      Benevento: {
        code: "BN",
      },
      Bergamo: {
        code: "BG",
      },
      Biella: {
        code: "BI",
      },
      Bologna: {
        code: "BO",
      },
      Bolzano: {
        code: "BZ",
      },
      Brescia: {
        code: "BS",
      },
      Brindisi: {
        code: "BR",
      },
      Cagliari: {
        code: "CA",
      },
      Caltanissetta: {
        code: "CL",
      },
      Campobasso: {
        code: "CB",
      },
      "Carbonia-Iglesias": {
        code: "CI",
      },
      Caserta: {
        code: "CE",
      },
      Catania: {
        code: "CT",
      },
      Catanzaro: {
        code: "CZ",
      },
      Chieti: {
        code: "CH",
      },
      Como: {
        code: "CO",
      },
      Cosenza: {
        code: "CS",
      },
      Cremona: {
        code: "CR",
      },
      Crotone: {
        code: "KR",
      },
      Cuneo: {
        code: "CN",
      },
      Enna: {
        code: "EN",
      },
      Fermo: {
        code: "FM",
      },
      Ferrara: {
        code: "FE",
      },
      Firenze: {
        code: "FI",
      },
      Foggia: {
        code: "FG",
      },
      "Forlì-Cesena": {
        alternate_names: ["Forli-Cesena"],
        code: "FC",
      },
      Frosinone: {
        code: "FR",
      },
      Genova: {
        code: "GE",
      },
      Gorizia: {
        code: "GO",
      },
      Grosseto: {
        code: "GR",
      },
      Imperia: {
        code: "IM",
      },
      Isernia: {
        code: "IS",
      },
      "L'Aquila": {
        code: "AQ",
      },
      "La Spezia": {
        code: "SP",
      },
      Latina: {
        code: "LT",
      },
      Lecce: {
        code: "LE",
      },
      Lecco: {
        code: "LC",
      },
      Livorno: {
        code: "LI",
      },
      Lodi: {
        code: "LO",
      },
      Lucca: {
        code: "LU",
      },
      Macerata: {
        code: "MC",
      },
      Mantova: {
        code: "MN",
      },
      "Massa-Carrara": {
        code: "MS",
      },
      Matera: {
        code: "MT",
      },
      "Medio Campidano": {
        code: "VS",
      },
      Messina: {
        code: "ME",
      },
      Milano: {
        code: "MI",
      },
      Modena: {
        code: "MO",
      },
      "Monza e Brianza": {
        code: "MB",
      },
      Napoli: {
        code: "NA",
      },
      Novara: {
        code: "NO",
      },
      Nuoro: {
        code: "NU",
      },
      Ogliastra: {
        code: "OG",
      },
      "Olbia-Tempio": {
        code: "OT",
      },
      Oristano: {
        code: "OR",
      },
      Padova: {
        code: "PD",
      },
      Palermo: {
        code: "PA",
      },
      Parma: {
        code: "PR",
      },
      Pavia: {
        code: "PV",
      },
      Perugia: {
        code: "PG",
      },
      "Pesaro e Urbino": {
        code: "PU",
      },
      Pescara: {
        code: "PE",
      },
      Piacenza: {
        code: "PC",
      },
      Pisa: {
        code: "PI",
      },
      Pistoia: {
        code: "PT",
      },
      Pordenone: {
        code: "PN",
      },
      Potenza: {
        code: "PZ",
      },
      Prato: {
        code: "PO",
      },
      Ragusa: {
        code: "RG",
      },
      Ravenna: {
        code: "RA",
      },
      "Reggio Calabria": {
        code: "RC",
      },
      "Reggio Emilia": {
        code: "RE",
      },
      Rieti: {
        code: "RI",
      },
      Rimini: {
        code: "RN",
      },
      Roma: {
        code: "RM",
      },
      Rovigo: {
        code: "RO",
      },
      Salerno: {
        code: "SA",
      },
      Sassari: {
        code: "SS",
      },
      Savona: {
        code: "SV",
      },
      Siena: {
        code: "SI",
      },
      Siracusa: {
        code: "SR",
      },
      Sondrio: {
        code: "SO",
      },
      Taranto: {
        code: "TA",
      },
      Teramo: {
        code: "TE",
      },
      Terni: {
        code: "TR",
      },
      Torino: {
        code: "TO",
      },
      Trapani: {
        code: "TP",
      },
      Trento: {
        code: "TN",
      },
      Treviso: {
        code: "TV",
      },
      Trieste: {
        code: "TS",
      },
      Udine: {
        code: "UD",
      },
      Varese: {
        code: "VA",
      },
      Venezia: {
        code: "VE",
      },
      "Verbano-Cusio-Ossola": {
        code: "VB",
      },
      Vercelli: {
        code: "VC",
      },
      Verona: {
        code: "VR",
      },
      "Vibo Valentia": {
        code: "VV",
      },
      Vicenza: {
        code: "VI",
      },
      Viterbo: {
        code: "VT",
      },
    },
    label: "Italy",
    phone: "39",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
  },
  {
    address_format: {
      edit: 10,
    },
    code: "JP",
    labels: {
      city: "City/ward/town/village",
      province: "Prefecture",
    },
    provinces: {
      Hokkaidō: {
        alternate_names: ["Hokkaido", "Hokkaido Prefecture", "北海道"],
        code: "JP-01",
      },
      Aomori: {
        alternate_names: ["Aomori Prefecture", "Aomori-ken", "青森県", "青森"],
        code: "JP-02",
      },
      Iwate: {
        alternate_names: ["Iwate Prefecture", "Iwate-ken", "岩手県", "岩手"],
        code: "JP-03",
      },
      Miyagi: {
        alternate_names: ["Miyagi Prefecture", "Miyagi-ken", "宮城県", "宮城"],
        code: "JP-04",
      },
      Akita: {
        alternate_names: ["Akita Prefecture", "Akita-ken", "秋田県", "秋田"],
        code: "JP-05",
      },
      Yamagata: {
        alternate_names: [
          "Yamagata Prefecture",
          "Yamagata-ken",
          "山形県",
          "山形",
        ],
        code: "JP-06",
      },
      Fukushima: {
        alternate_names: [
          "Fukushima Prefecture",
          "Fukushima-ken",
          "福島県",
          "福島",
        ],
        code: "JP-07",
      },
      Ibaraki: {
        alternate_names: [
          "Ibaraki Prefecture",
          "Ibaraki-ken",
          "茨城県",
          "茨城",
        ],
        code: "JP-08",
      },
      Tochigi: {
        alternate_names: [
          "Tochigi Prefecture",
          "Tochigi-ken",
          "栃木県",
          "栃木",
        ],
        code: "JP-09",
      },
      Gunma: {
        alternate_names: ["Gunma Prefecture", "Gunma-ken", "群馬県", "群馬"],
        code: "JP-10",
      },
      Saitama: {
        alternate_names: [
          "Saitama Prefecture",
          "Saitama-ken",
          "埼玉県",
          "埼玉",
        ],
        code: "JP-11",
      },
      Chiba: {
        alternate_names: ["Chiba Prefecture", "Chiba-ken", "千葉県", "千葉"],
        code: "JP-12",
      },
      Tōkyō: {
        alternate_names: [
          "Tokyo",
          "Tokyo Prefecture",
          "Tōkyō-to",
          "Tokyo-to",
          "東京都",
          "東京",
        ],
        code: "JP-13",
      },
      Kanagawa: {
        alternate_names: [
          "Kanagawa Prefecture",
          "Kanagawa-ken",
          "神奈川県",
          "神奈川",
        ],
        code: "JP-14",
      },
      Niigata: {
        alternate_names: [
          "Niigata Prefecture",
          "Niigata-ken",
          "新潟県",
          "新潟",
        ],
        code: "JP-15",
      },
      Toyama: {
        alternate_names: ["Toyama Prefecture", "Toyama-ken", "富山県", "富山"],
        code: "JP-16",
      },
      Ishikawa: {
        alternate_names: [
          "Ishikawa Prefecture",
          "Ishikawa-ken",
          "石川県",
          "石川",
        ],
        code: "JP-17",
      },
      Fukui: {
        alternate_names: ["Fukui Prefecture", "Fukui-ken", "福井県", "福井"],
        code: "JP-18",
      },
      Yamanashi: {
        alternate_names: [
          "Yamanashi Prefecture",
          "Yamanashi-ken",
          "山梨県",
          "山梨",
        ],
        code: "JP-19",
      },
      Nagano: {
        alternate_names: ["Nagano Prefecture", "Nagano-ken", "長野県", "長野"],
        code: "JP-20",
      },
      Gifu: {
        alternate_names: ["Gifu Prefecture", "Gifu-ken", "岐阜県", "岐阜"],
        code: "JP-21",
      },
      Shizuoka: {
        alternate_names: [
          "Shizuoka Prefecture",
          "Shizuoka-ken",
          "静岡県",
          "静岡",
        ],
        code: "JP-22",
      },
      Aichi: {
        alternate_names: ["Aichi Prefecture", "Aichi-ken", "愛知県", "愛知"],
        code: "JP-23",
      },
      Mie: {
        alternate_names: ["Mie Prefecture", "Mie-ken", "三重県", "三重"],
        code: "JP-24",
      },
      Shiga: {
        alternate_names: ["Shiga Prefecture", "Shiga-ken", "滋賀県", "滋賀"],
        code: "JP-25",
      },
      Kyōto: {
        alternate_names: [
          "Kyoto",
          "Kyoto Prefecture",
          "Kyōto-fu",
          "Kyoto-fu",
          "京都府",
          "京都",
        ],
        code: "JP-26",
      },
      Ōsaka: {
        alternate_names: [
          "Osaka",
          "Osaka Prefecture",
          "Ōsaka-fu",
          "Osaka-fu",
          "大阪府",
          "大阪",
        ],
        code: "JP-27",
      },
      Hyōgo: {
        alternate_names: [
          "Hyogo",
          "Hyogo Prefecture",
          "Hyōgo-ken",
          "Hyogo-ken",
          "兵庫県",
          "兵庫",
        ],
        code: "JP-28",
      },
      Nara: {
        alternate_names: ["Nara Prefecture", "Nara-ken", "奈良県", "奈良"],
        code: "JP-29",
      },
      Wakayama: {
        alternate_names: [
          "Wakayama Prefecture",
          "Wakayama-ken",
          "和歌山県",
          "和歌山",
        ],
        code: "JP-30",
      },
      Tottori: {
        alternate_names: [
          "Tottori Prefecture",
          "Tottori-ken",
          "鳥取県",
          "鳥取",
        ],
        code: "JP-31",
      },
      Shimane: {
        alternate_names: [
          "Shimane Prefecture",
          "Shimane-ken",
          "島根県",
          "島根",
        ],
        code: "JP-32",
      },
      Okayama: {
        alternate_names: [
          "Okayama Prefecture",
          "Okayama-ken",
          "岡山県",
          "岡山",
        ],
        code: "JP-33",
      },
      Hiroshima: {
        alternate_names: [
          "Hiroshima Prefecture",
          "Hiroshima-ken",
          "広島県",
          "広島",
        ],
        code: "JP-34",
      },
      Yamaguchi: {
        alternate_names: [
          "Yamaguchi Prefecture",
          "Yamaguchi-ken",
          "山口県",
          "山口",
        ],
        code: "JP-35",
      },
      Tokushima: {
        alternate_names: [
          "Tokushima Prefecture",
          "Tokushima-ken",
          "徳島県",
          "徳島",
        ],
        code: "JP-36",
      },
      Kagawa: {
        alternate_names: ["Kagawa Prefecture", "Kagawa-ken", "香川県", "香川"],
        code: "JP-37",
      },
      Ehime: {
        alternate_names: ["Ehime Prefecture", "Ehime-ken", "愛媛県", "愛媛"],
        code: "JP-38",
      },
      Kōchi: {
        alternate_names: [
          "Kochi",
          "Kochi Prefecture",
          "Kōchi-ken",
          "Kochi-ken",
          "高知県",
          "高知",
        ],
        code: "JP-39",
      },
      Fukuoka: {
        alternate_names: [
          "Fukuoka Prefecture",
          "Fukuoka-ken",
          "福岡県",
          "福岡",
        ],
        code: "JP-40",
      },
      Saga: {
        alternate_names: ["Saga Prefecture", "Saga-ken", "佐賀県", "佐賀"],
        code: "JP-41",
      },
      Nagasaki: {
        alternate_names: [
          "Nagasaki Prefecture",
          "Nagasaki-ken",
          "長崎県",
          "長崎",
        ],
        code: "JP-42",
      },
      Kumamoto: {
        alternate_names: [
          "Kumamoto Prefecture",
          "Kumamoto-ken",
          "熊本県",
          "熊本",
        ],
        code: "JP-43",
      },
      Ōita: {
        alternate_names: [
          "Oita",
          "Oita Prefecture",
          "Ōita-ken",
          "Oita-ken",
          "大分県",
          "大分",
        ],
        code: "JP-44",
      },
      Miyazaki: {
        alternate_names: [
          "Miyazaki Prefecture",
          "Miyazaki-ken",
          "宮崎県",
          "宮崎",
        ],
        code: "JP-45",
      },
      Kagoshima: {
        alternate_names: [
          "Kagoshima Prefecture",
          "Kagoshima-ken",
          "鹿児島県",
          "鹿児島",
        ],
        code: "JP-46",
      },
      Okinawa: {
        alternate_names: [
          "Okinawa Prefecture",
          "Okinawa-ken",
          "沖縄県",
          "沖縄",
        ],
        code: "JP-47",
      },
    },
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  {
    address_format: {
      edit: 0,
    },
    code: "JE",
    label: "Jersey",
    phone: "44",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "JO",
    label: "Jordan",
    phone: "962",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KE",
    label: "Kenya",
    phone: "254",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KI",
    label: "Kiribati",
    phone: "686",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "XK",
    label: "Kosovo",
    phone: "383",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KW",
    label: "Kuwait",
    phone: "965",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LA",
    labels: {
      province: "Province",
    },
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LV",
    label: "Latvia",
    phone: "371",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LB",
    label: "Lebanon",
    phone: "961",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LS",
    label: "Lesotho",
    phone: "266",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LR",
    label: "Liberia",
    phone: "231",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LY",
    label: "Libya",
    phone: "218",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LT",
    label: "Lithuania",
    phone: "370",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LU",
    label: "Luxembourg",
    phone: "352",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MO",
    label: "Macao",
    phone: "853",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MG",
    label: "Madagascar",
    phone: "261",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "MW",
    label: "Malawi",
    phone: "265",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "MY",
    labels: {
      province: "State/territory",
      zip: "Postcode",
    },
    provinces: {
      Johor: {
        code: "JHR",
      },
      Kedah: {
        code: "KDH",
      },
      Kelantan: {
        code: "KTN",
      },
      "Kuala Lumpur": {
        code: "KUL",
      },
      Labuan: {
        code: "LBN",
      },
      Melaka: {
        code: "MLK",
      },
      "Negeri Sembilan": {
        code: "NSN",
      },
      Pahang: {
        code: "PHG",
      },
      Penang: {
        alternate_names: ["Pulau Pinang"],
        code: "PNG",
      },
      Perak: {
        code: "PRK",
      },
      Perlis: {
        code: "PLS",
      },
      Putrajaya: {
        code: "PJY",
      },
      Sabah: {
        code: "SBH",
      },
      Sarawak: {
        code: "SWK",
      },
      Selangor: {
        code: "SGR",
      },
      Terengganu: {
        code: "TRG",
      },
    },
    label: "Malaysia",
    phone: "60",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MV",
    label: "Maldives",
    phone: "960",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "ML",
    label: "Mali",
    phone: "223",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MT",
    label: "Malta",
    phone: "356",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MQ",
    label: "Martinique",
    phone: "596",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MR",
    label: "Mauritania",
    phone: "222",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MU",
    label: "Mauritius",
    phone: "230",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "YT",
    label: "Mayotte",
    phone: "262",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "MX",
    labels: {
      address1: "Street and house number",
      province: "State",
    },
    provinces: {
      Aguascalientes: {
        code: "AGS",
      },
      "Baja California": {
        code: "BC",
      },
      "Baja California Sur": {
        code: "BCS",
      },
      Campeche: {
        code: "CAMP",
      },
      Chiapas: {
        code: "CHIS",
      },
      Chihuahua: {
        code: "CHIH",
      },
      "Ciudad de México": {
        alternate_names: ["Ciudad de Mexico"],
        code: "DF",
      },
      Coahuila: {
        code: "COAH",
      },
      Colima: {
        code: "COL",
      },
      Durango: {
        code: "DGO",
      },
      Guanajuato: {
        code: "GTO",
      },
      Guerrero: {
        code: "GRO",
      },
      Hidalgo: {
        code: "HGO",
      },
      Jalisco: {
        code: "JAL",
      },
      México: {
        alternate_names: ["Mexico"],
        code: "MEX",
      },
      Michoacán: {
        alternate_names: ["Michoacan"],
        code: "MICH",
      },
      Morelos: {
        code: "MOR",
      },
      Nayarit: {
        code: "NAY",
      },
      "Nuevo León": {
        alternate_names: ["Nuevo Leon"],
        code: "NL",
      },
      Oaxaca: {
        code: "OAX",
      },
      Puebla: {
        code: "PUE",
      },
      Querétaro: {
        alternate_names: ["Queretaro"],
        code: "QRO",
      },
      "Quintana Roo": {
        code: "Q ROO",
      },
      "San Luis Potosí": {
        alternate_names: ["San Luis Potosi"],
        code: "SLP",
      },
      Sinaloa: {
        code: "SIN",
      },
      Sonora: {
        code: "SON",
      },
      Tabasco: {
        code: "TAB",
      },
      Tamaulipas: {
        code: "TAMPS",
      },
      Tlaxcala: {
        code: "TLAX",
      },
      Veracruz: {
        code: "VER",
      },
      Yucatán: {
        alternate_names: ["Yucatan"],
        code: "YUC",
      },
      Zacatecas: {
        code: "ZAC",
      },
    },
    label: "Mexico",
    phone: "52",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MC",
    label: "Monaco",
    phone: "377",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MN",
    label: "Mongolia",
    phone: "976",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "ME",
    label: "Montenegro",
    phone: "382",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MS",
    label: "Montserrat",
    phone: "1-664",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MA",
    label: "Morocco",
    phone: "212",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MZ",
    label: "Mozambique",
    phone: "258",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MM",
    label: "Myanmar",
    phone: "95",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NA",
    label: "Namibia",
    phone: "264",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NR",
    label: "Nauru",
    phone: "674",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NP",
    label: "Nepal",
    phone: "977",
  },
  {
    address_format: {
      edit: 1,
    },
    code: "NL",
    labels: {
      address1: "Street and house number",
    },
    label: "Netherlands",
    phone: "31",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "AN",
    label: "Netherlands Antilles",
    phone: "599",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NC",
    label: "New Caledonia",
    phone: "687",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "NZ",
    provinces: {
      Auckland: {
        code: "AUK",
      },
      "Bay of Plenty": {
        code: "BOP",
      },
      Canterbury: {
        code: "CAN",
      },
      Gisborne: {
        code: "GIS",
      },
      "Hawke's Bay": {
        code: "HKB",
      },
      "Manawatu-Wanganui": {
        code: "MWT",
      },
      Marlborough: {
        code: "MBH",
      },
      Nelson: {
        code: "NSN",
      },
      Northland: {
        code: "NTL",
      },
      Otago: {
        code: "OTA",
      },
      Southland: {
        code: "STL",
      },
      Taranaki: {
        code: "TKI",
      },
      Tasman: {
        code: "TAS",
      },
      Waikato: {
        code: "WKO",
      },
      Wellington: {
        code: "WGN",
      },
      "West Coast": {
        code: "WTC",
      },
    },
    label: "New Zealand",
    phone: "64",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NI",
    label: "Nicaragua",
    phone: "505",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NE",
    label: "Niger",
    phone: "227",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "NG",
    labels: {
      province: "State",
    },
    provinces: {
      Abia: {
        code: "AB",
      },
      "Abuja Federal Capital Territory": {
        code: "FC",
      },
      Adamawa: {
        code: "AD",
      },
      "Akwa Ibom": {
        code: "AK",
      },
      Anambra: {
        code: "AN",
      },
      Bauchi: {
        code: "BA",
      },
      Bayelsa: {
        code: "BY",
      },
      Benue: {
        code: "BE",
      },
      Borno: {
        code: "BO",
      },
      "Cross River": {
        code: "CR",
      },
      Delta: {
        code: "DE",
      },
      Ebonyi: {
        code: "EB",
      },
      Edo: {
        code: "ED",
      },
      Ekiti: {
        code: "EK",
      },
      Enugu: {
        code: "EN",
      },
      Gombe: {
        code: "GO",
      },
      Imo: {
        code: "IM",
      },
      Jigawa: {
        code: "JI",
      },
      Kaduna: {
        code: "KD",
      },
      Kano: {
        code: "KN",
      },
      Katsina: {
        code: "KT",
      },
      Kebbi: {
        code: "KE",
      },
      Kogi: {
        code: "KO",
      },
      Kwara: {
        code: "KW",
      },
      Lagos: {
        code: "LA",
      },
      Nasarawa: {
        code: "NA",
      },
      Niger: {
        code: "NI",
      },
      Ogun: {
        code: "OG",
      },
      Ondo: {
        code: "ON",
      },
      Osun: {
        code: "OS",
      },
      Oyo: {
        code: "OY",
      },
      Plateau: {
        code: "PL",
      },
      Rivers: {
        code: "RI",
      },
      Sokoto: {
        code: "SO",
      },
      Taraba: {
        code: "TA",
      },
      Yobe: {
        code: "YO",
      },
      Zamfara: {
        code: "ZA",
      },
    },
    label: "Nigeria",
    phone: "234",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NU",
    label: "Niue",
    phone: "683",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "NF",
    label: "Norfolk Island",
    phone: "672",
  },

  {
    address_format: {
      edit: 0,
    },
    code: "NO",
    labels: {
      address1: "Street and house number",
    },
    label: "Norway",
    phone: "47",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "OM",
    label: "Oman",
    phone: "968",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PK",
    label: "Pakistan",
    phone: "92",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  {
    address_format: {
      edit: 7,
    },
    code: "PA",
    provinces: {
      "Bocas del Toro": {
        code: "PA-1",
      },
      Chiriquí: {
        alternate_names: ["Chiriqui"],
        code: "PA-4",
      },
      Coclé: {
        alternate_names: ["Cocle"],
        code: "PA-2",
      },
      Colón: {
        alternate_names: ["Colon"],
        code: "PA-3",
      },
      Darién: {
        alternate_names: ["Darien"],
        code: "PA-5",
      },
      Emberá: {
        alternate_names: ["Embera"],
        code: "PA-EM",
      },
      Herrera: {
        code: "PA-6",
      },
      "Kuna Yala": {
        code: "PA-KY",
      },
      "Los Santos": {
        code: "PA-7",
      },
      "Ngöbe-Buglé": {
        alternate_names: ["Ngobe-Bugle"],
        code: "PA-NB",
      },
      Panamá: {
        alternate_names: ["Panama"],
        code: "PA-8",
      },
      "Panamá Oeste": {
        alternate_names: ["Panama Oeste"],
        code: "PA-10",
      },
      Veraguas: {
        code: "PA-9",
      },
    },
    label: "Panama",
    phone: "507",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PG",
    label: "Papua New Guinea",
    phone: "675",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PY",
    label: "Paraguay",
    phone: "595",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "PE",
    provinces: {
      Amazonas: {
        code: "PE-AMA",
      },
      Áncash: {
        alternate_names: ["Ancash", "Ancash"],
        code: "PE-ANC",
      },
      Apurímac: {
        alternate_names: ["Apurimac", "Apurimac"],
        code: "PE-APU",
      },
      Arequipa: {
        code: "PE-ARE",
      },
      Ayacucho: {
        code: "PE-AYA",
      },
      Cajamarca: {
        code: "PE-CAJ",
      },
      Callao: {
        code: "PE-CAL",
      },
      Cuzco: {
        code: "PE-CUS",
      },
      Huancavelica: {
        code: "PE-HUV",
      },
      Huánuco: {
        alternate_names: ["Huanuco", "Huanuco"],
        code: "PE-HUC",
      },
      Ica: {
        code: "PE-ICA",
      },
      Junín: {
        alternate_names: ["Junin", "Junin"],
        code: "PE-JUN",
      },
      "La Libertad": {
        code: "PE-LAL",
      },
      Lambayeque: {
        code: "PE-LAM",
      },
      "Lima (departamento)": {
        code: "PE-LIM",
      },
      "Lima (provincia)": {
        code: "PE-LMA",
      },
      Loreto: {
        code: "PE-LOR",
      },
      "Madre de Dios": {
        code: "PE-MDD",
      },
      Moquegua: {
        code: "PE-MOQ",
      },
      Pasco: {
        code: "PE-PAS",
      },
      Piura: {
        code: "PE-PIU",
      },
      Puno: {
        code: "PE-PUN",
      },
      "San Martín": {
        alternate_names: ["San Martin", "San Martin"],
        code: "PE-SAM",
      },
      Tacna: {
        code: "PE-TAC",
      },
      Tumbes: {
        code: "PE-TUM",
      },
      Ucayali: {
        code: "PE-UCA",
      },
    },
    label: "Peru",
    phone: "51",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PH",
    label: "Philippines",
    phone: "63",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PN",
    label: "Pitcairn",
    phone: "870",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PL",
    label: "Poland",
    phone: "48",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "PT",
    provinces: {
      Açores: {
        alternate_names: ["Acores"],
        code: "PT-20",
      },
      Aveiro: {
        code: "PT-01",
      },
      Beja: {
        code: "PT-02",
      },
      Braga: {
        code: "PT-03",
      },
      Bragança: {
        alternate_names: ["Braganca"],
        code: "PT-04",
      },
      "Castelo Branco": {
        code: "PT-05",
      },
      Coimbra: {
        code: "PT-06",
      },
      Évora: {
        alternate_names: ["Evora"],
        code: "PT-07",
      },
      Faro: {
        code: "PT-08",
      },
      Guarda: {
        code: "PT-09",
      },
      Leiria: {
        code: "PT-10",
      },
      Lisboa: {
        code: "PT-11",
      },
      Madeira: {
        code: "PT-30",
      },
      Portalegre: {
        code: "PT-12",
      },
      Porto: {
        code: "PT-13",
      },
      Santarém: {
        alternate_names: ["Santarem"],
        code: "PT-14",
      },
      Setúbal: {
        alternate_names: ["Setubal"],
        code: "PT-15",
      },
      "Viana do Castelo": {
        code: "PT-16",
      },
      "Vila Real": {
        code: "PT-17",
      },
      Viseu: {
        code: "PT-18",
      },
    },
    label: "Portugal",
    phone: "351",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "QA",
    label: "Qatar",
    phone: "974",
  },

  {
    address_format: {
      edit: 0,
    },
    code: "RE",
    label: "Reunion",
    phone: "262",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "RO",
    labels: {
      province: "County",
    },
    provinces: {
      Alba: {
        code: "AB",
      },
      Arad: {
        code: "AR",
      },
      Argeș: {
        alternate_names: ["Arge?"],
        code: "AG",
      },
      Bacău: {
        alternate_names: ["Bacau"],
        code: "BC",
      },
      Bihor: {
        code: "BH",
      },
      "Bistrița-Năsăud": {
        alternate_names: ["Bistri?a-Nasaud"],
        code: "BN",
      },
      Botoșani: {
        alternate_names: ["Boto?ani"],
        code: "BT",
      },
      Brăila: {
        alternate_names: ["Braila"],
        code: "BR",
      },
      Brașov: {
        alternate_names: ["Bra?ov"],
        code: "BV",
      },
      București: {
        alternate_names: ["Bucure?ti"],
        code: "B",
      },
      Buzău: {
        alternate_names: ["Buzau"],
        code: "BZ",
      },
      "Caraș-Severin": {
        alternate_names: ["Cara?-Severin"],
        code: "CS",
      },
      Cluj: {
        code: "CJ",
      },
      Constanța: {
        alternate_names: ["Constan?a"],
        code: "CT",
      },
      Covasna: {
        code: "CV",
      },
      Călărași: {
        alternate_names: ["Calara?i"],
        code: "CL",
      },
      Dolj: {
        code: "DJ",
      },
      Dâmbovița: {
        alternate_names: ["Dambovi?a"],
        code: "DB",
      },
      Galați: {
        alternate_names: ["Gala?i"],
        code: "GL",
      },
      Giurgiu: {
        code: "GR",
      },
      Gorj: {
        code: "GJ",
      },
      Harghita: {
        code: "HR",
      },
      Hunedoara: {
        code: "HD",
      },
      Ialomița: {
        alternate_names: ["Ialomi?a"],
        code: "IL",
      },
      Iași: {
        alternate_names: ["Ia?i"],
        code: "IS",
      },
      Ilfov: {
        code: "IF",
      },
      Maramureș: {
        alternate_names: ["Maramure?"],
        code: "MM",
      },
      Mehedinți: {
        alternate_names: ["Mehedin?i"],
        code: "MH",
      },
      Mureș: {
        alternate_names: ["Mure?"],
        code: "MS",
      },
      Neamț: {
        alternate_names: ["Neam?"],
        code: "NT",
      },
      Olt: {
        code: "OT",
      },
      Prahova: {
        code: "PH",
      },
      Sălaj: {
        alternate_names: ["Salaj"],
        code: "SJ",
      },
      "Satu Mare": {
        code: "SM",
      },
      Sibiu: {
        code: "SB",
      },
      Suceava: {
        code: "SV",
      },
      Teleorman: {
        code: "TR",
      },
      Timiș: {
        alternate_names: ["Timi?"],
        code: "TM",
      },
      Tulcea: {
        code: "TL",
      },
      Vâlcea: {
        alternate_names: ["Valcea"],
        code: "VL",
      },
      Vaslui: {
        code: "VS",
      },
      Vrancea: {
        code: "VN",
      },
    },
    label: "Romania",
    phone: "40",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "RU",
    provinces: {
      "Altai Krai": {
        code: "ALT",
      },
      "Altai Republic": {
        code: "AL",
      },
      "Amur Oblast": {
        code: "AMU",
      },
      "Arkhangelsk Oblast": {
        code: "ARK",
      },
      "Astrakhan Oblast": {
        code: "AST",
      },
      "Belgorod Oblast": {
        code: "BEL",
      },
      "Bryansk Oblast": {
        code: "BRY",
      },
      "Chechen Republic": {
        code: "CE",
      },
      "Chelyabinsk Oblast": {
        code: "CHE",
      },
      "Chukotka Autonomous Okrug": {
        code: "CHU",
      },
      "Chuvash Republic": {
        code: "CU",
      },
      "Irkutsk Oblast": {
        code: "IRK",
      },
      "Ivanovo Oblast": {
        code: "IVA",
      },
      "Jewish Autonomous Oblast": {
        code: "YEV",
      },
      "Kabardino-Balkarian Republic": {
        code: "KB",
      },
      "Kaliningrad Oblast": {
        code: "KGD",
      },
      "Kaluga Oblast": {
        code: "KLU",
      },
      "Kamchatka Krai": {
        code: "KAM",
      },
      "Karachay–Cherkess Republic": {
        alternate_names: ["Karachay?Cherkess Republic"],
        code: "KC",
      },
      "Kemerovo Oblast": {
        code: "KEM",
      },
      "Khabarovsk Krai": {
        code: "KHA",
      },
      "Khanty-Mansi Autonomous Okrug": {
        code: "KHM",
      },
      "Kirov Oblast": {
        code: "KIR",
      },
      "Komi Republic": {
        code: "KO",
      },
      "Kostroma Oblast": {
        code: "KOS",
      },
      "Krasnodar Krai": {
        code: "KDA",
      },
      "Krasnoyarsk Krai": {
        code: "KYA",
      },
      "Kurgan Oblast": {
        code: "KGN",
      },
      "Kursk Oblast": {
        code: "KRS",
      },
      "Leningrad Oblast": {
        code: "LEN",
      },
      "Lipetsk Oblast": {
        code: "LIP",
      },
      "Magadan Oblast": {
        code: "MAG",
      },
      "Mari El Republic": {
        code: "ME",
      },
      Moscow: {
        code: "MOW",
      },
      "Moscow Oblast": {
        code: "MOS",
      },
      "Murmansk Oblast": {
        code: "MUR",
      },
      "Nizhny Novgorod Oblast": {
        code: "NIZ",
      },
      "Novgorod Oblast": {
        code: "NGR",
      },
      "Novosibirsk Oblast": {
        code: "NVS",
      },
      "Omsk Oblast": {
        code: "OMS",
      },
      "Orenburg Oblast": {
        code: "ORE",
      },
      "Oryol Oblast": {
        code: "ORL",
      },
      "Penza Oblast": {
        code: "PNZ",
      },
      "Perm Krai": {
        code: "PER",
      },
      "Primorsky Krai": {
        code: "PRI",
      },
      "Pskov Oblast": {
        code: "PSK",
      },
      "Republic of Adygeya": {
        code: "AD",
      },
      "Republic of Bashkortostan": {
        code: "BA",
      },
      "Republic of Buryatia": {
        code: "BU",
      },
      "Republic of Dagestan": {
        code: "DA",
      },
      "Republic of Ingushetia": {
        code: "IN",
      },
      "Republic of Kalmykia": {
        code: "KL",
      },
      "Republic of Karelia": {
        code: "KR",
      },
      "Republic of Khakassia": {
        code: "KK",
      },
      "Republic of Mordovia": {
        code: "MO",
      },
      "Republic of North Ossetia–Alania": {
        alternate_names: ["Republic of North Ossetia?Alania"],
        code: "SE",
      },
      "Republic of Tatarstan": {
        code: "TA",
      },
      "Rostov Oblast": {
        code: "ROS",
      },
      "Ryazan Oblast": {
        code: "RYA",
      },
      "Saint Petersburg": {
        code: "SPE",
      },
      "Sakha Republic (Yakutia)": {
        code: "SA",
      },
      "Sakhalin Oblast": {
        code: "SAK",
      },
      "Samara Oblast": {
        code: "SAM",
      },
      "Saratov Oblast": {
        code: "SAR",
      },
      "Smolensk Oblast": {
        code: "SMO",
      },
      "Stavropol Krai": {
        code: "STA",
      },
      "Sverdlovsk Oblast": {
        code: "SVE",
      },
      "Tambov Oblast": {
        code: "TAM",
      },
      "Tomsk Oblast": {
        code: "TOM",
      },
      "Tula Oblast": {
        code: "TUL",
      },
      "Tver Oblast": {
        code: "TVE",
      },
      "Tyumen Oblast": {
        code: "TYU",
      },
      "Tyva Republic": {
        code: "TY",
      },
      Udmurtia: {
        code: "UD",
      },
      "Ulyanovsk Oblast": {
        code: "ULY",
      },
      "Vladimir Oblast": {
        code: "VLA",
      },
      "Volgograd Oblast": {
        code: "VGG",
      },
      "Vologda Oblast": {
        code: "VLG",
      },
      "Voronezh Oblast": {
        code: "VOR",
      },
      "Yamalo-Nenets Autonomous Okrug": {
        code: "YAN",
      },
      "Yaroslavl Oblast": {
        code: "YAR",
      },
      "Zabaykalsky Krai": {
        code: "ZAB",
      },
    },
    label: "Russian Federation",
    phone: "7",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "RW",
    label: "Rwanda",
    phone: "250",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "BL",
    label: "Saint Barthelemy",
    phone: "590",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SH",
    label: "Saint Helena",
    phone: "290",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LC",
    label: "Saint Lucia",
    phone: "1-758",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "WS",
    label: "Samoa",
    phone: "685",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SM",
    label: "San Marino",
    phone: "378",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SN",
    label: "Senegal",
    phone: "221",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "RS",
    label: "Serbia",
    phone: "381",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SC",
    label: "Seychelles",
    phone: "248",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SL",
    label: "Sierra Leone",
    phone: "232",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SG",
    label: "Singapore",
    phone: "65",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SK",
    label: "Slovakia",
    phone: "421",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SI",
    label: "Slovenia",
    phone: "386",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SB",
    label: "Solomon Islands",
    phone: "677",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SO",
    label: "Somalia",
    phone: "252",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "ZA",
    labels: {
      province: "Province",
    },
    provinces: {
      "Eastern Cape": {
        code: "EC",
      },
      "Free State": {
        code: "FS",
      },
      Gauteng: {
        code: "GT",
      },
      "KwaZulu-Natal": {
        code: "NL",
      },
      Limpopo: {
        code: "LP",
      },
      Mpumalanga: {
        code: "MP",
      },
      "North West": {
        code: "NW",
      },
      "Northern Cape": {
        code: "NC",
      },
      "Western Cape": {
        code: "WC",
      },
    },
    label: "South Africa",
    phone: "27",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  {
    address_format: {
      edit: 13,
    },
    code: "KR",
    labels: {
      province: "Province",
    },
    provinces: {
      Busan: {
        code: "KR-26",
      },
      Chungbuk: {
        code: "KR-43",
      },
      Chungnam: {
        code: "KR-44",
      },
      Daegu: {
        code: "KR-27",
      },
      Daejeon: {
        code: "KR-30",
      },
      Gangwon: {
        code: "KR-42",
      },
      Gwangju: {
        code: "KR-29",
      },
      Gyeongbuk: {
        code: "KR-47",
      },
      Gyeonggi: {
        code: "KR-41",
      },
      Gyeongnam: {
        code: "KR-48",
      },
      Incheon: {
        code: "KR-28",
      },
      Jeju: {
        code: "KR-49",
      },
      Jeonbuk: {
        code: "KR-45",
      },
      Jeonnam: {
        code: "KR-46",
      },
      Sejong: {
        code: "KR-50",
      },
      Seoul: {
        code: "KR-11",
      },
      Ulsan: {
        code: "KR-31",
      },
    },
    label: "Korea, Republic of",
    phone: "82",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "SS",
    label: "South Sudan",
    phone: "211",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "ES",
    labels: {
      address1: "Street and house number",
      province: "Province",
    },
    provinces: {
      "A Coruña": {
        alternate_names: ["A Coruna"],
        code: "C",
      },
      Álava: {
        alternate_names: ["Alava"],
        code: "VI",
      },
      Albacete: {
        code: "AB",
      },
      Alicante: {
        code: "A",
      },
      Almería: {
        alternate_names: ["Almeria"],
        code: "AL",
      },
      Asturias: {
        code: "O",
      },
      Ávila: {
        alternate_names: ["Avila"],
        code: "AV",
      },
      Badajoz: {
        code: "BA",
      },
      Balears: {
        alternate_names: ["Baleares"],
        code: "PM",
      },
      Barcelona: {
        code: "B",
      },
      Burgos: {
        code: "BU",
      },
      Cáceres: {
        alternate_names: ["Caceres"],
        code: "CC",
      },
      Cádiz: {
        alternate_names: ["Cadiz"],
        code: "CA",
      },
      Cantabria: {
        code: "S",
      },
      Castellón: {
        alternate_names: ["Castellon", "Castelló", "Castello"],
        code: "CS",
      },
      Ceuta: {
        code: "CE",
      },
      "Ciudad Real": {
        code: "CR",
      },
      Córdoba: {
        alternate_names: ["Cordoba", "Cordova"],
        code: "CO",
      },
      Cuenca: {
        code: "CU",
      },
      Girona: {
        alternate_names: ["Gerona"],
        code: "GI",
      },
      Granada: {
        code: "GR",
      },
      Guadalajara: {
        code: "GU",
      },
      Guipúzcoa: {
        alternate_names: ["Guipuzcoa", "Gipuzkoa"],
        code: "SS",
      },
      Huelva: {
        code: "H",
      },
      Huesca: {
        alternate_names: ["Uesca", "Osca"],
        code: "HU",
      },
      Jaén: {
        alternate_names: ["Jaen"],
        code: "J",
      },
      "La Rioja": {
        code: "LO",
      },
      "Las Palmas": {
        code: "GC",
      },
      León: {
        alternate_names: ["Leon"],
        code: "LE",
      },
      Lleida: {
        alternate_names: ["Lérida", "Lerida"],
        code: "L",
      },
      Lugo: {
        code: "LU",
      },
      Madrid: {
        code: "M",
      },
      Málaga: {
        alternate_names: ["Malaga"],
        code: "MA",
      },
      Melilla: {
        code: "ML",
      },
      Murcia: {
        code: "MU",
      },
      Navarra: {
        alternate_names: ["Nafarroa", "Navarre"],
        code: "NA",
      },
      Ourense: {
        code: "OR",
      },
      Palencia: {
        code: "P",
      },
      Pontevedra: {
        code: "PO",
      },
      Salamanca: {
        code: "SA",
      },
      "Santa Cruz de Tenerife": {
        alternate_names: ["Santa Cruz"],
        code: "TF",
      },
      Segovia: {
        code: "SG",
      },
      Sevilla: {
        alternate_names: ["Seville"],
        code: "SE",
      },
      Soria: {
        code: "SO",
      },
      Tarragona: {
        code: "T",
      },
      Teruel: {
        code: "TE",
      },
      Toledo: {
        code: "TO",
      },
      Valencia: {
        alternate_names: ["València"],
        code: "V",
      },
      Valladolid: {
        code: "VA",
      },
      Vizcaya: {
        alternate_names: ["Biscay", "Bizkaia"],
        code: "BI",
      },
      Zamora: {
        code: "ZA",
      },
      Zaragoza: {
        alternate_names: ["Saragossa"],
        code: "Z",
      },
    },
    label: "Spain",
    phone: "34",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SD",
    label: "Sudan",
    phone: "249",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SR",
    label: "Suriname",
    phone: "597",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SZ",
    label: "Swaziland",
    phone: "268",
  },
  {
    address_format: {
      edit: 1,
    },
    code: "SE",
    labels: {
      address1: "Street and house number",
      city: "City/town",
    },
    label: "Sweden",
    phone: "46",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "CH",
    labels: {
      address1: "Street and house number",
      address2: "Additional address (optional)",
    },
    label: "Switzerland",
    phone: "41",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TJ",
    label: "Tajikistan",
    phone: "992",
  },

  {
    address_format: {
      edit: 4,
    },
    code: "TH",
    labels: {
      province: "Province",
    },
    provinces: {
      "Amnat Charoen": {
        code: "TH-37",
      },
      "Ang Thong": {
        code: "TH-15",
      },
      Bangkok: {
        alternate_names: ["Krung Thep Maha Nakhon"],
        code: "TH-10",
      },
      "Bueng Kan": {
        code: "TH-38",
      },
      Buriram: {
        alternate_names: ["Buri Ram"],
        code: "TH-31",
      },
      Chachoengsao: {
        code: "TH-24",
      },
      "Chai Nat": {
        code: "TH-18",
      },
      Chaiyaphum: {
        code: "TH-36",
      },
      Chanthaburi: {
        code: "TH-22",
      },
      "Chiang Mai": {
        code: "TH-50",
      },
      "Chiang Rai": {
        code: "TH-57",
      },
      "Chon Buri": {
        code: "TH-20",
      },
      Chumphon: {
        code: "TH-86",
      },
      Kalasin: {
        code: "TH-46",
      },
      "Kamphaeng Phet": {
        code: "TH-62",
      },
      Kanchanaburi: {
        code: "TH-71",
      },
      "Khon Kaen": {
        code: "TH-40",
      },
      Krabi: {
        code: "TH-81",
      },
      Lampang: {
        code: "TH-52",
      },
      Lamphun: {
        code: "TH-51",
      },
      Loei: {
        code: "TH-42",
      },
      Lopburi: {
        alternate_names: ["Lop Buri"],
        code: "TH-16",
      },
      "Mae Hong Son": {
        code: "TH-58",
      },
      "Maha Sarakham": {
        code: "TH-44",
      },
      Mukdahan: {
        code: "TH-49",
      },
      "Nakhon Nayok": {
        code: "TH-26",
      },
      "Nakhon Pathom": {
        code: "TH-73",
      },
      "Nakhon Phanom": {
        code: "TH-48",
      },
      "Nakhon Ratchasima": {
        code: "TH-30",
      },
      "Nakhon Sawan": {
        code: "TH-60",
      },
      "Nakhon Si Thammarat": {
        code: "TH-80",
      },
      Nan: {
        code: "TH-55",
      },
      Narathiwat: {
        code: "TH-96",
      },
      "Nong Bua Lam Phu": {
        code: "TH-39",
      },
      "Nong Khai": {
        code: "TH-43",
      },
      Nonthaburi: {
        code: "TH-12",
      },
      "Pathum Thani": {
        code: "TH-13",
      },
      Pattani: {
        code: "TH-94",
      },
      Pattaya: {
        code: "TH-S",
      },
      Phangnga: {
        code: "TH-82",
      },
      Phatthalung: {
        code: "TH-93",
      },
      Phayao: {
        code: "TH-56",
      },
      Phetchabun: {
        code: "TH-67",
      },
      Phetchaburi: {
        code: "TH-76",
      },
      Phichit: {
        code: "TH-66",
      },
      Phitsanulok: {
        code: "TH-65",
      },
      "Phra Nakhon Si Ayutthaya": {
        code: "TH-14",
      },
      Phrae: {
        code: "TH-54",
      },
      Phuket: {
        code: "TH-83",
      },
      "Prachin Buri": {
        code: "TH-25",
      },
      "Prachuap Khiri Khan": {
        code: "TH-77",
      },
      Ranong: {
        code: "TH-85",
      },
      Ratchaburi: {
        code: "TH-70",
      },
      Rayong: {
        code: "TH-21",
      },
      "Roi Et": {
        code: "TH-45",
      },
      "Sa Kaeo": {
        code: "TH-27",
      },
      "Sakon Nakhon": {
        code: "TH-47",
      },
      "Samut Prakan": {
        code: "TH-11",
      },
      "Samut Sakhon": {
        code: "TH-74",
      },
      "Samut Songkhram": {
        code: "TH-75",
      },
      Saraburi: {
        code: "TH-19",
      },
      Satun: {
        code: "TH-91",
      },
      "Sing Buri": {
        code: "TH-17",
      },
      Sisaket: {
        alternate_names: ["Si Sa Ket"],
        code: "TH-33",
      },
      Songkhla: {
        code: "TH-90",
      },
      Sukhothai: {
        code: "TH-64",
      },
      "Suphan Buri": {
        code: "TH-72",
      },
      "Surat Thani": {
        code: "TH-84",
      },
      Surin: {
        code: "TH-32",
      },
      Tak: {
        code: "TH-63",
      },
      Trang: {
        code: "TH-92",
      },
      Trat: {
        code: "TH-23",
      },
      "Ubon Ratchathani": {
        code: "TH-34",
      },
      "Udon Thani": {
        code: "TH-41",
      },
      "Uthai Thani": {
        code: "TH-61",
      },
      Uttaradit: {
        code: "TH-53",
      },
      Yala: {
        code: "TH-95",
      },
      Yasothon: {
        code: "TH-35",
      },
    },
    label: "Thailand",
    phone: "66",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TL",
    label: "Timor-Leste",
    phone: "670",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "TG",
    label: "Togo",
    phone: "228",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TK",
    label: "Tokelau",
    phone: "690",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TO",
    label: "Tonga",
    phone: "676",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TN",
    label: "Tunisia",
    phone: "216",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TR",
    label: "Turkey",
    phone: "90",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TM",
    label: "Turkmenistan",
    phone: "993",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "TV",
    label: "Tuvalu",
    phone: "688",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "UG",
    label: "Uganda",
    phone: "256",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "UA",
    label: "Ukraine",
    phone: "380",
  },
  {
    address_format: {
      edit: 7,
    },
    code: "AE",
    labels: {
      province: "Emirate",
    },
    provinces: {
      "Abu Dhabi": {
        code: "AZ",
      },
      Ajman: {
        code: "AJ",
      },
      Dubai: {
        code: "DU",
      },
      Fujairah: {
        code: "FU",
      },
      "Ras al-Khaimah": {
        code: "RK",
      },
      Sharjah: {
        code: "SH",
      },
      "Umm al-Quwain": {
        code: "UQ",
      },
    },
    label: "United Arab Emirates",
    phone: "971",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "GB",
    labels: {
      zip: "Postcode",
    },
    label: "United Kingdom",
    phone: "44",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  {
    address_format: {
      edit: 4,
    },
    code: "US",
    labels: {
      province: "State",
      zip: "ZIP code",
    },
    provinces: {
      Alabama: {
        code: "AL",
      },
      Alaska: {
        code: "AK",
      },
      "American Samoa": {
        code: "AS",
      },
      Arizona: {
        code: "AZ",
      },
      Arkansas: {
        code: "AR",
      },
      California: {
        code: "CA",
      },
      Colorado: {
        code: "CO",
      },
      Connecticut: {
        code: "CT",
      },
      Delaware: {
        code: "DE",
      },
      "District of Columbia": {
        code: "DC",
      },
      "Federated States of Micronesia": {
        code: "FM",
      },
      Florida: {
        code: "FL",
      },
      Georgia: {
        code: "GA",
      },
      Guam: {
        code: "GU",
      },
      Hawaii: {
        code: "HI",
      },
      Idaho: {
        code: "ID",
      },
      Illinois: {
        code: "IL",
      },
      Indiana: {
        code: "IN",
      },
      Iowa: {
        code: "IA",
      },
      Kansas: {
        code: "KS",
      },
      Kentucky: {
        code: "KY",
      },
      Louisiana: {
        code: "LA",
      },
      Maine: {
        code: "ME",
      },
      "Marshall Islands": {
        code: "MH",
      },
      Maryland: {
        code: "MD",
      },
      Massachusetts: {
        code: "MA",
      },
      Michigan: {
        code: "MI",
      },
      Minnesota: {
        code: "MN",
      },
      Mississippi: {
        code: "MS",
      },
      Missouri: {
        code: "MO",
      },
      Montana: {
        code: "MT",
      },
      Nebraska: {
        code: "NE",
      },
      Nevada: {
        code: "NV",
      },
      "New Hampshire": {
        code: "NH",
      },
      "New Jersey": {
        code: "NJ",
      },
      "New Mexico": {
        code: "NM",
      },
      "New York": {
        code: "NY",
      },
      "North Carolina": {
        code: "NC",
      },
      "North Dakota": {
        code: "ND",
      },
      "Northern Mariana Islands": {
        code: "MP",
      },
      Ohio: {
        code: "OH",
      },
      Oklahoma: {
        code: "OK",
      },
      Oregon: {
        code: "OR",
      },
      Palau: {
        code: "PW",
      },
      Pennsylvania: {
        code: "PA",
      },
      "Puerto Rico": {
        code: "PR",
      },
      "Rhode Island": {
        code: "RI",
      },
      "South Carolina": {
        code: "SC",
      },
      "South Dakota": {
        code: "SD",
      },
      Tennessee: {
        code: "TN",
      },
      Texas: {
        code: "TX",
      },
      Utah: {
        code: "UT",
      },
      Vermont: {
        code: "VT",
      },
      "Virgin Islands": {
        code: "VI",
      },
      Virginia: {
        code: "VA",
      },
      Washington: {
        code: "WA",
      },
      "West Virginia": {
        code: "WV",
      },
      Wisconsin: {
        code: "WI",
      },
      Wyoming: {
        code: "WY",
      },
      "Armed Forces Americas": {
        code: "AA",
      },
      "Armed Forces Europe": {
        code: "AE",
      },
      "Armed Forces Pacific": {
        code: "AP",
      },
    },
    label: "United States",
    phone: "1",
    suggested: true,
  },
  {
    address_format: {
      edit: 0,
    },
    code: "UM",
    label: "United States Minor Outlying Islands",
    labels: {
      province: "State",
    },
    phone: "246",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "UY",
    label: "Uruguay",
    phone: "598",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "VU",
    label: "Vanuatu",
    phone: "678",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "VE",
    label: "Venezuela",
    phone: "58",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "VN",
    label: "Vietnam",
    phone: "84",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "WF",
    label: "Wallis and Futuna",
    phone: "681",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "EH",
    label: "Western Sahara",
    phone: "212",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "YE",
    label: "Yemen",
    phone: "967",
  },
  {
    address_format: {
      edit: 0,
    },
    code: "ZM",
    label: "Zambia",
    phone: "260",
  },
  {
    address_format: {
      edit: 2,
    },
    code: "ZW",
    label: "Zimbabwe",
    phone: "263",
  },
]

export const europeanUnionMemberStates = [
  { name: "Austria", code: "AT" },
  { name: "Belgium", code: "BE" },
  { name: "Bulgaria", code: "BG" },
  { name: "Croatia", code: "HR" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Estonia", code: "EE" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "Greece", code: "GR" },
  { name: "Hungary", code: "HU" },
  { name: "Ireland", code: "IE" },
  { name: "Italy", code: "IT" },
  { name: "Latvia", code: "LV" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Malta", code: "MT" },
  { name: "Netherlands", code: "NL" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Romania", code: "RO" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Spain", code: "ES" },
  { name: "Sweden", code: "SE" },
]

// Probably not entirely correct
export const europeanCountries = [
  {
    name: "Andorra",
    code: "AD",
  },
  {
    name: "Albania",
    code: "AL",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Åland Islands",
    code: "AX",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
  },
  {
    name: "Belgium",
    code: "BE",
  },
  {
    name: "Bulgaria",
    code: "BG",
  },
  {
    name: "Belarus",
    code: "BY",
  },
  {
    name: "Switzerland",
    code: "CH",
  },
  {
    name: "Cyprus",
    code: "CY",
  },
  {
    name: "Czech Republic",
    code: "CZ",
  },
  {
    name: "Germany",
    code: "DE",
  },
  {
    name: "Denmark",
    code: "DK",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Spain",
    code: "ES",
  },
  {
    name: "Finland",
    code: "FI",
  },
  {
    name: "Faroe Islands",
    code: "FO",
  },
  {
    name: "France",
    code: "FR",
  },
  {
    name: "United Kingdom",
    code: "GB",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Greece",
    code: "GR",
  },
  {
    name: "Croatia",
    code: "HR",
  },
  {
    name: "Hungary",
    code: "HU",
  },
  {
    name: "Ireland",
    code: "IE",
  },
  {
    name: "Isle of Man",
    code: "IM",
  },
  {
    name: "Iceland",
    code: "IC",
  },
  {
    name: "Italy",
    code: "IT",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Lithuania",
    code: "LT",
  },
  {
    name: "Luxembourg",
    code: "LU",
  },
  {
    name: "Latvia",
    code: "LV",
  },
  {
    name: "Monaco",
    code: "MC",
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Netherlands",
    code: "NL",
  },
  {
    name: "Norway",
    code: "NO",
  },
  {
    name: "Poland",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Romania",
    code: "RO",
  },
  {
    name: "Russian Federation",
    code: "RU",
  },
  {
    name: "Sweden",
    code: "SE",
  },
  {
    name: "Slovenia",
    code: "SI",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  {
    name: "Slovakia",
    code: "SK",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Ukraine",
    code: "UA",
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
  },
]
