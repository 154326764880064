import { semanticEmergency, semanticWarning } from "src/ui/colors"
import Icon from "src/ui/icons/error.svg"
import { SVGProps } from "src/utils/tsUtil"

export type TErrorIconState = "error" | "warning"

export function ErrorIcon({
  state = "error",
  className,
  svgProps,
  style,
}: {
  state?: TErrorIconState
  className?: string
  svgProps?: SVGProps
  style?: React.CSSProperties
}) {
  return (
    <Icon
      fill={getColor(state)}
      className={className}
      width={24}
      style={style}
      {...svgProps}
    />
  )
}

function getColor(state: TErrorIconState) {
  switch (state) {
    case "error": {
      return semanticEmergency
    }
    case "warning": {
      return semanticWarning
    }
  }
}
