import qs from "qs"

import { IDevicesFilter } from "src/data/devices/types/deviceTypes"
import { createBasicLocation, ILocation } from "src/router/routeTypes"

const Sensors: ILocation<IDevicesFilter | void> = {
  location(filters) {
    const queryFilter = qs.stringify(filters)
    if (!queryFilter) {
      return { pathname: `/sensors` }
    }
    return { pathname: `/sensors`, search: `?${queryFilter}` }
  },
} as const

const SensorsWithDeviceId: ILocation<string> = {
  location(deviceId) {
    return { pathname: `/sensors/${deviceId}` }
  },
}

const RemoteDebugUser = createBasicLocation("/debug")
const RemoteDebugDev = createBasicLocation("/debug-dev")

export const SensorRoutes = {
  Sensors,
  SensorsWithDeviceId,
  RemoteDebugUser,
  RemoteDebugDev,
} as const
