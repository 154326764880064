import styled from "styled-components"

import MUIChip from "@material-ui/core/Chip"

import { primaryTint } from "src/ui/colors"

const SChip = styled(MUIChip)`
  background-color: ${primaryTint}33;
  border-width: 2px;
  padding: 1rem 0;
  font-size: 1rem;

  .MuiChip-deleteIcon {
    /* height: 10px;
    width: 10px; */
    height: 16px;
    width: 16px;
  }
`

/** A basic wrapper for MUI Chip, that just adds custom styling */
export function Chip({ ...props }) {
  //   return <SChip deleteIcon={<XSvg />} {...props} />
  return <SChip {...props} />
}
