// This module keeps track of env-variables that may be used in the application.

// Once we've refactored the app so that we can keep the .env-file checked into
// source control, it will be less useful.

export const REACT_APP_ALWAYS_SHOW_FLAGS = import.meta.env
  .REACT_APP_ALWAYS_SHOW_FLAGS
  ? true
  : undefined

/** Container for BE login related env vars. If using with OAuth make sure the
 * REACT_APP_CLIENT_ID points to a client with the internal role! */
export const BackendLoginClient = {
  clientId: import.meta.env.REACT_APP_CLIENT_ID,
  clientSecret: import.meta.env.REACT_APP_CLIENT_SECRET,
  clientRedirectPath: "/auth/redirect", // Do NOT change this route unless also changing the allowed redirects for the client in Paradise
  backendUri: import.meta.env.REACT_APP_API_URL,
  // XXX: Use later
  // get redirectUri() {
  //   return `${window.location.origin}${Routes.OAuthExchangeCodeForToken.location().pathname}`
  // },
}

for (const [key, value] of Object.entries(BackendLoginClient)) {
  if (!value && process.env.NODE_ENV !== "test") {
    throw Error(`key ${key} is undefined, app will not function properly`)
  }
}

export const isDev = process.env.NODE_ENV === "development"
const isProd = process.env.NODE_ENV === "production"

export const isProdEnv =
  window.location.hostname.includes("web.minut.com") && isProd

export const isStagingEnv =
  window.location.hostname.includes("web.staging.minut.com") && isProd

// ts-prune-ignore-next
export const isDevelopEnv =
  window.location.hostname.includes("web.develop.staging.minut.com") && isProd

export const isDemoEnv =
  window.location.hostname.includes("web.demo.minut.com") && isProd

export const isParadiseStagingEnv = window.location.hostname.includes(
  "paradise2.staging.minut.com"
)
export const isParadiseProdEnv = window.location.hostname.includes(
  "paradise2.minut.com"
)
