import { lazy } from "react"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { useFlags } from "src/hooks/useFlags"
import { defaultRetryHandler } from "src/utils/requests"

/**
 * It is vital that the query client is defined in the global scope like this.
 * It turned out that moving it into the provider below will generate multiple
 * caches and really hard-to-track bugs.
 */
const queryClient = new QueryClient()

// TODO WEB-363: Look into adding global Sentry error handling instead
queryClient.setDefaultOptions({
  queries: {
    retry: defaultRetryHandler,
  },
})

const ReactQueryDevtoolsProduction = lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
)

/**
 * React query and devtools provider.
 *
 * Devtools: https://tanstack.com/query/latest/docs/react/devtools
 */
export function MQueryClientProvider(props: { children: React.ReactNode }) {
  const { reactQueryDevTools } = useFlags()

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}

      {reactQueryDevTools && (
        <ReactQueryDevtoolsProduction initialIsOpen={false} />
      )}
    </QueryClientProvider>
  )
}
