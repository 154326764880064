import { useEffect } from "react"

import { useAppData } from "src/context/useAppData"
import {
  getCommonEventProperties,
  usePostTrackPage,
} from "src/data/analytics/queries/analyticsQueries"
import { useAppLocation } from "src/hooks/useAppLocation"
import { Routes } from "src/router/routes"

const EXCLUDE_PATHS = [
  Routes.Login.location().pathname,
  Routes.Logout.location({ reason: "" }).pathname,
]

export function useTrackPage() {
  const postTrackPage = usePostTrackPage()
  const {
    user,
    activeOrg,
    authState,
    currentSubscription,
    numberOfHomes,
    ready,
  } = useAppData()
  const location = useAppLocation()

  /**
   * The point of this useEffect is to send a track page event when a user
   * goes to a different page, changes organization or logs in as a different
   * user.  We are only looking for user, location and activeOrg since
   * these are the params that actually signify change, we have no logic of
   * rerunning thus we do not want to react on when the postTrackPage hook
   * changes.
   */
  useEffect(() => {
    if (!ready) {
      return
    }
    if (EXCLUDE_PATHS.includes(location.pathname)) {
      return // avoid sending analytics for excluded paths
    }

    const commonEventProperties = getCommonEventProperties({
      user,
      numberOfHomes,
      org: activeOrg,
      currentSubscription,
    })

    postTrackPage.mutate({
      name: location.pathname,
      category: createCategory(location.pathname),
      title: document.title,
      search: location.search,
      referrer: document.referrer,
      url: window.location.href,
      path: location.pathname,
      hash: location.hash,
      ...commonEventProperties,
    })
    // Disabling exhaustive-deps check because of postTrackPage which causes
    // endless re render of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    location,
    authState.authorization?.access_token,
    activeOrg,
    currentSubscription,
    numberOfHomes,
    //postTrackPage,
  ])
}

function createCategory(pathname: string) {
  if (pathname.startsWith(Routes.Home.location("").pathname)) {
    return "Home"
  }

  if (pathname.startsWith(Routes.SensorsWithDeviceId.location("").pathname)) {
    return "Sensor"
  }

  return undefined
}
