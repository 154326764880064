import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { FeatureFlag } from "src/constants/featureFlags"

/* eslint-disable no-console */
const isProd = process.env.NODE_ENV === "production"
const noop = (..._: unknown[]) => {}

// ts-prune-ignore-next
export class Log {
  static enabled = !isProd

  static get log() {
    if (!this.enabled) return noop
    return console.log.bind(window.console, ">>>")
  }

  static get warn() {
    if (!this.enabled) return noop
    return console.warn.bind(window.console, ">>>")
  }

  static get error() {
    if (!this.enabled) return noop
    return console.error.bind(window.console, ">>>")
  }
}

export class Logger {
  private _debug = noop
  private _info = noop
  private _log = noop
  private _warn = noop
  private _error = noop
  enabled: boolean

  constructor(props?: { prefix: string; enabled?: boolean }) {
    const { enabled = true, prefix = ">>>" } = props || {}
    this.enabled = enabled
    this._debug = console.debug.bind(window.console, prefix)
    this._info = console.info.bind(window.console, prefix)
    this._log = console.log.bind(window.console, prefix)
    this._warn = console.warn.bind(window.console, prefix)
    this._error = console.error.bind(window.console, prefix)
  }

  get debug() {
    if (!this.enabled) {
      return noop
    }
    return this._debug
  }

  get info() {
    if (!this.enabled) {
      return noop
    }
    return this._info
  }

  get log() {
    if (!this.enabled) {
      return noop
    }
    return this._log
  }

  get warn() {
    if (!this.enabled) {
      return noop
    }
    return this._warn
  }

  get error() {
    if (!this.enabled) {
      return noop
    }
    return this._error
  }
}

const debugLogEnabled = isStoredFeatureEnabled(FeatureFlag.LOGGING_DEBUG)
export const debug = new Logger({ enabled: debugLogEnabled, prefix: "[Debug]" })

export const logger = new Logger({ prefix: "[web-app]" })
