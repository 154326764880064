import { paths } from "@minuthq/minut-api-types/schema"
import { useMutation } from "@tanstack/react-query"
import { AxiosRequestConfig } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

type TPostNotificationRegistrationBody =
  paths["/users/{user_id}/push_tokens"]["post"]["requestBody"]["content"]["application/json"]
type TPostNotificationRegistrationResponse =
  paths["/users/{user_id}/push_tokens"]["post"]["responses"]["201"]["content"]["application/json"]

// https://api.staging.minut.com/latest/docs/internal#post-/users/-user_id-/push_tokens
export function usePostPushNotificationRegistration() {
  async function usePostPushNotificationRegistration({
    userId,
    token,
  }: { userId: string } & Pick<TPostNotificationRegistrationBody, "token">) {
    const data: TPostNotificationRegistrationBody = {
      token,
      vendor: "gcm",
      client_type: "web",
    }
    const config: AxiosRequestConfig = {
      headers: { "Minut-App-Version": "web" },
    }
    const result =
      await minutApiHttpClient.post<TPostNotificationRegistrationResponse>(
        `${API_DEFAULT}/users/${userId}/push_tokens`,
        data,
        config
      )
    return result.data
  }

  return useMutation({
    mutationFn: usePostPushNotificationRegistration,
  })
}
