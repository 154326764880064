import { useRef, useState } from "react"
import styled, { css } from "styled-components"

import { useMediaQuery, useResizeObserver } from "usehooks-ts"

import { breakpoint } from "src/constants/breakpoints"
import { shadowBottom, shadowTop } from "src/constants/shadows"
import { useTranslate } from "src/i18n/useTranslate"
import { BaseModalDialog } from "src/ui/BaseModalDialog/BaseModalDialog"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface IMDialog {
  /**
   * Callback to be called when the confirm button is clicked
   * If this is not provided, the confirm button will not be rendered
   */
  onConfirm?: () => void
  /*
   * Callback to be called when the close button is clicked
   */
  onClose: () => void
  open: boolean
  autoFocus?: boolean
  title?: React.ReactNode
  description?: string | React.ReactNode
  closeLabel?: string
  confirmLabel?: string
  fullWidth?: boolean
  loading?: boolean
  /**
   * If true, the dialog will take up the full screen when the screen is small
   */
  responsive?: boolean
  confirmButtonProps?: TMButtonProps
  formId?: string
  children?: React.ReactNode
  error?: React.ReactNode
  infoAlert?: React.ReactNode
  centerActionButtons?: boolean
  /**
   * If true, the close button will be hidden
   */
  hideClose?: boolean
}

export type TMDialogProps = IMDialog

export function MDialog({
  onConfirm,
  onClose,
  open,
  title,
  error,
  infoAlert,

  // Optional
  fullWidth,
  hideClose,
  closeLabel,
  autoFocus = true,
  formId,
  confirmLabel,
  description,
  responsive = true,
  loading = false,
  children = null,
  confirmButtonProps,
  centerActionButtons = false,
}: TMDialogProps) {
  const [showTopShadow, setShowTopShadow] = useState(false)
  const [showBottomShadow, setShowBottomShadow] = useState(false)

  const dialogRef = useRef<HTMLDivElement>(null)

  const mediumUp = useMediaQuery(`(${breakpoint.mediumUp})`)

  const { t, langKeys } = useTranslate()

  useResizeObserver({
    ref: dialogRef,
    onResize: () => {
      handleScroll()
    },
  })

  function handleScroll() {
    if (dialogRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = dialogRef.current
      const scrollBottom = scrollHeight - scrollTop - clientHeight
      setShowBottomShadow(scrollBottom > 0)
      setShowTopShadow(scrollTop !== 0)
    }
  }

  const _confirmLabel = confirmLabel ?? t(langKeys.ok)
  const _closeLabel = closeLabel ?? t(langKeys.cancel)

  if (!open) {
    return null
  }

  return (
    <BaseModalDialog
      open={open}
      onClose={onClose}
      content={
        <DialogWrapper
          $fullScreen={!mediumUp && responsive}
          $fullWidth={fullWidth}
        >
          <Top $showShadow={showTopShadow}>
            <MText variant="heading2">{title}</MText>
          </Top>
          <Content onScroll={handleScroll} ref={dialogRef}>
            <MText marginBottom={spacing.S} hidden={!description}>
              {description}
            </MText>

            <MText>{children}</MText>
          </Content>
          <ActionsBar $showShadow={showBottomShadow}>
            {infoAlert && (
              <MBanner
                type="info"
                fullWidth
                style={{ flexBasis: "100%", marginBottom: spacing.S }}
              >
                {infoAlert}
              </MBanner>
            )}
            {error && (
              <MBanner
                fullWidth
                type="error"
                style={{ flexBasis: "100%", marginBottom: spacing.S }}
              >
                {error}
              </MBanner>
            )}
            <ActionButtonsWrapper $centerButtons={centerActionButtons}>
              <div>
                {!hideClose && (
                  <TextButton onClick={onClose}>{_closeLabel}</TextButton>
                )}
              </div>
              <div>
                {formId ? (
                  <MButton
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    form={formId}
                    {...confirmButtonProps}
                  >
                    {_confirmLabel}
                  </MButton>
                ) : onConfirm ? (
                  <MButton
                    autoFocus={autoFocus}
                    onClick={onConfirm}
                    loading={loading}
                    disabled={loading}
                    {...confirmButtonProps}
                  >
                    {_confirmLabel}
                  </MButton>
                ) : null}
              </div>
            </ActionButtonsWrapper>
          </ActionsBar>
        </DialogWrapper>
      }
    />
  )
}

const fullscreenCss = css`
  width: 100%;
  height: 100%;
`

const floatingCss = css<{ $fullWidth?: boolean }>`
  width: calc(100% - ${spacing.XL4});
  max-width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "600px")};
  max-height: calc(100% - ${spacing.XL4});
  border-radius: 1.5rem;
`

const DialogWrapper = styled.div<{
  $fullScreen: boolean
  $fullWidth?: boolean
}>`
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  background-color: ${mColors.neutralLight};

  ${({ $fullScreen }) => ($fullScreen ? fullscreenCss : floatingCss)}
`

const Top = styled.div<{ $showShadow: boolean }>`
  padding: ${spacing.L};
  ${(props) => props.$showShadow && shadowBottom};
`

const Content = styled.div`
  flex: 1;
  padding-inline: ${spacing.L};
  overflow-y: auto;
`

const ActionsBar = styled.div<{ $showShadow: boolean }>`
  padding: ${spacing.L};
  ${(props) => props.$showShadow && shadowTop};
`

const ActionButtonsWrapper = styled.div<{
  $centerButtons: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props) =>
    props.$centerButtons ? "center" : "space-between"};
`
