import { isEurekaDevice } from "src/data/devices/logic/deviceLogic"
import { TVirtualDevice } from "src/data/devices/types/deviceTypes"
import { semanticEmergency, semanticGood, semanticWarning } from "src/ui/colors"
import BatteryIcon5 from "src/ui/icons/battery5.svg"
import BatteryIcon20 from "src/ui/icons/battery20.svg"
import BatteryIcon80 from "src/ui/icons/battery80.svg"
import BatteryIcon100 from "src/ui/icons/battery100.svg"

export function VirtualDeviceBatteryIcon({
  voltage,
  device,
}: {
  voltage: number
  device: TVirtualDevice
}) {
  const lowLimit = isEurekaDevice(device) ? 2.6 : 3.3
  const middleLimit = isEurekaDevice(device) ? 2.8 : 3.6
  const highLimit = isEurekaDevice(device) ? 2.9 : 4.0

  const fill =
    voltage <= lowLimit
      ? semanticEmergency
      : voltage <= middleLimit
        ? semanticWarning
        : semanticGood

  if (voltage <= lowLimit) return <BatteryIcon5 width={32} fill={fill} />
  if (voltage <= middleLimit) return <BatteryIcon20 width={32} fill={fill} />
  if (voltage <= highLimit) return <BatteryIcon80 width={32} fill={fill} />
  return <BatteryIcon100 width={32} fill={fill} />
}
