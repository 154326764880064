import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import { HomeGroupRole } from "src/data/homeGroups/types/homeGroupTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"

export function useIsHomeGroupAdmin() {
  const { org } = useOrganization()

  const fetchHomegroups = useFetchHomeGroups({
    orgId: org.id,
    filter: { role: HomeGroupRole.ADMIN },
  })

  const homegroups = fetchHomegroups.data?.homegroups || []
  const isHomegroupAdmin = homegroups.length >= 1

  return {
    ready: !fetchHomegroups.isLoading || !!fetchHomegroups.data,
    value: isHomegroupAdmin,
  }
}
