export type TAgentDeviceType = "Tablet" | "Mobile" | "Desktop"

export interface IBrowser {
  name: string
  version: string
}

export interface IOS {
  name: string
  version: string
}

export interface IUserAgentResult {
  browser: IBrowser
  device_type: TAgentDeviceType
  os: IOS
  ua: string
}

// Regex from here: https://the-average-developer.medium.com/browser-detection-using-javascript-9fe59fee23b0
export function parseUserAgent(): IUserAgentResult {
  const userAgent = navigator.userAgent

  function getBrowser() {
    let browserName = "Unknown"
    let browserVersion = ""

    browserName = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browserName
    browserName = /edg/i.test(userAgent) ? "Edge" : browserName
    browserName = /googlebot/i.test(userAgent) ? "GoogleBot" : browserName
    browserName = /chromium/i.test(userAgent) ? "Chromium" : browserName

    browserName =
      /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
        ? "Firefox"
        : browserName

    browserName =
      /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
        ? "IE"
        : browserName

    browserName =
      /chrome|crios/i.test(userAgent) &&
      !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
        ? "Chrome"
        : browserName

    browserName =
      /safari/i.test(userAgent) &&
      !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
        userAgent
      )
        ? "Safari"
        : browserName
    browserName = /opr|opera/i.test(userAgent) ? "Opera" : browserName

    const versionRegex =
      browserName === "Edge"
        ? new RegExp(`Edg[/]([\\d.]+)`)
        : new RegExp(`${browserName}[/]([\\d.]+)`)

    const matches = userAgent.match(versionRegex)
    if (matches && matches.length >= 2) {
      browserVersion = matches[1] ?? "Unknown"
    }

    return {
      name: browserName,
      version: browserVersion,
    }
  }

  // https://stackoverflow.com/questions/69940501/how-to-know-device-type-from-js-navigator-object
  function getDeviceType() {
    const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i
    const mobRegex =
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/

    if (tabletRegex.test(userAgent)) return "Tablet"
    if (mobRegex.test(userAgent)) return "Mobile"
    return "Desktop"
  }

  function getOperatingSystemAndVersion() {
    const osRegexes: { name: string; regex: RegExp }[] = [
      { name: "Windows", regex: /Windows NT ([0-9.]+)/ },
      { name: "MacOS", regex: /Mac OS X ([0-9_.]+)/ },
      { name: "iOS", regex: /(?:iPhone|iPad|iPod).* OS ([0-9_.]+)/ },
      { name: "Android", regex: /Android ([0-9.]+)/ },
      { name: "Linux", regex: /Linux/ },
    ]

    const osMatch = osRegexes.find((os) => os.regex.test(userAgent))

    if (osMatch) {
      const versionMatch = userAgent.match(osMatch.regex)
      const detectedVersion =
        versionMatch && versionMatch[1]
          ? versionMatch[1].replace(/_/g, ".")
          : "Unknown Version"

      return {
        name: osMatch.name,
        version: detectedVersion,
      }
    }

    return {
      name: "Unknown",
      version: "Unknown",
    }
  }

  return {
    browser: getBrowser(),
    device_type: getDeviceType(),
    os: getOperatingSystemAndVersion(),
    ua: userAgent,
  }
}
