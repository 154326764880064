import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { add, endOfDay, roundToNearestMinutes } from "date-fns"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  homeTokenKeys,
  useHomeTokenCache,
} from "src/data/homes/queries/homeTokenQueryCache"
import {
  IPostUserHomeTokensPurchaseBody,
  TFetchHomeTokensEstimateResponseError,
  TFetchHomeTokensResponse,
  THomeDeleteRefundResponse,
  THomeTokenPurchaseResponse,
  TOrganizationHomeTokenQueryParams,
  TPostHomeTokenCostEstimateResponse,
  TUserHomeTokenQueryParams,
} from "src/data/homes/types/homeTokenTypes"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

// Fetch available home tokens for an organization. The returned home token
// quantity equals the home tokens owned by the organization owner.
export function useFetchHomeTokens({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: TOrganizationHomeTokenQueryParams
  options?: UseQueryOptions<
    TFetchHomeTokensResponse,
    AxiosError,
    TFetchHomeTokensResponse,
    ReturnType<typeof homeTokenKeys.homeTokenList>
  >
}) {
  const homeTokens = useHomeTokens()
  const homeTokensFeatureAvailable = homeTokens.available

  async function fetchHomeTokens({
    orgId,
    filters,
  }: {
    orgId: string
    filters: TOrganizationHomeTokenQueryParams
  }): Promise<TFetchHomeTokensResponse> {
    const response = await minutApiHttpClient.get<TFetchHomeTokensResponse>(
      `${API_DEFAULT}/organizations/${orgId}/home_tokens`,
      {
        params: filters,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: homeTokenKeys.homeTokenList(orgId, filters),
    queryFn: () => fetchHomeTokens({ orgId, filters }),
    ...options,
    enabled:
      homeTokensFeatureAvailable &&
      (options?.enabled !== undefined ? options.enabled : true),
  })
}

/**
 * Hook to fetch home tokens that are expiring within a specified number of months.
 * @param params.orgId - The organization ID to fetch tokens for
 * @param params.months - Number of months to look ahead for expiring tokens (default: 1)
 * @returns A query result containing home tokens that will expire within the specified month range
 */
export function useFetchExpiringHomeTokens({
  orgId,
  months = 1,
  options,
}: {
  orgId: string
  months?: number
  options?: UseQueryOptions<
    TFetchHomeTokensResponse,
    AxiosError,
    TFetchHomeTokensResponse,
    ReturnType<typeof homeTokenKeys.homeTokenList>
  >
}) {
  return useFetchHomeTokens({
    orgId,
    filters: {
      activated: false,
      // Round to the nearest 10 minutes
      // By rounding we make sure that the cache key is the same during re-renders within the same 10 minute window
      // If we don't round the cache key would change on every re-render and we would fetch new data every time
      expires_after: roundToNearestMinutes(new Date(), {
        nearestTo: 10,
        roundingMethod: "ceil",
      }).toISOString(),
      expires_before: add(endOfDay(new Date()), {
        months: months,
      }).toISOString(),
      sort: "expires_at",
      order: "asc",
    },
    options,
  })
}

export function useFetchHomeDeletionRefundType({
  orgId,
  homeId,
  options,
}: {
  orgId: string
  homeId: string
  options?: UseQueryOptions<
    THomeDeleteRefundResponse,
    AxiosError,
    THomeDeleteRefundResponse,
    ReturnType<typeof homeTokenKeys.refundType>
  >
}) {
  async function fetchHomeDeletionRefundType() {
    const response = await minutApiHttpClient.get<THomeDeleteRefundResponse>(
      `${API_DEFAULT}/organizations/${orgId}/homes/${homeId}/delete_refund`
    )

    return response.data
  }

  return useQuery({
    queryFn: () => fetchHomeDeletionRefundType(),
    queryKey: homeTokenKeys.refundType({ orgId, homeId }),
    enabled: options?.enabled,
  })
}

// ts-prune-ignore-next
export function usePostHomeTokenPurchase({ orgId }: { orgId: string }) {
  async function postHomeTokenPurchase({ quantity }: { quantity: number }) {
    const response = await minutApiHttpClient.post<THomeTokenPurchaseResponse>(
      `${API_DEFAULT}/organizations/${orgId}/home_tokens`,
      {
        quantity: quantity,
      }
    )

    return response.data
  }

  const homeTokenCache = useHomeTokenCache()
  return useMutation({
    mutationFn: postHomeTokenPurchase,
    onSuccess: (data, vars) => {
      homeTokenCache.invalidateOrganizationHomeTokenEstimate(
        orgId,
        vars.quantity
      )
    },
  })
}

// ts-prune-ignore-next
export function useFetchHomeTokenCostEstimate({
  quantity,
  discountCode,
  orgId,
  options,
}: {
  quantity: number
  discountCode?: string
  orgId: string
  options?: UseQueryOptions<
    TPostHomeTokenCostEstimateResponse,
    AxiosError<TFetchHomeTokensEstimateResponseError | undefined>,
    TPostHomeTokenCostEstimateResponse,
    ReturnType<typeof homeTokenKeys.costEstimate>
  >
}) {
  async function postHomeTokenCostEstimate() {
    const response =
      await minutApiHttpClient.post<TPostHomeTokenCostEstimateResponse>(
        `${API_DEFAULT}/subscriptions/estimate/home_tokens`,
        {
          quantity,
          discount_code: discountCode,
          organization_id: orgId,
        }
      )

    return response.data
  }

  return useQuery({
    queryKey: homeTokenKeys.costEstimate({ orgId, quantity }),
    queryFn: postHomeTokenCostEstimate,
    ...options,
  })
}

// Fetches home tokens owned by a specific user. Used in the billing portal
// since billing is currently based on the organization owner. Also used
// in paradise to fetch tokens for specific users
export function useFetchUserHomeTokens({
  userId,
  filter,
  options,
}: {
  userId: string
  filter?: TUserHomeTokenQueryParams
  options?: UseQueryOptions<
    TFetchHomeTokensResponse,
    AxiosError,
    TFetchHomeTokensResponse,
    ReturnType<typeof homeTokenKeys.userHomeTokenList>
  >
}) {
  async function fetchUserHomeTokens() {
    const response = await minutApiHttpClient.get(
      `${API_DEFAULT}/users/${userId}/home_tokens`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: homeTokenKeys.userHomeTokenList(userId, filter),
    queryFn: fetchUserHomeTokens,
    ...options,
  })
}

/**
 * Hook to fetch home tokens that are expiring within a specified number of months.
 * @param params.userId - The user ID to fetch tokens for
 * @param params.months - Number of months to look ahead for expiring tokens (default: 1)
 * @returns A query result containing home tokens that will expire within the specified month range
 */
export function useFetchUserExpiringHomeTokens({
  userId,
  months = 1,
  options,
}: {
  userId: string
  months?: number
  options?: UseQueryOptions<
    TFetchHomeTokensResponse,
    AxiosError,
    TFetchHomeTokensResponse,
    ReturnType<typeof homeTokenKeys.userHomeTokenList>
  >
}) {
  return useFetchUserHomeTokens({
    userId,
    filter: {
      activated: false,
      // Round to the nearest 10 minutes
      // By rounding we make sure that the cache key is the same during re-renders within the same 10 minute window
      // If we don't round the cache key would change on every re-render and we would fetch new data every time
      expires_after: roundToNearestMinutes(new Date(), {
        nearestTo: 10,
        roundingMethod: "ceil",
      }).toISOString(),
      expires_before: add(endOfDay(new Date()), {
        months: months,
      }).toISOString(),
      sort: "expires_at",
      order: "asc",
    },
    options,
  })
}

export function usePostUserHomeTokensPurchase() {
  const homeTokenCache = useHomeTokenCache()

  async function postUserHomeTokensPurchase({
    userId,
    body,
  }: {
    userId: string
    body: IPostUserHomeTokensPurchaseBody
  }) {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/users/${userId}/home_tokens`,
      body
    )

    return response.data
  }

  return useMutation<
    unknown,
    AxiosError<{ error: string; error_key: string; message: string }>,
    {
      userId: string
      body: IPostUserHomeTokensPurchaseBody
    }
  >({
    mutationFn: postUserHomeTokensPurchase,
    onSuccess: (data, vars) => {
      return homeTokenCache.invalidateUserHomeTokensList(vars.userId)
    },
  })
}

export function useDeleteUserHomeToken() {
  const homeTokenCache = useHomeTokenCache()

  async function deleteUserHomeToken({
    userId,
    tokenId,
    create_refund,
  }: {
    userId: string
    tokenId: string
    create_refund: boolean
  }) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/users/${userId}/home_tokens/${tokenId}`,
      {
        params: {
          create_refund,
        },
      }
    )

    return response.data
  }

  return useMutation({
    mutationFn: deleteUserHomeToken,
    onSuccess(data, vars) {
      return homeTokenCache.invalidateUserHomeTokensList(vars.userId)
    },
  })
}
