import { ILocation } from "src/router/routeTypes"

const CALL_ASSIST_ROUTE = "/call-assist"

const CallAssistOperatorFeedback: ILocation<{ eventId: string } | void> = {
  location(props) {
    if (!props) {
      return { pathname: `${CALL_ASSIST_ROUTE}/operator-feedback` }
    }
    return {
      pathname: `${CALL_ASSIST_ROUTE}`,
      search: props.eventId ? `?eventId=${props.eventId}` : "",
    }
  },
}

const CallAssistActivate: ILocation<void> = {
  location() {
    return { pathname: `${CALL_ASSIST_ROUTE}/activate` }
  },
}

export const CallAssistRoutes = {
  CallAssistOperatorFeedback,
  CallAssistActivate,
} as const
