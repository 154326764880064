import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TPlanUpgradeEventTypes = "Upgrade plan clicked"

/** Contexts for when we show upgrade blockers, to help analytics. At some point
 * they should probably be aligned with the feature availability endpoint, but
 * that endpoint needs to be cleansed of all its cruft first. */
export type TUpgradeBlockerContext =
  // Specific contexts
  | "incident_reports"
  | "reports"
  | "violation_banner"
  | "api_clients"
  | "integrations"
  | "guest_connect"
  | "smoking_detection"
  | "subscription_required"
  // Generic contexts
  | "editfield-generic"
  | "featureblocker-generic"
  | "settings-generic"

function usePostPlanUpgradeTrackingEvent<TEventProperties>(
  event: TPlanUpgradeEventTypes
) {
  return usePostTrackEventWithParams<TPlanUpgradeEventTypes, TEventProperties>({
    event,
  })
}

export type TPlanUpgradeClickedEventProperties = {
  context: TUpgradeBlockerContext
}
export function usePostPlanUpgradeClicked() {
  return usePostPlanUpgradeTrackingEvent<TPlanUpgradeClickedEventProperties>(
    "Upgrade plan clicked"
  )
}
