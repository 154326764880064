import styled from "styled-components"

import { TParadiseOrder } from "src/data/paradise/paradiseOrders/paradiseOrderTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseOrderAmount({
  totalPrice,
  tax,
}: {
  totalPrice: TParadiseOrder["total_price"]
  tax: TParadiseOrder["tax"]
}) {
  return (
    <Box>
      <MText variant="bodyS" color="tertiary">
        Tax:
      </MText>

      <MText variant="bodyS">{tax.formatted_amount}</MText>

      <MText variant="bodyS" color="tertiary">
        Total:
      </MText>

      <MText variant="bodyS">{totalPrice.formatted_amount}</MText>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  column-gap: ${spacing.XL};
  row-gap: ${spacing.XS2};
  grid-template-columns: min-content 1fr;
  margin-left: ${spacing.S};
  align-items: center;
`
