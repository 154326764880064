import { Redirect, Route } from "react-router-dom"

interface IDeepRedirect {
  from: string
  to: string
  hash?: string
  external?: boolean
}

/**
 * Redirects from the specified path to another path, while
 * preserving all location props (such as query params and hash params)
 */
// eslint-disable-next-line import/no-default-export
export default function DeepRedirect({
  from,
  to,
  hash,
  external = false,
}: IDeepRedirect) {
  if (external) {
    window.location.href = to
    return <div>Redirecting...</div>
  }

  return (
    <Route
      path={from}
      render={(renderProps) => {
        const redirectTo = {
          ...renderProps.location,
          pathname: renderProps.location.pathname.replace(from, to),
          hash: hash || renderProps.location.hash,
        }
        return <Redirect to={redirectTo} />
      }}
    />
  )
}
