import { createContext } from "react"

export type TRecentlyViewedPageType =
  | "User"
  | "Device"
  | "Home"
  | "Organization"
  | "Order"
  | "Home group"

export type TRecentlyViewedPagesStorage = {
  [key: string]: TRecentlyViewedPage[]
}

export type TRecentlyViewedPage = {
  title: string
  enitytId: string
  type: TRecentlyViewedPageType
  path: string
  timestamp: string
}

export type TAddRecentlyViewedPageArgs = Omit<TRecentlyViewedPage, "timestamp">

type TRecentlyViewedContext = {
  recentlyViewedPages: TRecentlyViewedPage[]
  addPage: (page: TAddRecentlyViewedPageArgs) => void
  clear: () => void
}

export const RecentlyViewedContext = createContext<TRecentlyViewedContext>({
  recentlyViewedPages: [],
  addPage(): void {},
  clear(): void {},
})
