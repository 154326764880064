import { useState } from "react"
import styled from "styled-components"

import { Divider } from "@material-ui/core"

import { MonitoringPopup } from "src/components/Homes/HomeDetails/MonitoringPopup"
import { HREF_MINUT_HELP_CROWD_DETECT } from "src/constants/hrefs"
import { Violations } from "src/data/homes/types/homeTypes"
import { useFetchOccupancyThresholdMapping } from "src/data/occupancy/queries/occupancyQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import HomeIcon from "src/ui/icons/home.svg"
import OccupancyOffOutlined from "src/ui/icons/occupancy-detection-off-outlined.svg"
import OccupancyOnOutlined from "src/ui/icons/occupancy-detection-outlined.svg"
import { QuantitySelector } from "src/ui/Input/QuantitySelector"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { BetaFeaturePill } from "src/ui/MBadge/BetaFeaturePill"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function OccupancyPopover({
  open,
  activeInitial,
  capacityInitial,
  anchorEl,
  violations,
  update,
  handleClose,
}: {
  open: boolean
  activeInitial: boolean
  capacityInitial: number | undefined
  threshold: number | undefined
  handleClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  anchorEl: any
  violations?: Violations[]
  update: ({
    active,
    capacity,
  }: {
    active?: boolean
    capacity?: number
  }) => void
}) {
  const { t } = useTranslate()
  const fetchOccupancyThresholdMapping = useFetchOccupancyThresholdMapping()
  const [capacity, setCapacity] = useState(capacityInitial || 0)
  const showAlert = !activeInitial && !capacityInitial && capacity < 1
  const calculatedThreshold =
    fetchOccupancyThresholdMapping.data?.[capacity]?.threshold

  const description = `${t(langKeys.crowd_detect_short_description)} ${t(
    langKeys.crowd_detect_battery_life_disclaimer
  )}`

  function onClose({ active }: { active?: boolean }) {
    if (active !== undefined || capacity !== capacityInitial) {
      // The idea here is to call update iff active is set to true or false, or the
      // capacity has been changed; that way we won't send a request if the user
      // just opens and then closes the popover
      update({ active, capacity })
    } else {
      handleClose()
    }
  }

  function updateCapacity(value: number) {
    setCapacity(value)
  }

  function OffIcon() {
    if (activeInitial) {
      return <OccupancyOffOutlined height="56" />
    }
    return (
      <OccupancyOffOutlined
        height="56"
        fill="#edf1f5"
        stroke={mColors.primary}
        strokeWidth="3"
      />
    )
  }

  function OnIcon() {
    if (!activeInitial) {
      return <OccupancyOnOutlined height="56" />
    }
    return (
      <OccupancyOnOutlined
        height="56"
        fill="#edf1f5"
        stroke={mColors.primary}
        strokeWidth="3"
      />
    )
  }

  return (
    <MonitoringPopup
      open={open}
      error={false}
      wide
      anchorEl={anchorEl}
      onClose={onClose}
      title={t(langKeys.crowd_detect_title)}
      description={description}
      warning={t(langKeys.update_your_plan)}
      violations={violations}
      buttons={[
        {
          icon: <OffIcon />,
          onClick: () => {
            onClose({ active: false })
          },
          label: t(langKeys.off),
          // disablePointerEvents: !activeInitial,
          id: "occupancyMonitoringOff",
        },
        {
          icon: <OnIcon />,
          onClick: () => {
            onClose({ active: true })
          },
          label: (
            <div>
              <div>{t(langKeys.on)}</div>
              <BetaFeaturePill />
            </div>
          ),
          disablePointerEvents: capacity < 1,
          disableOnWarning: true,
          id: "occupancyMonitoringOn",
        },
      ]}
      subtitle={
        <>
          <StyledHomeIcon width="24px" height="24px" />
          {t(langKeys.home_indoor_label)}
        </>
      }
    >
      {showAlert && (
        <MBanner type="info" style={{ marginTop: spacing.M }} fullWidth>
          {t(langKeys.crowd_detect_all_fields_not_set)}
        </MBanner>
      )}

      <SettingsBox>
        <Divider />
        <CapacityBox>
          <div>
            <MText variant="subtitleS">
              {t(langKeys.crowd_detect_capacity_title)}
            </MText>
            <MText variant="bodyS" color="tertiary">
              {t(langKeys.crowd_detect_capacity_short_description)}
            </MText>
          </div>
          <QuantitySelector
            value={capacity || "-"}
            onRemove={() => updateCapacity(capacity - 1)}
            onAdd={() => updateCapacity(capacity + 1)}
            disableRemove={capacity <= 1}
            disableAdd={capacity >= 50}
          />
        </CapacityBox>

        <Divider />

        <section>
          <MText variant="subtitleS">
            {t(langKeys.crowd_detect_device_threshold_title)}
          </MText>
          {!!calculatedThreshold ? (
            <MText variant="heading1">
              {fetchOccupancyThresholdMapping?.data?.[capacity]?.threshold}
            </MText>
          ) : (
            <NotSetBox>{t(langKeys.not_set)}</NotSetBox>
          )}

          <MText variant="bodyS" color="tertiary">
            {t(langKeys.crowd_detect_device_threshold_short_description)}
          </MText>
        </section>

        <ExternalLink href={HREF_MINUT_HELP_CROWD_DETECT}>
          {t(langKeys.crowd_detect_device_threshold_read_more)}
        </ExternalLink>
      </SettingsBox>
    </MonitoringPopup>
  )
}

const SettingsBox = styled.div`
  display: grid;
  margin-top: ${spacing.XL2};
  grid-gap: ${spacing.L};
`

const CapacityBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  margin-left: ${spacing.M};
  & > :first-child {
    flex: 1 1 200px;
  }
  & > :last-child {
    margin: auto;
  }
`

const NotSetBox = styled.div`
  font-size: 24px;
  color: ${mColors.neutral};
  margin: ${spacing.L} 0;
`

const StyledHomeIcon = styled(HomeIcon)`
  margin-right: ${spacing.XS};
`
