import { useCallback, useMemo } from "react"
// Using useNavigate so that useUrlParam may be used inside useRouter
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from "react-router-dom-v5-compat"

import { getUrlParamValue, setUrlParam } from "src/data/filters/filtersUrl"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import {
  asBoolean,
  asNumber,
  isBoolean,
  isNil,
  isNumber,
} from "src/utils/genericUtil"
import { debug } from "src/utils/logger"
import { Maybe } from "src/utils/tsUtil"

interface IUrlParamHook<T = string> {
  value: T | undefined
  pushValue: (v?: T) => void
  replaceValue: (v?: T) => void
}

/**
 * Custom hook to set and subscribe to URL params; see it as a useState for the
 * url. Setting a value to '', null or undefined will remove it from the URL.
 */
export function useUrlParam<ValueType extends string = string>(
  key: string,
  options?: {
    initialValue?: ValueType
    unique?: boolean
  }
): IUrlParamHook<ValueType> {
  const navigate = useNavigate()

  const location = useAppLocation()

  const { unique = true, initialValue } = options || {}

  const replaceValue = useCallback(
    (value: ValueType | undefined) => {
      const param: string | null = getUrlParamValue(key)

      debug.log(
        `[useUrlParams]: Attempting to set query param. Value: ${value} param: ${param}`
      )

      if (value !== param) {
        setUrlParam(
          key,
          value,
          (search) =>
            navigate(
              // we need to use window.location here, since it turns out that
              // the react router dom location doesn't update as we expected,
              // which made location contain outdated info.
              { ...window.location, search },
              { replace: true, state: location.state }
            ),
          { unique }
        )
      }
    },
    [key, unique, navigate, location.state]
  )

  const pushValue = useCallback(
    (value: ValueType | undefined) => {
      const param: string | null = getUrlParamValue(key)
      if (value !== param) {
        setUrlParam(
          key,
          value,
          (search) =>
            navigate({ ...window.location, search }, { state: location.state }),
          { unique }
        )
      }
    },
    [key, unique, navigate, location.state]
  )

  const value: ValueType | undefined = useMemo(() => {
    const param: Maybe<ValueType> = getUrlParamValue<ValueType>(
      key,
      location.search
    )
    if (!param) {
      return undefined
    }
    return param
  }, [key, location.search])

  /** If the hook is created with an initial value it should be set immediately */
  useEffectOnce(() => {
    if (initialValue != null) {
      replaceValue(initialValue)
    }
  })

  return { value, pushValue, replaceValue }
}

/**
 * Custom hook to set and subscribe to number URL params; see it as a useState
 * for the url. It will handle conversions of string => number for you.
 */
export function useUrlParamNumber(
  key: string,
  options?: {
    defaultVal?: number
    unique?: boolean
  }
): IUrlParamHook<number> {
  const _options = {
    unique: options?.unique,
    defaultVal: isNil(options?.defaultVal)
      ? undefined
      : String(options?.defaultVal),
  }
  const {
    value: _value,
    pushValue: _pushValue,
    replaceValue: _replaceValue,
  } = useUrlParam(key, _options)

  const pushValue = useCallback(
    (value: number | undefined) => {
      _pushValue(String(value))
    },
    [_pushValue]
  )

  const replaceValue = useCallback(
    (value: number | undefined) => {
      _replaceValue(String(value))
    },
    [_replaceValue]
  )

  const value: number | undefined = useMemo(() => {
    if (isNumber(_value)) {
      return asNumber(_value)
    }
    return undefined
  }, [_value])

  return { value, pushValue, replaceValue }
}

/**
 * Custom hook to set and subscribe to number URL params; see it as a useState
 * for the url. It will handle conversions of string => boolean for you.
 */
export function useUrlParamBoolean(
  key: string,
  options?: {
    defaultVal?: boolean
    unique?: boolean
  }
): IUrlParamHook<boolean> {
  const _options = {
    unique: options?.unique,
    defaultVal: isNil(options?.defaultVal)
      ? undefined
      : String(options?.defaultVal),
  }
  const {
    value: _value,
    pushValue: _pushValue,
    replaceValue: _replaceValue,
  } = useUrlParam(key, _options)

  const replaceValue = useCallback(
    (value: boolean | undefined) => {
      if (value === undefined) {
        _replaceValue()
      }
      _replaceValue(String(value))
    },
    [_replaceValue]
  )

  const pushValue = useCallback(
    (value: boolean | undefined) => {
      if (value === undefined) {
        _pushValue()
      }
      _pushValue(String(value))
    },
    [_pushValue]
  )

  const value: boolean | undefined = useMemo(() => {
    if (isBoolean(_value)) {
      return asBoolean(_value)
    }
    return undefined
  }, [_value])

  return { value, pushValue, replaceValue }
}
