import styled, { css } from "styled-components"

import { mColors } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

export const Divider = styled.hr<{ $margin?: spacing | 0 }>`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: ${mColors.divider};
  margin: ${(props) => props.$margin || spacing.XS} 0;
`

export const MDivider = Divider

export const MDividerCss = (spacing: spacing) => css`
  padding-bottom: ${() => spacing};
  border-bottom: 1px solid ${mColors.divider};
  margin-bottom: ${() => spacing};
`
