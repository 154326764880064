import { useCallback } from "react"
// eslint-disable-next-line no-restricted-imports
import { NavigateOptions, useNavigate } from "react-router-dom-v5-compat"

import { Location } from "history"

import { isStoredFeatureEnabled } from "src/components/Flags/flagContextUtil"
import { BackendLoginClient } from "src/constants/env"
import { FeatureFlag } from "src/constants/featureFlags"
import { useAppData } from "src/context/useAppData"
import { useAppLocation } from "src/hooks/useAppLocation"
import {
  ILocationState,
  MLocation,
  TPartialLocation,
} from "src/router/routeTypes"
import { locationWithOrgId, urlSplit } from "src/ui/Link/internalLinkUtil"
import { isString } from "src/utils/genericUtil"
import { Logger } from "src/utils/logger"

const routerLogger = new Logger({
  prefix: "[useRouter]",
  enabled: isStoredFeatureEnabled(FeatureFlag.LOGGING_ROUTER),
})
/**
 * A hook that uses useNavigate hook from react router v6 under the
 * hood. This is to get better typing and always setting a state when we move
 * around in our app.
 *
 */
export function useRouter() {
  const navigate = useNavigate()
  const location = useAppLocation()
  const { activeOrg } = useAppData()

  const stableNavigate = useCallback(mNavigate, [
    activeOrg?.id,
    location,
    navigate,
  ])

  function mNavigate(
    to: Partial<MLocation> | string,
    options?: { state?: ILocationState; orgIdOverride?: string } & Omit<
      NavigateOptions,
      "state"
    >
  ) {
    const state = options?.state ?? location?.state
    const from = purgeForbiddenPathNames(
      state?.from ?? formatLocationObject(location)
    )
    const newState: MLocation["state"] = { ...state, from }

    const partialLocation: Partial<MLocation> = isString(to)
      ? urlSplit(to)
      : { ...to }

    const orgId = options?.orgIdOverride ?? activeOrg?.id
    // Here we're injecting active org id as a query param:
    const path: Partial<MLocation> = locationWithOrgId({
      location: partialLocation,
      orgId,
    })
    const newOptions = { ...options, state: newState }
    routerLogger.log("Navigating to", path, newOptions)
    navigate(path, newOptions)
  }

  const stableGoBack = useCallback(goBack, [
    location.state,
    navigate,
    stableNavigate,
  ])
  /**
   * Check the state param to determine if we can navigate back without exiting
   * the webapp. If not, navigate to `defaultPath`.
   */
  function goBack({ defaultPath }: { defaultPath: TPartialLocation | string }) {
    const state = location.state

    if (state?.from) {
      return navigate(-1) // built-in browser go back in history
    }

    routerLogger.info("goBack: falling back to defaultPath", defaultPath)
    stableNavigate(defaultPath) // forward navigation fallback
  }

  return {
    navigate: stableNavigate,
    goBack: stableGoBack,
  }
}

function purgeForbiddenPathNames(
  from: ILocationState["from"]
): ILocationState["from"] {
  if (from?.pathname === BackendLoginClient.clientRedirectPath) {
    return { ...from, pathname: "" } // pathname forbidden => unset it
  }
  return from // OK
}

function formatLocationObject(
  location: Location<ILocationState | undefined>
): MLocation {
  return {
    pathname: location.pathname,
    search: location.search,
    hash: location.hash,
    key: location.key,
    state: location.state,
  }
}
