import {
  minimumGray,
  semanticEmergency,
  semanticGood,
  semanticWarning,
} from "src/ui/colors"
import BatteryUnknown from "src/ui/icons/battery-unknown.svg"
import Battery5 from "src/ui/icons/battery5.svg"
import Battery20 from "src/ui/icons/battery20.svg"
import Battery30 from "src/ui/icons/battery30.svg"
import Battery50 from "src/ui/icons/battery50.svg"
import Battery60 from "src/ui/icons/battery60.svg"
import Battery80 from "src/ui/icons/battery80.svg"
import Battery90 from "src/ui/icons/battery90.svg"
import Battery100 from "src/ui/icons/battery100.svg"
import NoBattery from "src/ui/icons/no-battery.svg"
import { SVGProps } from "src/utils/tsUtil"

export function BatteryIcon({
  percent,
  offline,
  ...props
}: {
  percent: number | undefined
  offline?: boolean
  className?: string
} & SVGProps) {
  if (percent === undefined) {
    return <BatteryUnknown width={24} {...props} />
  }

  return (
    <DeviceBatteryIndicator percent={percent} offline={offline} {...props} />
  )
}

function DeviceBatteryIndicator({
  percent,
  offline,
  ...props
}: { percent: number; offline?: boolean } & SVGProps) {
  const goodColor = offline ? minimumGray : semanticGood
  const warningColor = offline ? minimumGray : semanticWarning
  const emergencyColor = offline ? minimumGray : semanticEmergency

  if (percent >= 90) {
    return <Battery100 width={24} fill={goodColor} {...props} />
  } else if (percent >= 80) {
    return <Battery90 width={24} fill={goodColor} {...props} />
  } else if (percent >= 70) {
    return <Battery80 width={24} fill={goodColor} {...props} />
  } else if (percent >= 50) {
    return <Battery60 width={24} fill={goodColor} {...props} />
  } else if (percent >= 40) {
    return <Battery50 width={24} fill={goodColor} {...props} />
  } else if (percent >= 20) {
    return <Battery30 width={24} fill={goodColor} {...props} />
  } else if (percent >= 5) {
    return <Battery20 width={24} fill={warningColor} {...props} />
  } else if (percent >= 1) {
    return <Battery5 width={24} fill={emergencyColor} {...props} />
  } else return <NoBattery width={24} {...props} />
}
