import { useQueryClient } from "@tanstack/react-query"

import { ICustomer, ISubscription } from "src/components/Account/types"
import {
  TBillingHistoryQueryParams,
  TPlanRenewalEstimateBreakdownFilter,
} from "src/data/billing/types/billingTypes"

export const billingKeys = {
  /** Use to target *all* billing data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["billing"] as const
  },

  /** Use to target customer data in cache*/
  customer(id: string) {
    return [...this.all(), "customer", id] as const
  },

  /** Use to target available plas for user in cache */
  availablePlans(userId: string) {
    return [...this.all(), "availablePlans", userId] as const
  },

  /** Use to target all subscription data in the cache, currently we only hold
   * one subscription due to fetching it is a anonymous get */
  subscription() {
    return [...this.all(), "subscription"] as const
  },

  /** Use to target current subscription data in cache*/
  currentSubscription() {
    return [...this.all(), "current_subscription"] as const
  },

  /** Use to target payment source data in cache*/
  paymentSource(id?: string) {
    if (id) {
      return [...this.all(), "paymentSource", id] as const
    }

    return [...this.all(), "paymentSource"] as const
  },

  billingHistory(orgId: string, filter?: TBillingHistoryQueryParams) {
    if (filter) {
      return [...this.all(), "billingHistory", orgId, filter] as const
    }

    return [...this.all(), "billingHistory", orgId] as const
  },

  billingEvent(eventId: string) {
    return [...this.all(), "billingHistory", eventId] as const
  },

  planRenewalEstimate(userId: string) {
    return [...this.all(), "planRenewalEstimate", userId] as const
  },

  planRenewalEstimateBreakdown(
    userId: string,
    filter?: TPlanRenewalEstimateBreakdownFilter
  ) {
    if (filter) {
      return [
        ...this.all(),
        "planRenewalEstimate",
        userId,
        "breakdown",
        filter,
      ] as const
    }

    return [...this.all(), "planRenewalEstimate", userId, "breakdown"] as const
  },
}

export function useBillingCache() {
  const queryClient = useQueryClient()

  /**
   * Update customer with new data, e.g., after a PATCH.
   *
   * @param customerId: The customer id
   * @param updater: Updater function which to modify customer in cache
   */
  function updateCachedCustomer(
    customerId: string,
    updater: (customer: ICustomer | undefined) => ICustomer
  ) {
    queryClient.setQueryData<ICustomer>(
      billingKeys.customer(customerId),
      updater
    )
  }
  /**
   * Update subscription with new data, e.g., after a PATCH.
   *
   * @param subscriptionId: The subscription id
   * @param updater: Updater function which to modify customer in cache
   */
  function updateCachedSubscription(
    updater: (subscription: ISubscription | undefined) => ISubscription
  ) {
    queryClient.setQueryData<ISubscription>(billingKeys.subscription(), updater)
  }

  /**
   * Removes queries for cached payment source. Essentially clearing the cache and removing any queries that listened to them.
   */
  function removeCachedPaymentSource() {
    queryClient.removeQueries(billingKeys.paymentSource())
  }

  function invalidateCachedCustomer(customerId: string) {
    queryClient.invalidateQueries(billingKeys.customer(customerId))
  }

  return {
    invalidateCachedCustomer,
    updateCachedCustomer,
    updateCachedSubscription,
    removeCachedPaymentSource,
  }
}
