import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosInstance, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  profilesCache,
  TProfileItemCache,
  TProfileListCache,
} from "src/data/homeProfiles/queries/homeProfilesCache"
import {
  IProfileDeleteResponse,
  IProfileFetchFilter,
  IProfileQueryResponse,
  IProfileRequest,
  IProfileResponse,
} from "src/data/homeProfiles/types/homeProfilesTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const client: AxiosInstance = minutApiHttpClient
const profilesUrl = `${API_DEFAULT}/profiles`

export function useFetchProfiles({
  orgId,
  filter,
  options,
}: {
  orgId?: string
  filter?: IProfileFetchFilter
  options?: UseQueryOptions<
    IProfileQueryResponse,
    AxiosError,
    IProfileQueryResponse,
    TProfileListCache
  >
}) {
  const { limit, offset } = filter || {}

  async function fetchProfiles(): Promise<IProfileQueryResponse> {
    const response = await minutApiHttpClient.get<IProfileQueryResponse>(
      `${API_DEFAULT}/organizations/${orgId}/profiles`,
      { params: { limit, offset } }
    )
    return response.data
  }

  return useQuery(profilesCache.list(filter, orgId), fetchProfiles, {
    keepPreviousData: true,
    enabled: !!orgId,
    ...options,
  })
}

export function useFetchProfile<Select = IProfileResponse>(props: {
  id: string
  options?: UseQueryOptions<
    IProfileResponse,
    AxiosError,
    Select,
    TProfileItemCache
  >
}) {
  const { id, options } = props || {}
  async function fetchProfile(id: string): Promise<IProfileResponse> {
    const response = await client.get(`${profilesUrl}/${id}`)
    return response.data
  }

  return useQuery(profilesCache.item(id), () => fetchProfile(id), {
    ...options,
  })
}

export function usePostProfile() {
  const cache = useQueryClient()

  async function postProfile(
    config?: IProfileRequest
  ): Promise<IProfileResponse> {
    const response = await client.post(profilesUrl, {
      ...config,
      name: config?.name || String(new Date()),
    })
    return response.data
  }

  return useMutation(postProfile, {
    onSuccess: async (newProfile: IProfileResponse) => {
      cache.setQueryData(profilesCache.item(newProfile.id), newProfile)
      await Promise.all([cache.invalidateQueries(profilesCache.lists())])
    },
  })
}

export function useDeleteProfile() {
  const cache = useQueryClient()

  async function deleteProfile(id: string) {
    const url = `${profilesUrl}/${id}`
    return await client.delete<IProfileDeleteResponse>(url)
  }

  return useMutation(deleteProfile, {
    onSuccess: async (response, deletedId) => {
      cache.removeQueries(profilesCache.item(deletedId))
      await Promise.all([cache.invalidateQueries(profilesCache.lists())])
    },
  })
}

export function usePatchProfile() {
  const cache = useQueryClient()

  async function patchProfile(
    id: string,
    data: IProfileRequest
  ): Promise<IProfileResponse> {
    const url = `${profilesUrl}/${id}`
    const profileReq: AxiosResponse<IProfileResponse> = await client.patch(
      url,
      data
    )
    return profileReq.data
  }

  return useMutation(
    ({ id, data }: { id: string; data: IProfileRequest }) =>
      patchProfile(id, data),
    {
      onSuccess: async (data) => {
        return await Promise.all([
          cache.invalidateQueries(profilesCache.lists()),
          cache.invalidateQueries(profilesCache.item(data.id)),
        ])
      },
    }
  )
}

export function usePatchFireCOAutomatedTests() {
  const cache = useQueryClient()

  async function pathFireCoAutomatedTests({
    profileId,
    enabled,
  }: {
    profileId: string
    enabled: boolean
  }) {
    const url = `${profilesUrl}/${profileId}/fire_and_co_alarm`
    const profileReq = await client.patch<IProfileResponse>(url, {
      automated_testing: !!enabled,
    })
    return profileReq.data
  }

  return useMutation({
    mutationFn: pathFireCoAutomatedTests,
    onSuccess: async (data) => {
      return await Promise.all([
        cache.invalidateQueries(profilesCache.lists()),
        cache.invalidateQueries(profilesCache.item(data.id)),
      ])
    },
  })
}
