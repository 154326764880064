import { useQueryClient } from "@tanstack/react-query"

import { TUser } from "src/data/user/user"

export const userKeys = {
  /** Use to target *all* user data in the cache, with fuzzy matching; matching this
   * way is ineffective and should be used with care */
  all() {
    return ["user"] as const
  },

  /** Use to target specific user data in cache */
  user(id: string) {
    return [...this.all(), id] as const
  },

  invitations(userId: string) {
    return [...this.user(userId), "invitations"] as const
  },

  invitation(userId: string, invitationCode: string) {
    return [...this.user(userId), "invitation", invitationCode] as const
  },

  invitationInfo(inviteCode: string) {
    return [...this.all(), "invitation-info", inviteCode] as const
  },
}

export function useUserCache() {
  const queryClient = useQueryClient()

  function updateCachedUser(
    userId: string,
    updater: (user: TUser | undefined) => TUser | undefined
  ) {
    queryClient.setQueryData<TUser>(userKeys.user(userId), updater)
  }

  return {
    updateCachedUser,
  }
}
