export enum AccessLevelRank {
  RESPONDER,
  MEMBER,
  ADMIN,
  OWNER,
}

/**
 * Mapping between features and subscription levels.
 *
 * # Owner
 *
 *    - Transfer home ownership
 *    - Delete home
 *    - Pays for the home plan
 *    - Remove members
 *    - Make members admins
 *    - Change all configuration
 *
 * # Admin (Part of the team group, previously family)
 *
 *    - Change all configuration
 *    - Invite other members
 *
 * ### Member (Part of the team group, previously family)
 *
 *    - See all data in a home
 *    - Toggle security alarm
 *    - Access chat groups
 */
export enum RestrictedFeature {
  // Min-level 'OWNER'
  TRANSFER_HOME_OWNERSHIP = "transfer home ownership",
  DELETE_HOME = "delete home",
  PAY_FOR_PLAN = "pay for home plan",
  REMOVE_MEMBERS = "remove members",
  MAKE_ADMIN = "make admin",
  EDIT_HOME = "min-level owner",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OWNER_FEATURE = "min-level owner",

  // Min-level 'ADMIN'
  CHANGE_CONFIG = "change_config",
  INVITE_MEMBERS = "invite members",
  ADMIN_FEATURE = "min-level admin",

  // Min-level 'MEMBER'
  VIEW_ALL_HOME_DATA = "view all home data",
  TOGGLE_SECURITY = "toggle security",
  ACCESS_CHAT_GROUPS = "access chat groups",
}
